import "./App.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAccount, useDisconnect } from "wagmi";
import { AddressZero } from "@ethersproject/constants";

import { setAuth } from "./store/auth/authSlice";
import Registration from "./Components/Auth/Registration";
import User from "./Components/Auth/User";
import Org from "./Components/Auth/Org";
import OrgType from "./Components/Auth/OrgType";
import Login from "./Components/Auth/Login";
import { PrivateRoute } from "./Components/PrivateRoute/PrivateRoute";
import Intervention from "./Components/Intervention/Intervention";
import AddEditIntervention from "./Components/Intervention/AddEditIntervention";
import Methods from "./Components/Intervention/Methods";
import Asset from "./Components/Intervention/Asset";
import AddEditAsset from "./Components/Intervention/AddEditAsset";
import Yield from "./Components/Intervention/Yield";
import DocType from "./Components/Document/DocType";
import Entitlements from "./Components/Intervention/Entitlements";

import jwt_decode from "jwt-decode";
import "antd/dist/antd.css";
import { Tabs, Layout, Menu, message, Typography } from "antd";
import {
  SafetyCertificateOutlined,
  UnlockOutlined,
  LoginOutlined,
  SettingOutlined,
  PoweroffOutlined,
  UserAddOutlined,
  FileProtectOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import ViewDocuments from "./Components/Document/ViewDocuments";
import CertificateView from "./Components/Intervention/CertificateView";
import ViewYields from "./Components/Intervention/viewYields";

import "@rainbow-me/rainbowkit/styles.css";

import { authApi } from "./store/auth/authapi";
import { groupApi } from "./store/auth/groupapi";
import { orgApi } from "./store/auth/orgapi";
import { orgtypeApi } from "./store/auth/orgtypeapi";
import { userApi } from "./store/auth/userapi";
import { doctypeApi } from "./store/ds/doctypeapi";
import { documentAccessApi } from "./store/ds/documentaccessapi";
import { documentApi } from "./store/ds/documentapi";
import { assetApi } from "./store/is/assetapi";
import { assignmentApi } from "./store/is/assignmentapi";
import { interventionApi } from "./store/is/interventionapi";
import { methodsApi } from "./store/is/methodsapi";
import { paramsApi } from "./store/is/paramsapi";
import { verificationApi } from "./store/is/verificationapi";
import { finaltokApi } from "./store/is/finaltokapi";
import { yieldApi } from "./store/is/yieldapi";
import { validationApi } from "./store/is/validationapi";
import { transactionApi } from "./store/is/transactionapi";
import { setAsset, setIntervention, setYield } from "./store/is/isSlice";
import { CustomConnectButton } from "./Components/Utils/CustomConnectButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import { isAddressesSame } from "./bc-client/BCUtil";
import ViewAssets from "./Components/Intervention/ViewAssets";
import ContactAdmin from "./Components/Intervention/ContactAdmin";
import { entitlementCreditBundleApi } from "./store/is/entitlementCreditBundleApi";

//notificaiton

const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;

function App() {
  const history = useHistory();

  // //notificaiton starts::
  const [initialRender, setInitialRender] = useState(true);
  let userid = useSelector((state) => state.auth.userid);
  let userBAddress = useSelector((state) => state.auth.baddress);
  const userOrgName = useSelector((state) => state.auth?.org?.name);
  const userGroupName = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );
  let auth = useSelector((state) => state.auth);
  //captures Bc address when login
  const { address = "0x", isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  //notificaiotn ends::
  let location = useLocation();
  const [current, setCurrent] = React.useState("");
  const [colapsed, setCollapsed] = useState(!(location.pathname === "/iv"));

  let token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  if (token === "") {
    const jwtFromStorage = localStorage.getItem("jwt");
    if (jwtFromStorage) {
      token = jwtFromStorage;
      token = token.replace("Bearer ", "");
      const decoded = jwt_decode(token);

      let expired = decoded.exp < (Date.now() - 1000 * 60 * 5) / 1000;
      if (expired) {
        //TODO: refresh token incase expired

        //logout if expired
        const authObj = {
          token: "",
          userid: "",
          baddress: "",
          groups: [],
          org: {},
        };
        dispatch(setAuth(authObj));
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
      }

      const authObj = {
        token: token,
        userid: decoded.userId,
        baddress: JSON.parse(localStorage.getItem("user"))?.baddress,
        groups: JSON.parse(localStorage.getItem("user"))?.group,
        org: JSON.parse(localStorage.getItem("user"))?.org,
      };
      dispatch(setAuth(authObj));
    }
  }

  const firstName = JSON.parse(localStorage.getItem("user"))?.fname;
  const lastName = JSON.parse(localStorage.getItem("user"))?.lname;
  const useOwnWallet = JSON.parse(localStorage.getItem("user"))?.useownwallet;

  useEffect(() => {
    if (token) {
      const timer = setTimeout(() => {
        setInitialRender(false);
      }, 1);
      return () => clearTimeout(timer);
    } else {
      setInitialRender(true);
    }
  }, [token]);

  useEffect(() => {
    if (userid === "") return;
    if (
      userBAddress === undefined ||
      userBAddress === AddressZero ||
      userBAddress === ""
    )
      return;
    if (
      isConnected &&
      useOwnWallet &&
      !isAddressesSame(address, userBAddress)
    ) {
      message.error({
        content: `This is not your registered blockchain Address. Please switch to ${userBAddress}`,
        key: "updatable",
        duration: 5,
      });
      disconnect();
    }
  }, [address, userBAddress, disconnect, isConnected, userid]);

  const allMenuItems = [
    {
      icon: <HomeOutlined />,
      key: "intervention",
      label: "GHG Project",
      privid: "l1_intervention",
      children: [
        {
          label: <Link to="/intervention/list">GHG Project</Link>,
          key: "intervention",
          privid: "l2_intervention",
        },
      ],
    },
    {
      icon: <FileProtectOutlined />,
      key: "documents",
      label: "Documents",
      privid: "l1_documents",
      children: [
        {
          label: <Link to="/documents">Documents</Link>,
          key: "documents",
          privid: "l2_documents",
        },
      ],
    },
    {
      icon: <SettingOutlined />,
      key: "configuration",
      label: "Configuration",
      privid: "l1_configuration",
      children: [
        {
          label: <Link to="/method">Methodology</Link>,
          key: "methods",
          privid: "l2_configuration_methods",
        },
        {
          label: <Link to="/doctype">Document Type</Link>,
          key: "doctype",
          privid: "l2_configuration_doctype",
        },
      ],
    },

    {
      label: "Administration",
      icon: <UnlockOutlined />,
      key: "admin",
      privid: "l1_administration",
      children: [
        {
          label: <Link to="/user">User</Link>,
          key: "user",
          privid: "l2_administration_user",
        },
        {
          label: <Link to="/org">Organization</Link>,
          key: "org",
          privid: "l2_administration_org",
        },
        {
          label: <Link to="/orgtype">Organization Type</Link>,
          key: "orgtype",
          privid: "l2_administration_orgtype",
        },
      ],
    },
    {
      label: "Certificates",
      icon: <SafetyCertificateOutlined />,
      key: "certificateview",
      privid: "l1_certificatesview",
      children: [
        {
          label: <Link to="/certificateview">Credits</Link>,
          key: "certificatesview",
          privid: "l2_certificatesview",
        },
        {
          label: <Link to="/intervention/entitlements">Pre-Credits</Link>,
          key: "entitlements",
          privid: "l2_certificatesview_precredits",
        },
      ],
    },
    {
      label:
        userBAddress === undefined ||
        userBAddress === AddressZero ||
        userBAddress === "" ? (
          <></>
        ) : (
          <CustomConnectButton />
        ),
      key: "bcbutton",
      privid: "default",
      children: [],
      style: { marginLeft: "auto" },
    },
    {
      label: <span onClick={() => history.push("/login")}>Login</span>,
      icon: <LoginOutlined onClick={() => history.push("/login")} />,
      key: "login",
      privid: "na",
      children: [],
      style: { marginLeft: "auto" },
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "60px",
            lineHeight: "20px",
          }}
        >
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text
                style={{ color: "inherit" }}
              >{`Welcome, ${firstName} ${lastName}`}</Typography.Text>
              {userOrgName && (
                <Typography.Text
                  style={{
                    color: "inherit",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  ({userOrgName})
                </Typography.Text>
              )}
            </div>
          </div>
        </div>
      ),
      key: "username",
      privid: "default",
      className: "user-name-text",
      style: {
        fontSize: "1rem",
        cursor: "not-allowed",
        pointerEvents: "none",
        ...(!(
          userGroupName?.includes("Certificate Access") &&
          userGroupName?.length === 1 &&
          useOwnWallet
        )
          ? { marginLeft: "auto" }
          : {}),
      },
    },
    {
      label: "Logout",
      icon: <PoweroffOutlined />,
      key: "logout",
      privid: "default",
    },
    {
      label: <span onClick={() => history.push("/register")}>Register</span>,
      icon: <UserAddOutlined onClick={() => history.push("/register")} />,
      key: "register",
      privid: "na",
      children: [],
    },
  ];

  let menuPrivIds = [];
  menuPrivIds.push("default");

  let usergroups = [];
  usergroups = useSelector((state) => state.auth.groups);

  if (usergroups && usergroups.length > 0) {
    usergroups.forEach((group) => {
      if (group) {
        group?.privileges.forEach((priv) => {
          if (priv) {
            menuPrivIds.push(priv.name);
          }
        });
      }
    });
  }
  var menuItemsToLoad = [];

  token
    ? allMenuItems.forEach((e1) =>
        menuPrivIds.forEach((e2) => {
          if (e1.privid === e2) {
            if (e1.children) {
              var childs = [];
              e1.children.forEach((c1) =>
                menuPrivIds.forEach((c2) => {
                  if (c1.privid === c2) {
                    childs.push(c1);
                  }
                })
              );
              childs = [...new Set(childs)];
              e1.children = childs;
            }
            if (
              e1.privid === "default" &&
              e1.key === "bcbutton" &&
              userGroupName?.includes("Certificate Access") &&
              userGroupName?.length === 1 &&
              useOwnWallet
            ) {
              menuItemsToLoad.push(e1);
            } else if (e1.key !== "bcbutton") {
              menuItemsToLoad.push(e1);
            }
          }
        })
      )
    : allMenuItems.forEach((e1) => {
        if (e1.privid === "na") {
          menuItemsToLoad.push(e1);
        }
      });

  //remove duplciates
  let uniqueMenuItemsToLoad = [...new Set(menuItemsToLoad)];

  // invalidates the cache of the apis
  const invalidateApiCache = () => {
    //auth
    dispatch(authApi.util.resetApiState());
    dispatch(groupApi.util.resetApiState());
    dispatch(orgApi.util.resetApiState());
    dispatch(orgtypeApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());

    //ds
    dispatch(doctypeApi.util.resetApiState());
    dispatch(documentAccessApi.util.resetApiState());
    dispatch(documentApi.util.resetApiState());

    //is
    dispatch(assetApi.util.resetApiState());
    dispatch(assignmentApi.util.resetApiState());
    dispatch(interventionApi.util.resetApiState());
    dispatch(methodsApi.util.resetApiState());
    dispatch(paramsApi.util.resetApiState());
    dispatch(verificationApi.util.resetApiState());
    dispatch(yieldApi.util.resetApiState());
    dispatch(validationApi.util.resetApiState());
    dispatch(transactionApi.util.resetApiState());
    dispatch(finaltokApi.util.resetApiState());
    dispatch(entitlementCreditBundleApi).util.resetApiState();

    //reset Slice
    dispatch(setAsset(null));
    dispatch(setIntervention(null));
    dispatch(setYield(null));
  };

  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
    if (e.key === "logout") {
      setCollapsed(true);
      const authObj = {
        token: "",
        userid: "",
        baddress: "",
        groups: [],
        org: {},
      };
      dispatch(setAuth(authObj));
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");

      // remove all cache when logging out
      invalidateApiCache();
    }

    if (e.key === "login") {
      history.push("/login");
    }

    if (e.key === "register") {
      history.push("/register");
    }
  };

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: "#123B2E",
        }}
      >
        <div className="logo">
          <img src="/negative_logo.png" alt="logo" style={{ height: 36 }} />
        </div>

        {initialRender ? (
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            theme="dark"
            mode="horizontal"
            items={uniqueMenuItemsToLoad}
            style={{ backgroundColor: "#123B2E" }}
            openKeys={[]}
          />
        ) : (
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            theme="dark"
            mode="horizontal"
            items={uniqueMenuItemsToLoad}
            style={{ backgroundColor: "#123B2E" }}
          />
        )}

        {/* {isConnected && (
          <button style={{marginTop:24}} className="button">
            Mint
         </button>
         )} */}
      </Header>

      <Layout>
        <Content
          className="site-layout"
          style={{
            padding: "0 10px",
            marginTop: 58,
            backgroundColor: location.pathname === "/" ? "#ffffff" : "#f2f4f5",
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 500,
              backgroundColor:
                location.pathname === "/" ? "#ffffff" : "#f2f4f5",
            }}
          >
            <Switch>
              {/* TODO: DO this in a more elegant way */}
              <Route exact path="/">
                {!token ? (
                  <Login />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: location },
                    }}
                  />
                )}
              </Route>

              <Route path="/login">
                {!token ? (
                  <Login />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/intervention/list",
                      state: { from: location },
                    }}
                  />
                )}
              </Route>
              <Route path="/register">
                {!token ? (
                  <Registration />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/intervention",
                      state: { from: location },
                    }}
                  />
                )}
              </Route>
              <Route path="/forgot-password">
                {!token ? (
                  <ForgotPassword />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/intervention/list",
                      state: { from: location },
                    }}
                  />
                )}
              </Route>
              <Route path="/reset-password/:token">
                {!token ? (
                  <ResetPassword />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/intervention/list",
                      state: { from: location },
                    }}
                  />
                )}
              </Route>
              <PrivateRoute path="/user">
                <User />
              </PrivateRoute>
              <PrivateRoute path="/org">
                <Org />
              </PrivateRoute>
              <PrivateRoute path="/orgtype">
                <OrgType />
              </PrivateRoute>
              <PrivateRoute path="/intervention/list">
                <Intervention />
              </PrivateRoute>
              <PrivateRoute path="/intervention/entitlements">
                <Entitlements />
              </PrivateRoute>
              <PrivateRoute path="/contact-admin">
                <ContactAdmin />
              </PrivateRoute>
              <PrivateRoute path="/documents">
                <ViewDocuments />
              </PrivateRoute>
              <PrivateRoute path="/intervention/:id">
                <AddEditIntervention />
              </PrivateRoute>
              <PrivateRoute path="/inter/yields/view">
                <ViewYields />
              </PrivateRoute>
              <PrivateRoute path="/inter/assets/view">
                <ViewAssets />
              </PrivateRoute>
              <PrivateRoute path="/asset/list">
                <Asset />
              </PrivateRoute>
              <PrivateRoute path="/asset/:id">
                <AddEditAsset />
              </PrivateRoute>
              <PrivateRoute path="/asset/yield">
                <Yield />
              </PrivateRoute>
              <PrivateRoute path="/method">
                <Methods />
              </PrivateRoute>
              <PrivateRoute path="/doctype">
                <DocType />
              </PrivateRoute>
              <PrivateRoute path="/certificateview">
                <CertificateView />
              </PrivateRoute>

              <Route path="*">
                <h1>404 Page not found</h1>
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
