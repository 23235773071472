import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAddUserMutation } from "../../store/auth/authapi";
import CryptoJS from 'crypto-js';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  Button,
  message,
  PageHeader,
  Card,
  Checkbox,
} from "antd";

import { EditOutlined } from "@ant-design/icons";

import { useAccount, useDisconnect, useSignMessage } from "wagmi";

import { verifyMessage } from "ethers/lib/utils.js";

import { AddressZero } from "@ethersproject/constants";
import { CustomConnectButton } from "../Utils/CustomConnectButton";
import { REACT_APP_CRYPTO_SECRET_KEY } from "../../config/config";

function Registration() {
  const [addUser] = useAddUserMutation();
  const [bAddress, setBAddress] = useState("");

  const { isConnected, isConnecting } = useAccount();
  const [isRegistered, setIsRegistered] = useState(false);
  const [useOwnWallet, setUseOwnWallet] = useState(false);
  const { disconnect } = useDisconnect();
  const history = useHistory();

  const messageToSign = `Welcome to Proba!\n\nYou need to sign this message to verify your blockchain address.\n\n${new Date().toDateString()}`;

  const {
    data: signedMessage,
    isLoading: isSigning,
    isSuccess: isSigned,
    reset,
    signMessage,
  } = useSignMessage({
    message: messageToSign,
  });

  const userAddress = isSigned ? verifyMessage(messageToSign, signedMessage).toLowerCase() : AddressZero;

  const key = "updatable";

  const [form] = Form.useForm();
  const onFinish = (values) => {
    message.loading({
      content: "Logging in.....",
      key,
    });

    handleAddUser(values);
  };

  const encryptPassword = (password) => {
    return CryptoJS.AES.encrypt(password, REACT_APP_CRYPTO_SECRET_KEY).toString();
  };

  const handleAddUser = async (user) => {
    
    try {
      const finalUserAddress = user.useownwallet ? userAddress : AddressZero;
      const encryptedPassword = encryptPassword(user.password);
      const encryptedconfirmPassword =encryptPassword(user.confirmPassword)
      
      await addUser({ ...user, password:encryptedPassword, confirmPassword: encryptedconfirmPassword,baddress: finalUserAddress })
        .unwrap()
        .then((res) => {
          message.success({
            content: "User Registered successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          setIsRegistered(true);
        })
        .catch((err) => {
          message.error({
            content: "User Registration Failed!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckboxChange = (e) => {
    setUseOwnWallet(e.target.checked);
    if (!e.target.checked) {
      if (isConnected) {
        disconnect();
      }
    }
  };

  const loginPageRedirect = () => {
    history.push(`/login`);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!isRegistered ? (
        <Card className="shadow-card" style={{ width: "35vw", marginTop: 20 }}>
          <Row>
            <Col span={2} />
            <Col span={20}>
              <h1
                style={{ fontSize: 36, textAlign: "center", fontWeight: 600 }}
              >
                User Registration
              </h1>
              <br></br>
              <div>
                <Form
                  layout="vertical"
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item
                    name="fname"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input First name!",
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lname"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Last name!",
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                        {
                          min: 8,
                          message: "Password must be at least 8 characters long!",
                        },
                      ]}
                      hasFeedback
                    style={{ marginBottom: 10 }}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input.Password />
                  </Form.Item>

                  <div
                    style={{ marginBottom: 10 }}
                  >
                    <div>
                      <Form.Item name={"useownwallet"} valuePropName="checked">
                      <Checkbox onChange={handleCheckboxChange}>
                        Use your own Blockchain Wallet
                      </Checkbox>
                      </Form.Item>
                      {useOwnWallet && (
                        <div>
                          <p
                            style={{ marginBottom: "8px", color: "lightgray" }}
                          >
                            {isConnected
                              ? "You are currently using your own connected wallet for transactions."
                              : "Buyers can opt to use their personal blockchain wallet or the organization's wallet for transactions."}
                          </p>
                          {!isConnected ? (
                            <CustomConnectButton />
                          ) : (
                            <Button
                              loading={isSigning}
                              type="dashed"
                              icon={<EditOutlined />}
                              disabled={isSigned}
                              onClick={() => signMessage()}
                            >
                              {isSigned ? `${userAddress}` : "Sign from Wallet"}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <br></br>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Register
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col span={2} />
          </Row>
        </Card>
      ) : (
        <Card className="shadow-card" style={{ width: "45vw", marginTop: 20 }}>
          <Row>
            <Col span={2} />
            <Col span={20}>
              <h1
                style={{ fontSize: 36, textAlign: "center", fontWeight: 600 }}
              >
                Registration Successful
              </h1>
              <br />
              <p style={{ fontSize: 16, textAlign: "center" }}>
                Thank you for registering! Here are your next steps:
              </p>
              <div>
                <ol style={{ fontSize: 16 }}>
                  <li>
                    Notify your organization administrator about your registration.
                  </li>
                  <li>
                    Request the administrator to assign you to the necessary groups and permissions.
                  </li>
                  <li>
                    Access your account by visiting the login page: <a onClick={loginPageRedirect}>Login Page</a>.
                  </li>
                </ol>
              </div>
            </Col>
            <Col span={2} />
          </Row>
        </Card>
      )}
    </div>
  );
}

export default Registration;
