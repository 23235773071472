import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Collapse, Modal, Space, Typography, message } from "antd";
import { useUpdateAssetStatusMutation } from "../../../store/is/assetapi";

export default function PerformOperationaCheckOnAsset({ isCreate, closeModal, assetDetails, assetIds, usergroup, handleDispatchPerformOperationalCheck }) {
    const key = "updatable";

    const [openPerformOperationalCheckOnAssetModal, setPerformOperationalCheckOnAssetModal] = useState(false);
    const [ConfirmLoadingPerformOperationalCheckOnAsset, setConfirmLoadingPerformOperationalCheckOnAsset] = useState(false);

    const [updateAssetStatus] = useUpdateAssetStatusMutation();

    useEffect(() => {
        if (isCreate) {
            setPerformOperationalCheckOnAssetModal(true);
        }
    }, [isCreate]);

    const handleMakingAssetActive = async () => {
        let assetUpdateResponse;
      
        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;
      
        try {
          if (!usergroup.some((group) => group === "Proba Operator")) {
                message.error({
                content: "Action Prohibited",
                key,
                duration: 6,
                });
            } 
            else if (isArrayWithMultipleEntries) {
                const assetStatusUpdate = {};
                assetStatusUpdate["_ids"] = assetIds;
                assetStatusUpdate["status"] = "Active";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
                setPerformOperationalCheckOnAssetModal(false);
            }
            else {
                const assetStatusUpdate = { ...assetDetails };
                assetStatusUpdate["_ids"] = [assetStatusUpdate?.["_id"]];
                assetStatusUpdate["status"] = "Active";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
                setPerformOperationalCheckOnAssetModal(false);
            }
        } catch (error) {
          console.error("Error updating asset:", error);
        } finally {
          setConfirmLoadingPerformOperationalCheckOnAsset(false);
        }
    };

    const handleSubmittingAssetForValidation = async () => {
        let assetUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;
        
        try {
            if (!usergroup.some(group => group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } 
            else if (isArrayWithMultipleEntries) {
                const assetStatusUpdate = {};
                assetStatusUpdate["_ids"] = assetIds;
                assetStatusUpdate["status"] = "Validator Review";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            }
            else {
                const assetStatusUpdate = { ...assetDetails };
                assetStatusUpdate["_ids"] = [assetStatusUpdate?.["_id"]];
                assetStatusUpdate["status"] = "Validator Review";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnAsset(false);
        }
    };

    const handleMakingAssetRevision = async () => {
        let assetUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;

        try {
            if (!usergroup.includes("Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } 
            else if (isArrayWithMultipleEntries) {
                const assetStatusUpdate = {};
                assetStatusUpdate["_ids"] = assetIds;
                assetStatusUpdate["status"] = "Revision";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            }
            else {
                const assetstatusupdate = { ...assetDetails };
                assetstatusupdate["_ids"] = [assetstatusupdate?.["_id"]];
                assetstatusupdate["status"] = "Revision";
                assetUpdateResponse = await handleAssetUpdateStatus(assetstatusupdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnAsset(false);
        }
    };

    const handleCancelofPerformOperationalModal = () => {
        setPerformOperationalCheckOnAssetModal(false);
        closeModal();
    };

    const handleAssetUpdateStatus = async (record) => {
        try {
            const payload = {
                body: record,
            };
            const res = await updateAssetStatus(payload)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "Asset updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchPerformOperationalCheck(res);
                    setPerformOperationalCheckOnAssetModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update Asset status: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitForPerformOperationalCheckOnAssetModal
                            visible={isCreate}
                            openPerformOperationalCheckOnAssetModal={openPerformOperationalCheckOnAssetModal}
                            handleMakingAssetActive={handleMakingAssetActive}
                            handleSubmittingAssetForValidation={handleSubmittingAssetForValidation}
                            handleMakingAssetRevision={handleMakingAssetRevision}
                            ConfirmLoadingPerformOperationalCheckOnAsset={ConfirmLoadingPerformOperationalCheckOnAsset}
                            handleCancelofPerformOperationalModal={handleCancelofPerformOperationalModal}
                            closeModal={closeModal}
                            onCancel={() => {
                                closeModal();
                            }}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const isValidationDisabled = false
const SubmitForPerformOperationalCheckOnAssetModal = ({
    visible,
    openPerformOperationalCheckOnAssetModal,
    handleMakingAssetActive,
    handleSubmittingAssetForValidation,
    handleMakingAssetRevision,
    ConfirmLoadingPerformOperationalCheckOnAsset,
    handleCancelofPerformOperationalModal
}) => {
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    const renderPerformOperationalCheckOnAssetPanelContent = () => {
        return null;
    };


    return (
        <Modal
            title="Operational check"
            visible={openPerformOperationalCheckOnAssetModal}
            onOk={handleMakingAssetActive}
            okText="Operational"
            confirmLoading={ConfirmLoadingPerformOperationalCheckOnAsset}
            onCancel={handleCancelofPerformOperationalModal}
            footer={[
                <Button onClick={handleMakingAssetRevision} style={{ color: 'red' }}>
                    Revision
                </Button>,
                <Button type="primary" onClick={handleMakingAssetActive} loading={ConfirmLoadingPerformOperationalCheckOnAsset}>
                    Activate
                </Button>,
                <Button type="primary" onClick={handleSubmittingAssetForValidation} loading={ConfirmLoadingPerformOperationalCheckOnAsset}>
                    Submit for Validation
                </Button>,

            ]}
        >
            {isValidationDisabled === false ? (
                <p>The Asset is ready for the Active/Revision/Validation. Do you want to submit for Active/Revision/Validation?</p>
            ) : (
                <>
                    <p>Please fix the following errors to make the asset ready.</p>

                    <Collapse defaultActiveKey={['Operation Check']}>
                        {renderPerformOperationalCheckOnAssetPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Operational Check</Text>
                                        {renderPerformOperationalCheckOnAssetPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderPerformOperationalCheckOnAssetPanelContent()}
                            </Panel>
                        )}

                    </Collapse>
                </>
            )}
        </Modal>
    );
};