import React, { useState } from "react";
import { Tooltip, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const TruncateWithTooltip = ({ text }) => {
  const [copiedText, setCopiedText] = useState(null);

  const truncateString = (str, maxLength) => {
    if (str?.length <= maxLength) return str;
    const half = Math.floor(maxLength / 2);
    return `${str?.slice(0, half)}...${str?.slice(-half)}`
  };

  return (
    <Tooltip
      title={
        <div
          style={{ display: "flex", flexDirection: "column", color: "#333" }}
        >
          {text}
          <Button
            style={{ marginTop: 8 }}
            icon={<CopyOutlined />}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(text);
              setCopiedText(text);
              setTimeout(() => setCopiedText(null), 3000);
            }}
            type="link"
          >
            {copiedText === text ? "Copied!" : "Copy"}
          </Button>
        </div>
      }
      color="#dadada"
      overlayStyle={{
        maxWidth: 300,
        padding: 12,
      }}
      mouseEnterDelay={0}
      destroyTooltipOnHide={true}
    >
      <span style={{ cursor: "pointer", fontSize:"16px" }}>{truncateString(text, 26)}</span>
    </Tooltip>
  );
};

export default TruncateWithTooltip;
