import { ContactsOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Statistic,
  Typography,
  message,
} from "antd";
import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import { createAssetsAction } from "../../constants/actionNames";
import { useGetOrgQuery } from "../../store/auth/orgapi";
import {
  assetApi,
  useAddAssetMutation,
  useAddDocumentToAssetMutation,
  useUpdateAssetMutation,
  useUpdateAssetStatusMutation,
} from "../../store/is/assetapi";
import { useAddAssignmentMutation } from "../../store/is/assignmentapi";
import { useGetInterventionsQuery } from "../../store/is/interventionapi";
import { setAsset } from "../../store/is/isSlice";
import {
  useDeleteParamsMutation,
  useGetParamQuery,
} from "../../store/is/paramsapi";
import { useGetValidationQuery } from "../../store/is/validationapi.js";
import { yieldApi } from "../../store/is/yieldapi";
import { DisplayDateTime } from "../Utils/DisplayDateTime";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import AddDocument from "./AddDocument";
import DisplayDocuments from "./DisplayDocuments";
import Params from "./Params.js";
import AssignVerifier from "./Utils/AssignVerifier";
import { DisplayIntervention } from "./Utils/DisplayIntervention";
import { ParamView } from "./Utils/ParamView";
import PerformOperationaCheckOnAsset from "./Utils/PerformOperationaCheckOnAsset.js";
import PerformValidationOnAsset from "./Utils/PerformValidationOnAsset.js";
import ViewDownloadCertificate from "./ViewDownloadCertificate.js";
import Yield from "./Yield";

const statusOptions = [
  { value: "New", label: "New" },
  { value: "Proba Review", label: "Proba Review" },
  { value: "Public Review", label: "Public Review" },
  { value: "Validator Review", label: "Validator Review" },
  { value: "Revision", label: "Revision" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Verified Quality", label: "Verified Quality" },
  { value: "Verified Quantity", label: "Verified Quantity" },
  { value: "verified", label: "Verified" },
  { value: "Reserved", label: "Reserved" },
  { value: "InClaim", label: "In Claim" },
  { value: "Booked", label: "Booked" },
  { value: "ProvDeleted", label: "Prov Deleted" },
  { value: "Invisible", label: "Invisible" },
  { value: "Final", label: "Final" },
];

function AddEditAsset() {
  const [spinning, setSpinning] = useState(false);
  const [updateAssetStatus] = useUpdateAssetStatusMutation();

  let userid = useSelector((state) => state.auth.userid);
  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );
  const orgId = useSelector((state) => state.auth?.org?._id);

  usergroup = usergroup.filter(Boolean);

  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  let asset = useSelector((state) => state.is.asset);
  let intervention = useSelector((state) => state.is.intervention);
  if (id !== "add" && !intervention?.name) {
    history.push({
      pathname: `/intervention/list`,
    });
  }
  if (id !== "add" && !asset?.name) {
    history.push({
      pathname: `/intervention/list`,
    });
  }
  const [isEditing, setIsEditing] = useState(id === "edit" ? true : false);
  const [isCreate, setIsCreate] = useState(id === "add" ? true : false);
  const [editingRecord, setEditingRecord] = useState(asset);
  const [isView, setIsView] = useState(id === "view" ? true : false);
  const [isAssetSignOff, setIsAssetSignOff] = useState(false);

  const [addAsset, { isCreateLoading }] = useAddAssetMutation();
  const [updateAsset, { isUpdateLoading }] = useUpdateAssetMutation();
  const [deleteParam] = useDeleteParamsMutation();

  const [addAssetDocument] = useAddDocumentToAssetMutation();
  const { data: organizationData, isLoading } = useGetOrgQuery(
    intervention?.organization
  );
  const {
    data: validationsData,
    refetch: refetchValidation,
  } = useGetValidationQuery(intervention?._id);

  const [isCreateParam, setIsCreateParam] = useState(false);
  const [isEditParam, setIsEditParam] = useState(false);
  const [editParamRecord, setEditParamRecord] = useState(null);
  const [paramId, setParamId] = useState(null);
  const [isAssetFreeze, setAssetFreeze] = useState(
    asset?.lock_status == "lock" ? true : false
  );

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isEditDocument, setIsEditDocument] = useState(false);
  const [editDocumentRecord, setEditDocumentRecord] = useState(null);
  const [isAssetHasAssignment, setIsAssetHasAssignment] = useState(false);

  const [isAssetVerified, setIsAssetVerified] = useState(false);

  const [isInterventionVerified, setIsInterventionVerified] = useState(false);
  const [addDocumentBtnDisabled, setAddDocumentBtnDisabled] = useState(false);
  const [addParamBtnDisabled, setAddParamBtnDisabled] = useState(false);
  const [isIntervHasAssignment, setIsIntervHasAssignment] = useState(false);
  const [isCreateAssigneeToAsset, setIsCreateAssigneeToAsset] = useState(false);
  const [isassignUserToAsset, setIsassignUserToAsset] = useState(null);
  const [openProbaReviewModal, setOpenProbaReviewModal] = useState(false);

  const [isSubmitForProbaReview, setIsSubmitForProbaReview] = useState(false);
  const [isProjectStsProbaReview, setIsProjectStsProbaReview] = useState(false);
  const [isProjectStsRevision, setIsProjectStsRevision] = useState(false);
  const [
    confirmLoadingProbaReviewModal,
    setConfirmLoadingProbaReviewModal,
  ] = useState(false);

  const [isIntervStsActive, setIntervStsActive] = useState(
    intervention?.status === "Active"
  );

  const [isAssetStsNew, setAssetStsNew] = useState(asset?.status === "New");
  const [isAssetStsRevision, setAssetStsRevision] = useState(
    asset?.status === "Revision"
  );
  const [isAssetStsProbaReview, setAssetStsProbaReview] = useState(
    asset?.status === "Proba Review"
  );
  const [isAssetStsActive, setAssetStsActive] = useState(
    asset?.status === "Active"
  );
  const [isAssetStsValidatorReview, setAssetStsValidatorReview] = useState(
    asset?.status === "Validator Review"
  );

  const [
    isPerformOperationalCheckOnAsset,
    setIsPerformOperationalCheckOnAsset,
  ] = useState(false);
  const [isPerformValidationOnAsset, setIsPerformValidationOnAsset] = useState(
    false
  );

  const isValidatorAvailable = intervention?.assignment?.some(
    (obj) => obj.assignee_type === "Validator"
  );

  useEffect(() => {
    // checking asset has assignment verifier and is verfied.
    // And also wwhether asset is verified by verifier and validator or not
    if (asset?.assignment && asset?.assignment.length) {
      let hasVerifier = false;
      let hasValidator = false;
      let isValidValidator = false;
      let hasVerifierVerified = false;
      let hasValidatorVerified = false;

      asset.assignment.forEach((assignee) => {
        if (assignee.assignee_type === "Verifier") {
          hasVerifier = true;
          if (assignee.verification_status === "verified") {
            hasVerifierVerified = true;
          }
        }
        if (assignee.assignee_type === "Validator") {
          hasValidator = true;
          if (assignee?.organization === orgId) {
            isValidValidator = true;
          }
          if (assignee.verification_status === "verified") {
            hasValidatorVerified = true;
          }
        }
      });

      setIsAssetHasAssignment({
        verifier: hasVerifier,
        isMatchingValidator: isValidValidator,
        validator: hasValidator,
      });

      setIsAssetVerified({
        verifier: hasVerifierVerified,
        validator: hasValidatorVerified,
      });
    } else {
      setIsAssetVerified(false);
      setIsAssetHasAssignment(false);
    }

    // checking intervention has assignment verifier and is verfied.
    // And also wwhether intervention is verified by verifier and validator or not
    if (intervention?.assignment && intervention?.assignment.length) {
      let hasVerifier = false;
      let hasValidator = false;
      let hasProjectSponsor = false;

      let hasVerifierVerified = false;
      let hasValidatorVerified = false;

      intervention.assignment.forEach((assignee) => {
        if (assignee.assignee_type === "Verifier") {
          hasVerifier = true;
          if (assignee.verification_status === "verified") {
            hasVerifierVerified = true;
          }
        }
        if (assignee.assignee_type === "Validator") {
          hasValidator = true;
          if (assignee.verification_status === "verified") {
            hasValidatorVerified = true;
          }
        }
        if (assignee.assignee_type === "Project Sponsor") {
          hasProjectSponsor = true;
        }
      });

      setIsIntervHasAssignment({
        verifier: hasVerifier,
        validator: hasValidator,
        projectSponsor: hasProjectSponsor,
      });

      setIsInterventionVerified({
        verifier: hasVerifierVerified,
        validator: hasValidatorVerified,
      });
    } else {
      setIsInterventionVerified(false);
      setIsIntervHasAssignment(false);
    }
  }, [asset]);

  useEffect(() => {
    // Disable the submit to revision btn for Proba Operator after subit to revision
    if (intervention?.status == "Proba Review") {
      setIsProjectStsProbaReview(true);
    }

    // Disable the submit to revision btn for Proba Operator after subit to revision
    if (intervention?.status == "Revision") {
      setIsProjectStsRevision(true);
    }
  }, [intervention]);

  const key = "updatable";
  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const closeModal = () => {
    //setEditParamRecord(null);
    setIsCreateParam(false);
    setIsEditParam(false);
  };

  const updateParam = (param) => {
    console.log("------", param);
    setEditParamRecord(param);
    setParamId(param._id);

    //Update the param id to methods
    if (editingRecord) {
      let editingRecordToUpdate = { ...editingRecord };
      editingRecordToUpdate.param_id = param ? param._id : null;
      handleEditRecord(editingRecordToUpdate);
      setEditingRecord(editingRecordToUpdate);
    }
  };

  const closeDocumentModal = () => {
    setIsCreateDocument(false);
    setIsEditDocument(false);
  };

  const updateDocument = (document) => {
    handleAddInterventionDocument(document);
  };

  const handleAddInterventionDocument = async (record) => {
    try {
      const body = {
        assetid: editingRecord?._id,
        requestbody: {
          document_id: record.document_id,
          asset_id: editingRecord?._id,
        },
      };

      await addAssetDocument(body)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Asset Document added successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Asset Doc!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleAddRecord = async (record) => {
    try {
      await addAsset(record)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "Asset added successfully!",
            key,
            duration: 2,
          });
          console.log(res);

          setIsEditing(true);
          setIsCreate(false);
          let currEditingRecord = { ...res?.data };
          onEditRecord(currEditingRecord);
          setParamId(res?.data?.param_id);
          dispatch(setAsset(currEditingRecord));
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Asset: " + Object?.values(err["data"])[0],
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleEditRecord = async (record) => {
    try {
      const payload = {
        id: record.asset_id,
        body: record,
      };
      await updateAsset(payload)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "Asset updated successfully!",
            key,
            duration: 2,
          });
          let currEditingRecord = { ...res?.data };
          setEditingRecord({ ...currEditingRecord });
          setParamId(res?.data?.param_id);
          dispatch(setAsset(currEditingRecord));
        })
        .catch((err) => {
          message.error({
            content:
              "Failed to update Asset: " + Object?.values(err["data"])[0],
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteParam = async (id) => {
    try {
      await deleteParam(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Param removed successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to removed Param!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const [addAssignment] = useAddAssignmentMutation();

  const onCreate = async (values) => {
    try {
      setSpinning(true);
      if (isEditing) {
        let assetToUpdate = { ...editingRecord };

        assetToUpdate.name = values.name;
        assetToUpdate.intervention_id = values.intervention_id;
        assetToUpdate.co2_emission_equivalent = values.co2_emission_equivalent;
        assetToUpdate.equivalent_calculation_method =
          values.equivalent_calculation_method;

        //set paramid
        // assetToUpdate.param_id = editParamRecord ? editParamRecord._id : null;

        //TODO
        handleEditRecord(assetToUpdate);
        setEditingRecord(assetToUpdate);
      } else if (isCreate) {
        // values.param_id = editParamRecord ? editParamRecord._id : "";
        await handleAddRecord(values);
        refetchValidation();
      }
    } catch {
    } finally {
      setSpinning(false);
    }
  };

  const [form] = Form.useForm();

  const onCancel = async () => {
    form.resetFields();
    let path = `/Asset`;
    history.push(path);
    onCancel();
  };
  const { Option } = Select;
  const viewAssetRouteChange = () => {
    let path = `/intervention/view`;
    history.push(path);
  };

  const handleAddDocument = () => {
    setIsCreateDocument(true);
    setIsEditDocument(false);
    setEditDocumentRecord(null);
  };

  const handleAddParam = () => {
    setIsCreateParam(true);
    setIsEditParam(false);
    setEditParamRecord(null);
    setParamId(null);
  };

  const handleDispatchSubmitForProbaReview = (assetUpdateResponse) => {
    let assetRecord = { ...asset };
    assetRecord.status = assetUpdateResponse?.data?.status;
    dispatch(setAsset(assetRecord));
    setEditingRecord(assetRecord);

    setAssetStsProbaReview(true);
    setAddDocumentBtnDisabled(true);
    setAddParamBtnDisabled(true);
  };

  const handleAssetEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      console.log("payload", payload);
      const res = await updateAssetStatus(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "GHG Asset updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          handleDispatchSubmitForProbaReview(res);
          setOpenProbaReviewModal(false);
          return res;
        })
        .catch((err) => {
          console.log("err", err);
          message.error({
            content:
              "Failed to update GHG Project: " + Object.values(err["data"])[0],
            key,
            duration: 6,
          });
          console.log(err);
        });
      return res;
    } catch {}
  };
  //bc-client
  const { onAccept: onAcceptCreateAssets } = useTransactionHandlers({
    actionname: createAssetsAction.name,
  });
  const createAssetsActionInBC = async (asset) => {
    return await onAcceptCreateAssets(asset);
  };
  //bc-client

  const handleOkOfProbaReviewModal = async () => {
    try {
      setSpinning(true);
      if (!usergroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
        return;
      } else if (!isIntervHasAssignment.validator) {
        message.error({
          content: "Assign the Validator for the Assets",
          key,
          duration: 6,
        });
        return;
      }

      const payload = {
        intervention: intervention,
        assets: [asset],
      };

      const result = await createAssetsActionInBC(payload);

      if (!result) {
        message.error({
          content: "Blockchain verification failed",
          key,
          duration: 6,
        });
        return;
      }

      const assetStatusUpdate = { ...asset };
      assetStatusUpdate["_ids"] = [assetStatusUpdate?.["_id"]];
      assetStatusUpdate["status"] = "Proba Review";
      const assetUpdateResponse = await handleAssetEditRecord(
        assetStatusUpdate
      );

      if (!assetUpdateResponse) {
        message.error({
          content: "Asset status update failed",
          key,
          duration: 6,
        });
        return;
      }
    } catch (error) {
      console.error(
        "Error calling blockchain function or updating asset:",
        error
      );
    } finally {
      setConfirmLoadingProbaReviewModal(false);
      setSpinning(false);
    }
  };

  const closeAssigneeToAssetModal = () => {
    setIsCreateAssigneeToAsset(false);
  };

  const closeSubmitForProbaReview = () => {
    setIsSubmitForProbaReview(false);
    setAssetStsProbaReview(false);
    setOpenProbaReviewModal(false);
  };

  const handleDispatchAfterAssignment = (assignmentResponse) => {
    const assigneeType = assignmentResponse?.assignee_type;
    let assetRecord = { ...asset };
    let assetRec_assignment_array = assetRecord?.assignment;

    const updateAssetRecord = () => {
      if (
        !Array.isArray(assetRec_assignment_array) ||
        assetRec_assignment_array === null
      ) {
        assetRecord.assignment = [assignmentResponse];
      } else {
        assetRecord.assignment = [
          ...assetRec_assignment_array,
          assignmentResponse,
        ];
      }
      dispatch(setAsset(assetRecord));
      setEditingRecord(assetRecord);
    };

    if (assigneeType === "Validator") {
      setIsAssetHasAssignment({
        verifier: true,
        validator: true,
      });
      updateAssetRecord();
    }

    if (assigneeType === "Verifier" || assigneeType === "Project Sponsor") {
      updateAssetRecord();
    }
  };

  const handleSubmitForProbaReview = () => {
    setOpenProbaReviewModal(true);
  };

  const handleAssignValidator = () => {
    setIsCreateAssigneeToAsset(true);
    setIsassignUserToAsset("Validator");
  };

  const ClosePerformOperationalCheckOnAssetModal = () => {
    setIsPerformOperationalCheckOnAsset(false);
    // setIntervStsValidatorReview(false)
  };

  const ClosePerformValidationOnAssetModal = () => {
    setIsPerformValidationOnAsset(false);
    // setIntervStsValidatorReview(false)
  };

  const handlePerformOperationalCheckOnAsset = () => {
    setIsPerformOperationalCheckOnAsset(true);
    // setIntervStsValidatorReview(true);
  };

  const handlePerformOValidationOnAsset = () => {
    setIsPerformValidationOnAsset(true);
    // setIntervStsValidatorReview(true);
  };

  const handleDispatchPerformOperationalCheck = (assetUpdateResponse) => {
    let assetRecord = { ...asset };
    assetRecord.status = assetUpdateResponse?.data?.status;
    dispatch(setAsset(assetRecord));
    setEditingRecord(assetRecord);

    let asset_sts = assetUpdateResponse?.data?.status;
    if (asset_sts === "Validator Review") {
      setAssetStsValidatorReview(true);
    } else if (asset_sts === "Active") {
      setAssetStsActive(true);
    } else if (asset_sts === "Revision") {
      setAssetStsRevision(true);
    }
    // setSubmitForProbaReviewBtnDisabled(true);
    // setAddDocumentBtnDisabled(true);
    // setAddParamBtnDisabled(true);
  };

  const handleDispatchPerformValidationOnAsset = (assetUpdateResponse) => {
    let assetRecord = { ...asset };
    assetRecord.status = assetUpdateResponse?.data?.status;
    dispatch(setAsset(assetRecord));
    setEditingRecord(assetRecord);

    let asset_sts = assetUpdateResponse?.data?.status;
    if (asset_sts === "Revision") {
      setAssetStsRevision(true);
    } else if (asset_sts === "Active") {
      setAssetStsActive(true);
    }
  };

  const menu = (
    <Menu>
      {!isCreate &&
        usergroup.includes("Project Developer") &&
        intervention?.organization === orgId && (
          <>
            <Menu.Item key="submitForProbaReview">
              <Button
                type="link"
                onClick={handleSubmitForProbaReview}
                disabled={
                  !isIntervStsActive ||
                  isAssetStsProbaReview ||
                  isAssetStsValidatorReview ||
                  isAssetStsActive
                }
              >
                Submit for Proba Review
              </Button>
            </Menu.Item>
            <Menu.Item key="assignVerifierQuantity">
              <Button
                type="link"
                onClick={handleAssignValidator}
                disabled={
                  isCreate ||
                  isIntervHasAssignment.validator ||
                  isAssetHasAssignment.validator ||
                  !isValidatorAvailable
                }
              >
                Assign Validator
              </Button>
            </Menu.Item>
          </>
        )}
        {(
        usergroup.includes("Proba Operator") ||
        usergroup.includes("Verifier") ||
        usergroup.includes("Validator") ||
        usergroup.includes("Project Developer")
        ) && (
          <Menu.Item key="addDocument">
            <Button
              type="link"
              onClick={handleAddDocument}
              disabled={
                isCreate ||
                addDocumentBtnDisabled ||
                isAssetStsProbaReview
              }
            >
              Add Document
            </Button>
          </Menu.Item>
        )}
      {!usergroup.includes("Verifier") && !usergroup.includes("Validator") && (
        <>
          {isCreate ? (
            <Menu.Item key="addParam">
              <Button
                type="link"
                onClick={handleAddParam}
                disabled={
                  isCreate ||
                  addParamBtnDisabled ||
                  isAssetStsProbaReview ||
                  isAssetStsActive
                }
              >
                Add Param
              </Button>
            </Menu.Item>
          ) : (
            <>
              {usergroup.includes("Project Developer") &&
                intervention?.organization === orgId && (
                  <>
                    {editingRecord && editingRecord?.param_id ? (
                      <>
                        <Menu.Item key="editParam">
                          <Button
                            disabled={isAssetStsProbaReview || isAssetStsActive}
                            type="link"
                            onClick={() => {
                              setIsCreateParam(false);
                              setIsEditParam(true);
                              setParamId(editingRecord?.param_id);
                            }}
                          >
                            Edit Param
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="removeParam">
                          <Button
                            disabled={isAssetStsProbaReview || isAssetStsActive}
                            type="link"
                            onClick={() => {
                              setIsCreateParam(false);
                              setIsEditParam(false);
                              Modal.confirm({
                                title:
                                  "Are you sure you want to remove the Param?",
                                okText: "Yes",
                                okType: "danger",
                                onOk: () => {
                                  handleDeleteParam(editingRecord?.param_id);
                                  setEditParamRecord(null);
                                  setParamId(null);

                                  let recordToUpdate = { ...editingRecord };
                                  recordToUpdate.param_id = null;
                                  handleEditRecord(recordToUpdate);
                                  setEditingRecord(recordToUpdate);
                                },
                              });
                            }}
                          >
                            Remove Param
                          </Button>
                        </Menu.Item>
                      </>
                    ) : (
                      <Menu.Item key="addParam">
                        <Button
                          disabled={
                            isAssetStsProbaReview ||
                            isAssetStsActive ||
                            addParamBtnDisabled
                          }
                          type="link"
                          onClick={() => {
                            setIsCreateParam(true);
                            setIsEditParam(false);
                            setEditParamRecord(null);
                            setParamId(null);
                          }}
                        >
                          Add Param
                        </Button>
                      </Menu.Item>
                    )}
                  </>
                )}
            </>
          )}
        </>
      )}
      {usergroup.includes("Proba Operator") && (
        <Menu.Item key="AssetPerformOperationalCheck">
          <Button
            type="link"
            onClick={handlePerformOperationalCheckOnAsset}
            disabled={
              !isIntervStsActive ||
              isAssetStsValidatorReview ||
              isAssetStsActive ||
              isAssetStsRevision
            }
          >
            Perform Operational Check
          </Button>
        </Menu.Item>
      )}
      {usergroup.includes("Validator") && (
        <Menu.Item key="AssetPerformValidation">
          <Button
            type="link"
            onClick={handlePerformOValidationOnAsset}
            disabled={
              !isAssetHasAssignment.isMatchingValidator ||
              !isAssetStsValidatorReview ||
              isAssetStsRevision ||
              isAssetStsActive
            }
          >
            Perform Validation
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Spin spinning={spinning}>
        <div style={{ height: "80vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={2}>
              {isCreate
                ? "GHG Project (" + intervention?.name + ") : Add Asset"
                : isEditing
                ? "GHG Project (" +
                  intervention?.name +
                  ") : Edit Asset (" +
                  editingRecord?.name +
                  ")"
                : "GHG Project (" +
                  intervention?.name +
                  ") : View Asset (" +
                  editingRecord?.name +
                  ")"}
            </Typography.Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Dropdown overlay={menu} trigger={["hover"]}>
                <Button type="primary">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
              <Divider type="vertical"></Divider>
              <Button
                type="primary"
                onClick={() => {
                  viewAssetRouteChange();

                  //refetch the table
                  dispatch(assetApi.util.resetApiState());
                  dispatch(yieldApi.util.resetApiState());
                }}
              >
                Back
              </Button>
            </div>
          </div>

          <Divider type="vertical"></Divider>
          <div>
            <Row gutter={[10, 10]} align="stretch" style={{ display: "flex" }}>
              <Col span={12}>
                <Card
                  className="shadow-card"
                  style={{ height: "100%", borderRadius: 8, flexGrow: 1 }}
                >
                  <Typography.Title level={4}>Assets Details</Typography.Title>
                  <br></br>
                  {isEditing || isCreate ? (
                    <AssetForm
                      visible={isEditing || isCreate}
                      onCreate={onCreate}
                      onCancel={() => {
                        resetEditing();
                      }}
                      editingRecord={editingRecord}
                      editParamRecord={editParamRecord}
                      isCreateParam={isCreateParam}
                      isEditParam={isEditParam}
                      closeModal={closeModal}
                      updateParam={updateParam}
                      paramId={paramId}
                      editDocumentRecord={editDocumentRecord}
                      isCreateDocument={isCreateDocument}
                      isEditDocument={isEditDocument}
                      closeDocumentModal={closeDocumentModal}
                      updateDocument={updateDocument}
                      intervention={intervention}
                      isAssetHasAssignment={isAssetHasAssignment}
                      isPerformOperationalCheckOnAsset={
                        isPerformOperationalCheckOnAsset
                      }
                    />
                  ) : (
                    isView && (
                      <AssetView
                        onCreate={onCreate}
                        editingRecord={editingRecord}
                        editParamRecord={editParamRecord}
                        isCreateParam={isCreateParam}
                        isEditParam={isEditParam}
                        closeModal={closeModal}
                        updateParam={updateParam}
                        paramId={paramId}
                        editDocumentRecord={editDocumentRecord}
                        isCreateDocument={isCreateDocument}
                        isEditDocument={isEditDocument}
                        closeDocumentModal={closeDocumentModal}
                        updateDocument={updateDocument}
                        intervention={intervention}
                        isAssetFreeze={isAssetFreeze}
                        organizationData={organizationData}
                        usergroup={usergroup}
                        isPerformOperationalCheckOnAsset={
                          isPerformOperationalCheckOnAsset
                        }
                      />
                    )
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  className="shadow-card"
                  style={{ height: "100%", borderRadius: 8 }}
                >
                  <Typography.Title level={4}>Documents</Typography.Title>
                  <DisplayDocuments
                    id={editingRecord?._id}
                    entity="asset"
                  ></DisplayDocuments>
                </Card>
                {/* <Card className="shadow-card" style={{ height: "50%", borderRadius: 8, flexGrow: 1 }}>
                                <Typography.Title level={4}>Parameters</Typography.Title>
                                {editingRecord?.param_id != "" && <ParamView paramid={editingRecord?.param_id}></ParamView>}
                                </Card> */}
              </Col>
            </Row>

            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col span={24}>
                <Card className="shadow-card">
                  <Typography.Title level={4}>Parameters</Typography.Title>
                  {editingRecord?.param_id != "" && (
                    <ParamView paramid={editingRecord?.param_id}></ParamView>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col span={24}>
                <Card className="shadow-card">
                  {editingRecord?._id != "" && editingRecord?._id && (
                    <Yield
                      assetid={editingRecord?._id}
                      setSpinning={setSpinning}
                      intervassignment={intervention?.assignment}
                    ></Yield>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
          <Params
            paramId={paramId}
            updateParam={updateParam}
            isCreate={isCreateParam}
            isEditing={isEditParam}
            closeModal={closeModal}
          />
          <AddDocument
            ids={[editingRecord?._id]}
            entity="asset"
            updateDocument={updateDocument}
            editingRecord={editDocumentRecord}
            isCreate={isCreateDocument}
            isEditings={isEditDocument}
            closeModal={closeDocumentModal}
          />
          <AssignVerifier
            isCreate={isCreateAssigneeToAsset}
            groupname={isassignUserToAsset}
            isDetails={asset}
            closeModal={closeAssigneeToAssetModal}
            assignmentTo={"GHG Asset"}
            handleDispatchAfterAssignment={handleDispatchAfterAssignment}
            refetchValidation={refetchValidation}
          />
          <PerformOperationaCheckOnAsset
            assetDetails={asset}
            usergroup={usergroup}
            isCreate={isPerformOperationalCheckOnAsset}
            closeModal={ClosePerformOperationalCheckOnAssetModal}
            handleDispatchPerformOperationalCheck={
              handleDispatchPerformOperationalCheck
            }
          />
          <PerformValidationOnAsset
            assetDetails={asset}
            updateDocument={updateDocument}
            setSpinning={setSpinning}
            spinning={spinning}
            usergroup={usergroup}
            isCreate={isPerformValidationOnAsset}
            closeModal={ClosePerformValidationOnAssetModal}
            handleDispatchPerformValidationOnAsset={
              handleDispatchPerformValidationOnAsset
            }
          />
        </div>
      </Spin>
      <Modal
        title="Submit for Proba Review"
        visible={openProbaReviewModal}
        onOk={handleOkOfProbaReviewModal}
        okText="Submit for Proba Review"
        confirmLoading={confirmLoadingProbaReviewModal || spinning}
        onCancel={() => {
          if (!(confirmLoadingProbaReviewModal || spinning))
            closeSubmitForProbaReview();
        }}
      >
        <p>Are you sure you want to submit asset for Proba Review?</p>
      </Modal>
    </>
  );
}

const AssetForm = ({
  onCreate,
  editingRecord,
  intervention,
  isAssetHasAssignment,
}) => {
  const [value, setValue] = useState("");

  const { data = [], isLoading } = useGetInterventionsQuery();
  const intervention_children = [];
  if (!isLoading) {
    data?.data?.forEach((intervention) => {
      intervention_children.push(
        <Option key={intervention._id}>{intervention.name}</Option>
      );
    });
  }

  const { TextArea } = Input;

  const [form] = Form.useForm();

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="asset"
        initialValues={editingRecord}
        onFinish={onCreate}
        autoComplete="off"
      >
        <Row gutter={[100, 0]}>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Asset Name"
              rules={[
                {
                  required: true,
                  message: "Please input Asset name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="intervention_id"
              label="GHG Project"
              rules={[
                {
                  required: true,
                  message: "Please select the GHG Project",
                },
              ]}
              initialValue={intervention?._id}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Please select the GHG Project"
                disabled={true}
              >
                {intervention_children}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="co2_emission_equivalent"
              label="CO2 Emission Equivalent"
              rules={[
                {
                  required: false,
                },
                {
                  validator: (_, value) => {
                    if (value === null || value === undefined || value === '') {
                      return Promise.resolve();
                    } else if (!/^\d+(\.\d{1,6})?$/.test(value)) {
                      return Promise.reject(
                        new Error("Value must be a number")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input inputMode={"numeric"} value={value} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="equivalent_calculation_method"
              label="Equivalent Calculation Method"
            >
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 4,
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="_id" label="Asset ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="intervention_id" label="GHG Project ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={
              (isAssetHasAssignment.verifier ||
                isAssetHasAssignment.validator) &&
              !(
                editingRecord?.status === "Revision" ||
                editingRecord?.status === "New"
              )
            }
            type="primary"
            htmlType="submit"
          >
            Submit Asset
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const AssetView = ({
  editingRecord,
  isAssetFreeze,
  organizationData,
  usergroup,
}) => {
  const { data: assetparam, isLoading: isParamLoading } = useGetParamQuery(
    editingRecord?.param_id,
    {
      skip: !editingRecord?.param_id,
    }
  );

  const [isShowCertificate, setIsShowCertificate] = useState(false);

  const closeCertificateModal = () => {
    setIsShowCertificate(false);
  };

  const paramDescriptions = [];
  if (!isParamLoading) {
    //TODO - It should only return an object not an array
    const paramsarray = assetparam
      ? assetparam.length > 0
        ? assetparam[0]?.param
        : []
      : [];
    paramsarray?.forEach((param) => {
      paramDescriptions.push(
        <Descriptions.Item
          label={<Typography.Text strong>{param.key}</Typography.Text>}
          span={1}
        >
          {param.value}
        </Descriptions.Item>
      );
    });
  }

  const { data = [], isLoading } = useGetInterventionsQuery();
  const intervention_children = [];
  if (!isLoading) {
    data?.data?.forEach((intervention) => {
      intervention_children.push(
        <Option key={intervention._id}>{intervention.name}</Option>
      );
    });
  }

  const { TextArea } = Input;

  const [form] = Form.useForm();

  const format = (value) => (
    <Typography.Text style={{ fontSize: 18, overflowWrap: "anywhere" }}>
      {value === true ? "Yes" : value === false ? "No" : value}
    </Typography.Text>
  );

  return (
    <>
      <Row gutter={[100, 20]}>
        <Col span={8}>
          <Statistic
            title="Asset Name"
            value={editingRecord?.name}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="External Asset Id"
            value={editingRecord?.external_asset_id}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Organization"
            value={organizationData?.name}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="GHG Project"
            value={editingRecord?.intervention_id}
            formatter={(value) => (
              <DisplayIntervention interventionid={value}></DisplayIntervention>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="CO2 Emission Equivalent"
            value={editingRecord?.co2_emission_equivalent}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Status"
            value={
              statusOptions?.find(
                ({ value }) => value === editingRecord?.status
              )?.label
            }
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={24}>
          <Statistic
            title="Equivalent Calculation Method"
            value={editingRecord?.equivalent_calculation_method}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Assignees"
            value={editingRecord?.assignment}
            formatter={(value) => {
              let validator_org_id, project_sponsor_org_id;
              if (Array.isArray(value)) {
                value.map((assignment) => {
                  if (assignment.assignee_type === "Validator") {
                    validator_org_id = assignment?.organization;
                  }
                  if (assignment.assignee_type === "Project Sponsor") {
                    project_sponsor_org_id = assignment?.organization;
                  }
                });
                return (
                  <Popover
                    content={
                      <>
                        <Typography.Text strong>Validator:</Typography.Text>
                        <DisplayOrganization
                          orgid={validator_org_id}
                          place="table"
                        />
                        <Typography.Text strong>
                          Project Sponsor:
                        </Typography.Text>
                        <DisplayOrganization
                          orgid={project_sponsor_org_id}
                          place="table"
                        />
                      </>
                    }
                  >
                    <ContactsOutlined />
                  </Popover>
                );
              } else {
                return (
                  <Popover content={<></>}>
                    <ContactsOutlined />
                  </Popover>
                );
              }
            }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Creator"
            value={editingRecord?.creator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Updator"
            value={editingRecord?.updator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Created At"
            value={editingRecord?.created}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Updated At"
            value={editingRecord?.updated}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
        {editingRecord?.is_updated_to_blockchain === true && (
          <Col span={8}>
            <Statistic
              title="Is Updated to Blockchain? "
              value={editingRecord?.is_updated_to_blockchain}
              formatter={(value) => format(value)}
            />
          </Col>
        )}
      </Row>

      <ViewDownloadCertificate
        isShowCertificate={isShowCertificate}
        assetrecord={editingRecord}
        closeModal={closeCertificateModal}
      />
    </>
  );
};

export default AddEditAsset;
