import { React, useRef } from "react";
import { Button, Card, Modal, Table } from "antd";

import { Typography } from "antd";
import html2pdf from "html2pdf.js";

const { Title } = Typography;
const { Text } = Typography;

export default function ViewCertificate({
  isShowCertificate,
  finalTokenData,
  yieldRecord,
  closeModal,
}) {
  if (yieldRecord) {
    var yieldRecordCertificateDetails = JSON.parse(yieldRecord);
  }

  const cardRef = useRef(null);
  const handleDownload = () => {
    const element = document.getElementById("card-container");

    const cards = element.querySelectorAll(".card");
    cards.forEach((card) => {
      card.setAttribute("data-html2pdf-page-break", "avoid");
    });

    const style = document.createElement("style");
    style.innerHTML = `
            tr, div, span, h2, h4 {
                page-break-inside: avoid !important;
                break-inside: avoid !important;
            }
            @media print {
                tr, div, span, h2, h4 {
                    page-break-inside: avoid !important;
                    break-inside: avoid !important;
                }
            }
        `;
    document.head.appendChild(style);
    const opt = {
      margin: [10, 10, 10, 10],
      filename: "PTCB- " + finalTokenData?.finaltoken_id + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 2,
      },
      jsPDF: {
        unit: "pt", // Set unit to points
        format: "letter",
        orientation: "portrait",
      },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .save();
  };

  return (
    <div>
      <>
        {isShowCertificate && (
          <CertificateModal
            visible={isShowCertificate}
            finalTokenData={finalTokenData}
            yieldRecordCertificateDetails={
              yieldRecordCertificateDetails?.data
                ? yieldRecordCertificateDetails?.data
                : [yieldRecordCertificateDetails]
            }
            cardRef={cardRef}
            handleDownload={handleDownload}
            onCancel={() => {
              closeModal();
            }}
          />
        )}
      </>
    </div>
  );
}

const CertificateModal = ({
  visible,
  onCancel,
  finalTokenData,
  yieldRecordCertificateDetails,
  handleDownload,
  cardRef,
}) => {
  const flatAssetsRecords = [];

  function getUniqueYields(yields) {
    if (!Array.isArray(yields)) {
      return [];
    }
    let uniqueYields = [];
    let seenYields = new Set();
    for (let yieldData of yields) {
      let uniqueKey = `${yieldData["yield_period"]}-${yieldData["yield_storage_duration"]}-${yieldData["yield_co2_realized"]}`;
      if (!seenYields.has(uniqueKey)) {
        seenYields.add(uniqueKey);
        uniqueYields.push(yieldData);
      }
    }
    return uniqueYields;
  }

  yieldRecordCertificateDetails.forEach(({ assets }) => {
    if (Array.isArray(assets) && assets.length > 0) {
      assets.forEach(
        ({
          asset_name,
          asset_co2_emission_equivalent,
          asset_equivalent_calulation_method,
          yields,
          asset_parameters,
        }) => {
          const uniqueYieldsRecords = getUniqueYields(yields);

          uniqueYieldsRecords.forEach(
            ({ yield_period, yield_storage_duration, yield_co2_realized }) => {
              const flatRecord = {
                asset_name,
                asset_co2_emission_equivalent,
                asset_equivalent_calulation_method,
                yield_period,
                yield_storage_duration,
                yield_co2_realized,
                asset_parameters,
              };

              flatAssetsRecords.push(flatRecord);
            }
          );
        }
      );
    }
  });

  const uniqueAssetsRecords = Array.from(
    new Set(
      flatAssetsRecords
        .filter(record => record?.yield_co2_realized != null)
        .map(JSON.stringify)
    )
  ).map(JSON.parse);

  const columns = [
    {
      title: "Characteristics",
      key: "Characteristics",
      render: (record) => {
        return (
          <>
            <div>
              {record?.asset_name && (
                <>
                  <Typography.Text>
                    <strong>Asset Name: </strong>
                    {record?.asset_name}
                  </Typography.Text>
                  <br />
                </>
              )}
              {record?.asset_co2_emission_equivalent && (
                <>
                  <Typography.Text>
                    <strong>
                      Co<sub>2</sub> Emission Equivalent:{" "}
                    </strong>
                    {record?.asset_co2_emission_equivalent}
                  </Typography.Text>
                  <br />
                </>
              )}
              {record?.asset_equivalent_calulation_method && (
                <>
                  <Typography.Text>
                    <strong>Equivalent calculation method: </strong>
                    {record?.asset_equivalent_calulation_method}
                  </Typography.Text>
                  <br />
                </>
              )}
              {record?.asset_parameters &&
                record.asset_parameters
                  .filter(
                    (param) =>
                      param.key && param.value && param.value.trim() !== ""
                  )
                  .map(({ key, value }) => (
                    <div key={key}>
                      <Typography.Text
                        strong
                        style={{ textTransform: "capitalize" }}
                      >
                        {key}
                      </Typography.Text>
                      :{" "}
                      {typeof value === "string"
                        ? value.charAt(0).toUpperCase() + value.slice(1)
                        : value}
                      <br />
                    </div>
                  ))}
              {record?.yield_co2_realized && (
                <>
                  <Typography.Text>
                    <strong>Realized tCo2e: </strong>
                    {Number(record?.yield_co2_realized) % 1 === 0
                      ? Number(record?.yield_co2_realized)?.toString()
                      : Number(record?.yield_co2_realized)
                          ?.toFixed(4)
                          ?.replace(/\.?0+$/, "")}
                  </Typography.Text>
                  <br></br>
                </>
              )}
              <Typography.Text>
                <strong>Period: </strong>
                {record?.yield_period}
              </Typography.Text>
              <br></br>
              <Typography.Text>
                <strong>Storage Duration: </strong>
                {record?.yield_storage_duration}
              </Typography.Text>
              <br></br>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Modal
      width={1000}
      open={visible}
      cancelText="Close"
      onCancel={onCancel}
      footer={[
        <Button key="download" type="primary" onClick={handleDownload}>
          Download PDF
        </Button>,
      ]}
    >
      <Card
        ref={cardRef}
        style={{
          width: "100%",
          height: "100%",
          padding: "20px 30px",
        }}
        id="card-container"
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <img src="/logo512.png" alt="logo" style={{ height: 36 }} />
        </div>
        <Title level={2} style={{ color: "#35B28D" }}>
          Proba Tradable Credit Bundle
        </Title>
        <Title level={4} style={{ color: "#35B28D" }}>
          Amount of Tradable Credits
        </Title>
        <Text strong>
          {yieldRecordCertificateDetails[0]?.amount_of_tradable_certificates
            ? Number(
                yieldRecordCertificateDetails[0].amount_of_tradable_certificates
              )
                .toFixed(4)
                .replace(/\.?0+$/, "")
            : 0}{" "}
          tCO<sub>2</sub>e
        </Text>
        <Title level={4} style={{ color: "#35B28D" }}>
          GHG Project Information
        </Title>
        <Text>
          <strong>Project Name: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_name}
        </Text>
        <br />
        <Text>
          <strong>Project ID: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_project_id}
        </Text>
        <br />
        <Text>
          <strong>ID: </strong>
          PTCB-{finalTokenData?.finaltoken_id}
        </Text>
        <br />
        <Text>
          <strong>GHG Project Type: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_type === "removal"
            ? "Removal"
            : "Reduction"}
        </Text>
        <br />
        <Text>
          <strong>Methodology: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_method}
        </Text>
        <br />
        <Text>
          <strong>Standards Used: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_standards_used}
        </Text>
        <br />
        <Text>
          <strong>GHG Project Summary: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_summary}
        </Text>
        <br />
        <Text>
          <strong>Location: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_region}
        </Text>
        <br />
        <Title level={4} style={{ color: "#35B28D", marginTop: "10px" }}>
          Detailed Characteristics
        </Title>
        <Table
          showHeader={false}
          pagination={false}
          dataSource={uniqueAssetsRecords}
          columns={columns}
        />
        <br />
        <Title level={4} style={{ color: "#35B28D" }}>
          Project Stakeholders
        </Title>
        <Text>
          <strong>Project Developer: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_operator}
        </Text>
        <br />
        {yieldRecordCertificateDetails[0]?.intervention_project_sponsor && (
          <>
            <Text>
              <strong>Project Sponsor: </strong>
              {yieldRecordCertificateDetails[0]?.intervention_project_sponsor}
            </Text>
            <br />
          </>
        )}
        <Text>
          <strong>Validator: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_validator_of_quality}
        </Text>
        <br />
        <Text>
          <strong>Verifier: </strong>
          {yieldRecordCertificateDetails[0]?.intervention_verifier_of_quality}
        </Text>
        <br />
      </Card>
    </Modal>
  );
};
