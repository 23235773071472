import { React, useState } from "react";
import {
  useAddOrgMutation,
  useDeleteOrgMutation,
  useGetOrgsQuery,
  useUpdateOrgsMutation,
} from "../../store/auth/orgapi";

import { useGetOrgTypesQuery } from "../../store/auth/orgtypeapi";

import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
  message,
} from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

function Org() {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (types) => {
        return types?.map((type) => type.name).join(", ");
      },
    },
    {
      key: "address",
      title: "Address",
      dataIndex: "address",
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: "createdAt",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated",
      dataIndex: "updatedAt",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditRecord(record);
              }}
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical"></Divider>
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 10 }}
            />
          </>
        );
      },
    },
  ];

  const { data = [], isLoading, isFetching, isError } = useGetOrgsQuery();
  const [addOrg, { isCreateLoading }] = useAddOrgMutation();
  const [updateOrgs, { isUpdateLoading }] = useUpdateOrgsMutation();
  const [deleteOrg, { isDeleteLoading }] = useDeleteOrgMutation();

  if (!isLoading) {
  }

  const key = "updatable";
  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const handleAddRecord = async (record) => {
    try {
      await addOrg(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization added successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content:
              "Failed to add Organization: " + JSON.stringify(err?.data?.error),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleEditRecord = async (record) => {
    try {
      await updateOrgs(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization updated successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Organization!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteRecord = async (id) => {
    try {
      await deleteOrg(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete Organization!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onCreate = (values) => {
    if (isEditing) {
      editingRecord.name = values.name;
      editingRecord.type = values.type;
      editingRecord.address = values.address;
      handleEditRecord(editingRecord);
      setIsEditing(false);
    } else if (isCreate) {
      handleAddRecord(values);
    }
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2}>Organizations</Typography.Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsEditing(false);
              setIsCreate(true);
              setEditingRecord(null);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          <Table
            size="small"
            columns={columns}
            dataSource={data.orgList}
          ></Table>
        </Card>
      </div>
      <>
        {isEditing || isCreate ? (
          <OrganizationFormModal
            visible={isEditing || isCreate}
            onCreate={onCreate}
            onCancel={() => {
              resetEditing();
            }}
            editingRecord={editingRecord}
          />
        ) : null}
      </>
    </div>
  );
}

const OrganizationFormModal = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
}) => {
  const {
    data: orgtypes,
    isLoading,
    isFetching,
    isError,
  } = useGetOrgTypesQuery();

  const { Option } = Select;

  const orgTypes = [];
  if (!isLoading && orgtypes) {
    orgtypes.forEach((orgtype) => {
      orgTypes.push(<Option key={orgtype._id}>{orgtype.name}</Option>);
    });
  }
  const initialTypeValues = editingRecord?.type?.map((t) => t._id) || [];

  const [form] = Form.useForm();
  return (
    <Modal
      width={500}
      title="Add/Edit Organization"
      open={visible}
      okText="Save"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form layout="vertical" form={form} name="organization">
        <Form.Item
          name="name"
          label="Name"
          initialValue={editingRecord?.name}
          rules={[
            {
              required: true,
              message: "Please input Organization name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={initialTypeValues}
          name="type"
          label="Type"
          rules={[]}
        >
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Please select the type"
            mode="multiple"
          >
            {orgTypes}
          </Select>
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          initialValue={editingRecord?.address}
          rules={[
            {
              required: true,
              message: "Please input Address!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Org;
