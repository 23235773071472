import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Form, Input, Typography } from "antd";
import { useForgotPasswordMutation } from "../../store/auth/authapi";
import { useHistory } from "react-router-dom";

function ForgotPassword() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [forgotPassword, { isSuccess, isError, isLoading }] = useForgotPasswordMutation();

  const onForgotPassword = async ({ email }: { email: string }) => {
    try {
      const res = await forgotPassword({ email }).unwrap();
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url('/login-bg.png')",
        backgroundSize: "cover",
        minHeight: "100%",
        minWidth: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <div style={{ width: 500 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "12px 24px", gap: 16 }}
          >
            {/* <div style={{ marginBottom: 40 }}>
              <img src="/hydrotwin-logo.svg" alt="logo" style={{ width: 250 }} />
            </div> */}
            {isError && (
              <Alert description="Failed to reset password" type="error" showIcon style={{ marginBottom: "20px" }} />
            )}
            {isSuccess ? (
              <Alert
                description={
                  <>
                    <h1>Email Sent</h1>
                    <p>Check your email and open the link to continue.</p>
                  </>
                }
                type="success"
                showIcon
                style={{ marginBottom: "20px" }}
              />
            ) : (
              <>
                <div style={{ alignSelf: "flex-start" }}>
                  <Typography.Title level={3} style={{ fontWeight: 600 }}>
                    Forgot Password?
                  </Typography.Title>
                  <Typography.Paragraph style={{ color: "gray" }}>
                    Enter your email to receive a link to reset your password
                  </Typography.Paragraph>
                </div>

                <Form
                  form={form}
                  name="login"
                  scrollToFirstError
                  style={{ width: "100%", marginBottom: -28 }}
                  data-testid="login"
                  onFinish={onForgotPassword}
                >
                  <p>Email address</p>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    style={{ marginTop: -12, marginBottom: 12 }}
                  >
                    <Input name="email" placeholder="Type your email" />
                  </Form.Item>
                  <Form.Item style={{ marginTop: 48 }}>
                    <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: "100%" }}>
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            <Button
              loading={isLoading}
              type="link"
              size="middle"
              style={{ width: "100%", color: "gray" }}
              onClick={() => history.push("/login")}
            >
              <ArrowLeftOutlined /> Back to Login
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ForgotPassword;
