import { React, useState, useEffect } from "react";

import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Modal,
  Row,
  Checkbox,
  Spin,
  Table,
  Typography,
  message,
  Input,
  Slider,
  Popover,
} from "antd";
import {
  DownOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import {
  createAllocateAction,
  createClaimAction,
  createTradableCertificateAction,
} from "../../constants/actionNames";
import {
  finaltokApi,
  useGetFinalTokenByParamQuery,
} from "../../store/is/finaltokapi";
import store from "../../store/store";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import ViewDownloadCertificate from "./ViewDownloadCertificate";
import ViewRetirementDownloadCertificate from "./ViewRetirementDownloadCertificate";
import { useGetInterventionsQuery } from "../../store/is/interventionapi";
import {
  preentitlementrecordApi,
  useGetPreEntitilementRecordByOrganizationQuery,
} from "../../store/is/preentitlementrecordsapi";
import { createTradableCertificatesUtil } from "./Utils/yieldutils";
import { useUpdateYieldBatchMutation } from "../../store/is/yieldapi";
import { useGetOrgsQuery } from "../../store/auth/orgapi";
import { useGetEntitlementCreditBundleDetailsMutation } from "../../store/is/entitlementCreditBundleApi";

function CertificateView() {
  //Bc-client -Allocate

  const { onAccept: onAcceptcreateAllocateAction } = useTransactionHandlers({
    actionname: createAllocateAction.name,
  });
  const createAllocateActionInBC = async (payload) => {
    return await onAcceptcreateAllocateAction(payload);
  };

  const { onAccept: onAcceptcreateClaimAction } = useTransactionHandlers({
    actionname: createClaimAction.name,
  });
  const createClaimActionInBC = async (payload) => {
    return await onAcceptcreateClaimAction(payload);
  };
  const [
    fetchDetails,
    { data: detailsData, error, isLoading },
  ] = useGetEntitlementCreditBundleDetailsMutation();

  let token = useSelector((state) => state.auth.token);
  token = token.replace("Bearer ", "");

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  const userOrgId = useSelector((state) => state.auth?.org?._id);

  let userid = useSelector((state) => state.auth.userid);
  let BCAddress = useSelector((state) => state.auth.baddress);
  const userOrgName = useSelector((state) => state.auth?.org?.name);

  const [isAllocateModalVisible, setIsAllocateModalVisible] = useState(false);
  const [
    isAllocateCreditsModalVisible,
    setIsAllocateCreditsModalVisible,
  ] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isCreateBundleModalVisible, setIsCreateBundleModalVisible] = useState(
    false
  );
  const [selectedEntryData, setSelectedEntryData] = useState(null);
  const [isShowRetiredCertificate, setIsShowRetiredCertificate] = useState(
    false
  );
  const [organizationMatchStatus, setOrganizationMatchStatus] = useState(null);
  const [organizationMatchingData, setOrganizationMatchingData] = useState();
  const [groupIds, setGroupIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCreditsRowKeys, setSelectedCreditsRowKeys] = useState([]);
  const [selectedCreditsRows, setSelectedCreditsRows] = useState([]);
  const [transferAmount, setTransferAmount] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [yieldDetails, setYieldDetails] = useState([]);

  const [claimedRanges, setClaimedRanges] = useState([]);
  const [rangeModalVisible, setRangeModalVisible] = useState(false);
  const [totalCredits, setTotalCredits] = useState(null);

  const [isBeneficiarySelected, setIsBeneficiarySelected] = useState(false);
  const [retirementPurpose, setRetirementPurpose] = useState("");

  const [updateYieldBatch] = useUpdateYieldBatchMutation();

  const [allocateForm] = Form.useForm();
  const [claimForm] = Form.useForm();
  const [form] = Form.useForm();

  const [totalTransferCredit, setTotalTransferCredit] = useState(0);

  const showModal = async () => {
    // setSelectedRecord(record);
    setIsAllocateModalVisible(true);
    const totalTransferAmount = selectedRows.reduce(
      (total, row) => total + row.no_of_token,
      0
    );
    setTransferAmount(totalTransferAmount);
    allocateForm.setFieldValue("transfer_amount", totalTransferAmount);
    resetButtonStates();

    const creditBundleUuids = selectedRows.map((row) => row._id);

    try {
      const response = await fetchDetails(creditBundleUuids).unwrap();
      setYieldDetails(response);
    } catch (error) {
      console.error("Failed to fetch details:", error);
    }
  };

  const calculateTransferAmount = (updatedYields) => {
    const total = updatedYields.reduce((sum, yieldDetail) => {
      return (
        sum +
        (yieldDetail.updated_no_of_credits !== undefined
          ? Number(yieldDetail.updated_no_of_credits)
          : yieldDetail.no_of_credits)
      );
    }, 0);

    setTransferAmount(total.toFixed(4));
  };

  const handleRealizedCO2eChange = (index, value) => {
    // Check if the value has decimal places
    const hasDecimal = value % 1 !== 0;

    // Update the row with the new value
    const updatedYields = yieldDetails.map((yieldDetails, i) =>
      i === index
        ? {
            ...yieldDetails,
            updated_no_of_credits: hasDecimal ? value : Math.floor(value),
          }
        : yieldDetails
    );
    setYieldDetails(updatedYields);
    calculateTransferAmount(updatedYields);
  };

  const showcreditsModal = () => {
    setIsAllocateCreditsModalVisible(true);
    const totalTransferAmount = selectedCreditsRows.reduce(
      (total, row) => total + row.no_of_entitlement_record,
      0
    );
    setTransferAmount(totalTransferAmount);
    // form.setFieldValue("transfer_amount", totalTransferAmount);
    resetCreditsButtonStates();
  };

  const showCreateBundleModal = () => {
    setIsCreateBundleModalVisible(true);
    const totalTransferAmount = selectedCreditsRows.reduce(
      (total, row) => total + row.no_of_entitlement_record,
      0
    );
    setTransferAmount(totalTransferAmount);
    // form.setFieldValue("transfer_amount", totalTransferAmount);
    resetCreditsButtonStates();
  };

  const showClaimModal = async () => {
    // setSelectedClaimRecord(record);
    setIsClaimModalVisible(true);
    const totalTransferAmount = selectedRows.reduce(
      (total, row) => total + row.no_of_token,
      0
    );
    setTransferAmount(totalTransferAmount);
    allocateForm.setFieldValue("transfer_amount", totalTransferAmount);
    resetButtonStates();

    const creditBundleUuids = selectedRows.map((row) => row._id);

    try {
      const response = await fetchDetails(creditBundleUuids).unwrap();
      setYieldDetails(response);
    } catch (error) {
      console.error("Failed to fetch details:", error);
    }
  };

  const showCertificateDetails = (record) => {
    if (record?.status === "retired") {
      setIsShowCertificate(false);
      setIsShowRetiredCertificate(true);
    } else {
      setIsShowRetiredCertificate(false);
      setIsShowCertificate(true);
    }
    setSelectedEntryData(record);
  };

  const key = "updatable";
  const handleCancel = () => {
    setIsAllocateModalVisible(false);
    setOrganizationMatchStatus(null);
    allocateForm.resetFields();
    resetButtonStates();
  };

  const handleCreditsCancel = () => {
    setIsAllocateCreditsModalVisible(false);
    setOrganizationMatchStatus(null);
    form.resetFields();
    resetCreditsButtonStates();
  };

  const handleCreateBundleCancel = () => {
    setIsCreateBundleModalVisible(false);
    resetCreditsButtonStates();
  };

  const handleClaimCancel = () => {
    setIsClaimModalVisible(false);
    resetButtonStates();
  };

  let marks = {};

  const closeRangeModal = () => {
    setRangeModalVisible(false);
    marks = {};
  };

  const openRangeModal = (claimedRange, noOfToken) => {
    setRangeModalVisible(true);
    const claimedToken =
      claimedRange?.length > 0
        ? claimedRange[claimedRange?.length - 1][1]
        : "0.0000";
    const totalCredits = Number(noOfToken) + Number(claimedToken);

    setClaimedRanges(claimedRange);
    setTotalCredits(totalCredits);

    const newSliderMarks = {
      0: "0", // Start slider from 0
    };

    if (claimedRange?.length > 0) {
      const formatNumber = (value) => {
        const number = parseFloat(value);
        return Number.isInteger(number) ? number.toString() : number.toFixed(4);
      };

      newSliderMarks[formatNumber(claimedRange[0][0])] = formatNumber(
        claimedRange[0][0]
      );
      newSliderMarks[
        formatNumber(claimedRange[claimedRange.length - 1][1])
      ] = formatNumber(claimedRange[claimedRange.length - 1][1]);
    }
  };

  const {
    onAccept: onAcceptcreateTradableCertificateAction,
  } = useTransactionHandlers({
    actionname: createTradableCertificateAction.name,
  });
  const createTradableCertificateActionInBC = async (payload) => {
    return await onAcceptcreateTradableCertificateAction(payload);
  };

  const handleTransferOfPreEntitlementRecords = async (values) => {
    try {
      setIsSpinning(true);
      if (
        !(
          usergroup.includes("Project Developer") ||
          usergroup.includes("Project Sponsor") ||
          usergroup.includes("Certificate Access")
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const updatedSelectedCreditsRows = selectedCreditsRows
          .map((row) => ({
            ...row,
            updated_no_of_entitlement_record:
              row.updated_no_of_entitlement_record !== undefined
                ? row.no_of_entitlement_record -
                  row.updated_no_of_entitlement_record
                : 0,
          }))
          .filter(
            (row) =>
              row.updated_no_of_entitlement_record !==
              row.no_of_entitlement_record
          );

        await createAllocateActionInBC({
          from: BCAddress,
          to: organizationMatchingData?._id,
          transferAmount: totalTransferCredit,
          entitlementRecordsToTransfer: updatedSelectedCreditsRows,
          flag: "EntitlementRecords",
          tokenType: "NFRY",
        });
      }
    } catch (error) {
      console.error("Error transferring pre credits records:", error);
      message.error({
        content: "Error transferring credits. Please try again later.",
        key,
        duration: 6,
      });
    } finally {
      refetchEntitlementDataList();
      setIsSpinning(false);
      setIsAllocateCreditsModalVisible(false);
      resetCreditsButtonStates();
    }
  };

  const {
    data: orgDetails,
    isLoading: isOrgDetailsLoading,
  } = useGetOrgsQuery();

  if (!isOrgDetailsLoading) {
  }

  const specificGroupList = [];
  if (!isOrgDetailsLoading && orgDetails) {
    orgDetails.orgList.forEach((orgData) => {
      specificGroupList.push(orgData);
    });
  }

  const queryParam = {
    organization_id: userOrgId,
  };

  const {
    data: finalTokenData,
    isLoading: isFinalTokenDataLoading,
  } = useGetFinalTokenByParamQuery(queryParam, {
    skip: !queryParam?.organization_id,
  });

  const [isShowCertificate, setIsShowCertificate] = useState(false);
  const closeCertificateModal = () => {
    setIsShowCertificate(false);
    setIsShowRetiredCertificate(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          type="link"
          disabled={
            selectedRowKeys.length === 0 ||
            selectedRows.some((row) => row?.status === "retired")
          }
          onClick={() => showModal()}
        >
          Transfer Credit
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type="link"
          disabled={
            selectedRowKeys.length === 0 ||
            selectedRows.some((row) => row?.status === "retired")
          }
          onClick={() => showClaimModal()}
        >
          Retire Credit
        </Button>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (record) => {
        return (
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      key: "ptcb",
      title: "Token Id",
      dataIndex: "finaltoken_id",
      render: (record) => {
        return <Typography.Text>PTCB-{record}</Typography.Text>;
      },
    },
    {
      key: "ptc_count",
      title: "Number of Credits",
      dataIndex: "no_of_token",
    },
    {
      key: "owner",
      title: "Owner",
      dataIndex: "organization_id",
      render: (record) => {
        return <DisplayOrganization orgid={record} place="table" />;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        return (
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      key: "created",
      title: "Created At",
      dataIndex: "created",
      render: (record) => {
        const timestamp = record
          ? record["$date"]
            ? record["$date"]
            : record
          : null;
        return (
          <span>
            {timestamp
              ? moment(parseInt(timestamp))
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        // Determine button label based on the record status
        const buttonLabel =
          record?.status === "retired"
            ? "View Proof of Retirement"
            : "View Certificate";

        return (
          <Row gutter={[10, 10]}>
            <Col>
              <Button
                type="primary"
                onClick={() => showCertificateDetails(record)}
                style={{ width: "15rem" }}
              >
                {buttonLabel}
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const creditsmenu = (
    <Menu>
      <Menu.Item>
        <Button
          type="link"
          disabled={selectedCreditsRowKeys.length === 0}
          onClick={() => {
            showCreateBundleModal();
            // handleCreateTradableCertificateRecordModal()
          }}
        >
          Create Credit Bundle
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type="link"
          disabled={selectedCreditsRowKeys.length === 0}
          onClick={() => showcreditsModal()}
        >
          Transfer Credits
        </Button>
      </Menu.Item>
    </Menu>
  );

  const creditscolumns = [
    {
      key: "ghg_project",
      title: "GHG Project",
      render: (_, record) => `${record?.intervention_details?.name}`,
    },
    {
      key: "asset",
      title: "Asset Name",
      render: (_, record) => `${record?.asset_details?.name}`,
    },
    {
      key: "token_info",
      title: "Token Id",
      sorter: (a, b) => {
        const tokenIDA = parseInt(a.yield_details.token_id);
        const tokenIDB = parseInt(b.yield_details.token_id);
        return tokenIDA - tokenIDB;
      },
      defaultSortOrder: "ascend",
      render: (_, record) =>
        `${record?.yield_details?.token_name} - ${record?.yield_details?.token_id}`,
    },
    {
      key: "no_of_entitlement_record",
      title: "Number of Credits",
      dataIndex: "no_of_entitlement_record",
    },
    {
      key: "owner",
      title: "Owner",
      dataIndex: "organization_id",
      render: (record) => {
        return <DisplayOrganization orgid={record} place="table" />;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        return entitlementStatusLabels[record];
      },
    },
    {
      key: "created",
      title: "Created At",
      dataIndex: "created",
      render: (record) => {
        const timestamp = record ? record : null;
        return (
          <span>
            {timestamp
              ? moment(new Date(timestamp))
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
  ];

  const handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
    const totalToken = selectedRows.reduce(
      (sum, yieldItem) => sum + (parseFloat(yieldItem.no_of_token) || 0),
      0
    );
    setTransferAmount(totalToken);
  };

  const handleCreditsRowSelectChange = (
    selectedCreditsRowKeys,
    selectedCreditsRows
  ) => {
    const initialTotalTransferCredit = selectedCreditsRows.reduce(
      (sum, row) => sum + row.no_of_entitlement_record,
      0
    );
    setTotalTransferCredit(initialTotalTransferCredit);
    setSelectedCreditsRowKeys(selectedCreditsRowKeys);
    setSelectedCreditsRows(selectedCreditsRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectChange,
  };

  const creditsRowSelection = {
    selectedRowKeys: selectedCreditsRowKeys,
    onChange: handleCreditsRowSelectChange,
  };

  const resetButtonStates = () => {
    setSelectedRowKeys([]);
  };

  const resetCreditsButtonStates = () => {
    setSelectedCreditsRowKeys([]);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  const handleCreditsChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  const handleCreateTradableCertificateRecordModal = async () => {
    try {
      setIsSpinning(true);
      const filteredCreditsRows = selectedCreditsRows.filter(
        (item) =>
          (item?.updated_no_of_entitlement_record ??
            item?.no_of_entitlement_record) > 0
      );
      if (
        !(
          usergroup.includes("Project Developer") ||
          usergroup.includes("Project Sponsor") ||
          usergroup.includes("Certificate Access")
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        let noOfEntitlementRecords = [];
        filteredCreditsRows.forEach((item) => {
          noOfEntitlementRecords.push(item.no_of_entitlement_record);
        });

        const inputYields = filteredCreditsRows;
        const interventionIdsSet = new Set();
        filteredCreditsRows.forEach((row) => {
          const interventionId = row?.intervention_details?._id;
          if (interventionId) {
            interventionIdsSet.add(interventionId);
          }
        });

        await createTradableCertificatesUtil(
          interventionIdsSet,
          noOfEntitlementRecords,
          inputYields,
          setIsSpinning,
          createTradableCertificateActionInBC,
          updateYieldBatch,
          userid
        );
      }
    } catch (error) {
      message.error({
        content: "Error in creating credits. Please try again later.",
        key,
        duration: 6,
      });
    } finally {
      refetchEntitlementDataList();
      setIsSpinning(false);
      resetCreditsButtonStates();
      setIsCreateBundleModalVisible(false);
    }
  };

  const { data = [] } = useGetInterventionsQuery();

  const {
    data: entitlementdatalist,
    isLoading: entitlementdatalistloading,
    refetch: refetchEntitlementDataList,
  } = useGetPreEntitilementRecordByOrganizationQuery();
  if (!entitlementdatalistloading) {
  }
  const entitlementStatusLabels = {
    realized: "Realized",
    notrealized: "Not Realized",
    inactive: "Inactive",
  };

  const FilterTabBasedData = (entitlementData) => {
    return entitlementData?.filter(
      (item) => item.organization_id === userOrgId && item.status === "realized"
    );
  };

  const handleTransferAction = async (values) => {
    try {
      setIsSpinning(true);
      allocateForm.resetFields();
      if (
        !(
          usergroup.includes("Project Developer") ||
          usergroup.includes("Project Sponsor") ||
          usergroup.includes("Certificate Access")
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const updateSelectedRows = () => {
          return selectedRows.map((selectedRow) => {
            const matchingYields = yieldDetails.filter(
              (yieldDetail) =>
                yieldDetail.finaltoken_id === selectedRow.finaltoken_id &&
                yieldDetail.finaltoken_name === selectedRow.finaltoken_name
            );

            const sumOfCredits = matchingYields.reduce((sum, yieldDetail) => {
              return (
                sum +
                (yieldDetail.updated_no_of_credits !== undefined
                  ? yieldDetail.updated_no_of_credits
                  : yieldDetail?.no_of_credits)
              );
            }, 0);

            const updatedNoOfToken = selectedRow.no_of_token - sumOfCredits;

            return {
              ...selectedRow,
              updated_no_of_token: updatedNoOfToken,
            };
          });
        };

        if (
          !(await createAllocateActionInBC({
            from: BCAddress,
            to: organizationMatchingData?._id,
            finalCertificate: updateSelectedRows(),
            tokenType: "PTCB",
            transferAmount: transferAmount,
            yieldDetails: yieldDetails,
          }))
        )
          return;
      }
      allocateForm.setFieldsValue({ organization_id: undefined });
    } catch {
    } finally {
      setIsSpinning(false);
      setIsAllocateModalVisible(false);
    }
  };

  const handleClaimAction = async (values) => {
    try {
      setIsSpinning(true);
      claimForm.resetFields();
      if (
        !(
          usergroup.includes("Project Developer") ||
          usergroup.includes("Project Sponsor") ||
          usergroup.includes("Certificate Access")
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const updateSelectedRows = () => {
          return selectedRows.map((selectedRow) => {
            const matchingYields = yieldDetails.filter(
              (yieldDetail) =>
                yieldDetail.finaltoken_id === selectedRow.finaltoken_id &&
                yieldDetail.finaltoken_name === selectedRow.finaltoken_name
            );

            const sumOfCredits = matchingYields.reduce((sum, yieldDetail) => {
              return (
                sum +
                (yieldDetail.updated_no_of_credits !== undefined
                  ? yieldDetail.updated_no_of_credits
                  : yieldDetail?.no_of_credits)
              );
            }, 0);

            const updatedNoOfToken = selectedRow.no_of_token - sumOfCredits;

            return {
              ...selectedRow,
              updated_no_of_token: updatedNoOfToken,
            };
          });
        };

        if (
          !(await createClaimActionInBC({
            finalCertificate: updateSelectedRows(),
            claimAmount: transferAmount,
            yieldDetails: yieldDetails,
            retirementPurpose: values?.retirementPurpose,
            beneficiaryName: values?.beneficiary_name,
          }))
        )
          return;
      }
    } catch {
    } finally {
      setIsSpinning(false);
      setIsClaimModalVisible(false);
      setIsAllocateModalVisible(false);
    }
  };

  const handleRowChange = (value, index) => {
    // Check if the value has decimal places
    const hasDecimal = value % 1 !== 0;

    // Update the row with the new value
    const updatedRows = selectedCreditsRows.map((row, i) =>
      i === index
        ? {
            ...row,
            updated_no_of_entitlement_record: hasDecimal
              ? value
              : Math.floor(value),
          }
        : row
    );

    setSelectedCreditsRows(updatedRows);
    const newTotalTransferCredit = updatedRows.reduce(
      (sum, row) =>
        sum +
        (row.updated_no_of_entitlement_record !== undefined
          ? row.updated_no_of_entitlement_record
          : row.no_of_entitlement_record),
      0
    );

    setTotalTransferCredit(newTotalTransferCredit);
  };

 

 

  const handleCreditBlur = (index) => {
    const updatedRows = selectedCreditsRows.map((row, i) => {
      if (i === index) {
        const value = row.updated_no_of_credits ?? row.no_of_credits;
        const precision = Number.isInteger(value)
          ? 0
          : Math.min(4, (value.toString().split(".")[1] || "").length);
        return { ...row, precision };
      }
      return row;
    });
    setSelectedCreditsRows(updatedRows);
  };

  const handleCheckboxChange = (e) => {
    setIsBeneficiarySelected(e.target.checked);
    if (!e.target.checked) {
      claimForm.setFieldValue("beneficiary_name", "");
      claimForm.setFieldValue(
        "retirementPurpose",
        `Retired on behalf of ${userOrgName}`
      );
    }
  };

  const handleBeneficiaryNameChange = (e) => {
    claimForm.setFieldValue(
      "retirementPurpose",
      `Retired by ${userOrgName} on behalf of ${e.target.value}`
    );
  };

  return (
    <div style={{ height: "80vh" }}>
      <Spin spinning={isSpinning}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2}>Credit Bundle(s)</Typography.Title>
          <div>
            <>
              <Divider type="vertical"></Divider>
              <Dropdown overlay={menu} trigger={["hover"]}>
                <Button type="primary">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </>
          </div>
        </div>
        <div>
          <Card className="shadow-card" style={{ borderRadius: 8 }}>
            {!isFinalTokenDataLoading && (
              <Table
                columns={columns}
                dataSource={finalTokenData?.filter(
                  (record) => record?.no_of_token !== 0
                )}
                onChange={handleChange}
                rowSelection={rowSelection}
                rowKey={(record) => record._id}
                pagination={{
                  pageSizeOptions: ["10", "50", "100"],
                  showSizeChanger: true,
                }}
              ></Table>
            )}
          </Card>
        </div>

        <div>
          {!usergroup.includes("Certificate Access") &&
            FilterTabBasedData(entitlementdatalist?.data)?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Typography.Title level={2}>Credits</Typography.Title>
                  <Divider type="vertical" />
                  <Dropdown overlay={creditsmenu} trigger={["hover"]}>
                    <Button type="primary">
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
                <Card className="shadow-card" style={{ borderRadius: 8 }}>
                  <Table
                    columns={creditscolumns}
                    dataSource={FilterTabBasedData(entitlementdatalist?.data)}
                    onChange={handleCreditsChange}
                    rowSelection={creditsRowSelection}
                    rowKey={(creditsRecord) => creditsRecord._id}
                    pagination={{
                      pageSizeOptions: ["10", "50", "100"],
                      showSizeChanger: true,
                    }}
                  />
                </Card>
              </>
            )}
        </div>
        <Modal
          title="Transfer Credit"
          visible={isAllocateModalVisible}
          onCancel={handleCancel}
          width={900}
          onOk={() => {
            allocateForm
              .validateFields()
              .then(async (values) => {
                allocateForm.resetFields();
                setOrganizationMatchStatus(null);
                handleTransferAction(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          okButtonProps={{ disabled: transferAmount == 0 }}
        >
          <Spin spinning={isSpinning || isLoading}>
            <Form form={allocateForm}>
              <Form.Item
                name="organization_id"
                label="Organization"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Organization name",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const organizationName = specificGroupList?.find(
                        (org) =>
                          org?.name?.toLowerCase() === value?.toLowerCase()
                      );
                      if (organizationName) {
                        setOrganizationMatchingData(organizationName);
                        setOrganizationMatchStatus("success");
                        return Promise.resolve();
                      } else {
                        setOrganizationMatchStatus("error");
                        return Promise.reject(
                          new Error("Organization name does not match!")
                        );
                      }
                    },
                  }),
                ]}
                validateStatus={organizationMatchStatus}
                help={
                  organizationMatchStatus === "success" ? (
                    <span style={{ color: "green" }}>
                      Organization match found
                    </span>
                  ) : null
                }
                extra={
                  <p>
                    The Organization name must match an existing Organization in
                    the system.
                  </p>
                }
              >
                <Input
                  onChange={() => setOrganizationMatchStatus(null)}
                  suffix={
                    organizationMatchStatus === "success" ? (
                      <CheckCircleOutlined
                        style={{ color: "green", fontSize: "16px" }}
                      />
                    ) : null
                  }
                />
              </Form.Item>
              {/* Header Row */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr 1fr",
                  alignItems: "center",
                  padding: "10px 15px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  marginBottom: "16px",
                }}
              >
                <div>Credit Bundle ID</div>
                <div>Project Name</div>
                <div>Asset Name</div>
                <div>Period</div>
                <div>Token ID</div>
                <div>Credits</div>
              </div>

              {/* Content Rows */}
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginBottom: "15px",
                }}
              >
                {yieldDetails.map((detail, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr 1fr",
                      alignItems: "center",
                      marginBottom:
                        index === yieldDetails.length - 1 ? "0" : "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div>{`${detail?.finaltoken_name} - ${detail?.finaltoken_id}`}</div>
                    <div>{`${detail?.intervention_name}`}</div>
                    <div>{`${detail?.asset_name}`}</div>
                    <div>{`${detail?.yield_period}`}</div>
                    <div>{`${detail?.yield_token_name} - ${detail?.yield_token_id}`}</div>
                    <div>
                      <InputNumber
                        min={0}
                        step={1}
                        value={
                          detail.updated_no_of_credits !== undefined
                            ? detail.updated_no_of_credits
                            : detail.no_of_credits
                        }
                        onChange={(value) =>
                          handleRealizedCO2eChange(index, value)
                        }
                        max={detail.no_of_credits}
                        precision={4}
                        formatter={(value) => {
                          return value ? parseFloat(value).toFixed(Math.min(4, (value.toString().split('.')[1] || "").length)) : '0';
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <Form.Item label="Transfer Amount">
                <InputNumber value={transferAmount} precision={4} disabled />
              </Form.Item>
              {transferAmount == 0 && (
                <div style={{ color: "red", marginBottom: "4px" }}>
                  Transfer amount should be greater than zero.
                </div>
              )}
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              Retire Credit
              <Popover
                content={
                  <>
                    Here you can retire your credits. Please select a specific
                    amount of credits if you don't want to retire the full
                    credit bundle.
                    <br />
                    There will be a retirement purpose derived automatically,
                    which can be manually updated.
                    <br />
                    If you are retiring the credits for another beneficiary,
                    indicate this below and add the beneficiary name in the
                    textbox.
                  </>
                }
              >
                <span
                  className="info-icon"
                  style={{
                    marginLeft: "5px",
                    alignSelf: "end",
                  }}
                >
                  <InfoCircleOutlined />
                </span>
              </Popover>
            </div>
          }
          visible={isClaimModalVisible}
          onCancel={handleClaimCancel}
          width={1000}
          onOk={() => {
            claimForm
              .validateFields()
              .then(async (values) => {
                if (!isBeneficiarySelected) {
                  // Set beneficiary_name to userOrgName
                  values.beneficiary_name = userOrgName;
                }
                claimForm.resetFields();
                setIsBeneficiarySelected(false);
                handleClaimAction(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          okButtonProps={{ disabled: transferAmount == 0 }}
        >
          <Spin spinning={isSpinning}>
            <Form form={claimForm}>
              <Form.Item name="beneficiary_checkbox_selected">
                <Checkbox onChange={handleCheckboxChange}>
                  Are you retiring credits for a different beneficiary?
                </Checkbox>
              </Form.Item>
              {isBeneficiarySelected && (
                <Form.Item
                  name="beneficiary_name"
                  label="Beneficiary Name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ width: "40%" }}
                  rules={[
                    {
                      required: isBeneficiarySelected,
                      message: "Beneficiary name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter the beneficiary name"
                    onChange={handleBeneficiaryNameChange}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="retirementPurpose"
                label="Retirement Purpose"
                initialValue={`Retired on behalf of ${userOrgName}`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  rows={4}
                  autoSize={{
                    minRows: 4,
                    maxRows: 5,
                  }}
                  placeholder="Retirement Purpose..."
                  style={{ marginBottom: "16px" }}
                  value={retirementPurpose}
                  onChange={(e) => setRetirementPurpose(e.target.value)}
                />
              </Form.Item>
              {/* Header */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr 1fr 1fr", // First two columns wider
                  gap: "16px", // Adjust column spacing
                  alignItems: "center",
                  padding: "10px 15px",
                  marginBottom: "16px",
                  backgroundColor: "#f0f0f0", // Background color for header
                  border: "1px solid #ccc", // Border for consistency
                  borderRadius: "4px",
                  fontWeight: "bold", // Bold text for header
                  fontSize: "14px", // Consistent font size
                }}
              >
                <div>Credit Bundle ID</div>
                <div>Project Name</div>
                <div>Asset Name</div>
                <div>Period</div>
                <div>Token ID</div>
                <div>Credits</div>
                <div>Range</div>
              </div>

              {/* Content */}
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginBottom: "15px",
                }}
              >
                {yieldDetails.map((detail, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr 1fr 1fr", // First two columns wider
                      gap: "16px", // Adjust column spacing to match header
                      alignItems: "center",
                      marginBottom:
                        index === yieldDetails.length - 1 ? "0" : "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      backgroundColor: "#f9f9f9", // Light background for content
                      fontSize: "14px", // Ensure the font size matches the header
                    }}
                  >
                    <div>{`${detail?.finaltoken_name} - ${detail?.finaltoken_id}`}</div>
                    <div>{`${detail?.intervention_name}`}</div>
                    <div>{`${detail?.asset_name}`}</div>
                    <div>{`${detail.yield_period}`}</div>
                    <div>{`${detail?.yield_token_name} - ${detail?.yield_token_id}`}</div>
                    <div>
                      <InputNumber
                        min={0.0}
                        step={1}
                        value={
                          detail?.updated_no_of_credits !== undefined
                            ? detail?.updated_no_of_credits
                            : detail?.no_of_credits
                        }
                        onChange={(value) =>
                          handleRealizedCO2eChange(index, value)
                        }
                        max={detail?.no_of_credits}
                        precision={
                         4
                        }
                        formatter={(value) => {
                          return value ? parseFloat(value).toFixed(Math.min(4, (value.toString().split('.')[1] || "").length)) : '0';
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        value={`${
                          detail?.final_token_claimed_range?.length > 0
                            ? `0 - ${parseFloat(
                                detail?.final_token_claimed_range[
                                  detail?.final_token_claimed_range.length - 1
                                ][1]
                              )}`
                            : ""
                        }`}
                        disabled
                        style={{ marginRight: "8px", width: "100%" }}
                      />
                      <InfoCircleOutlined
                        onClick={() =>
                          openRangeModal(
                            detail?.final_token_claimed_range,
                            detail?.no_of_token
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <Form.Item label="Amount of credits to be retired">
                <InputNumber value={transferAmount} precision={4} disabled />
              </Form.Item>

              {transferAmount == 0 && (
                <div style={{ color: "red", marginBottom: "4px" }}>
                  Amount of credits to be retired should be greater than zero.
                </div>
              )}
            </Form>
          </Spin>
        </Modal>
        <Modal
          title="Claimed Range"
          visible={rangeModalVisible}
          onCancel={closeRangeModal}
          footer={null}
        >
          <div style={{ position: "relative" }}>
            <Slider
              min={0}
              step={0.0001}
              range
              max={totalCredits}
              value={[
                0,
                claimedRanges?.length > 0
                  ? parseFloat(claimedRanges[claimedRanges?.length - 1][1])
                  : 0,
              ]}
              disabled
              marks={null}
            />
            <div style={{ marginTop: "10px" }}>
              <Typography.Text>
                Claimed Ranges:{" "}
                {claimedRanges?.map((range) => `0 - ${range[1]}`).join(", ")}
              </Typography.Text>
            </div>
          </div>
        </Modal>
        <Modal
          title="Transfer Credits"
          visible={isAllocateCreditsModalVisible}
          onCancel={handleCreditsCancel}
          width={900}
          confirmLoading={isSpinning}
          onOk={() => {
            form
              .validateFields()
              .then(async (values) => {
                try {
                  form.resetFields();
                  setOrganizationMatchStatus(null);
                  handleTransferOfPreEntitlementRecords(values);
                  form.setFieldsValue({ organization_id: undefined });
                } catch {
                } finally {
                  resetCreditsButtonStates();
                }
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
                resetCreditsButtonStates();
              });
          }}
          okButtonProps={{ disabled: totalTransferCredit === 0 }}
        >
          <Spin spinning={isSpinning}>
            <Form form={form}>
            <Form.Item
                name="organization_id"
                label="Organization"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Organization name",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const organizationName = specificGroupList?.find(
                        (org) => org?.name?.toLowerCase() === value?.toLowerCase()
                      );
                      if (organizationName) {
                        setOrganizationMatchingData(organizationName);
                        setOrganizationMatchStatus("success");
                        return Promise.resolve();
                      } else {
                        setOrganizationMatchStatus("error");
                        return Promise.reject(new Error("Organization name does not match!"));
                      }
                    },
                  }),
                ]}
                validateStatus={organizationMatchStatus}
                help={
                  organizationMatchStatus === "success" ? (
                    <span style={{ color: "green" }}>Organization match found</span>
                  ) : null
                }
                extra={
                  <p>The Organization name must match an existing Organization in the system.</p>
                }
              >
                <Input
                  onChange={() => setOrganizationMatchStatus(null)}
                  suffix={
                    organizationMatchStatus === "success" ? (
                      <CheckCircleOutlined
                        style={{ color: "green", fontSize: "16px" }}
                      />
                    ) : null
                  }
                />
              </Form.Item>
              {/* Header Row */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr", 
                  alignItems: "center",
                  marginBottom: "16px",
                  padding: "10px 15px", 
                  backgroundColor: "#f0f0f0", 
                  borderRadius: "4px", 
                  fontWeight: "bold", 
                }}
              >
                <div>Project Name</div>
                <div>Asset Name</div>
                <div>Token ID</div>
                <div>Period</div>
                <div>Credit Count</div>
              </div>

              {/* Content Rows */}
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginBottom: "15px",
                }}
              >
                {selectedCreditsRows.map((row, index) => (
                  <div
                    key={row._id}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
                      alignItems: "center",
                      marginBottom:
                        index === selectedCreditsRows.length - 1 ? "0" : "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div>{row?.intervention_details?.name}</div>
                    <div>{row?.asset_details?.name}</div>
                    <div>{`${row.yield_details?.token_name}-${row.yield_details?.token_id}`}</div>
                    <div>{row?.yield_details?.period}</div>
                    <div>
                      <InputNumber
                        value={
                          row.updated_no_of_entitlement_record !== undefined
                            ? row.updated_no_of_entitlement_record
                            : row.no_of_entitlement_record
                        }
                        min={0.0}
                        max={row.no_of_entitlement_record}
                        step={1}
                        precision={
                         4
                        }
                        onChange={(value) => handleRowChange(value, index)}
                        formatter={(value) => {
                          return value ? parseFloat(value).toFixed(Math.min(4, (value.toString().split('.')[1] || "").length)) : '0';
                        }}
                        style={{ marginLeft: "8px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <Form.Item label="Credit Count">
                <InputNumber
                  value={totalTransferCredit}
                  precision={4}
                  disabled
                />
              </Form.Item>

              {totalTransferCredit === 0 && (
                <div style={{ color: "red", marginBottom: "4px" }}>
                  Credit count should be greater than zero.
                </div>
              )}
            </Form>
          </Spin>
        </Modal>
        <Modal
          title="Create Credit Bundle"
          open={isCreateBundleModalVisible}
          onCancel={handleCreateBundleCancel}
          width={900}
          confirmLoading={isSpinning}
          onOk={() => {
            handleCreateTradableCertificateRecordModal().catch((info) => {
              console.log("Validate Failed:", info);
            });
          }}
          okButtonProps={{ disabled: totalTransferCredit === 0 }}
        >
          <Spin spinning={isSpinning}>
            <Form form={form}>
              {/* Header Row */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
                  alignItems: "center",
                  marginBottom: "16px",
                  padding: "10px 15px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4px",
                  fontWeight: "bold",
                }}
              >
                <div>Project Name</div>
                <div>Asset Name</div>
                <div>Token ID</div>
                <div>Period</div>
                <div>Credit Count</div>
              </div>

              {/* Content Rows */}
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginBottom: "15px",
                }}
              >
                {selectedCreditsRows.map((row, index) => (
                  <div
                    key={row._id}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr", // Same grid structure
                      alignItems: "center",
                      marginBottom:
                        index === selectedCreditsRows.length - 1 ? "0" : "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px 15px", // Same padding for alignment
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div>{row?.intervention_details?.name}</div>
                    <div>{row?.asset_details?.name}</div>
                    <div>{`${row.yield_details?.token_name}-${row.yield_details?.token_id}`}</div>
                    <div>{row?.yield_details?.period}</div>
                    <div>
                      <InputNumber
                        value={
                          row.updated_no_of_entitlement_record !== undefined
                            ? row.updated_no_of_entitlement_record
                            : row.no_of_entitlement_record
                        }
                        min={0.0}
                        max={row.no_of_entitlement_record}
                        step={1}
                        precision={4}
                        formatter={(value) => {
                          return value ? parseFloat(value).toFixed(Math.min(4, (value.toString().split('.')[1] || "").length)) : '0';
                        }}
                        onChange={(value) => handleRowChange(value, index)}
                        style={{ marginLeft: "8px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <Form.Item label="Credit Count">
                <InputNumber
                  value={totalTransferCredit}
                  precision={4}
                  disabled
                />
              </Form.Item>
              {totalTransferCredit === 0 && (
                <div style={{ color: "red", marginBottom: "4px" }}>
                  Credit count should be greater than zero.
                </div>
              )}
            </Form>
          </Spin>
        </Modal>
        {finalTokenData?.map((tokenData) => {
          if (tokenData?._id === selectedEntryData?._id) {
            if (isShowRetiredCertificate) {
              // Render ViewRetirementDownloadCertificate when the certificate is retired
              return (
                <ViewRetirementDownloadCertificate
                  key={tokenData?._id}
                  finalTokenData={tokenData}
                  isShowRetiredCertificate={isShowRetiredCertificate}
                  yieldRecord={tokenData?.final_certificate_json}
                  closeModal={closeCertificateModal}
                />
              );
            } else if (isShowCertificate) {
              // Render ViewDownloadCertificate when it's a regular certificate
              return (
                <ViewDownloadCertificate
                  key={tokenData?._id}
                  finalTokenData={tokenData}
                  isShowCertificate={isShowCertificate}
                  yieldRecord={tokenData?.final_certificate_json}
                  closeModal={closeCertificateModal}
                />
              );
            }
          }
          return null;
        })}
      </Spin>
    </div>
  );
}

export default CertificateView;
