import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Collapse,
  List,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import { useTransactionHandlers } from "../../../bc-client/useTransactionHandlers";
import { validateGHGProjectAction } from "../../../constants/actionNames";
import { useUpdateInterventionStatusMutation } from "../../../store/is/interventionapi";
import AddDocument from "../AddDocument";

export default function MakeActive({
  isCreate,
  closeModal,
  spinning,
  setSpinning,
  interventionDetails,
  usergroup,
  handleDispatchMakeActive,
  updateDocument,
}) {
  const key = "updatable";

  const [openMakeActiveConfirmModal, setMakeActiveConfirmModal] = useState(
    false
  );
  const [confirmMakeActiveModal, setConfirmLoadingMakeActiveModal] = useState(
    false
  );
  const [updateInterventionStatus] = useUpdateInterventionStatusMutation();

  useEffect(() => {
    if (isCreate) {
      setMakeActiveConfirmModal(true);
    }
  }, [isCreate]);

  const [uplodedDocumentHashes, setUplodedDocumentHashes] = useState([]);

  const getDocumentHashes = (document) => {
    let uplodedDocumentHashesUpdate = [...uplodedDocumentHashes];
    uplodedDocumentHashesUpdate.push(document?.document_hash);
    setUplodedDocumentHashes(uplodedDocumentHashesUpdate);
    updateDocument(document);
  };

  //********bc-client starts */
  const { onAccept: onAcceptValidateGHGProjectAction } = useTransactionHandlers(
    {
      actionname: validateGHGProjectAction.name,
    }
  );
  const validateGHGProjectActionInBC = async (payload) => {
    return await onAcceptValidateGHGProjectAction(payload);
  };
  //********bc-client ends */

  const handleOkOfMakeActiveModal = async () => {
    let interventionUpdateResponse;
    const intervstatusupdate = { ...interventionDetails };
    intervstatusupdate["status"] = "Active";

    try {
      setSpinning(true);
      if (!usergroup.includes("Validator")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const payload = {
          intervention: interventionDetails,
          dochashes: uplodedDocumentHashes,
        };
        const result = await validateGHGProjectActionInBC(payload);
        if (!result) {
          return;
        }
        interventionUpdateResponse = await handleIntervEditRecord(
          intervstatusupdate
        );
      }
    } catch (error) {
      console.error("Error updating intervention:", error);
    } finally {
      setSpinning(false);
    }
  };

  const handleCancelOfMakeActiveModal = async () => {
    let interventionUpdateResponse;
    const intervstatusupdate = { ...interventionDetails };
    intervstatusupdate["status"] = "Revision";

    try {
      if (!usergroup.includes("Validator")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        interventionUpdateResponse = await handleIntervEditRecord(
          intervstatusupdate
        );
      }
    } catch (error) {
      console.error("Error updating intervention:", error);
    } finally {
      setConfirmLoadingMakeActiveModal(false);
    }
  };

  const handleIntervEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      console.log("payload", payload);
      const res = await updateInterventionStatus(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "GHG Project updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          handleDispatchMakeActive(res);
          setMakeActiveConfirmModal(false);
          return res;
        })
        .catch((err) => {
          console.log("err", err);
          message.error({
            content:
              "Failed to update GHG Project: " + Object.values(err["data"])[0],
            key,
            duration: 6,
          });
          console.log(err);
        });
      return res;
    } catch {}
  };

  return (
    <div>
      <>
        {isCreate ? (
          <MakeActiveModal
            visible={isCreate}
            interventionDetails={interventionDetails}
            openMakeActiveConfirmModal={openMakeActiveConfirmModal}
            handleOkOfMakeActiveModal={handleOkOfMakeActiveModal}
            handleCancelOfMakeActiveModal={handleCancelOfMakeActiveModal}
            confirmMakeActiveModal={confirmMakeActiveModal || spinning}
            updateDocument={getDocumentHashes}
            closeModal={closeModal}
            onCancel={() => {
              if (!(confirmMakeActiveModal || spinning)) closeModal();
            }}
          />
        ) : null}
      </>
    </div>
  );
}

const isMakeActiveDisabled = false;
const MakeActiveModal = ({
  visible,
  openMakeActiveConfirmModal,
  handleOkOfMakeActiveModal,
  updateDocument,
  handleCancelOfMakeActiveModal,
  confirmMakeActiveModal,
  closeModal,
  interventionDetails,
}) => {
  const { Panel } = Collapse;
  const { Text, Paragraph } = Typography;
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [uplodedDocumentList, setUplodedDocumentList] = useState([]);

  const closeDocumentModal = () => {
    setIsCreateDocument(false);
  };

  const displayDocument = (document) => {
    let uplodedDocumentListToUpdate = [...uplodedDocumentList];
    uplodedDocumentListToUpdate.push({
      docname: document?.docname,
      docdname: document?.document_display_name,
    });
    setUplodedDocumentList(uplodedDocumentListToUpdate);
    updateDocument(document);
  };

  const renderValidationDocPanelContent = () => {
    return null;
  };

  return (
    <>
      <Modal
        title=" Perform Validation"
        visible={openMakeActiveConfirmModal}
        onOk={handleOkOfMakeActiveModal}
        okText=" Perform Validation"
        confirmLoading={confirmMakeActiveModal}
        onCancel={closeModal}
        // okButtonProps={{ disabled: isMakeActiveDisabled }}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancelOfMakeActiveModal}
            style={{ color: "red" }}
          >
            Revise
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOkOfMakeActiveModal}
            loading={confirmMakeActiveModal}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>
          As a VVB I have reviewed and validated the POD and any other
          supporting documents of the project. Upload the validation report that
          documents the outcome of the validation.
        </p>
        <Button
          type="primary"
          onClick={() => {
            setIsCreateDocument(true);
          }}
        >
          Upload Documents
        </Button>
        <br />
        {uplodedDocumentList?.length > 0 && (
          <List
            dataSource={uplodedDocumentList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.docname}</Typography.Text>
                <Typography.Text>{item.docdname}</Typography.Text>
              </List.Item>
            )}
          />
        )}
        <br />

        {isMakeActiveDisabled === false ? (
          <p>
            Confirm that the validation has been completed by clicking the
            Confirm button below and publish the validation report on the Proba
            registry.
          </p>
        ) : (
          <>
            <p>
              Please fix the following errors to make the project ready for
              active
            </p>

            <Collapse defaultActiveKey={["FeedbackDoc"]}>
              {renderValidationDocPanelContent() !== null && (
                <Panel
                  header={
                    <Space>
                      <Text strong>Errors in Validation Document</Text>
                      {renderValidationDocPanelContent() === null ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                      )}
                    </Space>
                  }
                  key="project"
                >
                  {renderValidationDocPanelContent()}
                </Panel>
              )}
            </Collapse>
          </>
        )}
      </Modal>
      <AddDocument
        ids={[interventionDetails?._id]}
        entity="intervention"
        updateDocument={displayDocument}
        isCreate={isCreateDocument}
        closeModal={closeDocumentModal}
      />
    </>
  );
};
