import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bcclientApi = createApi({
  reducerPath: "bcclientApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: "/bc/contract",
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
 }),
  tagTypes: ["BcClient"],
  endpoints: (build) => ({
    sendBlockChainRequest: build.mutation({
      query(body) {
        return {
          url: "/",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["BcClient"],
    }),
  }),
});

export const { useSendBlockChainRequestMutation } = bcclientApi;
