import { React, useState } from "react";
import {
  useDeleteInterventionMutation,
  useGetInterventionsQuery,
} from "../../store/is/interventionapi";

import {
  Button,
  Card,
  Divider,
  Modal,
  Popover,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";

import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
  ImportOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setIntervention } from "../../store/is/isSlice";
import { InterventionImport } from "../Importer/InterventionImport";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import { DisplayUserName } from "../Utils/DisplayUserName";
import TruncateWithTooltip from "./TruncateWithTooltip";

function Intervention() {
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isImport, setIsImport] = useState(false);

  const [editingRecord, setEditingRecord] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  let userid = useSelector((state) => state.auth.userid);
  const orgId = useSelector((state) => state.auth?.org?._id);

  const routeChange = () => {
    dispatch(setIntervention(null));
    let path = `/intervention/add`;
    history.push(path);
  };

  const interventionEditRouteChange = (record) => {
    //set Intervention object in store
    dispatch(setIntervention(record));
    let path = `/intervention/edit`;
    history.push(path);
  };

  const interventionViewRouteChange = (record) => {
    //set Intervention object in store
    dispatch(setIntervention(record));
    let path = `/intervention/view`;
    history.push(path);
  };

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup?.filter(Boolean);
  // let usergroup = useSelector((state) => state.auth.groups?.[0]?.name);

  const truncateString = (str, maxLength, isSummary = false) => {
    if (str.length <= maxLength) return str;
    if (isSummary) {
      return `${str.slice(0, maxLength)}...`;
    } else {
      const half = Math.floor(maxLength / 2);
      return `${str.slice(0, half)}...${str.slice(-half)}`;
    }
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (text) => {
        return <TruncateWithTooltip text={text} />;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },

    {
      key: "ghg_project_summary",
      title: "GHG Project Summary",
      dataIndex: "ghg_project_summary",
      render: (text) => {
        return <span>{truncateString(text, 200, true)}</span>;
      },
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    !usergroup?.includes("Proba Operator") && {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "organization",
      title: "Assignees",
      dataIndex: "organization",
      render: (text, record) => {
        let verifier_org_id, validator_org_id, project_sponsor_org_id;
        if (Array.isArray(record?.assignment)) {
          record?.assignment.map((assignment) => {
            if (assignment.assignee_type === "Validator") {
              validator_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Verifier") {
              verifier_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Project Sponsor") {
              project_sponsor_org_id = assignment?.organization;
            }
          });
          return (
            <Popover
              content={
                <>
                  <Typography.Text strong>Validator:</Typography.Text>
                  <DisplayOrganization orgid={validator_org_id} place="table" />
                  <Typography.Text strong>Verifier:</Typography.Text>
                  <DisplayOrganization orgid={verifier_org_id} place="table" />
                  <Typography.Text strong>Project Sponsor:</Typography.Text>
                  <DisplayOrganization
                    orgid={project_sponsor_org_id}
                    place="table"
                  />
                </>
              }
            >
              <ContactsOutlined />
            </Popover>
          );
        } else {
          return (
            <Popover content={<></>}>
              <ContactsOutlined />
            </Popover>
          );
        }
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        const isProjectRevised = record?.is_project_revised;
        const isProjectStatusNew = record?.status === "New" ? true : false;
        const isProjectStatusRevision =
          record?.status === "Revision" ? true : false;
        const isProjectStatusActive =
          record?.status === "Active" ? true : false;
        const isMatchingOrg = record?.organization === orgId ? true : false;

        return (
          <>
            <FolderViewOutlined
              onClick={() => {
                setIsView(true);
                setIsEditing(false);
                setIsCreate(false);
                onEditRecord(record);
                interventionViewRouteChange(record);
              }}
              style={{ marginRight: 10 }}
            />

            {usergroup?.includes("Project Developer") &&
              isMatchingOrg &&
              !usergroup?.includes("Proba Operator") &&
              !usergroup?.includes("Validator") &&
              (isProjectRevised ||
                isProjectStatusNew ||
                isProjectStatusRevision ||
                isProjectStatusActive) && (
                <>
                  <Divider type="vertical"></Divider>
                  <EditOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                      setIsCreate(false);
                      onEditRecord(record);
                      interventionEditRouteChange(record);
                    }}
                    style={{ marginRight: 10 }}
                  />
                  <Divider type="vertical"></Divider>
                  {!isProjectStatusActive && (
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteRecord(record);
                      }}
                      style={{ color: "red", marginLeft: 10 }}
                    />
                  )}
                </>
              )}
          </>
        );
      },
    },
  ].filter(Boolean); //remove null columns

  const {
    data = [],
    isLoading,
    isFetching,
    isError,
  } = useGetInterventionsQuery();

  const [
    deleteIntervention,
    { isDeleteLoading },
  ] = useDeleteInterventionMutation();

  if (!isLoading) {
    console.log(data);
  }

  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const key = "updatable";

  const handleDeleteRecord = async (id) => {
    try {
      await deleteIntervention(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "GHG Project deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete GHG Project!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        console.log({ ...record });
        console.log({ ...record }._id);

        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  return (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2}>GHG Projects</Typography.Title>
        {usergroup?.includes("Project Developer") && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsEditing(false);
                setIsCreate(true);
                setEditingRecord(null);
                routeChange();
              }}
              type="primary"
            >
              Add
            </Button>
            <Divider type="vertical"></Divider>
            <Button
              disabled
              icon={<ImportOutlined />}
              onClick={() => {
                setIsImport(true);
              }}
              type="primary"
            >
              Import
            </Button>
            <Divider type="vertical"></Divider>
          </div>
        )}
      </div>
      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          <Table
            size="small"
            columns={columns}
            dataSource={data.data}
            pagination={{
              pageSizeOptions: ["10", "50", "100"],
              showSizeChanger: true,
            }}
            onRow={(record) => ({
              onClick: () => {
                interventionViewRouteChange(record);
              },
              style: { cursor: "pointer" },
            })}
          ></Table>
        </Card>
      </div>

      <InterventionImport
        visible={isImport}
        onCancel={() => {
          setIsImport(false);
        }}
      ></InterventionImport>
    </div>
  );
}

export default Intervention;
