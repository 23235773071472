/**
 * Checks for a password between 8 and 32 characters with at least:
 * - One lowercase character
 * - One uppercase character
 * - One number
 * - One special character
 */
export const passwordRegexp = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-@]).{8,32}$");

/**
 * Only works for Latin alphabet (including accented characters, spaces, dots and hyphens), but should be good for now.
 */
export const nameRegexp = new RegExp("^[A-Za-zÀ-ÖØ-öø-ÿ .-]+");

export const isFieldEmpty = (fieldValue: unknown): boolean => {
  if (fieldValue === undefined || fieldValue === null) {
    return true;
  }
  if (Array.isArray(fieldValue)) {
    return fieldValue.every(isFieldEmpty);
  }
  if (typeof fieldValue === "object") {
    return Object?.values(fieldValue).every(isFieldEmpty);
  }
  if (typeof fieldValue === "string") {
    return fieldValue.trim().length === 0;
  }
  return false;
};
