import { React, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Popover,
  Table,
  Typography,
  message,
} from "antd";
import {
  useDeleteAssetMutation,
  useGetAssetsByParamQuery,
} from "../../store/is/assetapi";
import { useGetAssignmentByParamQuery } from "../../store/is/assignmentapi";

import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAsset } from "../../store/is/isSlice";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { DisplayOrganization } from "../Utils/DisplayOrganization";

function Asset({ intid, intname }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const routeChange = () => {
    dispatch(setAsset(null));
    let path = `/asset/add`;
    history.push(path);
  };

  const assetEditRouteChange = (record) => {
    //set Asset object in store
    dispatch(setAsset(record));
    let path = `/asset/edit`;
    history.push(path);
  };

  const assetViewRouteChange = (record) => {
    //set Asset object in store
    dispatch(setAsset(record));
    let path = "/asset/view";
    history.push(path);
  };

  // let usergroup = useSelector((state) => state.auth.groups?.[0]?.name);
  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup.filter(Boolean);
  let intervention = useSelector((state) => state.is.intervention);
  let asset = useSelector((state) => state.is.asset);
  let userid = useSelector((state) => state.auth.userid);
  let orgId = useSelector((state) => state.auth?.org?._id);

  const [isInterventionVerified, setIsInterventionVerified] = useState(false);
  const isProjectStatusNew = intervention?.status === "New" ? true : false;
  const isProjectStatusRevision =
    intervention?.status === "Revision" ? true : false;
  const isProjectStatusPublicReview =
    intervention?.status === "Public Review" ? true : false;
  const isProjectStatusValidatorReview =
    intervention?.status === "Validator Review" ? true : false;
  const isProjectStatusProbaReview =
    intervention?.status === "Proba Review" ? true : false;
  const isProjectStatusActive =
    intervention?.status === "Active" ? true : false;

  const assignment_queryparam = {
    intervention_id: intervention?._id,
    user_id: userid,
  };
  const {
    data: assignmentdata,
    isLoading: isassignmentdataloading,
  } = useGetAssignmentByParamQuery(assignment_queryparam, {
    skip: !assignment_queryparam?.intervention_id,
  });
  useEffect(() => {
    if (!assignmentdata || assignmentdata.length === 0) {
      setIsInterventionVerified(false);
    } else {
      setIsInterventionVerified(
        assignmentdata[0]?.verification_status === "verified"
      );
    }
  }, [assignmentdata]);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "external_asset_id",
      title: "External Asset Id",
      dataIndex: "external_asset_id",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },

    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "organization_id",
      title: "Assignees",
      dataIndex: "organization_id",
      render: (text, record) => {
        let validator_org_id, project_sponsor_org_id;
        if (Array.isArray(record?.assignment)) {
          record?.assignment.map((assignment) => {
            if (assignment.assignee_type === "Validator") {
              validator_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Project Sponsor") {
              project_sponsor_org_id = assignment?.organization;
            }
          });
          return (
            <Popover
              content={
                <>
                  <Typography.Text strong>Validator:</Typography.Text>
                  <DisplayOrganization orgid={validator_org_id} place="table" />
                  <Typography.Text strong>Project Sponsor:</Typography.Text>
                  <DisplayOrganization
                    orgid={project_sponsor_org_id}
                    place="table"
                  />
                </>
              }
            >
              <ContactsOutlined />
            </Popover>
          );
        } else {
          return (
            <Popover content={<></>}>
              <ContactsOutlined />
            </Popover>
          );
        }
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        const isAssetStatusNew = record?.status === "New" ? true : false;
        const isAssetStatusRevision =
          record?.status === "Revision" ? true : false;
        const isAssetStatusActive = record?.status === "Active" ? true : false;
        const isAssetStatusValidatorReview =
          record?.status === "Validator Review" ? true : false;
        const isAssetStatusProbaReview =
          record?.status === "Proba Review" ? true : false;

        return (
          <>
            <FolderViewOutlined
              onClick={() => {
                assetViewRouteChange(record);
              }}
              style={{ marginRight: 10 }}
            />
            {usergroup.includes("Project Developer") &&
            intervention?.organization === orgId &&
            (isProjectStatusNew ||
              isProjectStatusRevision ||
              isProjectStatusActive ||
              isAssetStatusNew ||
              isAssetStatusRevision) &&
            !(
              isAssetStatusActive ||
              isAssetStatusValidatorReview ||
              isAssetStatusProbaReview ||
              isProjectStatusPublicReview ||
              isProjectStatusValidatorReview ||
              isProjectStatusProbaReview
            ) ? (
              <>
                <Divider type="vertical"></Divider>
                <EditOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    assetEditRouteChange(record);
                  }}
                  style={{ marginRight: 10 }}
                />
                <Divider type="vertical"></Divider>
                {record.lock_status !== "lock" ? (
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteRecord(record);
                    }}
                    style={{ color: "red", marginLeft: 10 }}
                  />
                ) : null}
              </>
            ) : null}
          </>
        );
      },
    },
  ].filter(Boolean); //remove null columns

  const queryparam = {
    intervention_id: intid,
  };

  const {
    data = [],
    isLoading,
    isFetching,
    isError,
  } = useGetAssetsByParamQuery(queryparam, {
    skip: !queryparam?.intervention_id,
  });
  const [deleteAsset, { isDeleteLoading }] = useDeleteAssetMutation();

  const key = "updatable";
  const handleDeleteRecord = async (id) => {
    try {
      await deleteAsset(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Asset deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete Asset!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        console.log({ ...record });
        console.log({ ...record }._id);

        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={4}>Assets</Typography.Title>
        {(isProjectStatusNew ||
          isProjectStatusRevision ||
          isProjectStatusActive) &&
          usergroup.includes("Project Developer") &&
          intervention?.organization === orgId && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  routeChange();
                }}
                type="primary"
              >
                Add
              </Button>
            </div>
          )}
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={data.data}
        pagination={{
          pageSizeOptions: ["10", "50", "100"],
          showSizeChanger: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            assetViewRouteChange(record);
          },
          style: { cursor: "pointer" },
        })}
      ></Table>
    </div>
  );
}

export default Asset;
