import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const preentitlementrecordApi = createApi({
  reducerPath: "preentitlementrecordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/entitlement-record",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["PreEntitlementRecord"],
  endpoints: (build) => ({
    getPreEntitlementRecord: build.query({
      query: (id) => `${id}`,
      providesTags: (result, error, id) => [{ type: "PreEntitlementRecord", id }],
    }),
    getPreEntitlementRecords: build.query({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "PreEntitlementRecord", id })), // Individual records
              { type: "PreEntitlementRecord", id: "LIST" }, // List of records
            ]
          : [{ type: "PreEntitlementRecord", id: "LIST" }],
    }),
    getPreEntitlementRecordByParam: build.query({
      query: (params) => ({
        url: "",
        params: params,
      }),
      providesTags: ["PreEntitlementRecord"],
    }),
    getPreEntitilementRecordByYieldIds: build.query({
      query: (params) => ({
        url: "/fetch_by_yieldids",
        params: params,
      }),
      providesTags: ["PreEntitlementRecord"],
    }),
    getPreEntitilementRecordByOrganization: build.query({
      query: () => ({
        url: "/organization",
      }),
      providesTags: ["PreEntitlementRecord"],
    }),
    addPreEntitlementRecord: build.mutation({
      query(body) {
        return {
          url: "",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "PreEntitlementRecord", id: "LIST" }], // Invalidate the list
    }),
    entitlementTransferCalculation: build.mutation({
      query(body) {
        return {
          url: `/transfercalculation`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "PreEntitlementRecord", id: "LIST" }], // Invalidate the list
    }),
    updatePreEntitlementRecord: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "PreEntitlementRecord", id }], // Invalidate the specific record
    }),
    updatePreEntitlementRecords: build.mutation({
      query(payload) {
        return {
          url: `/bulkupdate`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: [{ type: "PreEntitlementRecord", id: "LIST" }], // Invalidate the list
    }),
    deletePreEntitlementRecord: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [{ type: "PreEntitlementRecord", id }], // Invalidate the specific record
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetPreEntitlementRecordQuery,
  useGetPreEntitlementRecordsQuery,
  useGetPreEntitlementRecordByParamQuery,
  useLazyGetPreEntitlementRecordByParamQuery,
  useGetPreEntitilementRecordByYieldIdsQuery,
  useGetPreEntitilementRecordByOrganizationQuery,
  useAddPreEntitlementRecordMutation,
  useEntitlementTransferCalculationMutation,
  useUpdatePreEntitlementRecordMutation,
  useUpdatePreEntitlementRecordsMutation,
  useDeletePreEntitlementRecordMutation,
} = preentitlementrecordApi;