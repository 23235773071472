import { React,  useState } from "react";
import { Form, Modal, Select, Upload, message } from "antd";
import { useGetDocTypesQuery } from "../../store/ds/doctypeapi";
import { documentApi, useUploadFileMutation } from "../../store/ds/documentapi";
import { InboxOutlined } from "@ant-design/icons";
import { interventionApi, useUpdateInterventionDocumentsMutation } from "../../store/is/interventionapi";
import { assetApi, useUpdateAssetDocumentsMutation } from "../../store/is/assetapi";
import store from "../../store/store";

export default function AddDocument({
  ids,
  entity,
  updateDocument,
  editingRecord,
  isEditing,
  isCreate,
  closeModal,
  handleIsDocumentUploaded,
}) {
  const [updateAssetDocuments] = useUpdateAssetDocumentsMutation();
  const [updateInterventionDocuments] = useUpdateInterventionDocumentsMutation();
  const batchSize = 100;

  const onCreate = (values) => {
    if (isEditing) {
      //
    } else if (isCreate) {
      const document = {
        document_id: values.doc[0].document_id + "", // convert to string
        document_hash: values.doc[0].document_hash + "", // convert to string
        docname: values.name,
        document_display_name: values.doc[0].name, // convert to string
        documentVisibility: values?.documentVisibility
          ? values?.documentVisibility
          : "private",
      };
      validateUploadedDocuments(document);
    }
  };

  async function validateUploadedDocuments(document) {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      let existingDocumentsInfo;

      if (entity === "intervention") {
        const interdocs = await store.dispatch(
          interventionApi.endpoints.getInterventionDocs.initiate(id)
        );
        const allDocIds = interdocs?.data?.data?.docs
          ?.filter((d) => d.document_id !== "undefined")
          .map((d) => `${d.document_id}`);

        if (allDocIds?.length > 0) {
          existingDocumentsInfo = await store.dispatch(
            documentApi.endpoints.getDocumentInfoByIds.initiate(allDocIds.join(","))
          );
        }
      } else if (entity === "asset") {
        const assetdocs = await store.dispatch(
          assetApi.endpoints.getAssetDocs.initiate(id)
        );
        const allDocIds = assetdocs?.data?.data?.docs
          ?.filter((d) => d.document_id !== "undefined")
          .map((d) => `${d.document_id}`);

        if (allDocIds?.length > 0) {
          existingDocumentsInfo = await store.dispatch(
            documentApi.endpoints.getDocumentInfoByIds.initiate(allDocIds.join(","))
          );
        }
      }

      // Match docname with existing documents
      const matchedDocs = existingDocumentsInfo?.data?.documents?.filter(
        (doc) => doc.doctype === document?.docname
      );

      if (matchedDocs?.length > 0) {
        const payload = matchedDocs.map((matchedDoc) => ({
          intervention_id: entity === "intervention" ? id : null,
          asset_id: entity === "asset" ? id : null,
          existing_document_id: matchedDoc?.ID,
          updated_document_id: document?.document_id,
        }));

        // Call the appropriate update function
        if (entity === "intervention") {
          await batchUpdate(payload, updateInterventionDocuments);
        } else if (entity === "asset") {
          await batchUpdate(payload, updateAssetDocuments);
        }
      } else {
        // If no match is found, create the document
        updateDocument(document);
      }
    }
  }

  async function batchUpdate(payload, updateFunction) {
    // Create an array to hold all batch promises
    const batchPromises = [];
  
    for (let i = 0; i < payload.length; i += batchSize) {
      const batchUpdate = payload.slice(i, i + batchSize);
  
      batchPromises.push(
        updateFunction(batchUpdate).unwrap().catch(error => {
          console.error("Batch update failed:", error);
          // Return a rejected promise to ensure Promise.all catches this failure
          return Promise.reject(error);
        })
      );
    }
  
    try {
      await Promise.all(batchPromises);
      message.success({
        content: "Documents updated successfully!",
        duration: 2,
      });
    } catch (error) {
      message.error({
        content: "Failed to update Documents!",
        duration: 2,
      });
    }
  }

  return (
    <div>
      <>
        {isEditing || isCreate ? (
          <DocumentFormModal
            visible={isCreate || isEditing}
            onCreate={onCreate}
            onCancel={() => {
              closeModal();
            }}
            editingRecord={editingRecord}
            handleIsDocumentUploaded={handleIsDocumentUploaded}
          />
        ) : null}
      </>
    </div>
  );
}

const DocumentFormModal = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
  handleIsDocumentUploaded,
}) => {
  const {
    data: doctypes,
    isLoading,
    isFetching,
    isError,
  } = useGetDocTypesQuery();

  const [fileList, setFileList] = useState([]);
  const [filePickerDisabled, setFilePickerDisabled] = useState(true);
  const [documentVisibility, setDocumentVisibility] = useState(null);

  const [uploadFileToDS, { isUploadLoading }] = useUploadFileMutation();
  const[isUploadComplete, setIsUploadComplete]= useState(false)

  const { Option } = Select;

  const docNames = [];
  if (!isLoading && doctypes) {
    doctypes.forEach((doctype) => {
      docNames.push(<Option key={doctype.doctype}>{doctype.doctype}</Option>);
    });
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  function uploadFile({ file, onSuccess }) {
    var documentType = "Default";
    form.validateFields().then((values) => {
      documentType = values.name;
      uploadtovault(file, documentType, documentVisibility, onSuccess);

      // To check atleast one document is uploaded to enable sign-off button
      handleIsDocumentUploaded("uploaded");
    });
  }

  async function uploadtovault(
    file,
    documentType,
    documentVisibility,
    onSuccess
  ) {
    const upload_file = new FormData();
    upload_file.append("uploadFile", file);
    upload_file.append("doctype", documentType);
    upload_file.append("authorizeMyOrg", true)
    upload_file.append("documentVisibility", documentVisibility);

    await uploadFileToDS(upload_file)
      .unwrap()
      .then((res) => {
        console.log("----res", res);
        file.document_id = res.document_id;
        file.document_hash = res.document_hash;
        setIsUploadComplete(true)
       
        onSuccess("ok");
        
      })
      .catch((err) => {
        setIsUploadComplete(false)
        console.log("-----err", err);
      });
  }

  const handleDocumentTypeChange = (value) => {
    setFilePickerDisabled(false);
    const selectedDocType = doctypes.find((type) => type.doctype === value);
    setDocumentVisibility(selectedDocType ? selectedDocType.visibility : null);
  };

  const [form] = Form.useForm();

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setIsUploadComplete(false)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      if (fileList.length >= 1) {
        message.error(
          "You can only upload one file!, Please remove the existing file to upload a diffrent file."
        );
        file.status = "error";
        return false;
      }
      setIsUploadComplete(false)
      return true;
    },
    fileList,
  };

  return (
    <Modal
      width={500}
      title="Add Document"
      open={visible}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate({ ...values, documentVisibility });
            onCancel();
            setFileList([]);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ disabled: !isUploadComplete}}
    >
      <Form layout="vertical" form={form} name="document">
        <Form.Item
          initialValue={editingRecord?.type?._id}
          name="name"
          label="Document Name"
          rules={[
            { required: true, message: "Please select the document name" },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Please select the document name"
            onChange={handleDocumentTypeChange}
          >
            {docNames}
          </Select>
        </Form.Item>

        <Form.Item label="Attach Document">
          <Form.Item
            name="doc"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
            rules={[{ required: true, message: "Please upload a document" }]}
          >
            <Upload.Dragger
              {...props}
              name="files"
              customRequest={uploadFile}
              disabled={filePickerDisabled}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag a file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support only a single file upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};
