import { React, useState } from "react";
import {
  useAddOrgTypeMutation,
  useDeleteOrgTypeMutation,
  useGetOrgTypesQuery,
  useUpdateOrgTypeMutation
} from "../../store/auth/orgtypeapi";

import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from "@ant-design/icons";
import moment from 'moment';

import TextArea from "antd/lib/input/TextArea";

function OrgType() {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "desc",
      title: "Description",
      dataIndex: "desc",
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: "createdAt",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "updatedAt",
      title: "Updated",
      dataIndex: "updatedAt",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditRecord(record);
              }}
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical"></Divider>
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 10 }}
            />
          </>
        );
      },
    },
  ];

  const { data = [], isLoading, isFetching, isError } = useGetOrgTypesQuery();
  const [addOrgType, { isCreateLoading }] = useAddOrgTypeMutation();
  const [updateOrgType, { isUpdateLoading }] = useUpdateOrgTypeMutation();
  const [deleteOrgType, { isDeleteLoading }] = useDeleteOrgTypeMutation();

  if (!isLoading) {
  }

  const key = "updatable";
  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const handleAddRecord = async (record) => {
    try {
      await addOrgType(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization Type added successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Organization Type: " + JSON.stringify(err?.data?.error),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const handleEditRecord = async (record) => {
    try {
      await updateOrgType(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization Type updated successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Organization Type!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const handleDeleteRecord = async (id) => {
    try {
      await deleteOrgType(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Organization Type deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete Organization Type!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const onCreate = (values) => {
    if (isEditing) {
      editingRecord.name = values.name;
      editingRecord.desc = values.desc;
      handleEditRecord(editingRecord);
      setIsEditing(false);
    } else if (isCreate) {
      handleAddRecord(values);
    }
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        console.log({ ...record });
        console.log({ ...record }._id);
        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  return (

    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={2}>Organization Types</Typography.Title>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsEditing(false);
              setIsCreate(true);
              setEditingRecord(null);
            }}
          >
            Add
          </Button>


        </div>
      </div>
      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          <Table size="small" columns={columns} dataSource={data}></Table>
        </Card>
      </div>
      <>
        {isEditing || isCreate ? (
          <OrganizationTypeFormModal
            visible={isEditing || isCreate}
            onCreate={onCreate}
            onCancel={() => {
              resetEditing();
            }}
            editingRecord={editingRecord}
          />
        ) : null}
      </>
    </div>
  );
}

const OrganizationTypeFormModal = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
}) => {

  const [form] = Form.useForm();
  return (
    <Modal
      width={500}
      title="Add/Edit Organization Type"
      open={visible}
      okText="Save"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log("------", values);
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="organizationtype"
        initialValues={editingRecord}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input Organization Type name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please input Organization Type desc!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrgType;
