import React from "react";
import { Col, Row, Statistic } from "antd";

import {
  useGetParamQuery
} from "../../../store/is/paramsapi";
import { DisplayValue } from "../../Utils/DisplayValue";

export const ParamView = ({ paramid }) => {

  const { data: methodparam, isLoading: isParamLoading } = useGetParamQuery(paramid);

  const format = (value) => <DisplayValue value={value}></DisplayValue>

  return (
    <>
      {!isParamLoading && methodparam && methodparam?.length > 0 && <Row gutter={[100, 20]}>
        {methodparam[0]?.param.map(item => {
          if (item.key === "Plot Polygon") {
            return null; // Skip rendering
          }
          return (
            <Col span={6}>
              <Statistic title={item.key} value={item.value} formatter={(value) => format(value)} />
            </Col>
          );
        })}
      </Row>}
    </>
  );
};