import { userApi } from "../store/auth/userapi";
import store from "../store/store";
import { orgApi } from "../store/auth/orgapi";

async function getUser(userid) {
  const { data, isLoading } = await store.dispatch(
    userApi.endpoints.getUser.initiate(userid)
  );
  console.log("authutils..getUser..", data, isLoading);
  if (!isLoading) {
    return data;
  }
}

async function getUserByOrg(orgid) {
  try {
    const { data, error, isLoading } = await store.dispatch(
      userApi.endpoints.getUserByOrg.initiate(orgid)
    );
    console.log("authutils..getOrganization..", data, error, isLoading);
    if (!isLoading) {
      if (error) {
        return { error: error.data };
      }
      return data;
    }
  } catch (err) {
    return { error: { text: 'An unexpected error occurred.' } };
  }
}

async function getOrganization(orgid) {
  const { data, isLoading } = await store.dispatch(
    orgApi.endpoints.getOrg.initiate(orgid)
  );
  if (!isLoading) {
    return data;
  }
}


export { getUser, getUserByOrg, getOrganization };
