import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/auth/user/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.query({
      query: (params) => ({
        url: "",
        params,
      }),
      providesTags: ["Users"],
    }),
    getUser: build.query({
      query: (id) => `${id}`,
      providesTags: ["Users"],
    }),
    getUserByOrg: build.query({
      query: (orgid) => `org/${orgid}`,
      providesTags: ["Users"],
    }),
    getGroupUsers: build.query({
      query: (groupId) => `group/${groupId}`,
      providesTags: ["Users"],
    }),
    getGroupUsersByIds: build.query({
      query: (groupIds) => `group/users?ids=${groupIds.join(",")}`,
      providesTags: ["Users"],
    }),
    updateUser: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteUser: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetGroupUsersQuery,
  useGetUserByOrgQuery,
  useGetGroupUsersByIdsQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
