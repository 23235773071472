import { message } from "antd";
export const generateMessageFromStatus = (res) => {
  const messages = {
    success: (res) => {
      message.success(res.message);
    },
    warn: (res) => {
      message.warn(res.message);
    },
    error: (res) => {
      message.error(res.message);
    },
  };
  messages[res.status](res);
};
