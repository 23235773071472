import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Alert, Button, Collapse, Modal, Space, Typography, message } from "antd";
import { useUpdateInterventionStatusMutation } from "../../../store/is/interventionapi";

export default function SubmitForValidation({ isCreate, closeModal, interventionDetails, usergroup, handleDispatchSubmitForValidation }) {
    const key = "updatable";

    const [openSubmitForValidationConfirmModal, setOpenSubmitForValidationConfirmModal] = useState(false);
    const [confirmSubmitForValidationModal, setConfirmLoadingSubmitForValidationModal] = useState(false);

    const [updateInterventionStatus] = useUpdateInterventionStatusMutation();

    useEffect(() => {
        if (isCreate) {
            setOpenSubmitForValidationConfirmModal(true);
        }
    }, [isCreate]);

    const handleOkOfSubmitValidationModal = async () => {
        let interventionUpdateResponse;
        const intervstatusupdate = { ...interventionDetails };
        intervstatusupdate["status"] = "Validator Review";
    
        try {
            if (!usergroup.some(group => group === "Project Developer" || group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } else {
                interventionUpdateResponse = await handleIntervEditRecord(intervstatusupdate);
            }
        } catch (error) {
            console.error("Error updating intervention:", error);
        } finally {
            setConfirmLoadingSubmitForValidationModal(false);
        }
    };

    const handleCancelOfSubmitForRevisionModal = async () => {
        let interventionUpdateResponse;
        const intervstatusupdate = { ...interventionDetails };
        intervstatusupdate["status"] = "Revision";
    
        try {
            if (!usergroup.some(group => group === "Project Developer" || group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } else {
                interventionUpdateResponse = await handleIntervEditRecord(intervstatusupdate);
            }
        } catch (error) {
            console.error("Error updating intervention:", error);
        } finally {
            setConfirmLoadingSubmitForValidationModal(false);
        }
    };

    const handleIntervEditRecord = async (record) => {
        try {
          const payload = {
            id: record._id,
            body: record,
          };
          console.log("payload", payload)
          const res = await updateInterventionStatus(payload)
            .unwrap()
            .then((res) => {
              message.success({
                content: "GHG Project updated successfully!",
                key,
                duration: 2,
              });
              console.log(res);
              handleDispatchSubmitForValidation(res)
              setOpenSubmitForValidationConfirmModal(false);
              return res;
            })
            .catch((err) => {
                console.log("err", err)
              message.error({
                content:
                  "Failed to update GHG Project: " + Object.values(err["data"])[0],
                key,
                duration: 6,
              });
              console.log(err);
            });
          return res;
        } catch { }
      };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitForValidationModal
                            visible={isCreate}
                            openSubmitForValidationConfirmModal={openSubmitForValidationConfirmModal}
                            handleOkOfSubmitValidationModal={handleOkOfSubmitValidationModal}
                            handleCancelOfSubmitForRevisionModal={handleCancelOfSubmitForRevisionModal}
                            confirmSubmitForValidationModal={confirmSubmitForValidationModal}
                            closeModal={closeModal}
                            onCancel={() => {
                                closeModal();
                            }}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const isSubmitForValidationDisabled = false
const SubmitForValidationModal = ({
    visible,
    openSubmitForValidationConfirmModal,
    handleOkOfSubmitValidationModal,
    handleCancelOfSubmitForRevisionModal,
    confirmSubmitForValidationModal,
    closeModal
}) => {
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    const renderFeedbackDocValidationPanelContent = () => {
        return null;
    };

    const renderFeedbackResponseValidationPanelContent = () => {
        return null;
    };

    return (
        <Modal
            title="Submit For Validation"
            visible={openSubmitForValidationConfirmModal}
            onOk={handleOkOfSubmitValidationModal}
            okText="Submit for Public Comment"
            confirmLoading={confirmSubmitForValidationModal}
            onCancel={closeModal}
            // okButtonProps={{ disabled: isSubmitForValidationDisabled }}
            footer={[
                <Button key="cancel" onClick={handleCancelOfSubmitForRevisionModal} style={{ color: 'red' }}>
                    Revise
                </Button>,
                <Button key="submit" type="primary" onClick={handleOkOfSubmitValidationModal} loading={confirmSubmitForValidationModal}>
                    Submit for Validation
                </Button>,
            ]}
        >
             <Alert
                message="Before submitting to Validator Review, please ensure you have added the feedback document and response."
                banner
                closable
                style={{ marginBottom: '16px' }} 
            />
            {isSubmitForValidationDisabled === false ? (
                <p>The Project is ready for the Validation. Do you want to submit for Validation?</p>
            ) : (
                <>
                    <p>Please fix the following errors to make the project ready for Validation</p>

                    <Collapse defaultActiveKey={['FeedbackDoc', 'FeedbackResponse']}>
                        {renderFeedbackDocValidationPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Feedback Document</Text>
                                        {renderFeedbackDocValidationPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderFeedbackDocValidationPanelContent()}
                            </Panel>
                        )}
                        {renderFeedbackResponseValidationPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Feedback Response</Text>
                                        {renderFeedbackResponseValidationPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="assets">
                                {renderFeedbackResponseValidationPanelContent()}
                            </Panel>
                        )}
                    </Collapse>
                </>
            )}
        </Modal>
    );
};