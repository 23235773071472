import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intervention: null,
  asset: null,
  yields: null,
};

export const isSlice = createSlice({
  name: "is",
  initialState,
  reducers: {
    setIntervention: (state, action) => {
      state.intervention = action.payload;
    },
    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setYield: (state, action) => {
      state.yields = action.payload;
    },
  },
});

export const { setAsset, setIntervention, setYield } = isSlice.actions;

export default isSlice.reducer;
