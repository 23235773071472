import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Collapse, Modal, Space, Typography, message } from "antd";
import { useUpdateAssetStatusMutation } from "../../../store/is/assetapi";
import { useGetYieldTransactionsQuery } from "../../../store/is/transactionapi";
import { useUpdateYieldStatusMutation } from "../../../store/is/yieldapi";

export default function PerformOperationaCheckOnYield({ isCreate, closeModal, yieldIds, yieldData, assetDetails, usergroup, isForFirstProbaReview, handleDispatchPerformOperationalCheck }) {
    const key = "updatable";
    const [openPerformOperationalCheckOnYieldModal, setPerformOperationalCheckOnYieldModal] = useState(false);
    const [ConfirmLoadingPerformOperationalCheckOnYield, setConfirmLoadingPerformOperationalCheckOnYield] = useState(false);

    const [updateYieldStatus] = useUpdateYieldStatusMutation();

    const {
        data: yieldTransactions,
        isLoading: isYieldTransactionLoading,
        refetch: refetchYields
    } = useGetYieldTransactionsQuery({
        yield_id: yieldIds,
    }, { skip: !yieldIds });

    const [updateAssetStatus] = useUpdateAssetStatusMutation();

    useEffect(() => {
        if (isCreate) {
            setPerformOperationalCheckOnYieldModal(true);
        }
    }, [isCreate]);

    const handleSubmitForPendingRealization = async () => {
        let yieldUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;

        try {
            if (!usergroup.some((group) => group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            }
            else if (isArrayWithMultipleEntries) {
                let assetstatusupdateList = [];
                for (const asset of assetDetails) {
                    let yieldStatusUpdate = { ...asset };
                    yieldStatusUpdate["status"] = "Pending Realization";
                    assetstatusupdateList.push(yieldStatusUpdate);
                }

                yieldUpdateResponse = await handleAssetUpdateStatusBatch(assetstatusupdateList);
                setPerformOperationalCheckOnYieldModal(false);
            }
            else {
                // const yieldStatusUpdate = { ...assetDetails };
                const yieldStatusUpdate = {};
                yieldStatusUpdate["_ids"] = yieldIds;
                yieldStatusUpdate["status"] = "Pending Realization";
                yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
                setPerformOperationalCheckOnYieldModal(false);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnYield(false);
        }
    };

    const handleSubmittingYieldForValidation = async () => {
        let yieldUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;

        try {
            if (!usergroup.some(group => group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            }
            else if (isArrayWithMultipleEntries) {
                let assetstatusupdateList = [];
                for (const asset of assetDetails) {
                    let yieldStatusUpdate = { ...asset };
                    yieldStatusUpdate["status"] = "Validator Review";
                    assetstatusupdateList.push(yieldStatusUpdate);
                }
                yieldUpdateResponse = await handleAssetUpdateStatusBatch(assetstatusupdateList);
            }
            else {
                const yieldStatusUpdate = {};
                yieldStatusUpdate["_ids"] = yieldIds;
                yieldStatusUpdate["status"] = "Validator Review";
                yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnYield(false);
        }
    };

    const handleSubmittingYieldForVerification = async () => {
        let yieldUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;

        try {
            if (!usergroup.some(group => group === "Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            }
            else if (isArrayWithMultipleEntries) {
                let assetstatusupdateList = [];
                for (const asset of assetDetails) {
                    let yieldStatusUpdate = { ...asset };
                    yieldStatusUpdate["status"] = "Validator Review";
                    assetstatusupdateList.push(yieldStatusUpdate);
                }
                yieldUpdateResponse = await handleAssetUpdateStatusBatch(assetstatusupdateList);
            }
            else {
                const yieldStatusUpdate = {};
                yieldStatusUpdate["_ids"] = yieldIds;
                yieldStatusUpdate["status"] = "Verifier Review";
                yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnYield(false);
        }
    };

    const handleMakingYieldRevision = async () => {
        let yieldUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;

        try {
            if (!usergroup.includes("Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            }
            else if (isArrayWithMultipleEntries) {
                let assetstatusupdateList = [];
                for (const asset of assetDetails) {
                    let yieldStatusUpdate = { ...asset };
                    yieldStatusUpdate["status"] = "Revision";
                    assetstatusupdateList.push(yieldStatusUpdate);
                }
                yieldUpdateResponse = await handleAssetUpdateStatusBatch(assetstatusupdateList);
            }
            else {
                const yieldStatusUpdate = {};
                yieldStatusUpdate["_ids"] = yieldIds;
                yieldStatusUpdate["status"] = "Revision";
                yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformOperationalCheckOnYield(false);
        }
    };

    const handleCancelofPerformOperationalModal = () => {
        setPerformOperationalCheckOnYieldModal(false);
        closeModal();
    };

    const handleYieldEditRecord = async (record) => {
        try {
            const payload = {
                id: record._id,
                body: record,
            };
            const res = await updateYieldStatus(payload)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "Yield updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchPerformOperationalCheck(res);
                    setPerformOperationalCheckOnYieldModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update Yield status: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    const handleAssetUpdateStatusBatch = async (record) => {
        try {
            const res = await updateAssetStatus(record)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "Asset updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchPerformOperationalCheck(res);
                    setPerformOperationalCheckOnYieldModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update Yield status: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitForPerformOperationalCheckOnYieldModal
                            visible={isCreate}
                            openPerformOperationalCheckOnYieldModal={openPerformOperationalCheckOnYieldModal}
                            handleSubmitForPendingRealization={handleSubmitForPendingRealization}
                            handleSubmittingYieldForValidation={handleSubmittingYieldForValidation}
                            handleSubmittingYieldForVerification={handleSubmittingYieldForVerification}
                            handleMakingYieldRevision={handleMakingYieldRevision}
                            ConfirmLoadingPerformOperationalCheckOnYield={ConfirmLoadingPerformOperationalCheckOnYield}
                            handleCancelofPerformOperationalModal={handleCancelofPerformOperationalModal}
                            isForFirstProbaReview={isForFirstProbaReview}
                            closeModal={closeModal}
                            yieldTransactions={yieldTransactions}
                            onCancel={() => {
                                closeModal();
                            }}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const isValidationDisabled = false
const SubmitForPerformOperationalCheckOnYieldModal = ({
    visible,
    openPerformOperationalCheckOnYieldModal,
    handleSubmitForPendingRealization,
    handleSubmittingYieldForValidation,
    handleSubmittingYieldForVerification,
    handleMakingYieldRevision,
    ConfirmLoadingPerformOperationalCheckOnYield,
    handleCancelofPerformOperationalModal,
    isForFirstProbaReview,
    yieldTransactions
}) => {
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    const renderPerformOperationalCheckOnYieldPanelContent = () => {
        return null;
    };

    return (
        <Modal
            title="Operational check"
            visible={openPerformOperationalCheckOnYieldModal}
            okText="Operational"
            confirmLoading={ConfirmLoadingPerformOperationalCheckOnYield}
            onCancel={handleCancelofPerformOperationalModal}
            footer={[
                <Button onClick={handleMakingYieldRevision} style={{ color: 'red' }}>
                    Revision
                </Button>,
                <Button type="primary" onClick={handleSubmitForPendingRealization} loading={ConfirmLoadingPerformOperationalCheckOnYield} disabled={!isForFirstProbaReview}>
                    Pending Realization
                </Button>,
                <Button type="primary" onClick={handleSubmittingYieldForValidation} loading={ConfirmLoadingPerformOperationalCheckOnYield} disabled={!isForFirstProbaReview}>
                    Validation
                </Button>,
                <Button type="primary" onClick={handleSubmittingYieldForVerification} loading={ConfirmLoadingPerformOperationalCheckOnYield} disabled={isForFirstProbaReview}>
                    Verification
                </Button>,

            ]}
        >
            {isValidationDisabled === false ? (
                <p> The yield is ready for the next step. Please select any of the options below. </p>
            ) : (
                <>
                    <p>Please fix the following errors to make the yield ready.</p>

                    <Collapse defaultActiveKey={['Operation Check']}>
                        {renderPerformOperationalCheckOnYieldPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Operational Check</Text>
                                        {renderPerformOperationalCheckOnYieldPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderPerformOperationalCheckOnYieldPanelContent()}
                            </Panel>
                        )}

                    </Collapse>
                </>
            )}
        </Modal>
    );
};