import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Alert, Button, Collapse, Modal, Space, Typography, message } from "antd";
import { useUpdateInterventionStatusMutation } from "../../../store/is/interventionapi";

export default function SubmitToPublicReview({ isCreate, closeModal, interventionDetails, usergroup, handleDispatchSubmitToPublicReview }) {
    const key = "updatable";

    const [openSubmitToPublicReviewConfirmModal, setOpenSubmitToPublicReviewConfirmModal] = useState(false);
    const [confirmSubmitToPublicReviewModal, setConfirmLoadingSubmitToPublicReviewModal] = useState(false);

    const [updateInterventionStatus] = useUpdateInterventionStatusMutation();

    useEffect(() => {
        if (isCreate) {
            setOpenSubmitToPublicReviewConfirmModal(true);
        }
    }, [isCreate]);

    const handleOkOfSubmitToPublicReviewModal = async () => {
        let interventionUpdateResponse;
        const intervstatusupdate = { ...interventionDetails };
        intervstatusupdate["status"] = "Public Review";

        try {
            if (!usergroup.includes("Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } else {
                interventionUpdateResponse = await handleIntervEditRecord(intervstatusupdate);
            }
        } catch (error) {
            console.error("Error updating intervention:", error);
        } finally {
            setConfirmLoadingSubmitToPublicReviewModal(false);
        }
    };

    const handleCancelOfSubmitToRevisionModal = async () => {
        let interventionUpdateResponse;
        const intervstatusupdate = { ...interventionDetails };
        intervstatusupdate["status"] = "Revision";

        try {
            if (!usergroup.includes("Proba Operator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } else {
                interventionUpdateResponse = await handleIntervEditRecord(intervstatusupdate);
            }
        } catch (error) {
            console.error("Error updating intervention:", error);
        } finally {
            setConfirmLoadingSubmitToPublicReviewModal(false);
        }
    };

    const handleCloseModal = () => {
        setOpenSubmitToPublicReviewConfirmModal(false);
        closeModal();
    };

    const handleIntervEditRecord = async (record) => {
        try {
            const payload = {
                id: record._id,
                body: record,
            };
            console.log("payload", payload)
            const res = await updateInterventionStatus(payload)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "GHG Project updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchSubmitToPublicReview(res)
                    setOpenSubmitToPublicReviewConfirmModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update GHG Project: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitToPublicReviewModal
                            visible={isCreate}
                            openSubmitToPublicReviewConfirmModal={openSubmitToPublicReviewConfirmModal}
                            handleOkOfSubmitToPublicReviewModal={handleOkOfSubmitToPublicReviewModal}
                            handleCancelOfSubmitToRevisionModal={handleCancelOfSubmitToRevisionModal}
                            confirmSubmitToPublicReviewModal={confirmSubmitToPublicReviewModal}
                            handleCloseModal={handleCloseModal}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const isSubmitToPublicReviewDisabled = false
const SubmitToPublicReviewModal = ({
    visible,
    openSubmitToPublicReviewConfirmModal,
    handleOkOfSubmitToPublicReviewModal,
    handleCancelOfSubmitToRevisionModal,
    confirmSubmitToPublicReviewModal,
    handleCloseModal
}) => {
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    const renderEligibilityCheckPanelContent = () => {
        return null;
    };

    const renderOperationalCheckPanelContent = () => {
        return null;
    };

    return (
        <Modal
            title="Submit for Public Review"
            open={openSubmitToPublicReviewConfirmModal}
            onOk={handleOkOfSubmitToPublicReviewModal}
            okText="Submit for Public Review"
            confirmLoading={confirmSubmitToPublicReviewModal}
            onCancel={handleCloseModal}
            footer={[
                <Button key="cancel" onClick={handleCancelOfSubmitToRevisionModal} style={{ color: 'red' }}>
                    Revise
                </Button>,
                <Button key="submit" type="primary" onClick={handleOkOfSubmitToPublicReviewModal} loading={confirmSubmitToPublicReviewModal}>
                    Submit for Public Review
                </Button>,
            ]}
        >
            <Alert
                message="Before submitting to Public Review, Please ensure you have performed the Eligibility and Operational checks."
                banner
                closable
                style={{ marginBottom: '16px' }} 
            />
            {isSubmitToPublicReviewDisabled === false ? (
                <p>The project is ready for Public Review. Do you want to submit for Public Review?</p>
            ) : (
                <>
                    <p>Please fix the following errors to make the project ready for Public Review</p>

                    <Collapse defaultActiveKey={['EligibilityCheck', 'OperationalCheck']}>
                        {renderEligibilityCheckPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Eligibility Check</Text>
                                        {renderEligibilityCheckPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderEligibilityCheckPanelContent()}
                            </Panel>
                        )}
                        {renderOperationalCheckPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Operational Check</Text>
                                        {renderOperationalCheckPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="assets">
                                {renderOperationalCheckPanelContent()}
                            </Panel>
                        )}
                    </Collapse>
                </>
            )}
        </Modal>
    );
};