import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Collapse, List, Modal, Space, Typography, message } from "antd";
import { useTransactionHandlers } from "../../../bc-client/useTransactionHandlers";
import { validateAssetsAction } from "../../../constants/actionNames";
import { useUpdateAssetStatusMutation } from "../../../store/is/assetapi";
import AddDocument from "../AddDocument";


export default function PerformValidationOnAsset({ isCreate, closeModal, spinning, setSpinning, assetDetails, assetIds, usergroup, handleDispatchPerformValidationOnAsset, updateDocument}) {
    const key = "updatable";

    const [openPerformValidationOnAssetModal, setPerformValidationOnAssetModal] = useState(false);
    const [ConfirmLoadingPerformValidationOnAsset, setConfirmLoadingPerformValidationOnAsset] = useState(false);

    const [updateAssetStatus] = useUpdateAssetStatusMutation();
    const [uplodedDocumentHashes, setUplodedDocumentHashes] = useState([]);

    
//********bc-client starts */
  const { onAccept: onAcceptValidateAssetsAction } = useTransactionHandlers({
    actionname: validateAssetsAction.name,
  });
  const validateAssetsActionInBC = async (payload) => {
    return await onAcceptValidateAssetsAction(payload);
  };
  //********bc-client ends */


    useEffect(() => {
        if (isCreate) {
            setPerformValidationOnAssetModal(true);
        }
    }, [isCreate]);

    const getDocumentHashes = (document) => {
        let uplodedDocumentHashesUpdate = [...uplodedDocumentHashes];
        uplodedDocumentHashesUpdate.push(document?.document_hash);
        setUplodedDocumentHashes(uplodedDocumentHashesUpdate);
        updateDocument(document);
    };

    const handleMakingAssetActive = async () => {
        let assetUpdateResponse;
        let assetStatusUpdate = {};
        let payloadList = []; // Initialize an array to store payloads
        
        try {
            setSpinning(true);
            if (!usergroup.some(group => group === "Validator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 4,
                });
            } 
            else if (Array.isArray(assetDetails) && assetDetails.length >= 1) {
                for (let i = 0; i < assetDetails.length; i++) {
                    assetStatusUpdate = { ...assetDetails[i] };
                    assetStatusUpdate["_ids"] = assetIds;
                    assetStatusUpdate["status"] = "Active";
                    payloadList.push(assetStatusUpdate);
                }
            }
            else {
                const assetStatusUpdate = { ...assetDetails };
                assetStatusUpdate["_ids"] = [assetStatusUpdate?.["_id"]];
                assetStatusUpdate["status"] = "Active";
                payloadList.push(assetStatusUpdate);
            }
            
            // Prepare payload containing the list of assets
            const payload = {
                assets: payloadList,
                dochashes: uplodedDocumentHashes,
            };
            
            // Send the payload to the blockchain
            const result = await validateAssetsActionInBC(payload);
            if (!result) {
                // Handle failure
                console.error("BC action failed");
                return;
            }
    
            // If successful, update asset status (if needed)
            console.log("assetss", assetStatusUpdate);
            assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            setPerformValidationOnAssetModal(false);
        } catch (error) {
            console.error("Error updating assets:", error);
        } finally {
            setSpinning(false);
            setConfirmLoadingPerformValidationOnAsset(false);
        }
    };
    

    const handleMakingAssetRevision = async () => {
        let assetUpdateResponse;

        // Check if assetDetails is an array
        const isArrayWithMultipleEntries = Array.isArray(assetDetails) && assetDetails.length >= 1;
      
        try {
            if (!usergroup.includes("Validator")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 4,
                });
            } 
            else if (isArrayWithMultipleEntries) {
                const assetStatusUpdate = {};
                assetStatusUpdate["_ids"] = assetIds;
                assetStatusUpdate["status"] = "Revision";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            }
            else {
                const assetStatusUpdate = { ...assetDetails };
                assetStatusUpdate["_ids"] = [assetStatusUpdate?.["_id"]];
                assetStatusUpdate["status"] = "Revision";
                assetUpdateResponse = await handleAssetUpdateStatus(assetStatusUpdate);
            }
        } catch (error) {
            console.error("Error updating asset:", error);
        } finally {
            setConfirmLoadingPerformValidationOnAsset(false);
        }
    };

    const handleCancelofPerformValidationModal = () => {
        setPerformValidationOnAssetModal(false);
        closeModal();
    };

    const handleAssetUpdateStatus = async (record) => {
        try {
            const payload = {
                body: record
            };
            const res = await updateAssetStatus(payload)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "Asset updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchPerformValidationOnAsset(res);
                    setPerformValidationOnAssetModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update Asset status: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitForPerformValidationOnAssetModal
                            visible={isCreate}
                            assetDetails={assetDetails}
                            openPerformValidationOnAssetModal={openPerformValidationOnAssetModal}
                            handleMakingAssetActive={handleMakingAssetActive}
                            handleMakingAssetRevision={handleMakingAssetRevision}
                            ConfirmLoadingPerformValidationOnAsset={ConfirmLoadingPerformValidationOnAsset || spinning}
                            handleCancelofPerformValidationModal={handleCancelofPerformValidationModal}
                            updateDocument={getDocumentHashes}
                            closeModal={closeModal}
                            onCancel={() => {
                                if(!(ConfirmLoadingPerformValidationOnAsset || spinning))
                                closeModal();
                            }}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const isValidationDisabled = false
const SubmitForPerformValidationOnAssetModal = ({
    visible,
    assetDetails,
    openPerformValidationOnAssetModal,
    handleMakingAssetActive,
    updateDocument,
    handleMakingAssetRevision,
    ConfirmLoadingPerformValidationOnAsset,
    handleCancelofPerformValidationModal
}) => {
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;
    const [isCreateDocument, setIsCreateDocument] = useState(false);
    const [uplodedDocumentList, setUplodedDocumentList] = useState([]);


    const renderPerformValidationOnAssetPanelContent = () => {
        return null;
    };
    const closeDocumentModal = () => {
        setIsCreateDocument(false);
      };
    
    const displayDocument = (document) => {
        let uplodedDocumentListToUpdate = [...uplodedDocumentList];
        uplodedDocumentListToUpdate.push({
          docname: document?.docname,
          docdname: document?.document_display_name,
          
        });
        setUplodedDocumentList(uplodedDocumentListToUpdate);
        updateDocument(document);
    };

    return (
        <>
        <Modal
            title="Perform Validation"
            visible={openPerformValidationOnAssetModal}
            onOk={handleMakingAssetActive}
            okText="Validation"
            confirmLoading={ConfirmLoadingPerformValidationOnAsset}
            onCancel={handleCancelofPerformValidationModal}
            footer={[
                <Button onClick={handleMakingAssetRevision} style={{ color: 'red' }}>
                    Revise
                </Button>,
                <Button type="primary" onClick={handleMakingAssetActive} loading={ConfirmLoadingPerformValidationOnAsset}>
                    Activate
                </Button>,
            ]}
     >
              <p>Upload the Validation Document</p>
        <Button
            type="primary"
            onClick={() => {
              setIsCreateDocument(true);
            }}
        >
            Upload Documents
        </Button>
        <br />

        {uplodedDocumentList?.length > 0 && (
          <List
            dataSource={uplodedDocumentList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.docname}</Typography.Text>
                <Typography.Text>{item.docdname}</Typography.Text>
              </List.Item>
            )}
          />
        )}
        <br />
        {isValidationDisabled === false ? (
                <p>The asset is ready to be activated. Do you want to activate the asset? </p>
            ) : (
                <>
                    <p>Please fix the following errors to make the asset ready.</p>

                    <Collapse defaultActiveKey={['Operation Check']}>
                        {renderPerformValidationOnAssetPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Validation</Text>
                                        {renderPerformValidationOnAssetPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderPerformValidationOnAssetPanelContent()}
                            </Panel>
                        )}

                    </Collapse>
                </>
            )}
      </Modal>

      <AddDocument
        ids={[assetDetails?._id]}
        entity="asset"
        updateDocument={displayDocument}
        isCreate={isCreateDocument}
        closeModal={closeDocumentModal}
      />
      </>
    );
};