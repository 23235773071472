import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paramsApi = createApi({
  reducerPath: "paramsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/params",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Params"],
  endpoints: (build) => ({
    getParam: build.query({
      query: (id) => `${id}`,
      providesTags: ["Params"],
    }),
    getParams: build.query({
      query: () => "",
      providesTags: ["Params"],
    }),
    getParamsByParam: build.query({
      query: (params) => {
        return {
          url: "",
          params: params,
        };
      },
      providesTags: ["Params"],
    }),
    addParams: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Params"],
    }),
    updateParams: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Params"],
    }),
    deleteParams: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Params"],
    }),
  }),
});

export const {
  useGetParamsByParamQuery,
  useGetParamQuery,
  useGetParamsQuery,
  useAddParamsMutation,
  useUpdateParamsMutation,
  useDeleteParamsMutation,
} = paramsApi;
