import { React, useState } from "react";
import { ContactsOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Menu,
  Popover,
  Spin,
  Table,
  Typography,
  message,
  Modal
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import { createAssetsAction } from "../../constants/actionNames";
import {
  useAddDocumentToAssetMutation,
  useGetAssetsByParamQuery,
  useUpdateAssetBatchMutation
} from "../../store/is/assetapi";
import {
  useAddAssignmentBatchMutation
} from "../../store/is/assignmentapi";
import { interventionApi } from "../../store/is/interventionapi";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { DisplayOrganization } from "../Utils/DisplayOrganization";

import AddDocument from "./AddDocument";
import PerformOperationaCheckOnAsset from "./Utils/PerformOperationaCheckOnAsset";
import PerformValidationOnAsset from "./Utils/PerformValidationOnAsset";
import { setAsset } from "../../store/is/isSlice";

function ViewAssets() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsUpdated, setSelectedRowsUpdated] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [openProbaReviewModal, setOpenProbaReviewModal] = useState(false);
  const [
    confirmLoadingProbaReviewModal,
    setConfirmLoadingProbaReviewModal,
  ] = useState(false);

  let intervention = useSelector((state) => state.is.intervention);
  let orgId = useSelector((state) => state.auth?.org?._id);

  const [updateAssetBatch] = useUpdateAssetBatchMutation();
  const [addAssignmentBatch] = useAddAssignmentBatchMutation();

  let userGroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );
  userGroup = userGroup.filter(Boolean);

  const isValidator = userGroup.includes("Validator");
  const isProbaOperator = userGroup.includes("Proba Operator");
  const isProjectStatusActive =
    intervention?.status === "Active" ? true : false;

  const [addAssetDocument] = useAddDocumentToAssetMutation();
  const [
    isPerformOperationalCheckOnAsset,
    setIsPerformOperationalCheckOnAsset,
  ] = useState(false);
  const [isPerformValidationOnAsset, setIsPerformValidationOnAsset] = useState(
    false
  );

  if (!intervention?.name) {
    history.push({
      pathname: `/intervention/list`,
    });
  }

  const viewInterventionRouteChange = () => {
    //set Asset object in store
    let path = "/intervention/view";
    history.push(path);
  };
  const key = "updatable";

  const [isSpinning, setSpinning] = useState(false);
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [spinningDesc, setSpinningDesc] = useState("Processing...");
  const [
    isEnableAssignValidatorButton,
    setIsEnableAssignValidatorButton,
  ] = useState(false);
  const [
    isEnableAddDocumentButton,
    setIsEnableAddDocumentButton
  ] = useState(false);
  const [
    isEnableSubmitForProbaReviewButton,
    setIsEnableSubmitForProbaReviewButton,
  ] = useState(false);
  const [
    isEnablePerformOperationalCheckButton,
    setIsEnablePerformOperationalCheckButton,
  ] = useState(false);
  const [
    isEnablePerformValidationButton,
    setIsEnablePerformValidationButton,
  ] = useState(false);

  const queryparam = {
    intervention_id: intervention?._id,
  };

  const {
    data = [],
    isLoading,
    refetch: refetchAssets,
  } = useGetAssetsByParamQuery(queryparam, {
    skip: !queryparam?.intervention_id,
  });
  const intervValidatorAvailable = intervention?.assignment.some(
    (obj) => obj.assignee_type === "Validator"
  );
  const intervAssignment = intervention?.assignment?.find(
    (obj) => obj.assignee_type === "Validator"
  );

  const closeDocumentModal = () => {
    setIsCreateDocument(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "external_asset_id",
      title: "External Asset Id",
      dataIndex: "external_asset_id",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: 'New',
          value: 'New',
        },
        {
          text: 'Proba Review',
          value: 'Proba Review',
        },
        {
          text: 'Revision',
          value: 'Revision',
        },
        {
          text: 'Validator Review',
          value: 'Validator Review',
        },
        {
          text: 'Active',
          value: 'Active',
        },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },

    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "user_id",
      title: "Assignees",
      dataIndex: "user_id",
      render: (text, record) => {
        let validator_org_id, project_sponsor_org_id;
        if (Array.isArray(record?.assignment)) {
          record?.assignment.map((assignment) => {
            if (assignment.assignee_type === "Validator") {
              validator_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Project Sponsor") {
              project_sponsor_org_id = assignment?.organization;
            }
          });
          return (
            <Popover
              content={
                <>
                  <Typography.Text strong>Validator:</Typography.Text>
                  <DisplayOrganization orgid={validator_org_id} place="table" />
                  <Typography.Text strong>Project Sponsor:</Typography.Text>
                  <DisplayOrganization
                    orgid={project_sponsor_org_id}
                    place="table"
                  />
                </>
              }
            >
              <ContactsOutlined />
            </Popover>
          );
        } else {
          return (
            <Popover content={<></>}>
              <ContactsOutlined />
            </Popover>
          );
        }
      },
    },
  ].filter(Boolean);

  const ClosePerformOperationalCheckOnAssetModal = () => {
    setIsPerformOperationalCheckOnAsset(false);
  };

  const ClosePerformValidationOnAssetModal = () => {
    setIsPerformValidationOnAsset(false);
  };

  const handleDispatchPerformOperationalCheck = () => {
    setIsPerformOperationalCheckOnAsset(false);
    resetButtonStates(); // Reset button states
    refetchAssets();
  };

  const handleDispatchPerformValidationOnAsset = () => {
    setIsPerformValidationOnAsset(false);
    resetButtonStates(); // Reset button states
    refetchAssets();
  };

  const resetButtonStates = () => {
    setIsEnableAssignValidatorButton(false);
    setIsEnableAddDocumentButton(false);
    setIsEnableSubmitForProbaReviewButton(false);
    setIsEnablePerformOperationalCheckButton(false);
    setIsEnablePerformValidationButton(false);
    setSelectedRowKeys([]);
  };

  const updateDocument = (document) => {
    handleAddAssetDocument(document);
  };

  const handleAddDocument = () => {
    setIsCreateDocument(true);
  };

  const closeSubmitForProbaReview = () => {
    setOpenProbaReviewModal(false);
  };

  const handleAssetSubmitForProbaReviewModal = () => {
    setOpenProbaReviewModal(true);
  };

  const handleAddAssetDocument = async (record) => {
    let isSuccess = true;
    try {
      setSpinning(true);
      for (let i = 0; i < selectedRowsUpdated?.length; i++) {
        const body = {
          assetid: selectedRowsUpdated[i]?._id,
          requestbody: {
            document_id: record.document_id,
            asset_id: selectedRowsUpdated[i]?._id,
          },
        };

        await addAssetDocument(body)
          .unwrap()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            isSuccess = false; // Set flag to false on error
            console.log(err);
          });
      }

      // Display message based on isSuccess flag
      if (isSuccess) {
        message.success({
          content: "Asset Documents added successfully!",
          key,
          duration: 2,
        });
      } else {
        message.error({
          content: "Failed to add Asset Documents!",
          key,
          duration: 2,
        });
      }
    } catch {
    } finally {
      setSpinning(false);
    }
  };

  //bc-client
  const { onAccept: onAcceptCreateAssets } = useTransactionHandlers({
    actionname: createAssetsAction.name,
  });
  const createAssetsActionInBC = async (asset) => {
    return await onAcceptCreateAssets(asset);
  };
  //bc-client

  const handleAssetSubmitForProbaReview = async () => {
    try {
      setSpinning(true);
      if (!userGroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
        return;
      }
      let assetSubmitForProbaReviewList = [];
      for (let i = 0; i < selectedRowsUpdated.length; i++) {
        let assetStatusUpdate = { ...selectedRowsUpdated[i] };
        assetStatusUpdate["status"] = "Proba Review";
        assetSubmitForProbaReviewList.push(assetStatusUpdate);
      }

      if (assetSubmitForProbaReviewList?.length > 0) {
        const payload = {
          assets: assetSubmitForProbaReviewList,
          intervention: intervention,
        };
        const result = await createAssetsActionInBC(payload);
        if (!result) {
          return;
        }
      }
      const batchSize = 100;
      for (let i = 0; i < assetSubmitForProbaReviewList.length; i+= batchSize) {
        const batchUpdate = assetSubmitForProbaReviewList.slice(
          i,
          i + batchSize
        );
        await updateAssetBatch(batchUpdate);
      }
      message.success({
        content: "Assets Batch Update is successful!",
        key,
        duration: 2,
      });
    } catch {
      message.error({
        content: "Failed to update Assets.",
        key,
        duration: 2,
      });
    } finally {
      setOpenProbaReviewModal(false);
      setConfirmLoadingProbaReviewModal(false);
      refetchAssets();
      resetButtonStates(); // Reset button states
      setSpinning(false);
    }
  };

  const handleAssetsValidatorAssignment = async () => {
    try {
      setSpinning(true);
      let assignmentsToAdd = [];
      for (let i = 0; i < selectedRowsUpdated.length; i++) {
        const assignmentToAdd = {
          assignee_type: "Validator",
          user_id: intervAssignment?.user_id,
          asset_id: selectedRowsUpdated[i]?._id,
          organization: intervAssignment?.organization,
        };
        assignmentsToAdd.push(assignmentToAdd);
      }
      const batchSize = 100;
      for (let i = 0; i < assignmentsToAdd.length; i += batchSize) {
        const batchAssignments = assignmentsToAdd.slice(i, i + batchSize);
        await addAssignmentBatch(batchAssignments);
      }
      message.success({
        content: "Validator Assignment is successful!",
        key,
        duration: 2,
      });
    } catch {
      message.error({
        content: "Failed to assign Validator.",
        key,
        duration: 2,
      });
    } finally {
      refetchAssets();
      resetButtonStates(); // Reset button states
      setSpinning(false);
    }
  };

  const menu = (
    <Menu>
      {userGroup.includes("Project Developer") && intervention?.organization === orgId && (
        <>
          <Menu.Item key="assignValidator">
            <Button
              type="link"
              disabled={!isEnableAssignValidatorButton}
              onClick={handleAssetsValidatorAssignment}
            >
              Assign Validator
            </Button>
          </Menu.Item>
        </>
      )}
      {userGroup.includes("Project Developer") && intervention?.organization === orgId && (
        <>
          <Menu.Item key="addDocument">
            <Button
              type="link"
              disabled={!isEnableAddDocumentButton}
              onClick={handleAddDocument}
            >
              Add Document
            </Button>
          </Menu.Item>
        </>
      )}
      {userGroup.includes("Project Developer") && intervention?.organization === orgId && (
        <>
          <Menu.Item key="submitForProbaReview">
            <Button
              type="link"
              disabled={!isEnableSubmitForProbaReviewButton}
              onClick={handleAssetSubmitForProbaReviewModal}
            >
              Submit for Proba Review
            </Button>
          </Menu.Item>
        </>
      )}
      {isProbaOperator && (
        <>
          <Menu.Item key="performOperationalCheck">
            <Button
              type="link"
              disabled={!isEnablePerformOperationalCheckButton}
              onClick={async () => {
                setIsPerformOperationalCheckOnAsset(true);
              }}
            >
              Perform Operational Check
            </Button>
          </Menu.Item>
        </>
      )}
      {isValidator && (
        <>
          <Menu.Item key="performValidation">
            <Button
              type="link"
              disabled={!isEnablePerformValidationButton}
              onClick={async () => {
                setIsPerformValidationOnAsset(true);
              }}
            >
              Perform Validation
            </Button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const handleMakeAvailable = async (selectedRows) => {
    let isEnableAssignValidatorFlag = true;
    let isEnableAddDocumentFlag = true;
    let isEnableSubmitForProbaReviewFlag = true;
    let isEnablePerformOperationalCheckFlag = true;
    let isEnablePerformValidationFlag = true;
    let isMatchingValidator = false;

    // If no rows selected
    if (selectedRows.length === 0) {
      isEnableAssignValidatorFlag = false;
      isEnableAddDocumentFlag = false;
      isEnableSubmitForProbaReviewFlag = false;
      isEnablePerformOperationalCheckFlag = false;
      isEnablePerformValidationFlag = false;
    }

    //If Rows selected
    for (let i = 0; i < selectedRows.length; i++) {
      let isEnableAssignValidatorRow = true;
      //Check1: Owner of the record
      if (!userGroup.includes("Project Developer")) {
        isEnableAssignValidatorRow = false;
      }
      //Check2: check if project has validator assigned
      if (!intervValidatorAvailable) {
        isEnableAssignValidatorRow = false;
      }
      //Check3: check if assets has validator
      let assetHasValidator = false;
      const assetsAssignments = selectedRows[i].assignment;

      assetsAssignments?.forEach((assetAssignee) => {
        if (assetAssignee?.assignee_type === "Validator") {
          assetHasValidator = true;
        }
        if (assetAssignee?.organization === orgId) {
          isMatchingValidator = true;
        }
      });
      if (assetHasValidator) {
        isEnableAssignValidatorRow = false;
      }
      if (!isEnableAssignValidatorRow) {
        isEnableAssignValidatorFlag = false;
      }

      let assetStatus = selectedRows[i]?.status;
      // BUTTON - 2
      if (
        !isProjectStatusActive ||
        assetStatus === "Proba Review" ||
        assetStatus === "Active" ||
        assetStatus === "Validator Review" 
      ) {
          isEnableAddDocumentFlag = false;
        }

      //BUTTON - 3
      if (
        !isProjectStatusActive ||
        assetStatus === "Proba Review" ||
        assetStatus === "Active" ||
        assetStatus === "Validator Review"
      ) {
        isEnableSubmitForProbaReviewFlag = false;
      }

      //BUTTON - 4
      if (
        (!isProjectStatusActive ||
          assetStatus === "Validator Review" ||
          assetStatus === "Active" ||
          assetStatus === "Revision" ||
          assetStatus === "New") &&
        isProbaOperator
      ) {
        isEnablePerformOperationalCheckFlag = false;
      }

      //BUTTON - 5
      if (
        ((!isProjectStatusActive ||
          assetStatus === "Proba Review" ||
          assetStatus === "Active" ||
          assetStatus === "New" ||
          assetStatus === "Revision") &&
        isValidator) || !isMatchingValidator
      ) {
        isEnablePerformValidationFlag = false;
      }
    }
    setSelectedRowsUpdated(selectedRows);

    setIsEnableAssignValidatorButton(isEnableAssignValidatorFlag);
    setIsEnableAddDocumentButton(isEnableAddDocumentFlag);
    setIsEnableSubmitForProbaReviewButton(isEnableSubmitForProbaReviewFlag);
    setIsEnablePerformOperationalCheckButton(
      isEnablePerformOperationalCheckFlag
    );
    setIsEnablePerformValidationButton(isEnablePerformValidationFlag);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      handleMakeAvailable(selectedRows);
      const validateAndShowMessage = (validStatusOptions, groupMessage) => {
        if (selectedRowKeys.length === 0) {
          message.destroy();
          return;
        }
      
        const selectedStatuses = new Set(selectedRows.map(row => row?.status));
        const isValidSelection = selectedStatuses.size === 1 && validStatusOptions.includes(selectedRows[0].status);
      
        if (!isValidSelection) {
          message.warning(`Please select assets with the same status of ${groupMessage} for actions.`);
        } else {
          message.destroy();
        }
      };
      
      if (userGroup.includes("Project Developer")) {
        validateAndShowMessage(["New", "Revision"], '"New" or "Revision"');
      } else if (userGroup.includes("Proba Operator")) {
        validateAndShowMessage(["Proba Review"], '"Proba Review"');
      } else if (userGroup.includes("Validator")) {
        validateAndShowMessage(["Validator Review"], '"Validator Review"');
      }
    },
  };

  const assetViewRouteChange = (record) => {
    //set Asset object in store
    dispatch(setAsset(record));
    let path = "/asset/view";
    history.push(path);
  };

  return (
    <Spin tip={spinningDesc} spinning={isSpinning} size="large">
      {!isLoading && (
        <div style={{ height: "80vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Title level={4}>Asset Details</Typography.Title>
            <div>
              {!userGroup.includes("Project Sponsor") && (
                <>
                  <Divider type="vertical"></Divider>
                  <Dropdown overlay={menu} trigger={["hover"]}>
                    <Button type="primary">
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </>
              )}
              
              <Divider type="vertical"></Divider>
              <Button
                type="primary"
                onClick={() => {
                  viewInterventionRouteChange();
                  //refetch the table
                  dispatch(interventionApi.util.resetApiState());
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <Card className="shadow-card" style={{ borderRadius: 8 }}>
            <Table
              size="small"
              columns={columns}
              dataSource={data.data}
              rowKey={(record) => record._id} // Provide a unique key for each row
              rowSelection={rowSelection} // Add row selection to the table
              onChange={handleChange}
              pagination={{
                pageSizeOptions: ["10", "50", "100"],
                showSizeChanger: true,
              }}
              onRow={(record) => ({
                onClick: () => {
                  assetViewRouteChange(record);
                },
                style: { cursor: "pointer" },
              })}
            ></Table>
          </Card>
        </div>
      )}
      <Modal
        title="Submit for Proba Review"
        visible={openProbaReviewModal}
        onOk={handleAssetSubmitForProbaReview}
        okText="Submit for Proba Review"
        confirmLoading={confirmLoadingProbaReviewModal || isSpinning}
        onCancel={() => {
          if (!(confirmLoadingProbaReviewModal || isSpinning))
            closeSubmitForProbaReview();
        }}
      >
        <p>Are you sure you want to submit assets for Proba Review?</p>
      </Modal>
      <PerformOperationaCheckOnAsset
        assetDetails={selectedRowsUpdated}
        assetIds={selectedRowKeys}
        usergroup={userGroup}
        isCreate={isPerformOperationalCheckOnAsset}
        closeModal={ClosePerformOperationalCheckOnAssetModal}
        handleDispatchPerformOperationalCheck={
          handleDispatchPerformOperationalCheck
        }
      />
      <PerformValidationOnAsset
        updateDocument={updateDocument}
        assetDetails={selectedRowsUpdated}
        setSpinning={setSpinning}
        spinning={isSpinning}
        assetIds={selectedRowKeys}
        usergroup={userGroup}
        isCreate={isPerformValidationOnAsset}
        closeModal={ClosePerformValidationOnAssetModal}
        handleDispatchPerformValidationOnAsset={
          handleDispatchPerformValidationOnAsset
        }
      />
      <AddDocument
        ids={selectedRowKeys}
        entity="asset"
        updateDocument={updateDocument}
        isCreate={isCreateDocument}
        closeModal={closeDocumentModal}
      />
    </Spin>
  );
}

export default ViewAssets;
