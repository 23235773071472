import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import authReducer from './auth/authSlice'
import isSlice from './is/isSlice'
import { authApi } from './auth/authapi'
import { userApi } from './auth/userapi'
import { orgApi } from './auth/orgapi'
import { orgtypeApi } from './auth/orgtypeapi'
import { groupApi } from './auth/groupapi'
import { interventionApi } from './is/interventionapi'
import { methodsApi } from './is/methodsapi'
import { paramsApi } from './is/paramsapi'
import { assetApi } from './is/assetapi'
import { doctypeApi } from './ds/doctypeapi'
import { documentApi } from './ds/documentapi'
import { finaltokApi } from './is/finaltokapi'
import { documentAccessApi } from './ds/documentaccessapi'
import { verificationApi } from './is/verificationapi'
import { assignmentApi } from './is/assignmentapi'
import { imsApi } from './ims/envattrapi'
import { ipfsApi } from './ipfs/ipfs'
import { yieldApi } from './is/yieldapi'
import { validationApi } from './is/validationapi'
import { transactionApi } from './is/transactionapi'
import { preentitlementrecordApi } from './is/preentitlementrecordsapi'
import { bcclientApi } from './bc-client/bcclientapi'
import { entitlementCreditBundleApi } from './is/entitlementCreditBundleApi'

const store = configureStore({
  reducer: {
    auth: authReducer,
    is: isSlice,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [orgtypeApi.reducerPath]: orgtypeApi.reducer,
    [interventionApi.reducerPath]: interventionApi.reducer,
    [methodsApi.reducerPath]: methodsApi.reducer,
    [paramsApi.reducerPath]: paramsApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [doctypeApi.reducerPath]: doctypeApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [finaltokApi.reducerPath]: finaltokApi.reducer,
    [entitlementCreditBundleApi.reducerPath]: entitlementCreditBundleApi.reducer,
    [documentAccessApi.reducerPath]: documentAccessApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [verificationApi.reducerPath]: verificationApi.reducer,
    [assignmentApi.reducerPath]: assignmentApi.reducer,
    [imsApi.reducerPath]: imsApi.reducer,
    [ipfsApi.reducerPath]: ipfsApi.reducer,
    [yieldApi.reducerPath]: yieldApi.reducer,
    [validationApi.reducerPath]: validationApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [preentitlementrecordApi.reducerPath]: preentitlementrecordApi.reducer,
    [bcclientApi.reducerPath]: bcclientApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      orgApi.middleware,
      orgtypeApi.middleware,
      interventionApi.middleware,
      methodsApi.middleware,
      paramsApi.middleware,
      assetApi.middleware,
      doctypeApi.middleware,
      documentApi.middleware,
      finaltokApi.middleware,
      entitlementCreditBundleApi.middleware,
      documentAccessApi.middleware,
      groupApi.middleware,
      verificationApi.middleware,
      assignmentApi.middleware,
      imsApi.middleware,
      ipfsApi.middleware,
      yieldApi.middleware,
      validationApi.middleware,
      transactionApi.middleware,
      preentitlementrecordApi.middleware,
      bcclientApi.middleware,
    ),
})

setupListeners(store.dispatch)

export default store