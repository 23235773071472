import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const verificationApi = createApi({
  reducerPath: "verificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/verification",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Verification"],
  endpoints: (build) => ({
    getVerifications: build.query({
      query: () => "",
      providesTags: ["Verification"],
    }),
    getVerification: build.query({
      query: (id) => `${id}`,
      providesTags: ["Verification"],
    }),
    getVerificationByParam: build.query({
      query: (params) => {
        return {
          url: "",
          params: params,
        };
      },
      providesTags: ["Verification"],
    }),
    addVerification: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Verification"],
    }),
    updateVerification: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Verification"],
    }),
    deleteVerification: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Verification"],
    }),
  }),
});

export const {
  useGetVerificationsQuery,
  useGetVerificationQuery,
  useGetVerificationByParamQuery,
  useAddVerificationMutation,
  useUpdateVerificationMutation,
  useDeleteVerificationMutation,
} = verificationApi;
