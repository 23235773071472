import { ProbaArtifact } from "./../contractArtifacts/ProbaArtifact";

const {
  REACT_APP_PROBA_CONTRACT_ADDRESS,
  PROBA_ETHEREUM_PROVIDER_IP,
  REACT_APP_PROBA_UI_ENV,
  REACT_APP_PINATA_API_URL,
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET_KEY,
  REACT_APP_CRYPTO_SECRET_KEY,
} = process.env;

if (!REACT_APP_PROBA_CONTRACT_ADDRESS) {
  console.log("[WARNING] REACT_APP_PROBA_CONTRACT_ADDRESS is empty");
}

console.log("REACT_APP_PROBA_UI_ENV",REACT_APP_PROBA_UI_ENV)
const YOUR_ETH_NODE_ADDRESS = PROBA_ETHEREUM_PROVIDER_IP;

const YOUR_PROBA_CONTRACT_ADDRESS = REACT_APP_PROBA_CONTRACT_ADDRESS;
const YOUR_PROBA_CONTRACT_ADDRESS_ABI = ProbaArtifact.abi;

export {
  YOUR_ETH_NODE_ADDRESS,
  YOUR_PROBA_CONTRACT_ADDRESS,
  YOUR_PROBA_CONTRACT_ADDRESS_ABI,
  REACT_APP_PROBA_UI_ENV,
  REACT_APP_PINATA_API_URL,
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET_KEY,
  REACT_APP_CRYPTO_SECRET_KEY,
};
