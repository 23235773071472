import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const transactionApi = createApi({
  reducerPath: "transactionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/transaction",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Transaction"],
  endpoints: (build) => ({
    getTransaction: build.query({
        query: (id) => `${id}`,
        providesTags: ["Transaction"],
    }),
    getTransactions: build.query({
        query: () => "",
        providesTags: ["Transaction"],
    }),
    getYieldTransactions: build.query({
      query: (params) => {
          return {
              url: "/yield/byIds",
              params: params,
          };
      },
      providesTags: ["Transaction"],
  }),
    addTransaction: build.mutation({
        query(body) {
            return {
                url: ``,
                method: "POST",
                body,
            };
        },
        invalidatesTags: ["Transaction"],
    }),
    deleteTransaction: build.mutation({
        query(id) {
            return {
                url: `${id}`,
                method: "DELETE",
            };
        },
        invalidatesTags: ["Transaction"],
    }),
  }),
});

export const {
    useGetTransactionQuery,
    useGetTransactionsQuery,
    useGetYieldTransactionsQuery,
    useAddTransactionMutation,
    useDeleteTransactionMutation,
} = transactionApi;