import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const orgtypeApi = createApi({
  reducerPath: "orgtypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/auth/orgtype/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["OrgTypes"],
  endpoints: (build) => ({
    getOrgTypes: build.query({
      query: () => "",
      providesTags: ["OrgTypes"],
    }),
    getOrgType: build.query({
      query: (id) => `${id}`,
      providesTags: ["OrgTypes"],
    }),
    addOrgType: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["OrgTypes"],
    }),
    updateOrgType: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["OrgTypes"],
    }),
    deleteOrgType: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OrgTypes"],
    }),
  }),
});

export const {
  useGetOrgTypeQuery,
  useGetOrgTypesQuery,
  useAddOrgTypeMutation,
  useUpdateOrgTypeMutation,
  useDeleteOrgTypeMutation,
} = orgtypeApi;
