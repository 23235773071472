import { React, useState } from "react";

import {
    Form,
    Input,
    Modal,
    Select,
    Spin,
    message
} from "antd";

import { useGetMethodsQuery } from "../../store/is/methodsapi";

import {
    useImportInterventionMutation
} from "../../store/ims/envattrapi";

import { useSelector } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;

export const InterventionImport = ({
    visible, onCancel
}) => {

    const [loading, setLoading] = useState(false);

    const [importIntervention, { isCreateLoading }] = useImportInterventionMutation();

    const onImportSubmit = (values) => {
        handlePostImport(values);
    };

    const handlePostImport = async (record) => {
        try {
            setLoading(true);
            await importIntervention({ ...record, status: "New" })
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "Intervention Imported Successfully!",
                    });
                    console.log(res);
                })
                .catch((err) => {
                    message.error({
                        content: "Failed to Import Intervention:" + Object.values(err['data'])[0],
                    });
                    console.log(err);
                });
        } catch {
            setLoading(false);
        }
        setLoading(false);
    };

    const { data: methoddata, isLoading: isMethodLoading } = useGetMethodsQuery();
    const methoddatalist = [];
    if (!isMethodLoading) {
        methoddata.forEach((method) => {
            methoddatalist.push(<Option key={method._id}>{method.name}</Option>);
        });
    }

    const [form] = Form.useForm();
    return (
        <Modal
            width={500}
            title="Import Intervention"
            open={visible}
            okText="Import"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onImportSubmit(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Spin spinning={loading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="Documenttype"
                //initialValues={editingRecord}
                >
                    <Form.Item
                        name="groupid"
                        label="Group Id"
                        rules={[
                            {
                                required: true,
                                message: "Please input Group Id!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="methodid"
                        label="Method"
                        rules={[
                            {
                                required: true,
                                message: "Please input Method!",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            placeholder="Please select the Method"
                        >
                            {methoddatalist}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="Intervention Type"
                        rules={[
                            {
                                required: true,
                                message: "Please select the Intervention Type",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            placeholder="Please select the Intervention Type"
                            options={[
                                { value: 'reduction', label: 'Reduction' },
                                { value: 'removal', label: 'Removal' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="story"
                        label="Story"
                        rules={[
                            {
                                required: true,
                                message: "Please input Intervention name!",
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};