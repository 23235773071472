import React from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";

import { useGetUserQuery } from "../../store/auth/userapi";

export const DisplayUserName = ({ userid, place }) => {
  const { data, isLoading } = useGetUserQuery(userid, { skip: !userid });
  if (!isLoading) {
  }

  const displayFontSize = place === "table" ? 14 : 16

  return (
    <div>
      <Row align="middle" gutter={[8, 0]}>
        <Col>
          <Avatar
            style={{ backgroundColor: "#36B28D" }}
            icon={<UserOutlined />}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Typography.Text style={{ fontSize: displayFontSize }}>
                {data?.fname} {data?.lname}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
