import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { REACT_APP_PINATA_API_URL, REACT_APP_PINATA_API_KEY, REACT_APP_PINATA_API_SECRET_KEY } from "../../config/config";

export const ipfsApi = createApi({
  reducerPath: "ipfsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_PINATA_API_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("pinata_api_key", REACT_APP_PINATA_API_KEY);
      headers.set("pinata_secret_api_key", REACT_APP_PINATA_API_SECRET_KEY);
      return headers;
    },
  }),
  tagTypes: ["Ipfs"],
  endpoints: (build) => ({
    postToIpfs: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Ipfs"],
    }),
  }),
});

export const { usePostToIpfsMutation
} = ipfsApi;
