import { React, useRef } from "react";
import { Button, Card, Modal, Table, Typography } from "antd";
import html2pdf from "html2pdf.js";
import moment from "moment";

const { Title } = Typography;
const { Text } = Typography;

export default function ViewRetirementDownloadCertificate({
  isShowRetiredCertificate,
  finalTokenData,
  yieldRecord,
  closeModal,
}) {
  if (yieldRecord) {
    var yieldRecordRetiredCertificateDetails = JSON.parse(yieldRecord);
  }

  const certificateRef = useRef(null);
  const handleDownload = () => {
    const element = document.getElementById("retirement-certificate-container");

    const cards = element.querySelectorAll(".card");
    cards.forEach((card) => {
      card.setAttribute("data-html2pdf-page-break", "avoid");
    });

    const style = document.createElement("style");
    style.innerHTML = `
        tr, div, span, h2, h3, h5 {
            page-break-inside: avoid !important;
            break-inside: avoid !important;
        }
        @media print {
            tr, div, span, h2, h3, h5 {
                page-break-inside: avoid !important;
                break-inside: avoid !important;
            }
        }
    `;
    document.head.appendChild(style);

    const opt = {
      margin: [10, 10, 10, 10],
      filename: "PTCB- " + finalTokenData?.finaltoken_id + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 2,
      },
      jsPDF: {
        unit: "pt", // Set unit to points
        format: "letter",
        orientation: "portrait",
      },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .save();
  };

  return (
    <div>
      <>
        {isShowRetiredCertificate && (
          <CertificateModal
            visible={isShowRetiredCertificate}
            finalTokenData={finalTokenData}
            yieldRecordRetiredCertificateDetails={
              yieldRecordRetiredCertificateDetails?.data
                ? yieldRecordRetiredCertificateDetails?.data
                : [yieldRecordRetiredCertificateDetails]
            }
            certificateRef={certificateRef}
            handleDownload={handleDownload}
            onCancel={() => {
              closeModal();
            }}
          />
        )}
      </>
    </div>
  );
}

const CertificateModal = ({
  visible,
  onCancel,
  finalTokenData,
  yieldRecordRetiredCertificateDetails,
  certificateRef,
  handleDownload,
}) => {
  const flatAssetsRecords = [];

  function getUniqueYields(yields) {
    if (!Array.isArray(yields)) {
      return [];
    }
    let uniqueYields = [];
    let seenYields = new Set();
    for (let yieldData of yields) {
      let uniqueKey = `${yieldData["yield_period"]}-${yieldData["yield_storage_duration"]}-${yieldData["yield_co2_realized"]}`;
      if (!seenYields.has(uniqueKey)) {
        seenYields.add(uniqueKey);
        uniqueYields.push(yieldData);
      }
    }
    return uniqueYields;
  }

  yieldRecordRetiredCertificateDetails?.forEach(({ assets }) => {
    if (Array.isArray(assets) && assets.length > 0) {
      assets.forEach(({ yields }) => {
        const uniqueYieldsRecords = getUniqueYields(yields);

        uniqueYieldsRecords.forEach(
          ({ yield_period, yield_storage_duration, yield_co2_realized }) => {
            const flatRecord = {
              yield_period,
              yield_co2_realized,
              yield_storage_duration,
            };

            flatAssetsRecords.push(flatRecord);
          }
        );
      });
    }
  });

  const uniqueAssetsRecords = Array.from(
    new Set(flatAssetsRecords.map(JSON.stringify))
  ).map(JSON.parse);

  const yieldColumns = [
    {
      title: "Period",
      dataIndex: "yield_period",
      key: "period",
    },
    {
      title: "Realized tCO₂e",
      dataIndex: "yield_co2_realized",
      key: "co2_realized",
      render: (text) => {
        if (text === undefined || text === null) {
          return '-';
        }
        const number = Number(text);
        return number % 1 === 0
          ? number?.toString()
          : number?.toFixed(4)?.replace(/\.?0+$/, '');
      }
    },
    {
      title: "Storage Duration",
      dataIndex: "yield_storage_duration",
      key: "storage_duration",
    },
  ];

  const columns = [
    {
      title: "Beneficiary",
      dataIndex: "beneficiary_name",
      key: "beneficiary",
    },
    {
      title: "Retirement Purpose",
      dataIndex: "certificate_retirement_purpose",
      key: "retirementPurpose",
    },
    {
      title: "Retirement Date",
      dataIndex: "created",
      key: "retirementDate",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY")
              : undefined}
          </span>
        );
      },
    },
  ];

  return (
    <Modal
      width={1000}
      open={visible}
      cancelText="Close"
      onCancel={onCancel}
      footer={[
        <Button key="download" type="primary" onClick={handleDownload}>
          Download PDF
        </Button>,
      ]}
      className="retired-certificate-modal"
    >
      <Card
        ref={certificateRef}
        style={{
          width: "100%",
          height: "100%",
          padding: "20px 30px",
        }}
        id="retirement-certificate-container"
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <img src="/logo512.png" alt="logo" style={{ height: 36 }} />
        </div>
        <Title level={2} style={{ color: "#35B28D" }}>
          Proof of Retirement
        </Title>
        <Text>
          <strong>{finalTokenData?.beneficiary_name}</strong> has retired{" "}
          <strong>
            {finalTokenData?.no_of_token
              ? Number(finalTokenData.no_of_token)
                  .toFixed(4)
                  .replace(/\.?0+$/, "")
              : "N/A"}{" "}
            tCO₂e
          </strong>{" "}
          of Carbon Credits as part of the
        </Text>
        <br />
        <Text strong>
          {yieldRecordRetiredCertificateDetails[0]?.intervention_name}
        </Text>

        <Title level={5}>Retirement Details</Title>
        <Table
          className="retired-certificate-table"
          dataSource={[finalTokenData]}
          columns={columns}
          pagination={false}
          bordered
          style={{ marginBottom: 20, backgroundColor: "#EBF9F5" }}
        />

        <Title level={3} style={{ color: "#35B28D" }}>
          GHG Project Summary
        </Title>
        <Text>
          {yieldRecordRetiredCertificateDetails[0]?.intervention_summary}
        </Text>

        <Title level={3} style={{ color: "#35B28D" }}>
          GHG Project Information
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            border: "1px solid #d9d9d9",
          }}
        >
          <div
            style={{
              flex: "1",
              maxWidth: "50%",
              borderRight: "1px solid #d9d9d9",
              padding: "10px",
            }}
          >
            <Text>
              <strong>Project Name:</strong>{" "}
              {yieldRecordRetiredCertificateDetails[0]?.intervention_name}
            </Text>
            <br />
            <Text>
              <strong>Project ID:</strong>{" "}
              {yieldRecordRetiredCertificateDetails[0]?.intervention_project_id}
            </Text>
            <br />
            <Text>
              <strong>Id:</strong> PTCB-{finalTokenData?.finaltoken_id}
            </Text>
            <br />
            <Text>
              <strong>GHG Project Type:</strong>{" "}
              {yieldRecordRetiredCertificateDetails[0]?.intervention_type}
            </Text>
            <br />
            <Text>
              <strong>Methodology:</strong>{" "}
              {yieldRecordRetiredCertificateDetails[0]?.intervention_method}
            </Text>
            <br />
            <Text>
              <strong>Standards Used:</strong>{" "}
              {
                yieldRecordRetiredCertificateDetails[0]
                  ?.intervention_standards_used
              }
            </Text>
            <br />
            <Text>
              <strong>Location:</strong>{" "}
              {yieldRecordRetiredCertificateDetails[0]?.intervention_region}
            </Text>
          </div>

          <div style={{ flex: "1" }}>
            <Text
              style={{
                display: "block",
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                padding: "10px",
              }}
            >
              <strong>Amount of Carbon Credits</strong>
            </Text>
            <div
              style={{
                display: "flex",
                marginTop: "35px",
                justifyContent: "center",
              }}
            >
              <Title level={2} style={{ color: "#35B28D" }}>
                {finalTokenData?.no_of_token} tCO₂e
              </Title>
            </div>
          </div>
        </div>

        <Title level={3} style={{ marginTop: "20px", color: "#35B28D" }}>
          Detailed Characteristics
        </Title>
        <Table
          dataSource={uniqueAssetsRecords}
          columns={yieldColumns}
          pagination={false}
          bordered
          style={{ marginBottom: 20 }}
        />

        <Title level={3} style={{ color: "#35B28D" }}>
          Project Stakeholders
        </Title>
        <Text>
          <strong>Project Developer: </strong>
          {yieldRecordRetiredCertificateDetails[0]?.intervention_operator}
        </Text>
        <br />
        {yieldRecordRetiredCertificateDetails[0]
          ?.intervention_project_sponsor && (
          <>
            <Text>
              <strong>Project Sponsor: </strong>
              {
                yieldRecordRetiredCertificateDetails[0]
                  ?.intervention_project_sponsor
              }
            </Text>
            <br />
          </>
        )}
        <Text>
          <strong>Validator: </strong>
          {
            yieldRecordRetiredCertificateDetails[0]
              ?.intervention_validator_of_quality
          }
        </Text>
        <br />
        <Text>
          <strong>Verifier: </strong>
          {
            yieldRecordRetiredCertificateDetails[0]
              ?.intervention_verifier_of_quality
          }
        </Text>
      </Card>
    </Modal>
  );
};
