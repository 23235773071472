import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const yieldApi = createApi({
    reducerPath: "yieldApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/is/asset/yield",
        prepareHeaders: (headers, { getState }) => {

            const token = getState().auth.token;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Yield"],
    endpoints: (build) => ({
        getYield: build.query({
            query: (id) => `${id}`,
            providesTags: ["Yield"],
        }),
        getYields: build.query({
            query: () => "",
            providesTags: ["Yield"],
        }),
        getYieldsByParam: build.query({
            query: (params) => {
                return {
                    url: "",
                    params: params,
                };
            },
            providesTags: ["Yield"],
        }),
        getInterventionYieldsByParam: build.query({
            query: (params) => {
                return {
                    url: "/view",
                    params: params,
                };
            },
            providesTags: ["InterventionYields"],
        }),
        addYield: build.mutation({
            query(body) {
                return {
                    url: ``,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["Yield"],
        }),
        addYieldsBatch: build.mutation({
            query(body) {
                return {
                    url: `/batch`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["Yield"],
        }),
        updateYield: build.mutation({
            query(payload) {
                return {
                    url: `${payload.id}`,
                    method: "PUT",
                    body: payload.body,
                };
            },
            invalidatesTags: ["Yield"],
        }),
        updateYieldStatus: build.mutation({
            query(payload) {
              return {
                url: `/status`,
                method: "PUT",
                body: payload.body,
              };
            },
            invalidatesTags: ["Yield"],
          }),
        updateYieldBatch: build.mutation({
            query(payload) {
                return {
                    url: `/batch`,
                    method: "PUT",
                    body: payload,
                };
            },
            invalidatesTags: ["InterventionYields"],
        }),
        deleteYield: build.mutation({
            query(id) {
                return {
                    url: `${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Yield"],
        }),
    }),
});

export const {
    useGetYieldQuery,
    useGetYieldsQuery,
    useGetYieldsByParamQuery,
    useGetInterventionYieldsByParamQuery,
    useAddYieldMutation,
    useUpdateYieldMutation,
    useUpdateYieldStatusMutation,
    useDeleteYieldMutation,
    useUpdateYieldBatchMutation,
    useAddYieldsBatchMutation
} = yieldApi;
