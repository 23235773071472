import { React, useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import {
  createAssetsAction,
  createEntitlementRecordsAction,
  createTradableCertificateAction,
  createYieldsAction,
} from "../../constants/actionNames";
import { setYield } from "../../store/is/isSlice";
import {
  useAddYieldMutation,
  useUpdateYieldMutation
} from "../../store/is/yieldapi";

function HandleYieldButtons({
  assetid,
  isCreate,
  isYieldEdit,
  selectedYieldRecord,
  closeModal,
  yieldRecord,
  refetchValidation,
  refetchYields
}) {
  let userid = useSelector((state) => state.auth.userid);
  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup.filter(Boolean);

  let { id } = useParams();
  const history = useHistory();

  let asset = useSelector((state) => state.is.asset);

  const [isEditing, setIsEditing] = useState(id === "edit" ? true : false);

  const [isYieldSignOff, setIsYieldSignOff] = useState(false);

  //********bc-client */
  const { onAccept } = useTransactionHandlers({
    actionname: createYieldsAction.name,
  });
  const createYieldsActionInBC = async (yields) => {
    return await onAccept(yields);
  };

  const { onAccept: onAcceptCreateAssets } = useTransactionHandlers({
    actionname: createAssetsAction.name,
  });
  const createAssetsActionInBC = async (asset) => {
    return await onAcceptCreateAssets(asset);
  };

  const {
    onAccept: onAcceptcreateEntitlementRecordsAction,
  } = useTransactionHandlers({
    actionname: createEntitlementRecordsAction.name,
  });
  const createEntitlementRecordsActionInBC = async (payload) => {
    return await onAcceptcreateEntitlementRecordsAction(payload);
  };

  //Final certificate
  const {
    onAccept: onAcceptcreateTradableCertificateAction,
  } = useTransactionHandlers({
    actionname: createTradableCertificateAction.name,
  });
  const createTradableCertificateActionInBC = async (payload) => {
    return await onAcceptcreateTradableCertificateAction(payload);
  };

  //********bc-client ends */

  useEffect(() => {
    dispatch(setYield(yieldRecord));
  }, []);

  let yields = useSelector((state) => state.is.yields);
  const [editingRecord, setEditingRecord] = useState(yields);

  const closeYieldSignOffModal = () => {
    setIsYieldSignOff(false);
  };

  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const key = "updatable";
  const dispatch = useDispatch();

  const onCreate = async (values) => {
    if (isYieldEdit) {

      let yieldToUpdate = { ...selectedYieldRecord[0] };

      yieldToUpdate.period = values?.period;
      yieldToUpdate.storage_duration = values?.storage_duration;
      yieldToUpdate.co2_estimated = values?.co2_estimated;
      yieldToUpdate.co2_realized = values?.co2_realized;
      yieldToUpdate.product_volume = values?.product_volume;

      handleEditRecord(yieldToUpdate);
      setEditingRecord(yieldToUpdate);
    }
    else if (isCreate) {
      values.asset_id = assetid;
      await handleAddRecord(values);
    }
    closeModal();
  };

  const [addYield, { isCreateLoading }] = useAddYieldMutation();
  const [updateYield, { isYieldUpdateLoading }] = useUpdateYieldMutation();

  const handleAddRecord = async (record) => {
    console.log("Yield Record", record);
    try {
      await addYield(record)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "Yield added successfully!",
            key,
            duration: 2,
          });

          console.log(res);
          let currEditingRecord = { ...res?.data };
          onEditRecord(currEditingRecord);
          dispatch(setYield({ currEditingRecord }));
          refetchValidation()
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Yield!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const handleEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      await updateYield(payload)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "Yield updated successfully!",
            key,
            duration: 2,
          });
          let currEditingRecord = { ...res?.data };
          setEditingRecord({ ...currEditingRecord });
          dispatch(setYield(currEditingRecord));
          refetchYields();
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Yield: " + Object.values(err["data"])[0],
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const [form] = Form.useForm();

  const onCancel = async () => {
    form.resetFields();
    let path = `/Asset`;
    history.push(path);
    onCancel();
  };

  return (
    <>
      {!usergroup.includes("Verifier") &&
        !usergroup.includes("Validator") && (
          <>
            <Divider type="vertical"></Divider>
            {(isCreate || isYieldEdit) && (
              <YieldFormModal
                visible={isCreate || isYieldEdit}
                selectedYieldRecord={selectedYieldRecord}
                onCreate={onCreate}
                isYieldEdit={isYieldEdit}
                onCancel={() => {
                  closeModal();
                }}
              />
            )
            }
          </>
        )}
    </>
  );
}

const YieldFormModal = ({ visible, onCreate, isYieldEdit, selectedYieldRecord, onCancel }) => {

  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const isEditDisabled = isYieldEdit && (selectedYieldRecord[0]?.status !== 'New ' && !(selectedYieldRecord?.[0]?.status === "Revision" && !selectedYieldRecord?.[0]?.is_pre_credits_available))

  return (
    <Modal
      width={500}
      title={isYieldEdit? "Edit Yield" : "Add a New Yield"}
      open={visible}
      
      okText="Submit Yield"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
            onCancel();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Typography.Title level={4}>
        {isYieldEdit ? "Edit the below Yield details" :
        "Please fill in the below values for the Yield you want to add."}
      </Typography.Title>
      <Form 
        layout="vertical"
        initialValues={isYieldEdit? selectedYieldRecord[0] : ""}
        form={form}
        name="yield">
        <Form.Item
          name="period"
          label="Period"
          rules={[
            {
              required: true,
              message: "Please enter the Period Value!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="storage_duration" label="Storage Duration">
          <Input />
        </Form.Item>

        <Form.Item
          name="co2_estimated"
          label="Estimated tCO2e"
          rules={[
            {
              required: true,
              message: "Please enter the Co2 Estimated Value!",
            },
            {
              validator: (_, value) => {
                if (value === null || value === undefined || value === '') {
                  return Promise.resolve();
                } else if (!/^\d+(\.\d{1,4})?$/.test(value)) {
                  return Promise.reject(new Error("Value must be a number with up to 4 decimal places"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input inputMode={"numeric"} pattern={"\\d*"} value={value} disabled={isEditDisabled} />
        </Form.Item>

        <Form.Item
          name="co2_realized"
          label="Realized tCO2e"
          rules={[
            {
              required: selectedYieldRecord?.[0]?.status === "Pending Realization" || (selectedYieldRecord?.[0]?.status === "Revision" && selectedYieldRecord?.[0]?.is_pre_credits_available),
              message: "Please enter the Co2 Realized Value!",
            },
            {
              validator: (_, value) => {
                if (value === null || value === undefined || value === '') {
                  return Promise.resolve();
                } else if (!/^\d+(\.\d{1,4})?$/.test(value)) {
                  return Promise.reject(new Error("Value must be a number with up to 4 decimal places"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input inputMode={"numeric"} pattern={"\\d*"} value={value} />
        </Form.Item>

        <Form.Item
          name="product_volume"
          label="Product Volume"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HandleYieldButtons;
