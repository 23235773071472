import React from "react";
import { Col, Row, Typography } from "antd";

import { useGetMethodQuery } from "../../../store/is/methodsapi";

export const DisplayMethod = ({ methodid }) => {
  const { data, isLoading } = useGetMethodQuery(methodid);
  if (!isLoading) {
  }

  return (
    <div>
      <Row align="middle" gutter={[8, 0]}>
        <Col>
          <Row>
            <Col>
              <Typography.Text style={{ fontSize: 18 }}>
                {data ? data[0]?.name:""}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
