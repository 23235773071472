import { useState } from "react";
import { DownOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Modal,
  Segmented,
  Select,
  Spin,
  Table,
  Typography,
  message,
  Input,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import {
  createAllocateAction,
  createTradableCertificateAction,
} from "../../constants/actionNames";
import { useGetInterventionsQuery } from "../../store/is/interventionapi";
import {
  preentitlementrecordApi,
  useGetPreEntitilementRecordByOrganizationQuery,
} from "../../store/is/preentitlementrecordsapi";
import { useUpdateYieldBatchMutation } from "../../store/is/yieldapi";
import store from "../../store/store";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import { useGetOrgsQuery } from "../../store/auth/orgapi";

function Entitlements() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSpinning, setSpinning] = useState(false);
  const [isAllocateModalVisible, setIsAllocateModalVisible] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filteredIntervention, setFilteredIntervention] = useState("");
  const [filterOption, setFilterOption] = useState("all");
  const [sortedInfo, setSortedInfo] = useState({});
  const [organizationMatchStatus, setOrganizationMatchStatus] = useState(null);
  const [organizationMatchingData, setOrganizationMatchingData] = useState();

  const [form] = Form.useForm();

  const [updateYieldBatch] = useUpdateYieldBatchMutation();

  const key = "updatable";
  const userid = useSelector((state) => state.auth?.userid);
  const orgid = useSelector((state) => state.auth?.org?._id);

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  let BCAddress = useSelector((state) => state.auth.baddress);

  usergroup = usergroup.filter(Boolean);
  // BC-client -allocate

  const { onAccept: onAcceptcreateAllocateAction } = useTransactionHandlers({
    actionname: createAllocateAction.name,
  });
  const createAllocateActionInBC = async (payload) => {
    return await onAcceptcreateAllocateAction(payload);
  };

  const { data = [] } = useGetInterventionsQuery();

  const {
    data: entitlementdatalist,
    isLoading: entitlementdatalistloading,
    refetch: refetchEntitlementDataList,
  } = useGetPreEntitilementRecordByOrganizationQuery();

  const [transferAmount, setTransferAmount] = useState(0);

  const entitlementStatusLabels = {
    realized: "Realized",
    notrealized: "Not Realized",
    inactive: "Inactive",
  };

  const showModal = () => {
    setIsAllocateModalVisible(true);
    const totalTransferAmount = selectedRows.reduce(
      (total, row) => total + row.no_of_entitlement_record,
      0
    );
    setTransferAmount(totalTransferAmount);
    form.setFieldValue("transfer_amount", totalTransferAmount);
  };

  const handleCancel = () => {
    setIsAllocateModalVisible(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const {
    onAccept: onAcceptcreateTradableCertificateAction,
  } = useTransactionHandlers({
    actionname: createTradableCertificateAction.name,
  });
  const createTradableCertificateActionInBC = async (payload) => {
    return await onAcceptcreateTradableCertificateAction(payload);
  };

  const resetButtonStates = () => {
    setSelectedRowKeys([]);
  };

  const handleTransferOfPreEntitlementRecords = async (values) => {
    try {
      setSpinning(true);
      if (
        !(
          usergroup.includes("Project Developer") ||
          usergroup.includes("Project Sponsor") ||
          usergroup.includes("Certificate Access")
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const { data: updatedRows, isLoading } = await store.dispatch(
          preentitlementrecordApi.endpoints.entitlementTransferCalculation.initiate(
            {
              transferable_amt: values?.transfer_amount,
              selectedrows: selectedRows,
            }
          )
        );
        const entitlementRecordsToTransfer = [];

        await createAllocateActionInBC({
          from: BCAddress,
          to: organizationMatchingData?._id,
          transferAmount: values?.transfer_amount,
          entitlementRecordsToTransfer: updatedRows?.data,
          flag: "EntitlementRecords",
          tokenType: "NFRY",
        });
      }
    } catch (error) {
      console.error("Error transferring pre credits records:", error);
    } finally {
      refetchEntitlementDataList();
      setSpinning(false);
      setIsAllocateModalVisible(false);
    }
  };

  const {
    data: orgDetails,
    isLoading: isOrgDetailsLoading,
  } = useGetOrgsQuery();

  if (!isOrgDetailsLoading) {
  }

  const { Option } = Select;
  const specificGroupList = [];
  if (!isOrgDetailsLoading && orgDetails) {
    orgDetails.orgList.forEach((orgData) => {
      specificGroupList.push(orgData);
    });
  }

  const menu = (
    <Menu>
      <Menu.Item key="submitForTransferEntitlements">
        <Button
          type="link"
          disabled={
            selectedRowKeys.length === 0 ||
            (selectedRowKeys.length !== 0 &&
              selectedRows.some((row) => row?.status === "inactive")) ||
            (selectedRows.some((row) => row?.status === "realized") &&
              selectedRows.some((row) => row?.status === "notrealized"))
          }
          onClick={async () => {
            showModal();
            resetButtonStates();
          }}
        >
          Transfer Pre-Credits
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleInterventionFilterChange = (value) => {
    if (value === "Reset") {
      setFilteredIntervention("");
      setFilterValue("");
    } else {
      setFilteredIntervention(value);
    }
  };

  const filteredData = entitlementdatalist?.data.filter(
    (record) =>
      (!filteredIntervention ||
        record?.intervention_details?._id === filteredIntervention) &&
      (!filterValue || record?.intervention_details?.name === filterValue)
  );

  const columns = [
    {
      key: "ghg_project",
      title: "GHG Project",
      render: (_, record) => `${record?.intervention_details?.name}`,
    },
    {
      key: "token_info",
      title: "Token",
      sorter: (a, b) => {
        const tokenIDA = parseInt(a.yield_details.token_id);
        const tokenIDB = parseInt(b.yield_details.token_id);
        return tokenIDA - tokenIDB;
      },
      defaultSortOrder: "ascend",
      render: (_, record) =>
        `${record?.yield_details?.token_name} - ${record?.yield_details?.token_id}`,
    },
    {
      key: "no_of_entitlement_record",
      title: "Number of Pre-Credits",
      dataIndex: "no_of_entitlement_record",
    },
    ...(filterOption === "Transactions"
      ? [
          {
            key: "organization_id",
            title: "Owner of Pre-Credits",
            dataIndex: "organization_id",
            render: (record) => {
              return <DisplayOrganization orgid={record} place="table" />;
            },
          },
          {
            key: "from_org",
            title: "From",
            dataIndex: "from_org",
            render: (record) => {
              return <DisplayOrganization orgid={record} place="table" />;
            },
          },
          {
            key: "to_org",
            title: "To",
            dataIndex: "to_org",
            render: (record) => {
              return <DisplayOrganization orgid={record} place="table" />;
            },
          },
        ]
      : []),
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        return entitlementStatusLabels[record];
      },
    },
    {
      key: "created",
      title: "Created At",
      dataIndex: "created",
      render: (record) => {
        const timestamp = record ? record : null;
        return (
          <span>
            {timestamp
              ? moment(new Date(timestamp))
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updated",
      title: "Updated At",
      dataIndex: "updated",
      render: (record) => {
        const timestamp = record ? record : null;
        return (
          <span>
            {timestamp
              ? moment(new Date(timestamp))
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
  ];

  const handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection =
    filterOption !== "Transactions"
      ? {
          selectedRowKeys,
          onChange: handleRowSelectChange,
        }
      : null;

  const FilterTabBasedData = (data) => {
    switch (filterOption) {
      case "Transactions":
        return data?.filter(
          (item) =>
            (item.from_org === orgid || item.to_org === orgid) &&
            item.status === "notrealized"
        );
      default:
        return data?.filter(
          (item) =>
            item.organization_id === orgid && item.status === "notrealized"
        );
    }
  };

  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Card className="shadow-card" style={{ width: "100%", borderRadius: 8 }}>
        <Spin spinning={isSpinning}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Title level={2}>Pre-Credits</Typography.Title>
            {!usergroup.includes("Certificate Access") && (
              <Select
                showSearch
                placeholder="Filter by GHG Project"
                optionFilterProp="children"
                onChange={handleInterventionFilterChange}
                style={{ width: "200px" }}
              >
                {data?.data?.map((intervention) => (
                  <Option key={intervention?._id} value={intervention?._id}>
                    {intervention?.name}
                  </Option>
                ))}
                <Option
                  key="Reset"
                  value="Reset"
                  style={{
                    position: "sticky",
                    backgroundColor: "#f0f2f5",
                    zIndex: 1,
                  }}
                >
                  Reset
                </Option>
              </Select>
            )}
            <div>
              <Divider type="vertical"></Divider>
              {filterOption === "Transactions" ? null : (
                <Dropdown overlay={menu} trigger={["hover"]}>
                  <Button type="primary">
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
          <Segmented
            style={{ marginBottom: "10px" }}
            defaultValue="all"
            onChange={setFilterOption}
            options={[
              { label: "Pre-Credits", value: "all" },
              { label: "Transactions", value: "Transactions" },
            ]}
          />
          <Table
            size="small"
            columns={columns}
            dataSource={FilterTabBasedData(filteredData)}
            onChange={handleChange}
            rowSelection={rowSelection}
            rowKey={(record) => record._id}
            expandable={{ defaultExpandedRowKeys: ["0"] }}
            pagination={{
              pageSizeOptions: ["10", "50", "100"],
              showSizeChanger: true,
            }}
          />
        </Spin>
      </Card>
      <Modal
        title="Transfer Pre-Credits"
        visible={isAllocateModalVisible}
        width={700}
        onCancel={handleCancel}
        confirmLoading={isSpinning}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              try {
                form.resetFields();
                handleTransferOfPreEntitlementRecords(values);
                form.setFieldsValue({ organization_id: undefined });
              } catch {
              } finally {
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={isSpinning}>
          <Form form={form}>
            <Form.Item
              name="organization_id"
              label="Organization"
              rules={[
                {
                  required: true,
                  message: "Please enter the Organization name",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const organizationName = specificGroupList?.find(
                      (org) => org?.name?.toLowerCase() === value?.toLowerCase()
                    );
                    if (organizationName) {
                      setOrganizationMatchingData(organizationName);
                      setOrganizationMatchStatus("success");
                      return Promise.resolve();
                    } else {
                      setOrganizationMatchStatus("error");
                      return Promise.reject(new Error("Organization name does not match!"));
                    }
                  },
                }),
              ]}
              validateStatus={organizationMatchStatus}
                help={
                  organizationMatchStatus === "success" ? (
                    <span style={{ color: "green" }}>Organization match found</span>
                  ) : null
                }
              extra={
                <p>The Organization name must match an existing Organization in the system.</p>
              }
            >
              <Input
                  onChange={() => setOrganizationMatchStatus(null)}
                  suffix={
                    organizationMatchStatus === "success" ? (
                      <CheckCircleOutlined
                        style={{ color: "green", fontSize: "16px" }}
                      />
                    ) : null
                  }
              />
            </Form.Item>
            <Form.Item name="transfer_amount" label="Transfer Amount">
              <InputNumber
                min={0.0001}
                step={1}
                max={transferAmount}
                precision={4}
                formatter={(value) => {
                  if (value) {
                    const [integerPart, decimalPart] = value.split(".");
                    return decimalPart
                      ? `${integerPart}.${decimalPart.slice(0, 4)}`
                      : integerPart;
                  }
                  return "";
                }}
                parser={(value) => {
                  if (value) {
                    const [integerPart, decimalPart] = value.split(".");
                    return decimalPart
                      ? `${integerPart}.${decimalPart.slice(0, 4)}`
                      : integerPart;
                  }
                  return "";
                }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}

export default Entitlements;
