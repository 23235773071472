import { utils } from "ethers";
import { getAddress, isAddress } from "ethers/lib/utils.js";
import {
YOUR_PROBA_CONTRACT_ADDRESS_ABI
} from "./../config/config";

const ifaceProba = new utils.Interface(YOUR_PROBA_CONTRACT_ADDRESS_ABI);

function isAddressesSame(...addresses) {
    const areAddresses = addresses.reduce(
      (isAllAddress, address) => isAddress(`${address}`.toLowerCase()) && isAllAddress,
      true
    );
    if (!areAddresses) return areAddresses;
  
    const checksumAddresses = addresses.map((addr) => getAddress(addr.toLowerCase()));
    const firstAddress = checksumAddresses[0];
    const areAllAddressesEqual = checksumAddresses.every((address) => firstAddress === address);
  
    return areAllAddressesEqual;
  }

export { ifaceProba, isAddressesSame };
