import { React, useState } from "react";
import {
  useGetInterventionDocsQuery
} from "../../store/is/interventionapi";

import {
  useGetAssetDocsQuery
} from "../../store/is/assetapi";


import {
  Button,
  Col,
  Row,
  Table,
  Tag,
  message
} from "antd";

import { DownloadOutlined, FileExcelFilled, FileFilled, FileImageFilled, FilePdfFilled, FileTextFilled, FileWordFilled } from "@ant-design/icons";
import moment from 'moment';

import { useGrantOrgAuthMutation, useGrantUserAuthMutation, useLiftOrgAuthMutation, useLiftUserAuthMutation, useUpdateDocVisibilityMutation } from "../../store/ds/documentaccessapi";
import { useGetDocumentInfoByIdsQuery, useLazyGetDocumentInfoQuery } from "../../store/ds/documentapi";

import { useSelector } from "react-redux";

import axios from 'axios';
import DocumentAccess from "../Document/DocumentAccess";


function DisplayDocuments({ id, entity }) {

  const [getDocumentInfoData] = useLazyGetDocumentInfoQuery()

  const { data: interdocs, isLoading: isInterventionDocLoading } = useGetInterventionDocsQuery(id, { skip: !entity === "intervention" });
  const { data: assetdocs, isLoading: isAssetDocLoading } = useGetAssetDocsQuery(id, { skip: !entity === "asset" });
  
  //const allDocIds = (entity === "intervention" ? interdocs : assetdocs)?.data?.docs?.map((d) => `${d.document_id}`)

  const allDocIds = (entity === "intervention" ? interdocs : assetdocs)?.data?.docs
  ?.filter((d) => d.document_id !== "undefined")
  .map((d) => `${d.document_id}`);


  const { data: documentsInfo } = useGetDocumentInfoByIdsQuery(
    allDocIds?.join()
    , { skip: isInterventionDocLoading || isAssetDocLoading })


  let token = useSelector((state) => state.auth.token);
  token = token.replace('Bearer ', '');


  const onDownloadDocument = (record) => {
    axios.get("/ds/document/" + record.ID, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((blob) => {
      console.log("blob", blob)
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        record.filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentVisibilityPublic, setIsDocumentVisibilityPublic] = useState(null);
  const [dropdownValue, setDropDownValue] = useState("");
  const [documentId, setDocumentId] = useState(null);
  const [grantUserAuth] = useGrantUserAuthMutation();
  const [grantOrgAuth] = useGrantOrgAuthMutation();
  const [liftUserAuth] = useLiftUserAuthMutation();
  const [liftOrgAuth] = useLiftOrgAuthMutation();
  const [updateDocVisibility] = useUpdateDocVisibilityMutation();

  const showModal = async (record) => {
    setIsModalOpen(true);
    setDocumentId(record?.ID);

    // Fetch document info for the selected document
    const documentInfo = await getDocumentInfoData(record?.ID).unwrap();

    // Set the initial state of documentVisibilityPublic based on the document's visibility
    if (documentInfo?.documentvisibility) {
      setIsDocumentVisibilityPublic(documentInfo?.documentvisibility);
    } else {
      setDropDownValue("");
      setIsDocumentVisibilityPublic("");
    }
  };

  const key = "updatable";

  const columns = [
    {
      key: "doctype",
      title: "Document Type",
      dataIndex: "doctype",
    },
    {
      key: "filename",
      title: "File Name",
      dataIndex: "filename",
    },
    {
      key: "documentvisibility",
      title: "Visibility",
      dataIndex: "documentvisibility",
      render: (record) => {
        return (
          <> {record === "PUBLIC" ? "Public" : record === "PRIVATE" ? "Private" : record}
          </>
        );
      },
    },
    {
      key: "contentType",
      title: "Content Type",
      dataIndex: "contentType",
      render: (record) => {
        switch (record) {
          case "application/pdf":
            return (
              <>
                <Tag icon={<FilePdfFilled />} color="#55acee">
                  pdf
                </Tag>
              </>
            );
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return (
              <>
                <Tag icon={<FileWordFilled />} color="#55acee">
                  word
                </Tag>
              </>
            );
          case "image/png":
            return (
              <>
                <Tag icon={<FileImageFilled />} color="#55acee">
                  image
                </Tag>
              </>
            );
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return (
              <>
                <Tag icon={<FileExcelFilled />} color="#55acee">
                  excel
                </Tag>
              </>
            );
          case "text/rtf":
          case "text/plain":
            return (
              <>
                <Tag icon={<FileTextFilled />} color="#55acee">
                  text
                </Tag>
              </>
            );
          default:
            return (
              <>
                <Tag icon={<FileFilled />} color="#55acee">
                  {record}
                </Tag>
              </>
            );
        }
      },
    },
    {
      key: "size",
      title: "Size",
      dataIndex: "size",
      render: (record) => {
        return (
          <>
            {record}Kb
          </>
        );
      },
    },
    {
      key: "CreatedAt",
      title: "Created",
      dataIndex: "CreatedAt",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <Row gutter={[10, 10]}>
              <Col><DownloadOutlined
                onClick={() => {
                  onDownloadDocument(record);
                }}
              /></Col>
            </Row>
          </>
        );
      },
    },
    {
      key: "Manage",
      title: "Manage",
      render: (record) => {
        return (
          <>
            <Button type="primary" onClick={() => showModal(record)}>
              Manage Access
            </Button>
          </>
        );
      },
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setDropDownValue(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const onGrantAccess = async (record) => {
    if (record.accesslevel === "userlevel") {
      grantUserAccess(record);
    } else if (record.accesslevel === "orglevel") {
      grantOrgAccess(record);
    }

    if (record.documentvisibility === "PUBLIC" || record.documentvisibility === "PRIVATE") {
      updateDocumentVisibility(record);
    }
  };


  const grantUserAccess = async (record) => {
    try {
      const payload = {
        id: record.accesslevellist,
        documentID: documentId,
      }
      await grantUserAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const grantOrgAccess = async (record) => {
    try {
      const payload = {
        id: record.accesslevellist,
        documentID: documentId,
      }
      await grantOrgAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const liftUserAccess = async (record, documentId) => {
    try {
      const payload = {
        id: record[0],
        documentID: documentId,
      }
      await liftUserAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Lifting Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const liftOrgAccess = async (record) => {
    try {
      const payload = {
        id: record[0],
        documentID: documentId,
      }
      await liftOrgAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Lifting Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const updateDocumentVisibility = async (record) => {
    try {
      const payload = {
        documentID: documentId,
        documentVisibility: record.documentvisibility
      }
      await updateDocVisibility(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Document Visibility Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Document Visibility Access : " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  return (
    <div>
      <Table pagination={{
        pageSize: 7,
      }}
        size="small" columns={columns} dataSource={documentsInfo?.documents ?? []}></Table>
      <DocumentAccess
        dropdownValue={dropdownValue}
        isModalOpen={isModalOpen}
        documentId={documentId}
        closeModal={closeModal}
        onGrantAccess={onGrantAccess}
        liftUserAccess={liftUserAccess}
        liftOrgAccess={liftOrgAccess}
        handleChange={handleChange}
        documentVisibilityPublic={documentVisibilityPublic}
      />
    </div>
  );
}

export default DisplayDocuments;
