
//import axios from 'axios';
import { ipfsApi } from '../store/ipfs/ipfs';
import store from '../store/store';


//upload Final Certificate  to iPFS
async function postFinalCertificateAttributesToIpfs(record) {

  const { data, isLoading } = await store.dispatch(
    ipfsApi.endpoints.postToIpfs.initiate(record)
  );
  console.log("ipfsApi..data..", data, isLoading);
  if (!isLoading) {
    const ipfsHash = data?.IpfsHash;

    const metadataUri = `https://ipfs.io/ipfs/${ipfsHash}`;

    return metadataUri;
  }
};



export {postFinalCertificateAttributesToIpfs };