import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const documentAccessApi = createApi({
  reducerPath: "documentaccessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/ds/auth",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["DocumentAccess"],
  endpoints: (build) => ({
    grantUserAuth: build.mutation({
      query(payload) {
        return {
          url: `/grant/user/${payload.id}/${payload.documentID}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["DocumentAccess"],
    }),
    grantOrgAuth: build.mutation({
      query(payload) {
        return {
          url: `/grant/org/${payload.id}/${payload.documentID}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["DocumentAccess"],
    }),
    liftUserAuth: build.mutation({
      query(payload) {
        return {
          url: `/lift/user/${payload.id}/${payload.documentID}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["DocumentAccess"],
    }),
    liftOrgAuth: build.mutation({
      query(payload) {
        return {
          url: `/lift/org/${payload.id}/${payload.documentID}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["DocumentAccess"],
    }),
    updateDocVisibility: build.mutation({
      query(payload) {
        return {
          url: `/${payload.documentID}/${payload.documentVisibility}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["DocumentAccess"],
    }),
    getAuthInfo: build.query({
      query: (id) => `${id}`,
      providesTags: ["DocumentAccess"],
    }),
  }),
});

export const {
  useGrantUserAuthMutation,
  useGrantOrgAuthMutation,
  useLiftUserAuthMutation,
  useLiftOrgAuthMutation,
  useUpdateDocVisibilityMutation,
  useGetAuthInfoQuery,
} = documentAccessApi;
