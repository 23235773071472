import React, { useState } from "react";
import { Form, Input, Modal, Select, message, Collapse, Spin } from "antd";
import { useGetValidateAssignmentInfoQuery } from "../../../store/is/assignmentapi";
import { useAddAssignmentMutation } from "../../../store/is/assignmentapi";
import { useGetInterventionQuery } from "../../../store/is/interventionapi";
import { useGetOrgsQuery } from "../../../store/auth/orgapi";

const { Panel } = Collapse;
const { Option } = Select;

export default function AssignVerifier({
  isDetails,
  isEditing,
  isCreate,
  closeModal,
  groupname,
  assignmentTo,
  handleDispatchAfterAssignment,
  refetchValidation,
}) {
  const key = "updatable";

  const [isAssigning, setIsAssigning] = useState(false);
  const [spinning, setSpinning] = useState(false);

  const [addAssignment] = useAddAssignmentMutation();

  const handleAddRecord = async (record) => {
    try {
      setIsAssigning(true);
      setSpinning(true);
      const res = await addAssignment(record).unwrap();
      message.success({
        content: groupname + " assigned successfully to " + assignmentTo,
        key,
        duration: 2,
      });
      console.log(res);
      handleDispatchAfterAssignment(res?.data);
      refetchValidation();
    } catch (err) {
      message.error({
        content: "Failed to assign " + assignmentTo + " to " + groupname,
        key,
        duration: 2,
      });
      console.log(err);
    } finally {
      setIsAssigning(false);
      setSpinning(false);
    }
  };

  const onCreate = async (values) => {
    if (isEditing) {
      // handle editing
    } else if (isCreate) {
      console.log("Values", values);
      await handleAddRecord(values);
    }
    closeModal();
  };

  return (
    <div>
      {(isEditing || isCreate) && (
        <AssignUserFormModal
          visible={isCreate || isEditing}
          onCreate={onCreate}
          isDetails={isDetails}
          spinning={spinning}
          onCancel={closeModal}
          groupname={groupname}
          assignmentTo={assignmentTo}
        />
      )}
    </div>
  );
}

const AssignUserFormModal = ({
  visible,
  onCreate,
  onCancel,
  isDetails,
  spinning,
  groupname,
  assignmentTo,
}) => {
  const {
    data: orgDetails,
    isLoading: isOrgDetailsLoading,
  } = useGetOrgsQuery();
  const {
    data: intervedetails,
    isLoading: isInterventionLoading,
  } = useGetInterventionQuery(isDetails?.intervention_id);
  const {
    data: assigneeDetails,
    isLoading: isValidateAssigneeDetailsLoading,
  } = useGetValidateAssignmentInfoQuery(
    {
      intervention_id: isDetails?._id,
      organization: isDetails?.assignment?.find(
        (assignee) => assignee?.assignee_type === groupname
      )?.organization,
      assignee_type: groupname,
    },
    {
      skip: !isDetails?._id,
    }
  );

  const [form] = Form.useForm();

  const specificGroupList = orgDetails?.orgList?.map((orgData) => (
    <Option key={orgData?._id} value={orgData?._id}>
      {orgData?.name}
    </Option>
  ));

  const fieldsValue = {
    assignee_type: groupname,
    ...(assignmentTo === "GHG Project" && { intervention_id: isDetails?._id }),
    ...(assignmentTo === "GHG Asset" && { asset_id: isDetails?._id }),
  };

  form.setFieldsValue(fieldsValue);

  const assetYields = {};
  assigneeDetails?.forEach((item) => {
    if (item?.name) {
      assetYields[item?.name] =
        item?.yields?.map((yieldItem) => ({
          period: yieldItem?.period,
          token_id: yieldItem?.token_id,
        })) ?? [];
    }
  });

  const renderAssetYields = (assetName, yields) => (
    <Panel header={`Asset name: ${assetName}`} key={assetName}>
      {yields?.length ? (
        <ul>
          {yields?.map((yieldItem, index) => (
            <li key={index}>
              Period: <strong>{yieldItem?.period}</strong>, NFRY-ID:{" "}
              <strong>{yieldItem?.token_id}</strong>
            </li>
          ))}
        </ul>
      ) : (
        <p>No yields to update for this asset.</p>
      )}
    </Panel>
  );

  const renderAssigneeDetails = () => (
    <div>
      {assigneeDetails?.map((item, index) => (
        <div key={index}>
          <h3>Asset name: {item?.name}</h3>
          {item?.yields && Array?.isArray(item?.yields) ? (
            <Collapse>
              {item?.yields?.map((yieldItem, yieldIndex) => (
                <Panel header="Yields" key={yieldIndex}>
                  <ul>
                    <li>
                      Period: <strong>{yieldItem?.period}</strong>
                    </li>
                    <li>
                      NFRY-ID: <strong>{yieldItem?.token_id}</strong>
                    </li>
                  </ul>
                </Panel>
              ))}
            </Collapse>
          ) : (
            <p>No yields to update for this asset.</p>
          )}
        </div>
      ))}
    </div>
  );

  const assetNames = Object.keys(assetYields);

  return (
    <Modal
      width={700}
      title={`Assign ${assignmentTo} to ${groupname}`}
      open={visible}
      okText="Save"
      cancelText="Cancel"
      confirmLoading={spinning}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            form.resetFields();
            onCreate(values);
            form.setFieldsValue({ organization: undefined });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {isValidateAssigneeDetailsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spin spinning={isValidateAssigneeDetailsLoading} />
        </div>
      ) : (
        <Form layout="vertical" form={form} name="assigntouser">
          <Form.Item
            name="organization"
            label="Organization"
            rules={[
              {
                required: true,
                message: "Please choose an Organization",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please choose an Organization"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {specificGroupList}
            </Select>
          </Form.Item>

          <Form.Item name="assignee_type" label="Assignee Type" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="intervention_id" label="GHG Project ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="asset_id" label="Asset ID" hidden>
            <Input />
          </Form.Item>
          {isDetails?.assignment && assetNames.length > 0 ? (
            <div style={{ marginBottom: 16, color: "red" }}>
              <p>
                {`By changing the ${groupname} organization, the following assets and yields will be validated/verified by the new VVB.  To confirm this change select Save, to cancel select Cancel`}
              </p>
              <h3>Assets</h3>
              <Collapse style={{ maxHeight: 300, overflowY: "auto" }}>
                {assetNames.map((assetName) =>
                  renderAssetYields(assetName, assetYields[assetName])
                )}
              </Collapse>
            </div>
          ) : (
            assigneeDetails?.length > 0 && renderAssigneeDetails()
          )}
        </Form>
      )}
    </Modal>
  );
};
