import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/ds/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Document'],
  endpoints: (build) => ({
    getDocumentInfoByIds: build.query({
      query(ids) {
        return {
          url: `document/ids?docids=${ids}`,
          
        }
      },
      providesTags: ["Document"]
    }),
    getDocumentValidationInfoByIds: build.query({
      query(ids) {
        return {
          url: `document/validation/ids?docids=${ids}`,
          
        }
      },
      providesTags: ["Document"]
    }),
    getUserDocuments: build.query({
      query: () => "document/user",
      providesTags: ["Document"],
    }),
    getUserDocumentsByParam: build.query({
      query: (params) => {
        return {
          url: "document/user",
          params: params,
        };
      },
      providesTags: ["Document"],
    }),
    getOrgDocuments: build.query({
      query: () => "document/org",
      providesTags: ["Document"],
    }),
    downloadDocument: build.query({
      query: (dochash) => `document/${dochash}`,
      providesTags: ["Document"],
    }),
    getDocumentInfo: build.query({
      query: (dochash) => `document/${dochash}/info`,
      providesTags: ["Document"],
    }),
    uploadFile: build.mutation({
      query(body) {
        return {
          url: `document`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Document"],
    }),
  }),
});

export const {
  useDownloadDocumentQuery,
  useGetDocumentInfoByIdsQuery,
  useGetDocumentValidationInfoByIdsQuery,
  useLazyGetDocumentInfoQuery,
  useGetDocumentInfoQuery,
  useGetUserDocumentsQuery,
  useGetOrgDocumentsQuery,
  useUploadFileMutation,
  useGetUserDocumentsByParamQuery
} = documentApi
