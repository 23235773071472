import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import store from "./store/store";
import { Provider } from "react-redux";

import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import wagmiClient from "./bc-client/wagmi";
import { WagmiConfig, configureChains } from "wagmi";
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import { sepolia, polygon } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { REACT_APP_PROBA_UI_ENV } from "./config/config";
const chainToUse = REACT_APP_PROBA_UI_ENV === "production" ? polygon : sepolia;
const { chains } = configureChains(
  [chainToUse],
  [
    publicProvider()
  ]
);

// window.PDFJS = require("pdfjs-dist")
// console.log(window.PDFJS)

const probaTheme = {
  primaryColor: "#36B28D",
  errorColor: "#EC4D4F",
  warningColor: "#FFF962",
  successColor: "#52c41a",
  infoColor: "#1890ff",
};

ConfigProvider.config({
  theme: probaTheme,
  prefixCls: "ant",
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// IMPORTANT: React.StrictMode needs to be disabled for the template editor to work
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <WagmiConfig client={wagmiClient}>
      <ConfigProvider>
        <RainbowKitProvider chains={chains}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RainbowKitProvider>
      </ConfigProvider>
    </WagmiConfig>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
