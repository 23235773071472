import { React, useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Alert, Collapse, Modal, Space, Typography, message } from "antd";

import { useTransactionHandlers } from "../../../bc-client/useTransactionHandlers";
import { createGHGProjectAction } from "../../../constants/actionNames";
import { useGetDocumentValidationInfoByIdsQuery } from "../../../store/ds/documentapi";
import { useGetInterventionDocsQuery, useUpdateInterventionStatusMutation } from "../../../store/is/interventionapi";

export default function SubmitForProbaReview({ isCreate, spinning, setSpinning, closeModal, interventionDetails, validationsData, usergroup, handleDispatchSubmitForProbaReview }) {
    const key = "updatable";
    const [openProbaReviewModal, setOpenProbaReviewModal] = useState(false);
    const [confirmLoadingProbaReviewModal, setConfirmLoadingProbaReviewModal] = useState(false);

    const [updateInterventionStatus] = useUpdateInterventionStatusMutation();
    const { data: interdocs, isLoading: isInterventionDocLoading } = useGetInterventionDocsQuery(interventionDetails?._id);
    const allDocIds = (interdocs)?.data?.docs
        ?.filter((d) => d.document_id !== "undefined")
        .map((d) => `${d.document_id}`);
    const { data: documentValidationData, isLoading: isDocumentValidationDataLoading } = useGetDocumentValidationInfoByIdsQuery(allDocIds?.join());
    if (!isDocumentValidationDataLoading) {
        console.log("documentValidationData", documentValidationData)
    }    

    useEffect(() => {
        if (isCreate) {
            setOpenProbaReviewModal(true);
        }
    }, [isCreate]);

    const validationData = {
        ...validationsData,
        has_POD_document_for_project: documentValidationData?.documentTypeResponse?.hasPODDocument,
    };
// ******* bc client*****
    const { onAccept } = useTransactionHandlers({
        actionname: createGHGProjectAction.name,
    });
    const createGHGProjectActionInBC = async (intervention) => {
        return await onAccept(intervention);
    };
    //   ******* bc client*****

    const handleOkOfProbaReviewModal = async () => {
        let interventionUpdateResponse;
        const intervstatusupdate = { ...interventionDetails };
        intervstatusupdate["status"] = "Proba Review";

        try {
            setSpinning(true);
            if (!usergroup.includes("Project Developer")) {
                message.error({
                    content: "Action Prohibited",
                    key,
                    duration: 6,
                });
            } else {
                const payload = {
                    intervention: interventionDetails,
                };
                const result = await createGHGProjectActionInBC(payload);
                if (!result) {
                   
            
                    return;
                }
                interventionUpdateResponse = await handleIntervEditRecord(intervstatusupdate);

            }
        } catch (error) {
            console.error("Error updating intervention:", error);
        } finally {
            setSpinning(false);
            setConfirmLoadingProbaReviewModal(false);
        }
    };
    const handleCancelOfProbaReviewModal = () => {
        setOpenProbaReviewModal(false);
        closeModal();
    };

    const handleIntervEditRecord = async (record) => {
        try {
            const payload = {
                id: record._id,
                body: record,
            };
            console.log("payload", payload)
            const res = await updateInterventionStatus(payload)
                .unwrap()
                .then((res) => {
                    message.success({
                        content: "GHG Project updated successfully!",
                        key,
                        duration: 2,
                    });
                    console.log(res);
                    handleDispatchSubmitForProbaReview(res)
                    setOpenProbaReviewModal(false);
                    return res;
                })
                .catch((err) => {
                    console.log("err", err)
                    message.error({
                        content:
                            "Failed to update GHG Project: " + Object.values(err["data"])[0],
                        key,
                        duration: 6,
                    });
                    console.log(err);
                });
            return res;
        } catch { }
    };

    return (
        <div>
            <>
                {isCreate ? (
                    (
                        <SubmitProbaReviewModal
                            visible={isCreate}
                            validationData={validationData}
                            openProbaReviewModal={openProbaReviewModal}
                            handleOkOfProbaReviewModal={handleOkOfProbaReviewModal}
                            handleCancelOfProbaReviewModal={handleCancelOfProbaReviewModal}
                            confirmLoadingProbaReviewModal={confirmLoadingProbaReviewModal || spinning}
                            onCancel={() => {
                                if (!(confirmLoadingProbaReviewModal || spinning))
                                closeModal();
                            }}
                        />
                    )
                ) : null}
            </>
        </div>
    );
};

const SubmitProbaReviewModal = ({
    visible,
    validationData,
    openProbaReviewModal,
    handleOkOfProbaReviewModal,
    handleCancelOfProbaReviewModal,
    confirmLoadingProbaReviewModal
}) => {
    console.log("validationData", validationData)
    
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    const renderProjectPanelContent = () => {

        const detailLabelMap = {
            has_intervention_id: 'Intervention',
            has_method_id: 'Methodology',
            has_name: 'Name',
            has_project_id: 'Project ID',
            has_region: 'Region',
            has_ghg_project_summary: 'Summary',
            has_type: 'Type',
            has_verifier: 'Verifier assignment',
            has_validator: 'Validator assignment',
            has_POD_document_for_project: 'POD Document',
        };

        const projectDetails = [
            "has_intervention_id",
            "has_method_id",
            "has_name",
            "has_project_id",
            "has_region",
            "has_ghg_project_summary",
            "has_type",
            "has_verifier",
            "has_validator",
            "has_POD_document_for_project"
        ];

        const invalidDetails = projectDetails?.filter(detail => !validationData[detail]);

        if (invalidDetails?.length > 0) {
            const errorMessage = `Please add any missing information or perform missing actions to make the project ready for Proba Review as indicated below? ${invalidDetails?.map(detail => detailLabelMap[detail])?.join(', ')}`;
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else {
            return null;
        }
    };

    const renderAssetsPanelContent = () => {
        const invalidAssets = validationData?.assets?.filter(asset => !asset?.has_yield)?.map(asset => asset?.name);
        const hasAtLeastOneYield = validationData?.assets?.some(asset => asset?.has_yield);
        if (validationData?.assets?.length === 0) {
            const errorMessage = "Must add at least one Asset to the Project";
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else if (!hasAtLeastOneYield) {
            const errorMessage = `Atleast anyone of the following Assets must have at least one Yield: ${invalidAssets?.join(', ')}`;
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else {
            return null;
        }
    };

    const renderNoQualityAssignmentsContent = () => {
        const invalidYields = validationData?.assets?.filter(asset => asset?.has_yield)?.flatMap(asset => asset?.yield)?.filter(y => !y?.has_quality_assignment)
            ?.map(y => y?.period);

        if (invalidYields?.length > 0) {
            const errorMessage = `Please assign verifier to Yields: ${invalidYields?.join(', ')}`;
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else {
            return null;
        }
    };

    const renderNoQuantityAssignmentsContent = () => {
        const invalidYields = validationData?.assets?.filter(asset => asset?.has_yield)?.flatMap(asset => asset?.yield)?.filter(y => !y?.has_quantity_assignment)?.map(y => y?.period);

        if (invalidYields?.length > 0) {
            const errorMessage = `Please assign validator to Yields: ${invalidYields?.join(', ')}`;
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else {
            return null;
        }
    };

    const renderYieldPanelContent = () => {
        const hasAtLeastOneYield = validationData?.assets?.some(asset => asset?.has_yield);
        const isQualityValid = renderNoQualityAssignmentsContent() === null;
        const isQuantityValid = renderNoQuantityAssignmentsContent() === null;

        if (!hasAtLeastOneYield) {
            const errorMessage = "Must have at least one yield";
            return (
                <Alert
                    message="Error"
                    description={
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            {errorMessage}
                        </Paragraph>
                    }
                    type="error"
                    showIcon
                />
            );
        } else if (!isQualityValid || !isQuantityValid) {
            return (
                <>
                    {renderNoQualityAssignmentsContent()}
                    <br></br>
                    {renderNoQuantityAssignmentsContent()}
                </>
            );
        }
        return null;
    };

    const isSubmitDisabled =
        renderProjectPanelContent() !== null ||
        renderAssetsPanelContent() !== null;
    return (
        <Modal
            title="Submit for Proba Review"
            visible={openProbaReviewModal}
            onOk={handleOkOfProbaReviewModal}
            okText="Submit for Proba Review"
            confirmLoading={confirmLoadingProbaReviewModal}
            onCancel={handleCancelOfProbaReviewModal}
            okButtonProps={{ disabled: isSubmitDisabled }}
        >
            {isSubmitDisabled === false ? (
                <p>The Project is ready for the Proba Review. Do you want to submit for Proba Review?</p>
            ) : (
                <>
                    <p>Please fix the following errors to make the project ready for Proba Review</p>

                    <Collapse defaultActiveKey={['project', 'assets', 'yield']}>
                        {renderProjectPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Project</Text>
                                        {renderProjectPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="project">
                                {renderProjectPanelContent()}
                            </Panel>
                        )}
                        {renderAssetsPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Assets</Text>
                                        {renderAssetsPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="assets">
                                {renderAssetsPanelContent()}
                            </Panel>
                        )}
                        {renderYieldPanelContent() !== null && (

                            <Panel
                                header={
                                    <Space>
                                        <Text strong>Errors in Yield</Text>
                                        {renderYieldPanelContent() === null ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                                    </Space>
                                }
                                key="yield"
                            >
                                {renderYieldPanelContent()}
                            </Panel>
                        )}

                    </Collapse>
                </>
            )}
        </Modal>
    );
};