import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }) => {
  let token = useSelector((state) => state.auth.token);

  let usergroup = useSelector((state) => state.auth.groups?.map((group) => group?.name));

  //Protect Admin URLs based on the Group
  let isUrlAllowed = true;
  if (!(usergroup?.includes("Admin") || usergroup?.includes("Org Admin"))) {
    if (rest?.path === "/user" || rest?.path === "/org" ||rest?.path === "/orgtype") {
      isUrlAllowed = false;
    }
  }

  if (token === "") {
    const jwtFromStorage = localStorage.getItem("jwt");
    if (jwtFromStorage) {
      token = jwtFromStorage;
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isUrlAllowed ? (
          <div>{children}</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
