import { React } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message
} from "antd";
import {
  useAddParamsMutation,
  useGetParamQuery,
  useUpdateParamsMutation
} from "../../store/is/paramsapi";

import {
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";


export default function Params({ updateParam, paramId, editParamRecord, isEditing, isCreate, closeModal }) {

  const { data: methodparam, isLoading: isParamLoading } = useGetParamQuery(paramId);

  const [addParams] = useAddParamsMutation();
  const [updateParams] = useUpdateParamsMutation();

  const key = "updatable";

  const handleAddRecord = async (record) => {
    try {
      await addParams(record)
        .unwrap()
        .then((res) => {
          
          message.success({
            content: "Params added successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          updateParam(res.data);
          closeModal();
        })
        .catch((err) => {
         
          message.error({
            content: "Failed to add Params!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const handleEditRecord = async (record) => {
    try {
      const payload = {
        id: paramId,
        body: record
      }
      await updateParams(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Params updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          updateParam(res.data);
          closeModal();
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Params!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  };

  const onCreate = (values) => {
    if (isEditing) {

      const paramtoUpdate = { ...methodparam[0] }

      //TODO -
      values.param_id = paramtoUpdate._id
      handleEditRecord(values);
    } else if (isCreate) {
      handleAddRecord(values);
    }
  };

  return (
    <div>
      <>
        {(isEditing || isCreate) && <>{!isParamLoading && <ParamsFormModal
          visible={isCreate || isEditing}
          onCreate={onCreate}
          onCancel={() => {
            closeModal();
          }}
          editParamRecord={methodparam ? methodparam?.length > 0 ? methodparam[0] : null : null}
        />}</>
        }
      </>
    </div>
  );
}

const ParamsFormModal = ({
  visible,
  onCreate,
  onCancel,
  editParamRecord,
}) => {

  const [form] = Form.useForm();
  const handleDropdownChange = (value, index) => {
    const param = form.getFieldValue(['param', index]);
    let newValue;
    switch (value) {
      case 'string':
        newValue = '';
        break;
      case 'number':
        newValue = 0;
        break;
      default:
        newValue = '';
        break;
    }
    form.setFieldsValue({
      ['param']: [
        ...form.getFieldValue(['param']).slice(0, index),
        { ...param, value: newValue },
        ...form.getFieldValue(['param']).slice(index + 1),
      ],
    });
  };
  return (
    <Modal
      width={500}
      title="Create New Params"
      open={visible}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        form={form}
        initialValues={editParamRecord}
      >
        <Form.List name="param">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                const type = form.getFieldValue(['param', index, 'type']);
                const keyField = form.getFieldValue(['param', index, 'key']);
                if (keyField === 'Plot Polygon') {
                  return null;
                }
                return (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[{ required: true, message: 'Missing Key' }]}
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[{ required: true, message: 'Missing Type' }]}
                    >
                      <Select style={{ width: 120 }} onChange={(value) => handleDropdownChange(value, index)} placeholder="Select a type">
                        <Select.Option value="string">Text</Select.Option>
                        <Select.Option value="number">Number</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing Value' },
                      {
                        validator: (_, value) => {
                          if (type === 'number' && isNaN(value)) {
                            return Promise.reject(new Error('Value must be a number'));
                          }
                          return Promise.resolve();
                        },
                      },
                      ]}
                    >
                      <Input
                        placeholder="Value"
                        inputMode={type === 'number' ? 'numeric' : 'text'}
                        pattern={type === 'number' ? '\\d*' : undefined}
                      />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                );
              })}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
