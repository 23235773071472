import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  userid: "",
  baddress: "",
  org: {},
  groups: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      // console.log(action);
      state.token = action.payload.token;
      state.userid = action.payload.userid;
      state.baddress = action.payload.baddress;
      state.org = action.payload.org;
      state.groups = action.payload.groups;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
