import React from "react";
import { Popover, Typography } from "antd";
export const DisplayValue = ({ value }) => {
  return (
    <div>
      {value.length > 25 ? (
        <Popover content={value} trigger="click">
          <Typography.Link copyable={{ text: value }} style={{ fontSize: 18 }}>
            {value?.slice(0, 17) +
              "..." +
              value?.slice(value?.length - 5, value?.length)}
          </Typography.Link>
        </Popover>
      ) : (
        <Typography.Text style={{ fontSize: 18 }}>
          {value === true ? "Yes" : value === false ? "No" : value}
        </Typography.Text>
      )}
    </div>
  );
};
