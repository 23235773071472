import { React, useEffect, useState } from "react";
import { Button, Popover, Table, Typography } from "antd";
import { assignmentApi } from "../../store/is/assignmentapi";
import {
  useGetYieldsByParamQuery
} from "../../store/is/yieldapi";

import { ContactsOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useSelector } from "react-redux";
import { useGetValidationQuery } from "../../store/is/validationapi";
import store from "../../store/store";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import HandleYieldButtons from "./HandleYieldButtons";

function Yield({ assetid, setSpinning }) {
  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup.filter(Boolean);
  let asset = useSelector((state) => state.is.asset);
  let intervention = useSelector((state) => state.is.intervention);
  let userid = useSelector((state) => state.auth.userid);
  const orgId = useSelector((state) => state.auth?.org?._id);

  const [assignmentData, setIsAssignmentDataAvailable] = useState(false);

  const { data: validationsData, refetch: refetchValidation  } = useGetValidationQuery(intervention?._id);

  const intervAssignmentQuery = {
    intervention_id: intervention?._id,
  };

  useEffect(() => {
    async function fetchData() {
      const {
        data: intervAssignmentDetails,
        isLoading: isIntervAssignmentDetailsLoading,
      } = await store.dispatch(
        assignmentApi.endpoints.getAssignmentByParam.initiate(
          intervAssignmentQuery
        )
      );
      if (!isIntervAssignmentDetailsLoading) {
        setIsAssignmentDataAvailable(intervAssignmentDetails);
      }
    }

    fetchData();
  }, [intervention]);

  const columns = [
    {
      key: "token_id",
      title: "NFRY ID",
      dataIndex: "token_id",
      render: (record) => {
        return <Typography.Text>NFRY-{record}</Typography.Text>;
      },
    },
    {
      key: "external_asset_id",
      title: "External Asset Id",
      dataIndex: "asset",
      render: (asset) => asset?.[0]?.external_asset_id || "",
    },
    {
      key: "period",
      title: "Period",
      dataIndex: "period",
      sorter: (a, b) => a?.period?.localeCompare(b?.period),
      defaultSortOrder: "ascend",
    },
    {
      key: "storage_duration",
      title: "Storage Duration",
      dataIndex: "storage_duration",
    },
    {
      key: "co2_estimated",
      title: "Estimated tCo2e",
      dataIndex: "co2_estimated",
    },
    {
      key: "co2_realized",
      title: "Realized tCo2e",
      dataIndex: "co2_realized",
    },
    {
      key: "product_volume",
      title: "Product Volume",
      dataIndex: "product_volume",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "is_updated_to_blockchain",
      title: "Is Updated to Blockchain",
      dataIndex: "is_updated_to_blockchain",
    },
    {
      key: "organization_id",
      title: "Assignees",
      dataIndex: "organization_id",
      render: (text, record) => {
        let verifier_org_id, validator_org_id, project_sponsor_org_id
        if (Array.isArray(record?.assignment)) {
          record?.assignment.map((assignment) => {
            if (assignment.assignee_type === "Validator") {
              validator_org_id = assignment?.organization
            }
            if (assignment.assignee_type === "Verifier") {
              verifier_org_id = assignment?.organization
            }
            if (assignment.assignee_type === "Project Sponsor") {
              project_sponsor_org_id = assignment?.organization
            }
          });
          return (
            <Popover content={
            <>
              <Typography.Text strong>Validator:</Typography.Text><DisplayOrganization orgid={validator_org_id} place="table" />
              <Typography.Text strong>Verifier:</Typography.Text><DisplayOrganization orgid={verifier_org_id} place="table" />
              <Typography.Text strong>Project Sponsor:</Typography.Text><DisplayOrganization orgid={project_sponsor_org_id} place="table" />
            </>
            }>
              <ContactsOutlined />
            </Popover>
          );
        }
        else {
          return (
            <Popover content={<></>}>
              <ContactsOutlined />
            </Popover>
          );
        }
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        console.log("Yield Records", record);
        if (
          assignmentData &&
          assignmentData != null &&
          assignmentData.length > 0
        ) {
          var hasInterventionQualityAssignment = assignmentData.find(function(
            records
          ) {
            return (
              records.asset_id === null &&
              records.assignee_type === "Verifier"
            );
          });
          var hasInterventionQuantityAssignment = assignmentData.find(function(
            records
          ) {
            return (
              records.asset_id === null &&
              records.assignee_type === "Validator"
            );
          });
        }
        // Extract and store the verification_Status from the matching record
        const interventionQualityVerificationStatus = hasInterventionQualityAssignment
          ? hasInterventionQualityAssignment?.verification_status
          : "";
        const isInterventionHasQuantityAssignment = !!hasInterventionQuantityAssignment;
        const interventionQuantityVerificationStatus = hasInterventionQuantityAssignment
          ? hasInterventionQuantityAssignment?.verification_status
          : "";
        const yieldQualityAssignmentRecord = record.assignment?.filter(
          (assign) => assign.assignee_type === "Verifier"
        );
        const isYieldHasQualityAssignment = yieldQualityAssignmentRecord?.length
          ? true
          : false;
        const yieldQuantityAssignmentRecord = record.assignment?.filter(
          (assign) => assign.assignee_type === "Validator"
        );
        const isYieldHasQuantityAssignment = yieldQuantityAssignmentRecord?.length
          ? true
          : false;

        return (
          <>
            <HandleYieldButtons
              yieldRecord={record}
              interventionQualityVerificationStatus={
                interventionQualityVerificationStatus
              }
              isInterventionHasQuantityAssignment={
                isInterventionHasQuantityAssignment
              }
              interventionQuantityVerificationStatus={
                interventionQuantityVerificationStatus
              }
              isYieldHasQualityAssignment={isYieldHasQualityAssignment}
              isYieldHasQuantityAssignment={isYieldHasQuantityAssignment}
              isYieldQualityVerified={
                yieldQualityAssignmentRecord?.[0]?.verification_status
              }
              isYieldQuantityVerified={
                yieldQuantityAssignmentRecord?.[0]?.verification_status
              }
              setSpinningYield={setSpinningYield}
              refetchValidation={refetchValidation}
            ></HandleYieldButtons>
          </>
        );
      },
    },
  ].filter(Boolean); //remove null columns

  const queryparam = {
    asset_id: assetid,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isAddYieldVisible, setIsAddYieldVisible] = useState(false);
  const {
    data = [],
  } = useGetYieldsByParamQuery(queryparam, { skip: !queryparam.asset_id });

  const key = "updatable";

  const setSpinningYield = (value) => {
    console.log(value);
    setSpinning(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddYieldVisible(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={4}>Yield</Typography.Title>
        {!usergroup.includes("Verifier") && usergroup.includes("Project Developer") && intervention?.organization === orgId && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCreate(true);
                setIsAddYieldVisible(true);
              }}
              type="primary"
            >
              Add
            </Button>
          </div>
        )}
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={data.data}
        pagination={{
          pageSizeOptions: ["10", "50", "100"],
          showSizeChanger: true,
        }}
      ></Table>

      {isAddYieldVisible && (
        <HandleYieldButtons
          isCreate={isCreate}
          assetid={assetid}
          setSpinningYield={setSpinningYield}
          closeModal={closeModal}
          refetchValidation={refetchValidation}
        ></HandleYieldButtons>
      )}
    </div>
  );
}

export default Yield;
