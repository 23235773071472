import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { polygon, sepolia } from "wagmi/chains";
import { REACT_APP_PROBA_UI_ENV } from "../config/config";
import { publicProvider } from "wagmi/providers/public";

const chainToUse = REACT_APP_PROBA_UI_ENV === "production" ? polygon : sepolia;

const { chains, provider } = configureChains([chainToUse], [publicProvider()]);
const { connectors } = getDefaultWallets({
  appName: "Proba",
  chains,
});
const wagmiClient = createClient({
  connectors,
  provider,
});

export default wagmiClient;
