import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const assignmentApi = createApi({
  reducerPath: "assignmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/assignment",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Assignment"],
  endpoints: (build) => ({
    getAssignments: build.query({
      query: () => "",
      providesTags: ["Assignment"],
    }),
    getAssignment: build.query({
      query: (id) => `${id}`,
      providesTags: ["Assignment"],
    }),
    getAssignmentByParam: build.query({
      query: (params) => {
        return {
          url: "",
          params: params,
        };
      },
      providesTags: ["Assignment"],
    }),
    getValidateAssignmentInfo: build.query({
      query:(params) => {
        return {
          url: "/validate",
          params: params,
        };
      },
      providesTags: ["Assignment"],
    }),
    addAssignment: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Assignment"],
    }),
    addAssignmentBatch: build.mutation({
      query(body) {
        return {
          url: `/batch`,
          method: "POST",
          body,
        };
      },
    }),
    updateAssignment: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Assignment"],
    }),
    updateAssignmentBatch: build.mutation({
      query(payload) {
        return {
          url: `/batch`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    deleteAssignment: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Assignment"],
    }),
  }),
});

export const {
  useGetAssignmentByParamQuery,
  useGetAssignmentsQuery,
  useGetAssignmentQuery,
  useGetValidateAssignmentInfoQuery,
  useAddAssignmentMutation,
  useUpdateAssignmentMutation,
  useDeleteAssignmentMutation,
  useAddAssignmentBatchMutation,
  useUpdateAssignmentBatchMutation
} = assignmentApi;
