import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const imsApi = createApi({
    reducerPath: "imsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/ims/import/intervention",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["IntermediateService"],
    endpoints: (build) => ({
        importIntervention: build.mutation({
            query(body) {
                return {
                    url: ``,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["IntermediateService"],
        }),
    }),
});

export const {
    useImportInterventionMutation,
} = imsApi;
