import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const assetApi = createApi({
  reducerPath: "assetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/asset",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Asset"],
  endpoints: (build) => ({
    getAsset: build.query({
      query: (id) => `${id}`,
      providesTags: ["Asset"],
    }),
    getAssets: build.query({
      query: () => "",
      providesTags: ["Asset"],
    }),
    getAssetsByParam: build.query({
      query: (params) => {
        return {
          url: "",
          params: params,
        };
      },
      providesTags: ["Asset"],
    }),
    getAssetsByIds: build.query({
      query: (params) => {
        return {
          url: "/byIds",
          params: params,
        };
      },
    }),
    addAsset: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    addAssetsBatch: build.mutation({
      query(body) {
        return {
          url: `/batch`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    updateAsset: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    updateAssetStatus: build.mutation({
      query(payload) {
        return {
          url: `/status`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    updateAssetBatch: build.mutation({
      query(payload) {
        return {
          url: `/batch`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    deleteAsset: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Asset"],
    }),
    getAssetDocs: build.query({
      query: (id) => `/${id}/document`,
      providesTags: ["Asset"],
    }),
    addDocumentToAsset: build.mutation({
      query(body) {
        return {
          url: `/${body.assetid}/document`,
          method: "POST",
          body: body.requestbody,
        };
      },
      invalidatesTags: ["Asset"],
    }),
    updateAssetDocuments: build.mutation({
      query(body) {
        return {
          url: `/batch/document`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Asset"],
    }),
  }),
});

export const {
  useGetAssetsByParamQuery,
  useAddDocumentToAssetMutation,
  useUpdateAssetDocumentsMutation,
  useGetAssetDocsQuery,
  useGetAssetQuery,
  useGetAssetsQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetBatchMutation,
  useUpdateAssetStatusMutation,
  useGetAssetsByIdsQuery,
  useAddAssetsBatchMutation
} = assetApi;
