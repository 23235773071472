import { React, useState } from "react";

import {
  documentApi,
  useGetOrgDocumentsQuery,
  useGetUserDocumentsByParamQuery
} from "../../store/ds/documentapi";

import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Tabs,
  Tag,
  Typography,
  message
} from "antd";


import { DownloadOutlined, FileExcelFilled, FileFilled, FileImageFilled, FilePdfFilled, FileTextFilled, FileWordFilled } from "@ant-design/icons";
import moment from 'moment';

import { useGrantOrgAuthMutation, useGrantUserAuthMutation, useLiftOrgAuthMutation, useLiftUserAuthMutation, useUpdateDocVisibilityMutation } from "../../store/ds/documentaccessapi";
import store from "../../store/store";

import { useSelector } from "react-redux";

import axios from 'axios';
import DocumentAccess from "./DocumentAccess";
const { TabPane } = Tabs;


function ViewDocuments({ id, entity }) {


  const { data: loadedOwnerDocs, isLoading: isDocumentsLoading } = useGetUserDocumentsByParamQuery();

  const { data: loadedOrgDocs, isLoading: isOrgDocumentsLoading } = useGetOrgDocumentsQuery();

  let token = useSelector((state) => state.auth.token);
  token = token.replace('Bearer ', '');


  const onDownloadDocument = (record) => {
    axios.get("/ds/document/" + record.ID, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((blob) => {
      console.log("blob", blob)
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        record.filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentVisibilityPublic, setIsDocumentVisibilityPublic] = useState(null);
  const [dropdownValue, setDropDownValue] = useState("");
  const [documentId, setDocumentId] = useState(null);
  const [grantUserAuth] = useGrantUserAuthMutation();
  const [grantOrgAuth] = useGrantOrgAuthMutation();
  const [liftUserAuth] = useLiftUserAuthMutation();
  const [liftOrgAuth] = useLiftOrgAuthMutation();
  const [updateDocVisibility] = useUpdateDocVisibilityMutation();

  async function getDocumentInfoData(documenthash) {
    try {
      const { data, isLoading } = await store.dispatch(
        documentApi.endpoints.getDocumentInfo.initiate(documenthash)
      );
      console.log("document..", data, isLoading);
      if (!isLoading) {
        return data;
      }
    } catch (error) {
      return null;
    }
  }

  const showModal = async (record) => {
    setIsModalOpen(true);
    setDocumentId(record.ID);

    // Fetch document info for the selected document
    const documentInfo = await getDocumentInfoData(record?.ID);

    // Set the initial state of documentVisibilityPublic based on the document's visibility
    if (documentInfo?.documentvisibility) {
      setIsDocumentVisibilityPublic(documentInfo?.documentvisibility);
    } else {
      setDropDownValue("");
      setIsDocumentVisibilityPublic("");
    }
  };

  const key = "updatable";

  const columns = [
    {
      key: "doctype",
      title: "Document Type",
      dataIndex: "doctype",
    },
    {
      key: "filename",
      title: "File Name",
      dataIndex: "filename",
    },
    {
      key: "documentvisibility",
      title: "Visibility",
      dataIndex: "documentvisibility",
      render: (record) => {
        return (
          <> {record==="PUBLIC"?"Public":record==="PRIVATE"?"Private":record}
          </>
        );
      },
    },
    {
      key: "contentType",
      title: "Content Type",
      dataIndex: "contentType",
      render: (record) => {
        switch (record) {
          case "application/pdf":
            return (
              <>
                <Tag icon={<FilePdfFilled />} color="#55acee">
                  pdf
                </Tag>
              </>
            );
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return (
              <>
                <Tag icon={<FileWordFilled />} color="#55acee">
                  word
                </Tag>
              </>
            );
          case "image/png":
            return (
              <>
                <Tag icon={<FileImageFilled />} color="#55acee">
                  image
                </Tag>
              </>
            );
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return (
              <>
                <Tag icon={<FileExcelFilled />} color="#55acee">
                  excel
                </Tag>
              </>
            );
          case "text/rtf":
          case "text/plain":
            return (
              <>
                <Tag icon={<FileTextFilled />} color="#55acee">
                  text
                </Tag>
              </>
            );
          default:
            return (
              <>
                <Tag icon={<FileFilled />} color="#55acee">
                  {record}
                </Tag>
              </>
            );
        }
      },
    },
    {
      key: "size",
      title: "Size",
      dataIndex: "size",
      render: (record) => {
        return (
          <>
            {record}Kb
          </>
        );
      },
    },
    {
      key: "CreatedAt",
      title: "Created",
      dataIndex: "CreatedAt",
      render: (record) => {
        return <span>{record ? moment.utc(record).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <Row gutter={[10, 10]}>
              <Col><DownloadOutlined
                onClick={() => {
                  onDownloadDocument(record);
                }}
              /></Col>
            </Row>
          </>
        );
      },
    },
    {
    key: "Manage",
    title: "Manage",
    render: (record) => {
      return (
        <>
          <Button type="primary" onClick={() => showModal(record)}>
           Manage Access
          </Button>
        </>
      );
    },
  }
  ];
 
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setDropDownValue(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const onGrantAccess = async (record) => {
    if (record.accesslevel === "userlevel") {
      grantUserAccess(record);
    } else if (record.accesslevel === "orglevel") {
      grantOrgAccess(record);
    }
  
    if (record.documentvisibility === "PUBLIC" || record.documentvisibility === "PRIVATE") {
      updateDocumentVisibility(record);
    }
  };
  

  const grantUserAccess = async (record) => {
    
    try {
      const payload = {
        id: record.accesslevellist,
        documentID: documentId,
      }
      await grantUserAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const grantOrgAccess = async (record) => {
    try {
      const payload = {
        id: record.accesslevellist,
        documentID: documentId,
      }
      await grantOrgAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const liftUserAccess = async (record, documentId) => {
    try {
      const payload = {
        id: record[0],
        documentID: documentId,
      }
      await liftUserAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Lifting Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const liftOrgAccess = async (record) => {
    try {
      const payload = {
        id: record[0],
        documentID: documentId,
      }
      await liftOrgAuth(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Lifting Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Access State: " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  const updateDocumentVisibility = async (record) => {
    try {
      const payload = {
        documentID: documentId,
        documentVisibility: record.documentvisibility
      }
      await updateDocVisibility(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Document Visibility Access Changes updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Document Visibility Access : " + Object.values(err['data']).join(''),
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch { }
  }

  return (
    <div style={{ height: '80vh' }}>


      <Typography.Title level={2}>Documents</Typography.Title>

      <Card className="shadow-card" style={{ borderRadius: 8 }}>
        {!isDocumentsLoading && <Tabs defaultActiveKey="1" type="card" >
          <TabPane tab="My Documents" key="1">
            <Table
              columns={columns} dataSource={loadedOwnerDocs}></Table>
          </TabPane>
          <TabPane tab="My Org Documents" key="2">
            <Table
              columns={columns} dataSource={loadedOrgDocs}></Table>
          </TabPane>
        </Tabs>}
      </Card>



      <DocumentAccess
        dropdownValue={dropdownValue}
        isModalOpen={isModalOpen}
        documentId={documentId}
        closeModal={closeModal}
        onGrantAccess={onGrantAccess}
        liftUserAccess={liftUserAccess}
        liftOrgAccess={liftOrgAccess}
        handleChange={handleChange}
        documentVisibilityPublic={documentVisibilityPublic}
      />
    </div>
  );
}


export default ViewDocuments;
