import { Card } from "antd";
import React from "react";

function ContactAdmin() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
        paddingTop: 20,
      }}
    >
      <Card className="shadow-card" style={{ width: "45vw", marginTop: 20 }}>
        <h1
          style={{
            fontSize: 36,
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Contact Admin for Access
        </h1>
        <div>
          <ol style={{ fontSize: 16 }}>
            <li>
              Contact your organization administrator to request appropriate
              roles and privileges.
            </li>
            <li>
              Specify the resources or sections you need access to for accurate
              permissions.
            </li>
            <li>
              Wait for confirmation from the administrator before attempting to
              access again.
            </li>
          </ol>
        </div>
      </Card>
    </div>
  );
}

export default ContactAdmin;
