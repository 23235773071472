import { React, useEffect, useState } from "react";
import {
  ContactsOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Statistic,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  interventionApi,
  useAddDocumentToInterventionMutation,
  useAddInterventionMutation,
  useUpdateInterventionMutation,
  useUpdateInterventionStatusMutation,
} from "../../store/is/interventionapi";
import { setIntervention } from "../../store/is/isSlice";
import { useGetMethodsQuery } from "../../store/is/methodsapi";
import {
  useDeleteParamsMutation,
  useGetParamQuery,
} from "../../store/is/paramsapi";
import {
  useGetValidationQuery,
  validationApi,
} from "../../store/is/validationapi";
import store from "../../store/store";
import { AssetImport } from "../Importer/AssetImport";
import { DisplayDateTime } from "../Utils/DisplayDateTime";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import { DisplayUserName } from "../Utils/DisplayUserName";
import AddDocument from "./AddDocument";
import Asset from "./Asset";
import DisplayDocuments from "./DisplayDocuments";
import Params from "./Params";
import AssignVerifier from "./Utils/AssignVerifier";
import { DisplayMethod } from "./Utils/DisplayMethod";
import MakeActive from "./Utils/MakeActive";
import { ParamView } from "./Utils/ParamView";
import SubmitForProbaReview from "./Utils/SubmitForProbaReview";
import SubmitForValidation from "./Utils/SubmitForValidation";
import SubmitToPublicReview from "./Utils/SubmitToPublicReview";
import ViewAssets from "./ViewAssets";
import ViewYields from "./viewYields";
import TruncateWithTooltip from "./TruncateWithTooltip";

const statusOptions = [
  { value: "New", label: "New" },
  { value: "Proba Review", label: "Proba Review" },
  { value: "Public Review", label: "Public Review" },
  { value: "Validator Review", label: "Validator Review" },
  { value: "Revision", label: "Revision" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Verified Quality", label: "Verified Quality" },
  { value: "Verified Quantity", label: "Verified Quantity" },
  { value: "verified", label: "Verified" },
  { value: "Reserved", label: "Reserved" },
  { value: "InClaim", label: "In Claim" },
  { value: "Booked", label: "Booked" },
  { value: "ProvDeleted", label: "Prov Deleted" },
  { value: "Invisible", label: "Invisible" },
  { value: "Final", label: "Final" },
];

function AddEditIntervention() {
  const [spinning, setSpinning] = useState(false);

  let userid = useSelector((state) => state.auth.userid);

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup.filter(Boolean);

  let organizationObj = useSelector((state) => state.auth?.org);

  const orgId = useSelector((state) => state.auth?.org?._id);

  let { id } = useParams();
  console.log(id);

  const [form] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();

  let intervention = useSelector((state) => state.is.intervention);
  let asset = useSelector((state) => state.is.asset);

  if (id !== "add" && !intervention?.name) {
    history.push({
      pathname: `/intervention/list`,
    });
  }
  const [isEditing, setIsEditing] = useState(id === "edit" ? true : false);
  const [isCreate, setIsCreate] = useState(id === "add" ? true : false);
  const [isView, setIsView] = useState(id === "view" ? true : false);
  const [editingRecord, setEditingRecord] = useState(intervention);
  const [isCreateParam, setIsCreateParam] = useState(false);
  const [isEditParam, setIsEditParam] = useState(false);
  const [editParamRecord, setEditParamRecord] = useState(null);
  const [paramId, setParamId] = useState(null);
  const [isImportCsv, setIsImportCsv] = useState(false);
  const [importlevel, setImportlevel] = useState();

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isEditDocument, setIsEditDocument] = useState(false);
  const [editDocumentRecord, setEditDocumentRecord] = useState(null);

  const [isCreateAssigneeToInterv, setIsCreateAssigneeToInterv] = useState(
    false
  );
  const [isassignUserToInterv, setIsassignUserToInterv] = useState(null);
  const [isInterventionVerified, setIsInterventionVerified] = useState(false);
  const [isIntervHasAssignment, setIsIntervHasAssignment] = useState(false);

  const [isInterventionYieldData, setIsInterventionYieldData] = useState(false);
  const [isInterventionAssetData, setIsInterventionAssetData] = useState(false);

  const [isSubmitForProbaReview, setIsSubmitForProbaReview] = useState(false);
  const [
    submitForProbaReviewBtnDisabled,
    setSubmitForProbaReviewBtnDisabled,
  ] = useState(false);
  const [addDocumentBtnDisabled, setAddDocumentBtnDisabled] = useState(false);
  const [addParamBtnDisabled, setAddParamBtnDisabled] = useState(false);

  const [
    verifyGhgProjectBtnDisabled,
    setVerifyGhgProjectBtnDisabled,
  ] = useState(false);
  const [isSubmitToPublicReview, setIsSubmitToPublicReview] = useState(false);
  const [isProjectStsProbaReview, setIsProjectStsProbaReview] = useState(false);
  const [isProjectStsRevision, setIsProjectStsRevision] = useState(false);
  const [isSubmitForValidation, setIsSubmitForValidation] = useState(false);
  const [isMakeActive, setIsMakeActive] = useState(false);
  const [
    eligibilityCheckModalVisible,
    setIsEligibilityCheckModalVisible,
  ] = useState(false);
  const [
    operationalCheckModalVisible,
    setIsOperationalCheckModalVisible,
  ] = useState(false);
  const [
    validationCheckModalVisible,
    setIsValidationCheckModalVisible,
  ] = useState(false);

  const [disableSubmitForValidation, setDisableSubmitForValidation] = useState(
    false
  );
  const [validationData, setValidationData] = useState();

  const [isIntervStsNew, setIntervStsNew] = useState(
    intervention?.status === "New"
  );
  const [isIntervStsProbaReview, setIntervStsProbaReview] = useState(
    intervention?.status === "Proba Review"
  );
  const [isIntervStsRevision, setIntervStsRevision] = useState(
    intervention?.status === "Revision"
  );
  const [isIntervStsPublicReview, setIntervStsPublicReview] = useState(
    intervention?.status === "Public Review"
  );
  const [isIntervStsValidatorReview, setIntervStsValidatorReview] = useState(
    intervention?.status === "Validator Review"
  );
  const [isIntervStsActive, setIntervStsActive] = useState(
    intervention?.status === "Active"
  );

  const [addIntervention, { isCreateLoading }] = useAddInterventionMutation();
  const [addInterventionDocument] = useAddDocumentToInterventionMutation();
  const [updateInterventionStatus] = useUpdateInterventionStatusMutation();

  const {
    data: validationsData,
    refetch: refetchValidation,
  } = useGetValidationQuery(intervention?._id);

  const [
    updateIntervention,
    { isUpdateLoading },
  ] = useUpdateInterventionMutation();
  const [deleteParam] = useDeleteParamsMutation();

  useEffect(() => {
    // checking intervention has assignment verifier and is verfied.
    // And also wwhether intervention is verified by verifier and validator or not
    if (intervention?.assignment && intervention?.assignment.length) {
      let hasVerifier = false;
      let hasValidator = false;
      let isValidValidator = false;
      let hasProjectSponsor = false;

      let hasVerifierVerified = false;
      let hasValidatorVerified = false;
      intervention.assignment.forEach((assignee) => {
        if (assignee.assignee_type === "Verifier") {
          hasVerifier = true;
          if (assignee.verification_status === "verified") {
            hasVerifierVerified = true;
          }
        }
        if (assignee.assignee_type === "Validator") {
          hasValidator = true;
          if (assignee?.organization === orgId) {
            isValidValidator = true;
          }
          if (assignee.verification_status === "verified") {
            hasValidatorVerified = true;
          }
        }
        if (assignee.assignee_type === "Project Sponsor") {
          hasProjectSponsor = true;
        }
      });
      setIsIntervHasAssignment({
        verifier: hasVerifier,
        isMatchingValidator: isValidValidator,
        validator: hasValidator,
        projectSponsor: hasProjectSponsor,
      });

      setIsInterventionVerified({
        verifier: hasVerifierVerified,
        validator: hasValidatorVerified,
      });
    } else {
      setIsInterventionVerified(false);
      setIsIntervHasAssignment(false);
    }
  }, [intervention]);

  useEffect(() => {
    // Disable the Verify GHG Project btn for all status except Validator Review
    if (intervention?.status !== "Validator Review") {
      setVerifyGhgProjectBtnDisabled(true);
    }

    // Disable the submit to revision btn for Proba Operator after subit to revision
    if (intervention?.status == "Proba Review") {
      setIsProjectStsProbaReview(true);
    }

    // Disable the submit to revision btn for Proba Operator after subit to revision
    if (intervention?.status == "Revision") {
      setIsProjectStsRevision(true);
    }
  }, [intervention]);

  const key = "updatable";

  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const closeModal = () => {
    setIsCreateParam(false);
    setIsEditParam(false);
  };

  const updateParam = (param) => {
    console.log("------", param);
    setEditParamRecord(param);
    setParamId(param._id);

    //Update the param id to methods
    if (editingRecord) {
      let editingRecordToUpdate = { ...editingRecord };
      editingRecordToUpdate.param_id = param ? param._id : null;
      handleEditRecord(editingRecordToUpdate);
      setEditingRecord(editingRecordToUpdate);
    }
  };

  const closeDocumentModal = () => {
    setIsCreateDocument(false);
    setIsEditDocument(false);
  };

  const updateDocument = (document) => {
    handleAddInterventionDocument(document);
  };

  const closeAssigneeToIntervModal = () => {
    setIsCreateAssigneeToInterv(false);
  };

  const closeSubmitForProbaReview = () => {
    setIsSubmitForProbaReview(false);
    setIntervStsProbaReview(false);
  };

  const closeSubmitToPublicReview = () => {
    setIsSubmitToPublicReview(false);
    setIntervStsPublicReview(false);
  };

  const closeSubmitForValidation = () => {
    setIsSubmitForValidation(false);
    setIntervStsValidatorReview(false);
  };

  const closeMakeActive = () => {
    setIsMakeActive(false);
    setIntervStsActive(false);
  };

  const handleAddInterventionDocument = async (record) => {
    try {
      const body = {
        interventionid: editingRecord?._id,
        requestbody: {
          document_id: record.document_id,
          intervention_id: editingRecord?._id,
        },
      };

      await addInterventionDocument(body)
        .unwrap()
        .then((res) => {
          message.success({
            content: "GHG Project Document added successfully!",
            key,
            duration: 2,
          });
          //viewInterventionRouteChange();
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to add GHG Project Doc!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const viewInterventionRouteChange = () => {
    let path = `/intervention/list`;
    history.push(path);
  };

  const viewYieldRouteChange = () => {
    dispatch(setIntervention(intervention));
    let path = "/inter/yields/view";
    history.push(path);
  };

  const viewAssetRouteChange = () => {
    let path = "/inter/assets/view";
    history.push(path);
  };

  const onCancel = async () => {
    form.resetFields();
    let path = `/intervention`;
    history.push(path);
    onCancel();
  };

  const handleDispatchAfterAssignment = (assignmentResponse) => {
    const assigneeType = assignmentResponse?.assignee_type;
    let interventionRecord = { ...intervention };
    let intervRec_assignment_array = interventionRecord?.assignment;

    const updateInterventionRecord = () => {
      if (
        !Array.isArray(intervRec_assignment_array) ||
        intervRec_assignment_array === null
      ) {
        interventionRecord.assignment = [assignmentResponse];
      } else {
        interventionRecord.assignment = [
          ...intervRec_assignment_array,
          assignmentResponse,
        ];
      }
      dispatch(setIntervention(interventionRecord));
      setEditingRecord(interventionRecord);
    };

    if (assigneeType === "Validator") {
      setIsIntervHasAssignment({
        verifier: true,
        validator: true,
      });
      updateInterventionRecord();
    }

    if (assigneeType === "Verifier" || assigneeType === "Project Sponsor") {
      updateInterventionRecord();
    }
  };

  const handleDispatchAfterVerified = (
    assignmentResponse,
    intervetionUpdateResponse
  ) => {
    let interventionRecord = { ...intervention };
    interventionRecord.assignment = [assignmentResponse];
    interventionRecord.status = intervetionUpdateResponse?.data?.status;
    console.log("interventionRecord", interventionRecord);
    dispatch(setIntervention(interventionRecord));
    setEditingRecord(interventionRecord);
  };

  const handleDispatchSubmitForProbaReview = (intervetionUpdateResponse) => {
    let interventionRecord = { ...intervention };
    interventionRecord.status = intervetionUpdateResponse?.data?.status;
    dispatch(setIntervention(interventionRecord));
    setEditingRecord(interventionRecord);

    setIntervStsProbaReview(true);
    // setSubmitForProbaReviewBtnDisabled(true);
    setAddDocumentBtnDisabled(true);
    setAddParamBtnDisabled(true);
    if (id === "edit") {
      history.push(`/intervention/view`);
      setIsEditing(false);
      setIsCreate(false);
      setIsView(true);
    }
  };

  const handleDispatchSubmitToPublicReview = (intervetionUpdateResponse) => {
    let interventionRecord = { ...intervention };
    interventionRecord.status = intervetionUpdateResponse?.data?.status;
    dispatch(setIntervention(interventionRecord));
    setEditingRecord(interventionRecord);

    setSubmitForProbaReviewBtnDisabled(true);
    setAddDocumentBtnDisabled(true);
    setAddParamBtnDisabled(true);
  };

  const handleDispatchSubmitForValidation = (intervetionUpdateResponse) => {
    let interventionRecord = { ...intervention };
    interventionRecord.status = intervetionUpdateResponse?.data?.status;
    dispatch(setIntervention(interventionRecord));
    setEditingRecord(interventionRecord);

    setSubmitForProbaReviewBtnDisabled(true);
    setAddDocumentBtnDisabled(true);
    setAddParamBtnDisabled(true);
    if (id === "edit") {
      history.push(`/intervention/view`);
      setIsEditing(false);
      setIsCreate(false);
      setIsView(true);
    }
  };

  const handleDispatchMakeActive = (intervetionUpdateResponse) => {
    let interventionRecord = { ...intervention };
    interventionRecord.status = intervetionUpdateResponse?.data?.status;
    dispatch(setIntervention(interventionRecord));
    setEditingRecord(interventionRecord);

    setSubmitForProbaReviewBtnDisabled(true);
    setAddDocumentBtnDisabled(true);
    setAddParamBtnDisabled(true);
  };

  const handleAddRecord = async (record) => {
    try {
      await addIntervention(record)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "GHG Project added successfully!",
            key,
            duration: 2,
          });
          console.log(res);

          setIsEditing(true);
          setIsCreate(false);
          let currEditingRecord = { ...res?.data };
          onEditRecord(currEditingRecord);
          setParamId(res?.data?.param_id);
          dispatch(setIntervention(currEditingRecord));
          setEditingRecord(currEditingRecord);
        })
        .catch((err) => {
          message.error({
            content:
              "Failed to add GHG Project: " + Object?.values(err["data"])[0],
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      await updateIntervention(payload)
        .unwrap()
        .then(async (res) => {
          message.success({
            content: "GHG Project updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          setIsEditing(true);
          setIsCreate(false);
          let currEditingRecord = { ...res?.data };
          onEditRecord(currEditingRecord);
          setParamId(res?.data?.param_id);
          dispatch(setIntervention(currEditingRecord));
          setEditingRecord(currEditingRecord);
        })
        .catch((err) => {
          message.error({
            content:
              "Failed to update GHG Project: " + Object?.values(err["data"])[0],
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteParam = async (id) => {
    try {
      await deleteParam(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Param removed successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to removed Param!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onCreate = async (values) => {
    console.log("VALUES", values);
    try {
      setSpinning(true);
      if (isEditing) {
        let intToUpdate = { ...editingRecord };

        intToUpdate.name = values.name;
        intToUpdate.organization = values.organization;
        intToUpdate.method_id = values.method_id;
        intToUpdate.type = values.type;
        intToUpdate.region = values.region;
        intToUpdate.pre_credits = values.pre_credits;
        intToUpdate.buffer_pool_value = values.buffer_pool_value;
        intToUpdate.is_pre_credits_active = values.is_pre_credits_active;
        intToUpdate.sponsor_pre_credits_flag = values.sponsor_pre_credits_flag;
        intToUpdate.standards_used = values.standards_used;
        intToUpdate.project_id = values.project_id;
        intToUpdate.ghg_project_summary = values.ghg_project_summary;
        // intToUpdate.param_id = editParamRecord ? editParamRecord._id : null;

        //TODO - remove from here

        //TODO - to remove later
        handleEditRecord(intToUpdate);
        setEditingRecord(intToUpdate);
      } else if (isCreate) {
        // values.param_id = editParamRecord ? editParamRecord._id : "";
        await handleAddRecord(values);
        setDisableSubmitForValidation(true);
      }
    } catch {
    } finally {
      setSpinning(false);
    }
  };

  const handleViewYields = () => {
    viewYieldRouteChange();
    setIsInterventionYieldData(true);
  };

  const handleViewAssets = () => {
    viewAssetRouteChange();
    setIsInterventionAssetData(true);
  };

  const handleAssignValidator = () => {
    setIsCreateAssigneeToInterv(true);
    setIsassignUserToInterv("Validator");
  };

  const handleAssignVerifier = () => {
    setIsCreateAssigneeToInterv(true);
    setIsassignUserToInterv("Verifier");
  };

  const handleSubmitToPublicReview = () => {
    setIsSubmitToPublicReview(true);
    setIntervStsPublicReview(true);
  };

  const handleSubmitForValidation = () => {
    setIsSubmitForValidation(true);
    setIntervStsValidatorReview(true);
  };

  const handleMakeActive = () => {
    setIsMakeActive(true);
    setIntervStsActive(true);
  };

  const handleEligibiltyCheck = () => {
    setIsEligibilityCheckModalVisible(true);
    console.log("Eligibility confirmed!");
  };

  const handleOperationalCheck = () => {
    setIsOperationalCheckModalVisible(true);
  };
  const handlevalidationCheck = () => {
    setIsValidationCheckModalVisible(true);
  };

  const handleCancel = () => {
    setIsEligibilityCheckModalVisible(false);
    setIsOperationalCheckModalVisible(false);
    setIsValidationCheckModalVisible(false);
  };

  const handleImportAssets = () => {
    setIsImportCsv(true);
    setImportlevel("Assets");
  };

  const handleImportYields = () => {
    setIsImportCsv(true);
    setImportlevel("Yields");
  };

  const handleAssignProjectSponsor = () => {
    setIsCreateAssigneeToInterv(true);
    setIsassignUserToInterv("Project Sponsor");
  };

  const handleAddDocument = () => {
    setIsCreateDocument(true);
    setIsEditDocument(false);
    setEditDocumentRecord(null);
  };

  const handleAddParam = () => {
    setIsCreateParam(true);
    setIsEditParam(false);
    setEditParamRecord(null);
    setParamId(null);
  };

  const handleSubmitForProbaReview = async () => {
    refetchValidation();
    setSpinning(true);
    const {
      data: validationsData,
      isLoading: isValidationDataLoading,
    } = await store.dispatch(
      validationApi.endpoints.getValidation.initiate(intervention?._id)
    );
    if (!isValidationDataLoading) {
      setValidationData(validationsData);
      setSpinning(false);
    }
    setIsSubmitForProbaReview(true);
    setIsView(true);
  };

  const handleIntervEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      console.log("payload", payload);
      const res = await updateInterventionStatus(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "GHG Project updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          handleDispatchSubmitToPublicReview(res);
          return res;
        })
        .catch((err) => {
          console.log("err", err);
          message.error({
            content:
              "Failed to update GHG Project: " + Object.values(err["data"])[0],
            key,
            duration: 6,
          });
          console.log(err);
        });
      return res;
    } catch {}
  };

  const handleProjectRevision = async () => {
    let interventionUpdateResponse;
    const intervstatusupdate = { ...intervention };
    intervstatusupdate["status"] = "Revision";

    try {
      if (!usergroup.includes("Proba Operator")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        interventionUpdateResponse = await handleIntervEditRecord(
          intervstatusupdate
        );
      }
    } catch (error) {
      console.error("Error updating intervention:", error);
    } finally {
      setIsEligibilityCheckModalVisible(false);
      setIsOperationalCheckModalVisible(false);
    }
  };

  const menu = (
    <Menu>
      {!isCreate && (
        <Menu.Item key="viewYields">
          <Button type="link" onClick={handleViewYields}>
            View Yields
          </Button>
        </Menu.Item>
      )}
      {!isCreate && (
        <Menu.Item key="viewAssets">
          <Button type="link" onClick={handleViewAssets}>
            View Assets
          </Button>
        </Menu.Item>
      )}
      {!isCreate &&
        usergroup.includes("Project Developer") &&
        intervention?.organization === orgId && (
          <>
            <Menu.Item key="submitForProbaReview">
              <Button
                type="link"
                onClick={handleSubmitForProbaReview}
                // disabled={!isIntervHasAsset && !isIntervHasYield}
                disabled={
                  isIntervStsProbaReview ||
                  isIntervStsPublicReview ||
                  isIntervStsValidatorReview ||
                  isIntervStsActive
                }
              >
                Submit for Proba Review
              </Button>
            </Menu.Item>
            <Menu.Item key="SubmitForValidation">
              <Button
                type="link"
                onClick={handleSubmitForValidation}
                disabled={
                  disableSubmitForValidation ||
                  isIntervStsNew ||
                  isIntervStsRevision ||
                  isIntervStsProbaReview ||
                  isIntervStsValidatorReview ||
                  isIntervStsActive
                }
              >
                Submit for Validation
              </Button>
            </Menu.Item>
          </>
        )}
      {usergroup.includes("Proba Operator") && (
        <Menu.Item key="EligibiltyCheck">
          <Button
            type="link"
            onClick={handleEligibiltyCheck}
            disabled={
              usergroup.includes("Proba Operator") &&
              (isIntervStsNew ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsActive ||
                isIntervStsRevision)
            }
          >
            Perform Eligibility Check
          </Button>
        </Menu.Item>
      )}
      {usergroup.includes("Proba Operator") && (
        <Menu.Item key="OperationalCheck">
          <Button
            type="link"
            onClick={handleOperationalCheck}
            disabled={
              usergroup.includes("Proba Operator") &&
              (isIntervStsNew ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsActive ||
                isIntervStsRevision)
            }
          >
            Perform Operational Check
          </Button>
        </Menu.Item>
      )}
      {usergroup.includes("Proba Operator") && (
        <Menu.Item key="Submit for Public Comment">
          <Button
            type="link"
            onClick={handleSubmitToPublicReview}
            disabled={
              usergroup.includes("Proba Operator") &&
              (isIntervStsNew ||
                isProjectStsRevision ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsActive ||
                isIntervStsValidatorReview)
            }
          >
            Submit for Public Comment
          </Button>
        </Menu.Item>
      )}
      {usergroup.includes("Proba Operator") && (
        <Menu.Item key="SubmitForValidation">
          <Button
            type="link"
            onClick={handleSubmitForValidation}
            disabled={
              isIntervStsNew ||
              isIntervStsValidatorReview ||
              isIntervStsActive ||
              isIntervStsPublicReview ||
              isProjectStsRevision
            }
          >
            Submit for Validation
          </Button>
        </Menu.Item>
      )}
      {usergroup.includes("Validator") && (
        <>
          <Menu.Item key="submitForValidation">
            <Button
              type="link"
              onClick={handleMakeActive}
              disabled={
                !isIntervHasAssignment.isMatchingValidator ||
                (usergroup.includes("Validator") &&
                  (isIntervStsNew ||
                    isIntervStsProbaReview ||
                    isIntervStsPublicReview ||
                    isIntervStsActive ||
                    isIntervStsRevision))
              }
              // disabled={verifyGhgProjectBtnDisabled}
            >
              Perform Validation
            </Button>
          </Menu.Item>
        </>
      )}
      {usergroup.includes("Project Developer") &&
        intervention?.organization === orgId &&
        !isCreate && (
          <Menu.Item key="importAssets">
            <Button
              type="link"
              onClick={handleImportAssets}
              disabled={
                !usergroup.includes("Project Developer") ||
                isIntervStsProbaReview ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsRevision
              }
            >
              Import Assets
            </Button>
          </Menu.Item>
        )}
      {usergroup.includes("Project Developer") &&
        intervention?.organization === orgId &&
        !isCreate && (
          <Menu.Item key="importYields">
            <Button
              type="link"
              onClick={handleImportYields}
              disabled={
                !usergroup.includes("Project Developer") ||
                isIntervStsProbaReview ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsRevision
              }
            >
              Import Yields
            </Button>
          </Menu.Item>
        )}

      {usergroup.includes("Project Developer") &&
        intervention?.organization === orgId &&
        !isCreate && (
          <>
            <Menu.Item key="assignValidator">
              <Button
                type="link"
                onClick={handleAssignValidator}
                disabled={
                  isIntervStsProbaReview ||
                  isIntervStsPublicReview ||
                  isIntervStsValidatorReview ||
                  isIntervStsRevision
                }
              >
                Assign Validator
              </Button>
            </Menu.Item>
            <Menu.Item key="assignVerifier">
              <Button
                type="link"
                onClick={handleAssignVerifier}
                disabled={
                  isIntervStsProbaReview ||
                  isIntervStsPublicReview ||
                  isIntervStsValidatorReview ||
                  isIntervStsRevision
                }
              >
                Assign Verifier
              </Button>
            </Menu.Item>
          </>
        )}
      {usergroup.includes("Project Developer") &&
        intervention?.organization === orgId &&
        !isCreate && (
          <Menu.Item key="assignProjectSponsor">
            <Button
              type="link"
              onClick={handleAssignProjectSponsor}
              disabled={
                isCreate ||
                isIntervStsProbaReview ||
                isIntervStsPublicReview ||
                isIntervStsValidatorReview ||
                isIntervStsRevision
              }
            >
              Assign Project Sponsor
            </Button>
          </Menu.Item>
        )}
      {(
        usergroup.includes("Proba Operator") ||
        usergroup.includes("Verifier") ||
        usergroup.includes("Validator") ||
        usergroup.includes("Project Developer")
      ) && (
          <Menu.Item key="addDocument">
            <Button
              type="link"
              onClick={handleAddDocument}
              disabled={
                isCreate ||
                addDocumentBtnDisabled ||
                isProjectStsProbaReview
              }
            >
              Add Document
            </Button>
          </Menu.Item>
        )}
      {isCreate ? (
        <Menu.Item key="addParam">
          <Button
            type="link"
            onClick={handleAddParam}
            disabled={
              isCreate ||
              addParamBtnDisabled ||
              isProjectStsProbaReview ||
              isIntervStsActive
            }
          >
            Add Param
          </Button>
        </Menu.Item>
      ) : (
        <>
          {usergroup.includes("Project Developer") &&
            intervention?.organization === orgId && (
              <>
                {editingRecord && editingRecord?.param_id ? (
                  <>
                    <Menu.Item key="editParam">
                      <Button
                        disabled={isProjectStsProbaReview || isIntervStsActive}
                        type="link"
                        onClick={() => {
                          setIsCreateParam(false);
                          setIsEditParam(true);
                          setParamId(editingRecord?.param_id);
                        }}
                      >
                        Edit Param
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="removeParam">
                      <Button
                        disabled={isProjectStsProbaReview || isIntervStsActive}
                        type="link"
                        onClick={() => {
                          setIsCreateParam(false);
                          setIsEditParam(false);
                          Modal.confirm({
                            title: "Are you sure you want to remove the Param?",
                            okText: "Yes",
                            okType: "danger",
                            onOk: () => {
                              handleDeleteParam(editingRecord?.param_id);
                              setEditParamRecord(null);
                              setParamId(null);

                              let recordToUpdate = { ...editingRecord };
                              recordToUpdate.param_id = null;
                              handleEditRecord(recordToUpdate);
                              setEditingRecord(recordToUpdate);
                            },
                          });
                        }}
                      >
                        Remove Param
                      </Button>
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item key="addParam">
                    <Button
                      disabled={
                        addParamBtnDisabled ||
                        isProjectStsProbaReview ||
                        addParamBtnDisabled ||
                        isIntervStsActive
                      }
                      type="link"
                      onClick={() => {
                        setIsCreateParam(true);
                        setIsEditParam(false);
                        setEditParamRecord(null);
                        setParamId(null);
                      }}
                    >
                      Add Param
                    </Button>
                  </Menu.Item>
                )}
              </>
            )}
        </>
      )}
    </Menu>
  );

  return (
    <>
      <Spin spinning={spinning}>
        <div style={{ height: "80vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={2}>
              {isCreate
                ? "Add GHG Project"
                : isEditing
                ? "Edit GHG Project: " + editingRecord?.name
                : "View GHG Project: " + editingRecord?.name}
            </Typography.Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Dropdown overlay={menu} trigger={["hover"]}>
                <Button type="primary">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
              <Divider type="vertical"></Divider>
              <Button
                type="primary"
                onClick={() => {
                  viewInterventionRouteChange();
                  //Reload the table on Back
                  dispatch(interventionApi.util.resetApiState());
                }}
              >
                Back
              </Button>
            </div>
          </div>

          <div>
            <Row gutter={[10, 10]} align="stretch" style={{ display: "flex" }}>
              <Col span={12}>
                <Card
                  className="shadow-card"
                  style={{ height: "100%", borderRadius: 8, flexGrow: 1 }}
                >
                  <Typography.Title level={4}>
                    GHG Project Details
                  </Typography.Title>
                  <br></br>
                  {isEditing || isCreate ? (
                    <InterventionForm
                      visible={isEditing || isCreate}
                      isEditing={isEditing}
                      isIntervStsActive={isIntervStsActive}
                      onCreate={onCreate}
                      onCancel={() => {
                        resetEditing();
                      }}
                      editingRecord={editingRecord}
                      editParamRecord={editParamRecord}
                      isCreateParam={isCreateParam}
                      isEditParam={isEditParam}
                      closeModal={closeModal}
                      organizationObj={organizationObj}
                      updateParam={updateParam}
                      paramId={paramId}
                      editDocumentRecord={editDocumentRecord}
                      isCreateDocument={isCreateDocument}
                      isEditDocument={isEditDocument}
                      closeDocumentModal={closeDocumentModal}
                      updateDocument={updateDocument}
                      isIntervHasAssignment={isIntervHasAssignment}
                      isSubmitForProbaReview={isSubmitForProbaReview}
                      isSubmitToPublicReview={isSubmitToPublicReview}
                      isProjectStsProbaReview={isProjectStsProbaReview}
                      isSubmitForValidation={isSubmitForValidation}
                      isMakeActive={isMakeActive}
                    />
                  ) : (
                    isView && (
                      <InterventionView
                        onCreate={onCreate}
                        editingRecord={editingRecord}
                        editParamRecord={editParamRecord}
                        isCreateParam={isCreateParam}
                        isEditParam={isEditParam}
                        closeModal={closeModal}
                        updateParam={updateParam}
                        organizationObj={organizationObj}
                        paramId={paramId}
                        editDocumentRecord={editDocumentRecord}
                        isCreateDocument={isCreateDocument}
                        isEditDocument={isEditDocument}
                        closeDocumentModal={closeDocumentModal}
                        updateDocument={updateDocument}
                        usergroup={usergroup}
                        isSubmitForProbaReview={isSubmitForProbaReview}
                        isSubmitToPublicReview={isSubmitToPublicReview}
                        isSubmitForValidation={isSubmitForValidation}
                        isMakeActive={isMakeActive}
                      />
                    )
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  className="shadow-card"
                  style={{ height: "100%", borderRadius: 8 }}
                >
                  <Typography.Title level={4}>Documents</Typography.Title>
                  <DisplayDocuments
                    id={editingRecord?._id}
                    entity="intervention"
                  ></DisplayDocuments>
                </Card>
                {/* <Card className="shadow-card" style={{ height: "40%", borderRadius: 8, flexGrow: 1 }}>
                            <Typography.Title level={4}>Parameters</Typography.Title>
                            {editingRecord?.param_id != "" && <ParamView paramid={editingRecord?.param_id}></ParamView>}
                        </Card> */}
              </Col>
            </Row>

            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col span={24}>
                <Card className="shadow-card">
                  <Typography.Title level={4}>Parameters</Typography.Title>
                  {editingRecord?.param_id != "" && (
                    <ParamView paramid={editingRecord?.param_id}></ParamView>
                  )}
                </Card>
              </Col>
            </Row>

            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col span={24}>
                <Card className="shadow-card">
                  {editingRecord?._id != "" && editingRecord?._id && (
                    <Asset
                      intid={editingRecord?._id}
                      intname={editingRecord?.name}
                    ></Asset>
                  )}
                </Card>
              </Col>
            </Row>

            <SubmitForProbaReview
              interventionDetails={intervention}
              validationsData={validationData}
              usergroup={usergroup}
              isCreate={isSubmitForProbaReview}
              setSpinning={setSpinning}
              spinning={spinning}
              closeModal={closeSubmitForProbaReview}
              handleDispatchSubmitForProbaReview={
                handleDispatchSubmitForProbaReview
              }
            />

            <SubmitToPublicReview
              interventionDetails={intervention}
              usergroup={usergroup}
              isCreate={isSubmitToPublicReview}
              closeModal={closeSubmitToPublicReview}
              handleDispatchSubmitToPublicReview={
                handleDispatchSubmitToPublicReview
              }
            />

            <SubmitForValidation
              interventionDetails={intervention}
              usergroup={usergroup}
              isCreate={isSubmitForValidation}
              closeModal={closeSubmitForValidation}
              handleDispatchSubmitForValidation={
                handleDispatchSubmitForValidation
              }
            />

            <MakeActive
              interventionDetails={intervention}
              updateDocument={updateDocument}
              usergroup={usergroup}
              isCreate={isMakeActive}
              setSpinning={setSpinning}
              spinning={spinning}
              closeModal={closeMakeActive}
              handleDispatchMakeActive={handleDispatchMakeActive}
            />
            <Params
              paramId={paramId}
              updateParam={updateParam}
              editParamRecord={editParamRecord}
              isCreate={isCreateParam}
              isEditing={isEditParam}
              closeModal={closeModal}
            />
            <AddDocument
              ids={[editingRecord?._id]}
              entity="intervention"
              updateDocument={updateDocument}
              editingRecord={editDocumentRecord}
              isCreate={isCreateDocument}
              isEditings={isEditDocument}
              closeModal={closeDocumentModal}
            />
            <AssignVerifier
              isCreate={isCreateAssigneeToInterv}
              groupname={isassignUserToInterv}
              isDetails={intervention}
              closeModal={closeAssigneeToIntervModal}
              assignmentTo={"GHG Project"}
              handleDispatchAfterAssignment={handleDispatchAfterAssignment}
              refetchValidation={refetchValidation}
            />
            <AssetImport
              visible={isImportCsv}
              interventionDetails={intervention}
              importlevel={importlevel}
              onCancel={() => {
                setIsImportCsv(false);
              }}
            />
            {isInterventionYieldData && <ViewYields />}
            {isInterventionAssetData && <ViewAssets />}
          </div>
        </div>
      </Spin>
      <Modal
        visible={eligibilityCheckModalVisible}
        title=" Perform  Eligibility check "
        onCancel={handleCancel}
        // onOk={handleOk}
        okText="Approve"
        cancelText="Revise"
        width="60%"
        onOk={() => {
          handleCancel();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={handleProjectRevision}
            style={{ color: "red" }}
          >
            Revise
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Approve
          </Button>,
        ]}
      >
        <div style={{ maxHeight: "650px", overflowY: "auto" }}>
          <Typography.Text level={5}>
            The Proba Management Board confirms that the GHG project is eligible
            for certification against the Proba Standard. Add the decision memo
            to the GHG project as proof of eligibility.
          </Typography.Text>
          <br></br>

          <Button
            type="primary"
            style={{ marginTop: "1rem" }}
            onClick={() => {
              setIsCreateDocument(true);
            }}
          >
            Upload Documents
          </Button>

          <br></br>
        </div>
      </Modal>
      <Modal
        visible={operationalCheckModalVisible}
        title=" Perform  Operational check "
        onCancel={handleCancel}
        // onOk={handleOk}
        okText="Approve"
        cancelText="Revise"
        onOk={() => {
          handleCancel();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={handleProjectRevision}
            style={{ color: "red" }}
          >
            Revise
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Approve
          </Button>,
        ]}
      ></Modal>

      <Modal
        visible={validationCheckModalVisible}
        title=" Submit for validation "
        onCancel={handleCancel}
        // onOk={handleOk}
        okText="Approve"
        cancelText="Revise"
        cancelButtonProps={{ style: { color: "red" } }}
        onOk={() => {
          handleCancel();
        }}
      ></Modal>
    </>
  );
}

const InterventionForm = ({
  onCreate,
  isEditing,
  isIntervStsActive,
  editingRecord,
  organizationObj,
  paramId,
  isIntervHasAssignment,
  isProjectStsProbaReview,
}) => {
  const {
    data: Interventionparam,
    isLoading: isParamLoading,
  } = useGetParamQuery(paramId === "" ? null : paramId, { skip: !paramId });
  const [showPreCreditselect, setShowPreCreditselect] = useState(
    isEditing &&
      editingRecord?.pre_credits &&
      editingRecord?.is_pre_credits_active
  );

  const organizationlist = [];
  const { Option } = Select;
  if (
    Object?.keys(organizationObj)?.length !== 0 &&
    organizationObj !== null &&
    organizationObj !== undefined
  ) {
    organizationlist.push(organizationObj);
  }

  const { data: methoddata, isLoading: isMethodLoading } = useGetMethodsQuery();
  const methoddatalist = [];
  if (!isMethodLoading) {
    methoddata.forEach((method) => {
      methoddatalist.push(<Option key={method._id}>{method.name}</Option>);
    });
  }

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [standardsLength, setStandardsLength] = useState(0);
  useEffect(() => {
    if (editingRecord) {
      setStandardsLength(editingRecord?.standards_used?.length);
    }
  }, [editingRecord]);

  const handleStandardsSummaryChange = (e) => {
    const value = e.target.value.slice(0, 255);
    form.setFieldsValue({ standards_used: value });
    setStandardsLength(value?.length);
  };

  const handleCheckboxChange = (event) => {
    if (!event.target.checked) {
      form.setFieldsValue({ pre_credits: null });
      form.setFieldsValue({ is_pre_credits_active: false });
      form.setFieldsValue({ sponsor_pre_credits_flag: false });
    } else {
      form.setFieldsValue({ pre_credits: 80 });
      form.setFieldsValue({ is_pre_credits_active: true });
    }
    setShowPreCreditselect((prevState) => !prevState);
  };

  const handleAllocateCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    form.setFieldValue("sponsor_pre_credits_flag", isChecked ? true : false);
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="intervention"
        initialValues={editingRecord}
        onFinish={onCreate}
        autoComplete="off"
      >
        <Row gutter={[10, 0]}>
          <Col span={7}>
            <Form.Item
              name="name"
              label="GHG Project Name"
              rules={[
                {
                  required: true,
                  message: "Please input GHG Project name!",
                },
              ]}
            >
              <Input disabled={isEditing && isIntervStsActive} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="project_id"
              label="ProjectID"
              rules={[
                {
                  required: true,
                  message: "Please input Minimum 4 characters!",
                },
                {
                  min: 4,
                  message: "ProjectID must be at least 4 characters.",
                },
              ]}
            >
              <Input disabled={isEditing && isIntervStsActive} />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              name="method_id"
              label="Methodology"
              rules={[
                {
                  required: true,
                  message: "Please input Methodology!",
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Please select the Methodology"
                disabled={isEditing && isIntervStsActive}
              >
                {methoddatalist}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="type"
              label="GHG Project Type"
              rules={[
                {
                  required: true,
                  message: "Please select the GHG Project Type",
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Please select the GHG Project Type"
                disabled={isEditing && isIntervStsActive}
                options={[
                  { value: "reduction", label: "Reduction" },
                  { value: "removal", label: "Removal" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="organization"
              label="Organization"
              initialValue={
                organizationlist.length > 0
                  ? organizationlist[0]._id
                  : undefined
              }
            >
              <Select
                style={{
                  width: "100%",
                }}
                disabled
              >
                {organizationlist.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item name="region" label="Region">
              <Input disabled={isEditing && isIntervStsActive} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="is_pre_credits_active">
              <Checkbox
                onChange={handleCheckboxChange}
                defaultChecked={
                  !!(
                    isEditing &&
                    editingRecord?.pre_credits &&
                    editingRecord?.is_pre_credits_active
                  )
                }
              >
                Pre-Credits
              </Checkbox>
            </Form.Item>
          </Col>

          {showPreCreditselect && (
            <Col span={8} style={{ display: "flex", flexDirection: "row" }}>
              <Form.Item
                name="pre_credits"
                label="Pre-Credits Limit"
                initialValue={80}
              >
                <InputNumber
                  min={1}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => {
                    const strippedValue = value.replace("%", "");
                    const parsedValue = parseInt(strippedValue);
                    return isNaN(parsedValue) ? 0 : parsedValue;
                  }}
                  step={1}
                />
              </Form.Item>
              <Popover content="The maximum percentage of pre-credits that can be created">
                <span
                  className="info-icon"
                  style={{
                    marginLeft: "5px",
                    alignSelf: "end",
                    paddingBottom: "1.75rem",
                  }}
                >
                  <InfoCircleOutlined />
                </span>
              </Popover>
            </Col>
          )}

          <Col span={8}>
            <Form.Item name="sponsor_pre_credits_flag">
              {showPreCreditselect && (
                <Checkbox
                  onChange={handleAllocateCheckboxChange}
                  defaultChecked={
                    !!(isEditing && editingRecord?.sponsor_pre_credits_flag)
                  }
                >
                  Allocate Pre-Credits to Project Sponsor
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              initialValue={10}
              name="buffer_pool_value"
              label="Buffer Pool Value"
            >
              <InputNumber
                min={10}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => {
                  const strippedValue = value.replace("%", "");
                  const parsedValue = parseInt(strippedValue);
                  return isNaN(parsedValue) ? 0 : parsedValue;
                }}
                step={1}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="standards_used"
              label="Standards Used"
              extra={`${standardsLength}/255`}
            >
              <TextArea
                autoSize={{
                  minRows: 2,
                  maxRows: 3,
                }}
                maxLength={255}
                onChange={handleStandardsSummaryChange}
                disabled={isEditing && isIntervStsActive}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="ghg_project_summary"
              label="GHG Project Summary"
              rules={[
                {
                  required: true,
                  message: "Please input GHG Project Summary!",
                },
              ]}
            >
              <TextArea rows={4} disabled={isEditing && isIntervStsActive} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="_id" label="GHG Project Id" hidden>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            // disabled={
            //   isIntervHasAssignment.verifier || isIntervHasAssignment.validator
            // }
            disabled={isProjectStsProbaReview}
            type="primary"
            htmlType="submit"
          >
            Submit GHG Project
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const InterventionView = ({ editingRecord, organizationObj, usergroup }) => {
  const { data: methodparam, isLoading: isParamLoading } = useGetParamQuery(
    editingRecord?.param_id,
    {
      skip: !editingRecord?.param_id,
    }
  );

  const paramDescriptions = [];
  if (!isParamLoading) {
    //TODO - It should only return an object not an array
    const paramsarray = methodparam
      ? methodparam.length > 0
        ? methodparam[0]?.param
        : []
      : [];
    paramsarray?.forEach((param) => {
      paramDescriptions.push(
        <Descriptions.Item
          label={<Typography.Text strong>{param.key}</Typography.Text>}
          span={1}
        >
          {param.value}
        </Descriptions.Item>
      );
    });
  }

  const organizationlist = [];
  const { Option } = Select;
  if (organizationObj) {
    if (
      Object?.keys(organizationObj)?.length !== 0 &&
      organizationObj !== null &&
      organizationObj !== undefined
    ) {
      organizationlist.push(organizationObj);
    }
  }

  const { data: methoddata, isLoading: isMethodLoading } = useGetMethodsQuery();
  const methoddatalist = [];
  if (!isMethodLoading) {
    methoddata.forEach((method) => {
      methoddatalist.push(<Option key={method._id}>{method.name}</Option>);
    });
  }

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const format = (value) => (
    <Typography.Text style={{ fontSize: 18 }}>
      {value === true ? "Yes" : value === false ? "No" : value}
    </Typography.Text>
  );

 

  return (
    <>
      <Row gutter={[60, 20]}>
        <Col span={8}>
          <Statistic
            title="GHG Project Name"
            value={editingRecord?.name}
            formatter={(value) => <TruncateWithTooltip text={value}/>}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Project ID"
            value={editingRecord?.project_id}
            formatter={(value) => <TruncateWithTooltip text={value}/>}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Organization"
            value={editingRecord?.organization}
            formatter={(value) => (
              <DisplayOrganization orgid={value}></DisplayOrganization>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Methodology"
            value={editingRecord?.method_id}
            formatter={(value) => (
              <DisplayMethod methodid={value}></DisplayMethod>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Status"
            value={
              statusOptions?.find(
                ({ value }) => value === editingRecord?.status
              )?.label
            }
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="GHG Project Type"
            value={editingRecord?.type}
            formatter={(value) => format(value)}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title="Region"
            value={editingRecord?.region}
            formatter={(value) => <TruncateWithTooltip text={value}/>}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title={<span>Pre-Credits</span>}
            value={editingRecord?.is_pre_credits_active === true ? "Yes" : "No"}
            formatter={(value) => format(value)}
          />
        </Col>

        {editingRecord?.is_pre_credits_active === true && (
          <Col span={8}>
            <Statistic
              title={
                <span>
                  Pre-Credits Limit
                  <Popover content="The maximum percentage of pre-credits that can be created">
                    <span className="info-icon" style={{ marginLeft: "5px" }}>
                      <InfoCircleOutlined />
                    </span>
                  </Popover>
                </span>
              }
              value={editingRecord?.pre_credits ?? ""}
              formatter={(value) => format(value)}
            />
          </Col>
        )}

        <Col span={8}>
          <Statistic
            title="Are Pre-Credits allocated to Project Sponsor?"
            value={editingRecord?.sponsor_pre_credits_flag ?? "No"}
            formatter={(value) => format(value === true ? "Yes" : "No")}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title="Buffer Pool"
            value={editingRecord?.buffer_pool_value ?? ""}
            formatter={(value) => format(value)}
          />
        </Col>

        <Col span={24}>
          <Statistic
            title="Standards Used"
            value={editingRecord?.standards_used}
            formatter={(value) => format(value)}
          />
        </Col>

        <Col span={24}>
          <Statistic
            title="GHG Project Summary"
            value={editingRecord?.ghg_project_summary}
            formatter={(value) => format(value)}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title="Assignees"
            value={editingRecord?.assignment}
            formatter={(value) => {
              let verifier_org_id, validator_org_id, project_sponsor_org_id;
              if (Array.isArray(value)) {
                value.map((assignment) => {
                  if (assignment.assignee_type === "Verifier") {
                    verifier_org_id = assignment?.organization;
                  }
                  if (assignment.assignee_type === "Validator") {
                    validator_org_id = assignment?.organization;
                  }
                  if (assignment.assignee_type === "Project Sponsor") {
                    project_sponsor_org_id = assignment?.organization;
                  }
                });
                return (
                  <Popover
                    content={
                      <>
                        <Typography.Text strong>Validator:</Typography.Text>
                        <DisplayOrganization
                          orgid={validator_org_id}
                          place="table"
                        />
                        <Typography.Text strong>Verifier:</Typography.Text>
                        <DisplayOrganization
                          orgid={verifier_org_id}
                          place="table"
                        />
                        <Typography.Text strong>
                          Project Sponsor:
                        </Typography.Text>
                        <DisplayOrganization
                          orgid={project_sponsor_org_id}
                          place="table"
                        />
                      </>
                    }
                  >
                    <ContactsOutlined />
                  </Popover>
                );
              } else {
                return (
                  <Popover content={<></>}>
                    <ContactsOutlined />
                  </Popover>
                );
              }
            }}
          />
        </Col>

        <Col span={8}>
          <Statistic
            title="Creator"
            value={editingRecord?.creator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Updator"
            value={editingRecord?.updator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Created At"
            value={editingRecord?.created}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Updated At"
            value={editingRecord?.updated}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
        {editingRecord?.is_updated_to_blockchain === true && (
          <Col span={8}>
            <Statistic
              title="Is Updated to Blockchain? "
              value={editingRecord?.is_updated_to_blockchain}
              formatter={(value) => format(value)}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default AddEditIntervention;
