import { React, useState, useEffect } from "react";
import {
  useGetInterventionYieldsByParamQuery,
  useUpdateYieldBatchMutation,
  useUpdateYieldMutation,
  useUpdateYieldStatusMutation,
} from "../../store/is/yieldapi";
import { interventionApi } from "../../store/is/interventionapi";
import {
  assetApi,
  useAddDocumentToAssetMutation,
  useUpdateAssetBatchMutation,
} from "../../store/is/assetapi";
import {
  assignmentApi,
  useAddAssignmentBatchMutation,
  useAddAssignmentMutation,
} from "../../store/is/assignmentapi";
import {
  Button,
  Table,
  Modal,
  Spin,
  Card,
  Typography,
  Divider,
  message,
  Popover,
  Dropdown,
  Menu,
  List,
  Tooltip,
  Tabs,
  Switch,
  Checkbox,
  Segmented,
  Badge,
  Space,
} from "antd";

import Moment from "react-moment";
import moment from "moment";
import {
  ContactsOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { useHistory } from "react-router-dom";
import store from "../../store/store";
import {
  createEntitlementRecordsUtil,
  createTradableCertificatesUtil,
  makeAvailableForQualityVerifier,
} from "./Utils/yieldutils";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import {
  createAssetsAction,
  createYieldsAction,
  verifyYieldsAction,
  validateYieldsAction,
  createTradableCertificateAction, // Remove unsed code -tradble certifcate moved to entilemnets
  createEntitlementRecordsAction,
  createCancelPreCreditsAction,
  acceptCancelOfPreCreditsAction,
} from "../../constants/actionNames";

import { useGetAssignmentByParamQuery } from "../../store/is/assignmentapi";
import { setYield } from "../../store/is/isSlice";
import AddDocument from "./AddDocument";
import PerformOperationaCheckOnYield from "./Utils/PerformOperationaCheckOnYield";
import HandleYieldButtons from "./HandleYieldButtons";
import { transactionApi } from "../../store/is/transactionapi";
import { preentitlementrecordApi, useAddPreEntitlementRecordMutation, useGetPreEntitilementRecordByYieldIdsQuery, useLazyGetPreEntitlementRecordByParamQuery } from "../../store/is/preentitlementrecordsapi";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import { generateMessageFromStatus } from "../../bc-client/MessageUtils";

function ViewYields() {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsUpdated, setSelectedRowsUpdated] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);
  const [isForFirstProbaReview, setForFirstProbaReview] = useState(false);
  const [
    isPerformOperationalCheckBtnDisabled,
    setIsPerformOperationalCheckBtnDisabled,
  ] = useState(false);

  const [isSubmitForProbaReview, setIsSubmitForProbaReview] = useState(false);
  const [
    isPerformOperationalCheckOnYield,
    setIsPerformOperationalCheckOnYield,
  ] = useState(false);
  const [openProbaReviewModal, setOpenProbaReviewModal] = useState(false);
  const [openValidatorReviewModal, setOpenValidatorReviewModal] = useState(
    false
  );
  const [openVerifierReviewModal, setOpenVerifierReviewModal] = useState(false);
  const [
    openPendingRealizationModal,
    setOpenPendingRealizationModal,
  ] = useState(false);
  const [
    openPerformOperationalCheckModal,
    setOpenPerformOperationalCheckModal,
  ] = useState(false);
  const [openSubmitForVerificationModal, setOpenVerificationModal] = useState(
    false
  );
  const [
    confirmLoadingProbaReviewModal,
    setConfirmLoadingProbaReviewModal,
  ] = useState(false);
  const [
    confirmLoadingPendingRealizationModal,
    setConfirmLoadingPendingRealizationModal,
  ] = useState(false);
  const [
    confirmLoadingValidatorModal,
    setConfirmLoadingValidatorModal,
  ] = useState(false);
  const [
    confirmLoadingVerifierModal,
    setConfirmLoadingVerifierModal,
  ] = useState(false);
  const [
    confirmLoadingSubmitForVerificationModal,
    setConfirmLoadingSubmitForVerificationModal,
  ] = useState(false);
  const [uplodedDocumentHashes, setUplodedDocumentHashes] = useState([]);
  const [
    openCreatePreEntitlementRecordsModal,
    setOpenCreatePreEntitlementRecordsModal,
  ] = useState(false);
  const [yieldidsString, setYieldIdsString] = useState();

  const [isYieldSignOff, setIsYieldSignOff] = useState(false);

  let intervention = useSelector((state) => state.is.intervention);
  let asset = useSelector((state) => state.is.asset);

  let userid = useSelector((state) => state.auth.userid);
  let yields = useSelector((state) => state.is.yields);
  const orgId = useSelector((state) => state.auth?.org?._id);

  const [editingRecord, setEditingRecord] = useState(yields);
  const [isIntervStsActive, setIntervStsActive] = useState(
    intervention?.status === "Active"
  );
  const [isIntervPreCreditsAvailable, setIntervPreCreditsAvailable] = useState(
    intervention?.pre_credits !== null
  );
  const [isAssetStsActive, setAssetStsActive] = useState(false);

  const [updateYield, { isYieldUpdateLoading }] = useUpdateYieldMutation();
  const [updateYieldBatch] = useUpdateYieldBatchMutation();
  const [addAssignmentBatch] = useAddAssignmentBatchMutation();
  const [updateAssignmentBatch] = useUpdateAssetBatchMutation();
  const [updateYieldStatus] = useUpdateYieldStatusMutation();
  const [
    getPreEntitlementRecords,
  ] = useLazyGetPreEntitlementRecordByParamQuery();
  const [projectSponsorOrgId, setProjectSponsorOrgId] = useState();

  const [
    addPreEntitlementRecord,
    { isCreateLoading },
  ] = useAddPreEntitlementRecordMutation();

  const [
    isCreateCertificateModalVisible,
    setIsCreateCertificateModalVisible,
  ] = useState(false);

  const [
    isCreatePreEntitlementRecordsModalVisible,
    setIsCreatePreEntitlementRecordsModalVisible,
  ] = useState(false);

  const [
    isCreateEntitlementRecordsModalVisible,
    setIsCreateEntitlementRecordsModalVisible,
  ] = useState(false);

  const [
    isCancelPreCreditsModalVisible,
    setIsCancelPreCreditsModalVisible,
  ] = useState(false);

  const [
    isAcceptCancelRequestPreCreditsModalVisible,
    setIsAcceptCancelRequestPreCreditsModalVisible,
  ] = useState(false);

  const { TabPane } = Tabs;

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );

  usergroup = usergroup.filter(Boolean);

  const isQualityVerifier = usergroup.includes("Verifier");
  const isQuantityVerifier = usergroup.includes("Validator");
  const isAdmin = usergroup.includes("Admin");
  const isProjectSponsor = usergroup.includes("Project Sponsor");

  const [addAssetDocument] = useAddDocumentToAssetMutation();

  const {
    data: assignmentData,
    isLoading: isIntervAssignmentDetailsLoading,
    isFetching,
    isError,
  } = useGetAssignmentByParamQuery(
    {
      intervention_id: intervention?._id,
    },
    { skip: !intervention?._id }
  );

  const [qualityMatchedAssigneeItem, setQualityMatchedAssigneeItem] = useState(
    null
  );
  const [
    quantityMatchedAssigneeItem,
    setQuantityMatchedAssigneeItem,
  ] = useState(null);

  const [projectSponsorAvailable, setProjectSponsorAvailable] = useState(false);

  useEffect(() => {
    if (!isIntervAssignmentDetailsLoading && assignmentData) {
      const matchedRecord = assignmentData.find((records) => {
        return (
          records.asset_id === null && records.assignee_type === "Validator"
        );
      });
      setQuantityMatchedAssigneeItem(matchedRecord || null);
    }

    if (!isIntervAssignmentDetailsLoading && assignmentData) {
      const matchedRecord = assignmentData.find((records) => {
        return (
          records.asset_id === null && records.assignee_type === "Verifier"
        );
      });
      setQualityMatchedAssigneeItem(matchedRecord || null);
    }

    if (!isIntervAssignmentDetailsLoading && assignmentData) {
      const matchedRecord = assignmentData.find((record) => {
        return (
          record.asset_id === null &&
          record.yield_id === null &&
          record.assignee_type === "Project Sponsor"
        );
      });
      setProjectSponsorOrgId(matchedRecord?.organization);
      setProjectSponsorAvailable(matchedRecord ? true : false);
    }
  }, [isIntervAssignmentDetailsLoading, assignmentData]);

  const [
    isEnableQualityVeriferButton,
    setIsEnableQualityVeriferButton,
  ] = useState(false);
  const [
    isEnableQuantityVeriferButton,
    setIsEnableQuantityVeriferButton,
  ] = useState(false);
  const [
    isYieldQualitySignOffButton,
    setIsYieldQualitySignOffButton,
  ] = useState(false);
  const [
    isYieldPerformValidationButton,
    setIsYieldPerformValidationButton,
  ] = useState(false);
  const [
    isYieldPerformVerificationButton,
    setIsYieldPerformVerificationButton,
  ] = useState(false);

  const [
    isCreateEntitlementRecordsButton,
    setIsCreateEntitlementRecordsButton,
  ] = useState(false);

  const [
    isCreateTradableCertificateButton,
    setIsCreateTradablecertificateButton,
  ] = useState(false);

  const [isSpinning, setSpinning] = useState(false);
  const [isYieldEditing, setIsYieldEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinningDesc, setSpinningDesc] = useState("Processing...");
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [uplodedDocumentList, setUplodedDocumentList] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const key = "updatable";

  const closeDocumentModal = () => {
    setIsCreateDocument(false);
  };

  const displayDocument = (document) => {
    let uplodedDocumentListToUpdate = [...uplodedDocumentList];
    uplodedDocumentListToUpdate.push({
      docname: document?.docname,
      docdname: document?.document_display_name,
    });
    setUplodedDocumentList(uplodedDocumentListToUpdate);
    updateDocument(document);
  };

  //********bc-client */
  const { onAccept } = useTransactionHandlers({
    actionname: createYieldsAction.name,
  });
  const createYieldsActionInBC = async (payloadYields) => {
    return await onAccept(payloadYields);
  };

  const { onAccept: onAcceptCreateAssets } = useTransactionHandlers({
    actionname: createAssetsAction.name,
  });
  const createAssetsActionInBC = async (asset) => {
    return await onAcceptCreateAssets(asset);
  };

  const { onAccept: onAcceptVerifyYields } = useTransactionHandlers({
    actionname: verifyYieldsAction.name,
  });
  const verifyYieldsActionInBC = async (payload) => {
    return await onAcceptVerifyYields(payload);
  };
  const { onAccept: onAcceptvalidateYieldsAction } = useTransactionHandlers({
    actionname: validateYieldsAction.name,
  });
  const validateYieldsActionInBC = async (payload) => {
    return await onAcceptvalidateYieldsAction(payload);
  };

  // Entitlement Records
  const {
    onAccept: onAcceptcreateEntitlementRecordsAction,
  } = useTransactionHandlers({
    actionname: createEntitlementRecordsAction.name,
  });
  const createEntitlementRecordsActionInBC = async (payload) => {
    return await onAcceptcreateEntitlementRecordsAction(payload);
  };

  //Final certificate
  const {
    onAccept: onAcceptcreateTradableCertificateAction,
  } = useTransactionHandlers({
    actionname: createTradableCertificateAction.name,
  });
  const createTradableCertificateActionInBC = async (payload) => {
    return await onAcceptcreateTradableCertificateAction(payload);
  };

  //********bc-client ends */

  if (!intervention?.name) {
    history.push({
      pathname: `/intervention/list`,
    });
  }

  const viewInterventionRouteChange = () => {
    let path = `/intervention/view`;
    history.push(path);
  };

  const yieldEditModal = () => {
    setIsYieldEditing(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsYieldEditing(false);
    setSelectedRowKeys([]);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const formatCreditStatusField = (field) => {
    return field
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const columns = [
    {
      key: "token_id",
      title: "NFRY ID",
      dataIndex: "token_id",
      render: (record) => {
        return <Typography.Text>NFRY-{record}</Typography.Text>;
      },
    },
    {
      key: "external_asset_id",
      title: "External Asset Id",
      dataIndex: "asset",
      render: (asset) => asset?.[0]?.external_asset_id || "",
    },
    {
      key: "period",
      title: "Period",
      dataIndex: "period",
    },
    {
      key: "storage_duration",
      title: "Storage Duration",
      dataIndex: "storage_duration",
    },
    {
      key: "co2_estimated",
      title: "Estimated tCo2e",
      dataIndex: "co2_estimated",
    },
    {
      key: "co2_realized",
      title: "Realized tCo2e",
      dataIndex: "co2_realized",
    },
    {
      key: "product_volume",
      title: "Product Volume",
      dataIndex: "product_volume",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: "New",
          value: "New",
        },
        {
          text: "Proba Review",
          value: "Proba Review",
        },
        {
          text: "Revision",
          value: "Revision",
        },
        {
          text: "Pending Realization",
          value: "Pending Realization",
        },
        {
          text: "Validator Review",
          value: "Validator Review",
        },
        {
          text: "Verifier Review",
          value: "Verifier Review",
        },
        {
          text: "Realized",
          value: "Realized",
        },
      ],
      filteredValue: filteredInfo?.status || null,
      onFilter: (value, record) => record?.status?.includes(value),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      key: 'credit_status',
      title: 'Credit Status',
      dataIndex: 'credit_status',
      render: (creditStatus) => {
        const trueFields = Object.entries(creditStatus)
          .filter(([key, value]) => value === true)
          .map(([key]) => formatCreditStatusField(key));
        return trueFields.join(', ') || 'None';
      },
      filters: [
        { text: 'Credits Available', value: 'credits_available' },
        { text: 'Credits Not Available', value: 'credits_not_available' },
        { text: 'Pre Credits Available', value: 'pre_credits_available' },
        { text: 'Pre Credits Not Available', value: 'pre_credits_not_available' },
      ],
      filteredValue: filteredInfo?.credit_status || null,
      onFilter: (value, record) => record?.credit_status[value] === true,
    },
    // {
    //   key: "has_entitlement_records",
    //   title: "Pre-Credits Created",
    //   dataIndex: "entitlement_records",
    //   render: (entitlement_records) => {
    //     const hasEntitlementRecords = entitlement_records.length > 0;
    //     return (
    //       <Popover
    //         content={
    //           hasEntitlementRecords ? (
    //             <>
    //               {entitlement_records.map((record, index) => (
    //                 <div key={index} style={{ display: "flex", alignItems: "center" }}>
    //                   <DisplayUserName userid={record.owner_of_entitlement_records} place="table" /> : {record.no_of_entitlement_record}
    //                 </div>
    //               ))}
    //             </>
    //           ) : (
    //             "No entitlement records found"
    //           )
    //         }
    //       >
    //         <span style={{ cursor: hasEntitlementRecords ? "pointer" : "default" }}>
    //           {hasEntitlementRecords ? "Yes" : "No"}
    //           {hasEntitlementRecords && <InfoCircleOutlined style={{ marginLeft: 4 }} />}
    //         </span>
    //       </Popover>
    //     );
    //   }
    // },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    {
      key: "user_id",
      title: "Assignees",
      dataIndex: "user_id",
      visible: usergroup.includes("Project Developer"),
      render: (text, record) => {
        let verifier_org_id, validator_org_id, project_sponsor_org_id;
        if (Array.isArray(record?.assignment)) {
          record?.assignment?.map((assignment) => {
            if (assignment.assignee_type === "Verifier") {
              verifier_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Validator") {
              validator_org_id = assignment?.organization;
            }
            if (assignment.assignee_type === "Project Sponsor") {
              project_sponsor_org_id = assignment?.organization;
            }
          });
          return (
            <Popover
              content={
                <>
                  <Typography.Text strong>Verifier:</Typography.Text>
                  <DisplayOrganization orgid={verifier_org_id} place="table" />
                  <Typography.Text strong>Validator:</Typography.Text>
                  <DisplayOrganization orgid={validator_org_id} place="table" />
                  <Typography.Text strong>Project Sponsor:</Typography.Text>
                  <DisplayOrganization orgid={project_sponsor_org_id} place="table" />
                </>
              }
            >
              <ContactsOutlined />
            </Popover>
          );
        } else {
          return (
            <Popover content={<></>}>
              <ContactsOutlined />
            </Popover>
          );
        }
      },
    },
  ].filter(Boolean); //remove null columns

  if (usergroup.includes("Project Developer")) {
    columns.push({
      key: "6",
      title: "Actions",
      render: (record) => {
        const isEditableStatus = [
          "New",
          "Revision",
          "Pending Realization",
        ].includes(record?.status);
        const tooltipTitle = "Cannot edit";

        if (isEditableStatus) {
          return (
            <>
              <EditOutlined
                onClick={() => {
                  setSelectedRowsUpdated([record]);
                  yieldEditModal();
                }}
                style={{ marginRight: 10 }}
              />
              <Divider type="vertical" />
            </>
          );
        } else {
          return (
            <>
              <Tooltip title={tooltipTitle}>
                <EditOutlined style={{ marginRight: 10, opacity: 0.5 }} />
              </Tooltip>
              <Divider type="vertical" />
            </>
          );
        }
      },
    });
  }
  const {
    data: interventionYieldData,
    isLoading: isInterventionYieldDataLoading,
    refetch: refetchYields,
  } = useGetInterventionYieldsByParamQuery(
    {
      intervention_id: intervention?._id,
    },
    { skip: !intervention?._id }
  );

  useEffect(() => {
    if (!isInterventionYieldDataLoading && interventionYieldData) {
      const yieldIdsArray = interventionYieldData.data.map((row) => row._id);
      const newYieldIdsString = yieldIdsArray.join(",");
      setYieldIdsString(newYieldIdsString);
    }
  }, [interventionYieldData, isInterventionYieldDataLoading]);

  const {
    data: pre_entitlement_data,
    isLoading: isPreEntitlementDataLoading,
  } = useGetPreEntitilementRecordByYieldIdsQuery(
    {
      intervention_id: intervention?._id,
    },
    { skip: !intervention?._id }
  );

  if (!isPreEntitlementDataLoading) {
    console.log("interventionYieldData", interventionYieldData);
    console.log("pre_entitlement_data", pre_entitlement_data);
  }
  const [addAssignment] = useAddAssignmentMutation();

  const hasPreEntitlementRecords = (record) => {
    const matchingData = pre_entitlement_data?.data?.filter(
      (item) => item.yield_id === record._id
    );
    console.log("matchingData", matchingData);
    return matchingData.length > 0;
  };

  const resetButtonStates = () => {
    setIsEnableQualityVeriferButton(false);
    setIsYieldQualitySignOffButton(false);
    setIsEnableQuantityVeriferButton(false);
    setIsCreateEntitlementRecordsButton(false);
    setIsCreateTradablecertificateButton(false);
    setSelectedRowKeys([]);
  };

  const handleMakeAvailable = async (selectedRows) => {
    let isEnableQualityVeriferFlag = true;
    let isYieldSignOffFag = true;
    let isEnableQuantityVerifierFlag = true;
    let isCreateEntitlementRecordsFlag = true;
    let isCreateTradableCertificateFlag = true;

    //If no rows selected
    if (selectedRows.length === 0) {
      isEnableQualityVeriferFlag = false;
      isYieldSignOffFag = false;
      isEnableQuantityVerifierFlag = false;
      isCreateEntitlementRecordsFlag = false;
      isCreateTradableCertificateFlag = false;
    }

    //If Rows selected
    for (let i = 0; i < selectedRows.length; i++) {
      let isEnableQualityVeriferRow = true;
      //Check1: owner of the record
      if (!usergroup.includes("Project Developer")) {
        isEnableQualityVeriferRow = false;
      }
      //check2: check if intervention has verifier assigned
      if (
        qualityMatchedAssigneeItem === undefined ||
        qualityMatchedAssigneeItem === null
      ) {
        isEnableQualityVeriferRow = false;
      }
      //Check3: check if yield has verifier
      let yieldHasVerifier = false;
      let yieldHasVerifierQuantity = false;
      const yieldAssignments = selectedRows[i]?.assignment;

      yieldAssignments?.forEach((yieldAssignee) => {
        if (yieldAssignee?.assignee_type === "Verifier") {
          yieldHasVerifier = true;
        }
        if (yieldAssignee?.assignee_type === "Validator") {
          yieldHasVerifierQuantity = true;
        }
      });
      if (yieldHasVerifier) {
        isEnableQualityVeriferRow = false;
      }
      //if one record fails then, stop the loop and retrun as false
      if (!isEnableQualityVeriferRow) {
        isEnableQualityVeriferFlag = false;
      }

      //BUTTON- 2
      let isYieldSignOffRow = true;

      let yieldQualityVerified = false;
      let yieldQuantityVerified = false;
      yieldAssignments?.forEach((yieldAssignee) => {
        if (
          yieldAssignee?.assignee_type === "Verifier" &&
          yieldAssignee?.verification_status === "verified"
        ) {
          yieldQualityVerified = true;
        }
        if (
          yieldAssignee?.assignee_type === "Validator" &&
          yieldAssignee?.verification_status === "verified"
        ) {
          yieldQuantityVerified = true;
        }
      });

      let isVerifier = false;
      if (isQualityVerifier || isQuantityVerifier) {
        isVerifier = true;
      }
      if (!isVerifier) {
        isYieldSignOffRow = false;
      }

      if (isQualityVerifier && yieldQualityVerified) {
        isYieldSignOffRow = false;
      }
      if (isQuantityVerifier && yieldQuantityVerified) {
        isYieldSignOffRow = false;
      }
      if (
        isQualityVerifier &&
        !(qualityMatchedAssigneeItem?.verification_status === "verified")
      ) {
        isYieldSignOffRow = false;
      }
      if (
        isQuantityVerifier &&
        !(quantityMatchedAssigneeItem?.verification_status === "verified")
      ) {
        isYieldSignOffRow = false;
      }

      //if one record fails then, stop the loop and retrun as false
      if (!isYieldSignOffRow) {
        isYieldSignOffFag = false;
      }

      //BUTTON 3
      let isEnableForQuantityRow = true;
      if (!isAdmin) {
        isEnableForQuantityRow = false;
      }
      if (!yieldQualityVerified) {
        isEnableForQuantityRow = false;
      }
      //Check if Intervention has Validator assigned
      if (
        quantityMatchedAssigneeItem === undefined ||
        quantityMatchedAssigneeItem === null
      ) {
        isEnableForQuantityRow = false;
      }

      if (selectedRows[i]?.status !== "Verified Quality") {
        isEnableForQuantityRow = false;
      }
      if (yieldHasVerifierQuantity) {
        isEnableForQuantityRow = false;
      }
      if (!isEnableForQuantityRow) {
        isEnableQuantityVerifierFlag = false;
      }

      //BUTTON 4
      let isCreateEntitlementrecordsRow = true;
      if (!isProjectSponsor) {
        isCreateEntitlementrecordsRow = false;
      }
      if (selectedRows[i]?.status !== "Verified Quantity") {
        isCreateEntitlementrecordsRow = false;
      }
      if (!isCreateEntitlementrecordsRow) {
        isCreateEntitlementRecordsFlag = false;
      }

      //BUTTON 5
      let isCreateTradableCertificateRow = true;
      if (!isProjectSponsor) {
        isCreateTradableCertificateRow = false;
      }
      // if (selectedRows[i]?.status !== "Entitlements Available") {
      //   isCreateTradableCertificateRow = false;
      // }
      if (!isCreateTradableCertificateRow) {
        isCreateTradableCertificateFlag = false;
      }
    }
    setSelectedRowsUpdated(selectedRows);
    // Return true only if all selected rows meet the condition

    setIsEnableQualityVeriferButton(isEnableQualityVeriferFlag);
    setIsYieldQualitySignOffButton(isYieldSignOffFag);
    setIsEnableQuantityVeriferButton(isEnableQuantityVerifierFlag);
    setIsCreateEntitlementRecordsButton(isCreateEntitlementRecordsFlag);
    setIsCreateTradablecertificateButton(isCreateTradableCertificateFlag);
  };

  const getAssetsByIds = async (assetids) => {
    var assetIdsString = assetids.join(",");
    const { data: assetDetails, isLoading } = await store.dispatch(
      assetApi.endpoints.getAssetsByIds.initiate({ assetids: assetIdsString })
    );
    if (!isLoading) {
      return assetDetails ? assetDetails?.data : null;
    }
  };

  const handleSelectedRows = async (selectedRows) => {
    const assetIdsList = [...new Set(selectedRows?.map((row) => row?.asset_id))];

    const assetsDetails = await getAssetsByIds(assetIdsList);
    const assetsStatuses = [...new Set(assetsDetails?.map((row) => row.status))];

    const allAssetsActive =
      assetsStatuses?.length > 0 &&
      assetsStatuses?.every((status) => status === "Active");
    setAssetStsActive(allAssetsActive);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: async (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      handleMakeAvailable(selectedRows);
      setMultipleRowsSelected(selectedRowKeys?.length > 1);

      const userRoles = ["Project Developer", "Proba Operator", "Validator", "Verifier"];
      const userHasRole = (roles) => roles.some(role => usergroup.includes(role));
      
      // Define role-based validation conditions
      const roleValidations = {
          "Project Developer": {
              validStatuses: ["New", "Revision", "Pending Realization", "Realized"],
              warningMessage: 'Please select yields with the same status of "New" or "Revision" or "Pending Realization" or "Realized" for actions.'
          },
          "Proba Operator": {
              validStatuses: ["Proba Review"],
              warningMessage: 'Please select yields with the same status of "Proba Review" for actions.',
              additionalCheck: async () => {
                  const {
                      data: yieldTransactions,
                      isLoading: isYieldTransactionLoading,
                      refetch: refetchYieldsTransactio,
                  } = await store.dispatch(
                      transactionApi.endpoints.getYieldTransactions.initiate({
                          yieldIds: selectedRowKeys,
                      })
                  );
      
                  console.log("yieldTransactions", yieldTransactions);
      
                  const immediatePreviousItems = [];
                  for (const [key, value] of Object.entries(yieldTransactions.data)) {
                      for (let i = 1; i < value.length; i++) {
                          if (i === value.length - 1) {
                              immediatePreviousItems.push(value[i - 1]);
                          }
                      }
                  }
      
                  console.log("immediatePreviousItems", immediatePreviousItems);
                  console.log(immediatePreviousItems.length, selectedRows.length);
      
                  const allPreviousItemsPendingRealization =
                      immediatePreviousItems?.length > 0
                          ? immediatePreviousItems.every(
                              (item) => item === "Pending Realization"
                          )
                          : false;
      
                  const immediatePreviousItemsSet = new Set(immediatePreviousItems);
                  const hasPendingRealization = immediatePreviousItemsSet.has(
                      "Pending Realization"
                  );
                  const hasNewOrRevision =
                      immediatePreviousItemsSet.has("New") ||
                      immediatePreviousItemsSet.has("Revision");
      
                  if (hasPendingRealization && hasNewOrRevision) {
                      message.warning(
                          "Please ensure the selected yields are not available for certain actions."
                      );
                      setIsPerformOperationalCheckBtnDisabled(true);
                  } else if (hasNewOrRevision) {
                      setIsPerformOperationalCheckBtnDisabled(false);
                      setForFirstProbaReview(true);
                  } else if (allPreviousItemsPendingRealization) {
                      setForFirstProbaReview(false);
                      setIsPerformOperationalCheckBtnDisabled(false);
                  }
              }
          },
          "Validator": {
              validStatuses: ["Validator Review"],
              warningMessage: 'Please select yields with the same status of "Validator Review" for actions.'
          },
          "Verifier": {
              validStatuses: ["Verifier Review"],
              warningMessage: 'Please select yields with the same status of "Verifier Review" for actions.'
          }
      };
      
      
      // Collect all valid statuses for the user's roles
      let combinedValidStatuses = [];
      let combinedWarningMessages = [];
      
      for (const role of userRoles) {
          if (userHasRole([role])) {
              combinedValidStatuses = combinedValidStatuses.concat(roleValidations[role].validStatuses);
              combinedWarningMessages.push(roleValidations[role].warningMessage);
          }
      }
      
      if (selectedRowKeys.length === 0) {
          message.destroy();
          return;
      }
      
      const selectedStatuses = new Set(selectedRows.map((row) => row.status));
      const isValidSelection = selectedStatuses.size === 1 && combinedValidStatuses.includes(selectedRows[0].status);
      
      if (!isValidSelection) {
          message.warning(`Please select yields with the same status of ${combinedValidStatuses.join(', ')} for actions.`);
          return;
      }
      
      // Perform additional checks for roles that have them
      for (const role of userRoles) {
          if (userHasRole([role]) && roleValidations[role].additionalCheck) {
              await roleValidations[role].additionalCheck();
          }
      }
      
      message.destroy();
      

      const allRowKeys = interventionYieldData?.data?.map((row) => row._id);
      setAllRowsSelected(
        selectedRowKeys.length === allRowKeys?.length &&
          allRowKeys?.every((key) => selectedRowKeys?.includes(key))
      );
      handleSelectedRows(selectedRows);
    },
  };

  const updateDocument = (document) => {
    handleAddInterventionDocument(document);
  };

  const handleAddInterventionDocument = async (record) => {
    try {
      //get unique list of assets from yields
      const uniqueAsset = {};
      const uniqueAssetsList = selectedRowsUpdated.filter((yieldRecord) => {
        if (!uniqueAsset[yieldRecord?.asset_id]) {
          uniqueAsset[yieldRecord?.asset_id] = true;
          return true;
        }
        return false;
      });

      for (let i = 0; i < uniqueAssetsList.length; i++) {
        const body = {
          assetid: uniqueAssetsList[i]?.asset_id,
          requestbody: {
            document_id: record?.document_id,
            asset_id: uniqueAssetsList[i]?.asset_id,
          },
        };
        await addAssetDocument(body)
          .unwrap()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch {}
  };

  const postUpdateCreateEntitilementRecords = (yieldstatusupdate) => {
    setIsCreateCertificateModalVisible(false);
  };

  const handleDispatchSubmitForProbaReview = (yieldUpdateResponse) => {
    refetchYields();
    setSelectedRowKeys([]);
  };

  const handleDispatchPerformOperationalCheck = (yieldUpdateResponse) => {
    refetchYields();
    setSelectedRowKeys([]);
    setIsPerformOperationalCheckOnYield(false);
  };

  const handleYieldEditRecord = async (record) => {
    try {
      const payload = {
        body: record,
      };
      console.log("payload", payload);
      const res = await updateYieldStatus(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Yield updated successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          handleDispatchSubmitForProbaReview(res);
          setOpenProbaReviewModal(false);
          return res;
        })
        .catch((err) => {
          console.log("err", err);
          message.error({
            content: "Failed to update yield: " + Object.values(err["data"])[0],
            key,
            duration: 6,
          });
          console.log(err);
        });
      return res;
    } catch {}
  };

  const closeSubmitForProbaReview = () => {
    setOpenProbaReviewModal(false);
    // setIntervStsProbaReview(false)
  };

  const closeSubmitForPendingRealization = () => {
    setOpenPendingRealizationModal(false);
    // setIntervStsProbaReview(false)
  };

  const closeSubmitForVerification = () => {
    setOpenVerificationModal(false);
    // setIntervStsProbaReview(false)
  };

  const closePerformOperationalCheck = () => {
    setOpenPerformOperationalCheckModal(false);
    setIsPerformOperationalCheckOnYield(false);
  };

  const handleSubmitForProbaReview = () => {
    setOpenProbaReviewModal(true);
  };

  const handleSubmitForPendingRealization = () => {
    setOpenPendingRealizationModal(true);
  };

  const handlePerfomOperationalCheck = () => {
    setOpenPerformOperationalCheckModal(true);
    setIsPerformOperationalCheckOnYield(true);
  };

  const closeValidatorReview = () => {
    setOpenValidatorReviewModal(false);
  };

  const handleValidatorReview = () => {
    setOpenValidatorReviewModal(true);
  };

  const closeVerifierReview = () => {
    setOpenVerifierReviewModal(false);
  };

  const handleVerifierReview = () => {
    setOpenVerifierReviewModal(true);
  };

  const handleSubmitForVerification = () => {
    setOpenVerificationModal(true);
  };

  const totalPreCredits = intervention?.pre_credits || 0;
  const sponsorPreCreditsAvailability =
    intervention?.sponsor_pre_credits_flag || false;

  const handleOkOfProbaReviewModal = async () => {
    try {
      setSpinning(true);
      let yieldUpdateResponse;

      let yieldStatusUpdate = {};
      // yieldStatusUpdate["_ids"] = selectedRowKeys;
      // yieldStatusUpdate["status"] = "Proba Review";

      if (!usergroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      }
      let yiedlsDataListBlockchain = [];
      let yieldsList = [];
      for (let i = 0; i < selectedRowsUpdated?.length; i++) {
        yieldStatusUpdate = { ...selectedRowsUpdated[i] };
        if (yieldStatusUpdate?.status === "New") {
          yieldStatusUpdate["status"] = "Proba Review";
          yiedlsDataListBlockchain.push(yieldStatusUpdate);
        } else {
          yieldStatusUpdate["status"] = "Proba Review";
          yieldsList.push(yieldStatusUpdate);
        }
      }
      if (yiedlsDataListBlockchain?.length > 0) {
        const payloadYields = {
          yields: yiedlsDataListBlockchain,
        };

        const resultYields = await createYieldsActionInBC(payloadYields);
        if (!resultYields) {
          return;
        }
      }
      if (yieldsList.length > 0) {
        yieldUpdateResponse = await updateYieldBatch(yieldsList);
        generateMessageFromStatus({
          status: "success",
          message:
            "Yield successfully submitted to the blockchain for processing",
        });
      }
    } catch (error) {
      console.error(
        "Error updating yield or calling blockchain function:",
        error
      );
    } finally {
      setConfirmLoadingProbaReviewModal(false);
      setOpenProbaReviewModal(false);
      setSpinning(false);
      setSelectedRowKeys([]);
    }
  };

  const handleOkOfSubmitForVerificationModal = async () => {
    try {
      let yieldUpdateResponse;
      const yieldStatusUpdate = {};
      yieldStatusUpdate["_id"] = selectedRowKeys[0]; //fr only one yield is selectd
      yieldStatusUpdate["status"] = "Verifier Review";
      console.log("yieldStatusUpdate", selectedRowKeys);

      if (!usergroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
      }
    } catch (error) {
      console.error("Error updating yield:", error);
    } finally {
      setConfirmLoadingSubmitForVerificationModal(false);
      setOpenVerificationModal(false);
      setSelectedRowKeys([]);
    }
  };

  const handleOkOfValidatorModal = async () => {
    try {
      setSpinning(true);
      let yieldUpdateResponse;
      const yieldStatusUpdate = {};
      yieldStatusUpdate["_ids"] = selectedRowKeys;
      yieldStatusUpdate["status"] = "Pending Realization";
      console.log("yieldStatusUpdate", selectedRowKeys);

      if (!usergroup.includes("Validator")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const result = await validateYieldsActionInBC({
          yields: selectedRowsUpdated,
          dochashes: uplodedDocumentHashes,
        });
        if (!result) {
          return;
        }
        yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
      }
    } catch (error) {
      console.error("Error updating yield:", error);
    } finally {
      setConfirmLoadingValidatorModal(false);
      setOpenValidatorReviewModal(false);
      setSpinning(false);
      setSelectedRowKeys([]);
    }
  };

  const handleMakeYieldRevision = async () => {
    try {
      let yieldUpdateResponse;
      const yieldStatusUpdate = {};
      yieldStatusUpdate["_ids"] = selectedRowKeys;
      yieldStatusUpdate["status"] = "Revision";
      if (
        !usergroup.some((role) =>
          ["Verifier", "Validator", "Project Developer"].includes(role)
        )
      ) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
      }
    } catch (error) {
      console.error("Error updating yield:", error);
    } finally {
      setConfirmLoadingVerifierModal(false);
      setConfirmLoadingValidatorModal(false);
      setOpenValidatorReviewModal(false);
      setOpenVerifierReviewModal(false);
      setSelectedRowKeys([]);
    }
  };

  const handleOkOfVerifierModal = async () => {
    try {
      setSpinning(true);
      let yieldUpdateResponse;
      const yieldStatusUpdate = {};
      yieldStatusUpdate["_ids"] = selectedRowKeys;
      yieldStatusUpdate["status"] = "Realized";

      if (!usergroup.includes("Verifier")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const result = await verifyYieldsActionInBC({
          yields: selectedRowsUpdated,
          dochashes: uplodedDocumentHashes,
        });
        if (!result) {
          return;
        }
        yieldUpdateResponse = await handleYieldEditRecord(yieldStatusUpdate);
      }
    } catch (error) {
      console.error("Error updating yield:", error);
    } finally {
      setConfirmLoadingVerifierModal(false);
      setOpenVerifierReviewModal(false);
      setSpinning(false);
      setSelectedRowKeys([]);
    }
  };

  const handleOkOfPreEntitlementsRecordModal = async (flag) => {
    try {
      if (!usergroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      } else {
        const inputYields = selectedRowsUpdated;
        const entitlementRecordDetails = [];
        inputYields.forEach((yieldData) => {
          const matchedPreEntitlement = pre_entitlement_data?.data?.filter(
            (preEntitlement) => preEntitlement?.yield_id === yieldData?._id
          );
          if (matchedPreEntitlement?.length > 0) {
            entitlementRecordDetails.push(...matchedPreEntitlement);
          }
        });
        createEntitlementRecordsUtil(
          inputYields,
          entitlementRecordDetails,
          projectSponsorOrgId,
          flag,
          totalPreCredits,
          sponsorPreCreditsAvailability,
          setSpinning,
          createEntitlementRecordsActionInBC,
          updateYieldBatch,
          getPreEntitlementRecords,
          postUpdateCreateEntitilementRecords,
          userid,
          refetchYields
        );
      }
    } catch (error) {
      console.error("Error updating pre credits records:", error);
    } finally {
      setIsCreatePreEntitlementRecordsModalVisible(false);
      setIsCreateEntitlementRecordsModalVisible(false);
      setSelectedRowKeys([]);
    }
  };

  // BC-client  cancel pre credits
  const {
    onAccept: onAcceptcreateCancelPreCreditsAction,
  } = useTransactionHandlers({
    actionname: createCancelPreCreditsAction.name,
  });
  const createCancelPreCreditsActionnInBC = async (payload) => {
    return await onAcceptcreateCancelPreCreditsAction(payload);
  };

  // BC-client  Accept cancel pre credits

  const {
    onAccept: onAcceptacceptCancelOfPreCreditsAction,
  } = useTransactionHandlers({
    actionname: acceptCancelOfPreCreditsAction.name,
  });
  const acceptCancelOfPreCreditsActionnInBC = async (payload) => {
    return await onAcceptacceptCancelOfPreCreditsAction(payload);
  };

  //cancel pre-credits

  const handleOkOfCancelPreCreditsModal = async () => {
    try {
      if (!usergroup.includes("Project Developer")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      }
      setSpinning(true);
      const entitlementRecordDetails = [];
      selectedRowsUpdated.forEach((yieldData) => {
        const matchedPreEntitlement = pre_entitlement_data?.data?.filter(
          (preEntitlement) => preEntitlement?.yield_id === yieldData?._id
        );
        if (matchedPreEntitlement?.length > 0) {
          matchedPreEntitlement?.forEach((preEntitlement) => {
            if (preEntitlement?.owner_of_entitlement_records !== userid) {
              const updatedPreEntitlement = {
                ...preEntitlement,
                status: "pendingapproval",
              };
              const combinedData = {
                ...updatedPreEntitlement,
                yield_details: yieldData,
              };
              entitlementRecordDetails.push(combinedData);
            }
          });
        }
      });

      const result = await createCancelPreCreditsActionnInBC(
        entitlementRecordDetails
      );
      if (!result) {
        return;
      }
    } catch (error) {
      console.error("Error updating intervention:", error);
    } finally {
      setSpinning(false);
      setIsCancelPreCreditsModalVisible(false);
    }
  };

  const handleOkOfAcceptPreCreditsCancelModal = async () => {
    try {
      if (!usergroup.includes("Project Sponsor")) {
        message.error({
          content: "Action Prohibited",
          key,
          duration: 6,
        });
      }

      setSpinning(true);
      const entitlementRecordDetails = [];
      selectedRowsUpdated.forEach((yieldData) => {
        const matchedPreEntitlement = pre_entitlement_data?.data?.filter(
          (preEntitlement) => preEntitlement?.yield_id === yieldData?._id
        );
        if (matchedPreEntitlement?.length > 0) {
          matchedPreEntitlement.forEach((preEntitlement) => {
            if (preEntitlement?.owner_of_entitlement_records == userid) {
              const updatedPreEntitlement = {
                ...preEntitlement,
                status: "realized",
              };
              const combinedData = {
                ...updatedPreEntitlement,
                yield_details: yieldData,
              };
              entitlementRecordDetails.push(combinedData);
            }
          });
        }
      });

      const result = await acceptCancelOfPreCreditsActionnInBC(
        entitlementRecordDetails
      );
      if (!result) {
        message.error({
          content: "Bc action failed",
          key,
          duration: 4,
        });
        return;
      }
    } catch (error) {
      console.error("Error updating intervention:", error);
    } finally {
      setSpinning(false);
      setIsAcceptCancelRequestPreCreditsModalVisible(false);
    }
  };
  
  const menu = (
    <Menu>

      {usergroup.includes("Project Developer") && intervention?.organization === orgId && (
        <>
          <Menu.Item key="submitForProbaReview">
            <Button
              type="link"
              onClick={handleSubmitForProbaReview}
              disabled={
                !isAssetStsActive ||
                !isIntervStsActive ||
                selectedRowKeys?.length === 0 ||
                !selectedRowsUpdated?.every((row) =>
                  ["New", "Revision", "Pending Realization"].includes(
                    row?.status
                  )
                ) ||
                (selectedRowsUpdated?.some(
                  (row) => row?.status === "Pending Realization"
                ) &&
                  !selectedRowsUpdated?.every(
                    (row) => row?.co2_realized && row?.co2_realized !== null
                  ))
              }
            >
              Submit for Proba Review
            </Button>
          </Menu.Item>
        </>
      )}

      {usergroup.includes("Proba Operator") && (
        <>
          <Menu.Item key="PerformOperationalCheck">
            <Button
              type="link"
              onClick={handlePerfomOperationalCheck}
              disabled={
                !isAssetStsActive ||
                !isIntervStsActive ||
                selectedRowKeys?.length === 0 ||
                !selectedRowsUpdated?.every(
                  (row) => row.status === "Proba Review"
                ) ||
                isPerformOperationalCheckBtnDisabled
              }
            >
              Perform Operational Check
            </Button>
          </Menu.Item>
        </>
      )}

      {usergroup.includes("Validator") && (
        <Menu.Item key="YieldPerformValidation">
          <>
            <Button
              type="link"
              // TODO disabled={!isYieldPerformValidationButton}
              disabled={
                !isAssetStsActive || !isIntervStsActive ||
                !selectedRowsUpdated?.every(row => row?.status === "Validator Review") ||
                selectedRowKeys?.length === 0 ||
                !selectedRowsUpdated?.every(row => 
                  row?.assignment?.some(assignee => assignee?.assignee_type === 'Validator' && assignee?.organization === orgId)
                )
              }
              onClick={handleValidatorReview}
            >
              Perform Validation
            </Button>
          </>
        </Menu.Item>
      )}
      {usergroup.includes("Verifier") && (
        <Menu.Item key="YieldPerformVerification">
          <>
            <Button
              type="link"
              // TODO disabled={!isYieldPerformVerificationButton}
              disabled={
                !isAssetStsActive || !isIntervStsActive ||
                !selectedRowsUpdated?.every(row => row?.status === "Verifier Review") ||
                selectedRowKeys?.length === 0  ||
                !selectedRowsUpdated?.every(row => 
                  row?.assignment?.some(assignee => assignee?.assignee_type === 'Verifier' && assignee?.organization === orgId)
                )
              }
              onClick={handleVerifierReview}
            >
              Perform Verification
            </Button>
          </>
        </Menu.Item>
      )}
      {!usergroup.includes("Verifier") &&
        !usergroup.includes("Validator") &&
        usergroup.includes("Project Developer") && intervention?.organization === orgId && (
          <Menu.Item key=" CreatePreEntitlementRecords">
            <>
              <Button
                type="link"
                disabled={
                  !isAssetStsActive ||
                  !isIntervStsActive ||
                  !isIntervPreCreditsAvailable ||
                  selectedRowKeys?.length === 0 ||
                  (intervention?.sponsor_pre_credits_flag &&
                    !intervention?.assignment?.some(
                      (assignee) => assignee?.assignee_type === "Project Sponsor"
                    )) ||
                  !selectedRowsUpdated?.every(
                    (row) => row?.status === "Pending Realization"
                  ) ||
                  selectedRowKeys?.some((rowKey) => {
                    const record = interventionYieldData?.data.find(
                      (item) => item?._id === rowKey
                    );
                    return record && hasPreEntitlementRecords(record);
                  }) ||
                  (selectedRowKeys?.length > 0 &&
                    !selectedRowKeys?.some((rowKey) => {
                      const record = interventionYieldData?.data?.find(
                        (item) => item?._id === rowKey
                      );
                      return record && !hasPreEntitlementRecords(record);
                    })) ||
                    selectedRowKeys.some((rowKey) => {
                        const record = interventionYieldData?.data.find(
                            (item) => item._id === rowKey
                        );
                        return record && record.co2_realized
                        != null && record.co2_realized > 0;
                    })
                }
                onClick={async () => {
                  setIsCreatePreEntitlementRecordsModalVisible(true);
                  resetButtonStates(); // Reset button states
                }}
              >
                Create Pre-Credits
              </Button>
            </>
          </Menu.Item>
        )}
      {!usergroup.includes("Verifier") &&
        !usergroup.includes("Validator") &&
        usergroup.includes("Project Developer") && intervention?.organization === orgId && (
        <Menu.Item key="CancelPrecredits">
          <>
            <Button
              type="link"
              disabled={selectedRowKeys.length === 0  || 
                !intervention?.sponsor_pre_credits_flag ||
                !selectedRowsUpdated.every(row => row?.status === "Realized") ||
                !selectedRowsUpdated.every(row => {
                const preCreditsPercentage = row?.co2_estimated * (intervention?.pre_credits / 100);
                return row?.co2_realized < preCreditsPercentage;
              })}
              onClick={async () => {
                setIsCancelPreCreditsModalVisible(true);
                resetButtonStates();
              }}
            >
              Cancel Pre-credits
            </Button>
          </>
        </Menu.Item>
      )}

      {!usergroup.includes("Verifier") &&
        !usergroup.includes("Validator") &&
        usergroup.includes("Project Sponsor") && (
          <Menu.Item key=" acceptcancelrequest">
            <>
              <Button
                type="link"
                disabled={
                  selectedRowKeys?.length === 0 ||
                  !intervention?.sponsor_pre_credits_flag ||
                  !selectedRowsUpdated?.every(
                    (row) => row?.status === "Realized"
                  ) ||
                  selectedRowKeys?.some((rowKey) => {
                    const matchingData = pre_entitlement_data?.data?.filter(
                      (item) => item?.yield_id === rowKey
                    );
                    return (
                      matchingData &&
                      !matchingData?.some(
                        (item) => item?.status === "pendingapproval"
                      )
                    );
                  })
                }
                onClick={async () => {
                  setIsAcceptCancelRequestPreCreditsModalVisible(true);
                  resetButtonStates(); // Reset button states
                }}
              >
                Accept cancel request
              </Button>
            </>
          </Menu.Item>
        )}

      {!usergroup.includes("Verifier") &&
        !usergroup.includes("Validator") &&
        (usergroup.includes("Project Developer") ||  usergroup.includes("Project Sponsor")) && intervention?.organization === orgId &&(
          <Menu.Item key=" CreateEntitlementRecords">
            <>
              <Button
                type="link"
                disabled={
                  !isAssetStsActive ||
                  !isIntervStsActive ||
                  !selectedRowsUpdated?.every(
                    (row) => row?.status === "Realized"
                  ) ||
                  selectedRowKeys?.length === 0 ||
                  selectedRowsUpdated?.some((row) => {
                    const matchingData = pre_entitlement_data?.data?.filter(
                      (item) => item?.yield_id === row._id
                    );
                    if (matchingData?.length === 0) {
                      return false; 
                  } else {
                      return matchingData?.some(item => item?.organization_id === orgId && 
                        (item?.status === 'realized' || item?.status === 'inactive'));
                  }
                  })
                }
                onClick={async () => {
                  setIsCreateEntitlementRecordsModalVisible(true);
                  resetButtonStates(); // Reset button states
                }}
              >
                Create Entitlement Records
              </Button>
            </>
          </Menu.Item>
        )}
    </Menu>
  );

  return (
    <Spin tip={spinningDesc} spinning={isSpinning} size="large">
      {!isIntervAssignmentDetailsLoading && (
        <div style={{ height: "80vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Title level={4}>Yield Details</Typography.Title>
            <div>
              {
                <>
                  <Divider type="vertical"></Divider>
                  <Dropdown overlay={menu} trigger={["hover"]}>
                    <Button type="primary">
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </>
              }
              <Divider type="vertical"></Divider>
              <Button
                type="primary"
                onClick={() => {
                  viewInterventionRouteChange();
                  //refetch the table
                  dispatch(interventionApi.util.resetApiState());
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <Card className="shadow-card" style={{ borderRadius: 8 }}>
            <Table
              size="small"
              columns={columns}
              dataSource={interventionYieldData?.data}
              rowKey={(record) => record?._id}
              rowSelection={rowSelection}
              onChange={handleChange}
              pagination={{
                pageSizeOptions: ["10", "50", "100"],
                showSizeChanger: true,
              }}
            ></Table>
          </Card>
        </div>
      )}

      <PerformOperationaCheckOnYield
        yieldData={selectedRowsUpdated}
        yieldIds={selectedRowKeys}
        assetDetails={asset}
        usergroup={usergroup}
        isCreate={isPerformOperationalCheckOnYield}
        isForFirstProbaReview={isForFirstProbaReview}
        closeModal={closePerformOperationalCheck}
        handleDispatchPerformOperationalCheck={
          handleDispatchPerformOperationalCheck
        }
      />

      <Modal
        title="Confirmation"
        visible={isCreatePreEntitlementRecordsModalVisible}
        onCancel={() => setIsCreatePreEntitlementRecordsModalVisible(false)}
        onOk={() => handleOkOfPreEntitlementsRecordModal("preentitlement")}
      >
        <p>Are you sure you want to create Pre-Credits ?</p>
      </Modal>

      <Modal
        title="Confirmation"
        visible={isCreateEntitlementRecordsModalVisible}
        onCancel={() => setIsCreateEntitlementRecordsModalVisible(false)}
        onOk={() => handleOkOfPreEntitlementsRecordModal("entitlement")}
      >
        <p>Are you sure you want to create Entitlement Records ?</p>
      </Modal>

      <Modal
        title="Confirmation"
        visible={isCancelPreCreditsModalVisible}
        onCancel={() => setIsCancelPreCreditsModalVisible(false)}
        onOk={() => handleOkOfCancelPreCreditsModal()}
      >
        <p>Are you sure you want to cancel Pre-Credits ?</p>
      </Modal>

      <Modal
        title="Confirmation"
        visible={isAcceptCancelRequestPreCreditsModalVisible}
        onCancel={() => setIsAcceptCancelRequestPreCreditsModalVisible(false)}
        onOk={() => handleOkOfAcceptPreCreditsCancelModal()}
      >
        <p>Are you sure you want to accept the cancel Pre-Credits request ?</p>
      </Modal>

      <Modal
        title="Submit for Proba Review"
        open={openProbaReviewModal}
        onOk={handleOkOfProbaReviewModal}
        okText="Submit for Proba Review"
        confirmLoading={confirmLoadingProbaReviewModal || isSpinning}
        onCancel={() => {
          if (!(confirmLoadingProbaReviewModal || isSpinning))
            closeSubmitForProbaReview();
        }}
      >
        <p>Are you sure you want to submit selected yields for Proba Review?</p>
      </Modal>
      <Modal
        title="Submit for Pending Realization"
        open={openValidatorReviewModal}
        okText="Submit for Pending Realization"
        confirmLoading={confirmLoadingValidatorModal}
        onCancel={closeValidatorReview}
        footer={[
          <Button onClick={handleMakeYieldRevision} style={{ color: "red" }}>
            Revise
          </Button>,
          <Button
            type="primary"
            onClick={handleOkOfValidatorModal}
            loading={confirmLoadingValidatorModal}
          >
            Submit for Pending Realization
          </Button>,
        ]}
      >
        <p>Upload the Validation Document</p>
        <Button
          type="primary"
          onClick={() => {
            setIsCreateDocument(true);
          }}
        >
          Upload Documents
        </Button>
        <br />
        {uplodedDocumentList?.length > 0 && (
          <List
            dataSource={uplodedDocumentList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.docname}</Typography.Text>
                <Typography.Text>{item.docdname}</Typography.Text>
              </List.Item>
            )}
          />
        )}
        <br />
        <p>
          Are you sure you want to submit selected yields for pending
          realization?
        </p>
      </Modal>

      <Modal
        title="Complete the Verification"
        open={openVerifierReviewModal}
        okText="Complete the Verification"
        confirmLoading={confirmLoadingVerifierModal}
        onCancel={closeVerifierReview}
        footer={[
          <Button onClick={handleMakeYieldRevision} style={{ color: "red" }}>
            Revise
          </Button>,
          <Button
            type="primary"
            onClick={handleOkOfVerifierModal}
            loading={confirmLoadingVerifierModal}
          >
            Complete the Verification
          </Button>,
        ]}
      >
        <p>Upload the Verification Document</p>
        <Button
          type="primary"
          onClick={() => {
            setIsCreateDocument(true);
          }}
        >
          Upload Documents
        </Button>
        <br />
        {uplodedDocumentList?.length > 0 && (
          <List
            dataSource={uplodedDocumentList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item?.docname}</Typography.Text>
                <Typography.Text>{item?.docdname}</Typography.Text>
              </List.Item>
            )}
          />
        )}
        <br />
        <p>
          Are you sure you want to complete the verification of the selected
          yields?
        </p>
      </Modal>

      <AddDocument
        ids={selectedRowKeys}
        entity="asset"
        updateDocument={displayDocument}
        isCreate={isCreateDocument}
        closeModal={closeDocumentModal}
      />

      <Modal
        title="Submit for Verification"
        open={openSubmitForVerificationModal}
        onOk={handleOkOfSubmitForVerificationModal}
        okText="Submit for Verification"
        confirmLoading={confirmLoadingSubmitForVerificationModal}
        onCancel={closeSubmitForVerification}
        footer={[
          <Button
            key="cancel"
            onClick={handleMakeYieldRevision}
            style={{ color: "red" }}
          >
            Revise
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOkOfSubmitForVerificationModal}
          >
            Submit for Verification
          </Button>,
        ]}
      >
        <p>Are you sure you want to submit yield for Verification?</p>
      </Modal>

      {isYieldEditing && (
        <HandleYieldButtons
          isYieldEdit={isYieldEditing}
          selectedYieldRecord={selectedRowsUpdated}
          closeModal={closeModal}
          refetchYields={refetchYields}
        />
      )}
    </Spin>
  );
}
export default ViewYields;