import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/auth/group/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Groups"],
  endpoints: (build) => ({
    getGroups: build.query({
      query: () => "",
      providesTags: ["Groups"],
    }),
    getGroup: build.query({
      query: (id) => `${id}`,
      providesTags: ["Groups"],
    }),
    getGroupByName: build.query({
      query: (names) =>{
        if (Array.isArray(names)) {
          return `?name=${names.join(",")}`;
        } else {
          return `?name=${names}`;
        }},
      providesTags: ["Groups"],
    }),
    addGroup: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Groups"],
    }),
    updateGroups: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Groups"],
    }),
    deleteGroup: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Groups"],
    }),
  }),
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useGetGroupByNameQuery,
  useAddGroupMutation,
  useUpdateGroupsMutation,
  useDeleteGroupMutation,
} = groupApi;
