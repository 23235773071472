import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const finaltokApi = createApi({
    reducerPath: "finaltokApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/is/final-token",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["FinalToken"],
    endpoints: (build) => ({
        getFinalToken: build.query({
            query: (id) => `${id}`,
            providesTags: ["FinalToken"],
        }),
        getFinalTokens: build.query({
            query: () => "",
            providesTags: ["FinalToken"],
        }),
        getFinalTokenByParam: build.query({
            query: (params) => {
                return {
                    url: "",
                    params: params,
                };
            },
            providesTags: ["FinalToken"],
        }),
        addFinalToken: build.mutation({
            query(body) {
                return {
                    url: ``,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["FinalToken"],
        }),
        finalTokenTransferCalculation: build.mutation({
            query(body) {
                return {
                    url: `/transfercalculation`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["FinalToken"]
        }),
        updateFinalToken: build.mutation({
            query(payload) {
                return {
                    url: `${payload.id}`,
                    method: "PUT",
                    body: payload.body,
                };
            },
            invalidatesTags: ["FinalToken"],
        }),
        updateFinalTokenRecords: build.mutation({
            query(payload) {
                return {
                    url: `/bulkupdate`,
                    method: "PUT",
                    body: payload,
                };
            },
            invalidatesTags: ["FinalToken"],
        }),
        deleteFinalToken: build.mutation({
            query(id) {
                return {
                    url: `${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["FinalToken"],
        }),
    }),
});

export const {
    useGetFinalTokenQuery,
    useGetFinalTokensQuery,
    useGetFinalTokenByParamQuery,
    useAddFinalTokenMutation,
    useFinalTokenTransferCalculationMutation,
    useUpdateFinalTokenMutation,
    useUpdateFinalTokenRecordsMutation,
    useDeleteFinalTokenMutation,
} = finaltokApi;