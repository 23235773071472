import { ifaceProba } from "./../bc-client/BCUtil";
const createGHGProjectAction = {
  name: "createGHGProject",
  bcid: () => ifaceProba.getSighash("createGHGProject"),
};
const createAssetsAction = {
  name: "createAssets",
  bcid: () => ifaceProba.getSighash("createAssets"),
};
const createYieldsAction = {
  name: "createYields",
  bcid: () => ifaceProba.getSighash("createYields"),
};
const validateGHGProjectAction = {
  name: "validateGHGProject",
  bcid: () => ifaceProba.getSighash("validateGHGProject"),
};
const validateAssetsAction = {
  name: "validateAssets",
  bcid: () => ifaceProba.getSighash("validateAssets"),
};
const verifyYieldsAction = {
  name: "verifyYields",
  bcid: () => ifaceProba.getSighash("verifyYields"),
};
const validateYieldsAction = {
  name: "validateYields",
  bcid: () => ifaceProba.getSighash("validateYields"),
};
const createEntitlementRecordsAction = {
  name: "createEntitlementRecords",
  bcid: () => ifaceProba.getSighash("createEntitlementRecords"),
};
const createTradableCertificateAction = {
  name: "createTradableCertificate",
  bcid: () => ifaceProba.getSighash("createTradableCertificate"),
};
const createAllocateAction = {
  name: "createAllocate",
  bcid: () => ifaceProba.getSighash("createAllocate"),
};
const createClaimAction = {
  name: "createClaim",
  bcid: () => ifaceProba.getSighash("createClaim"),
};
const createCancelPreCreditsAction = {
  name: "createCancelPreCredits",
  bcid: () => ifaceProba.getSighash("createCancelPreCredits"),
};

const acceptCancelOfPreCreditsAction = {
  name: "acceptCancelOfPreCredits",
  bcid: () => ifaceProba.getSighash("acceptCancelOfPreCredits"),
};
const grantProbaRoleAction = {
  name: "grantProbaRole",
  bcid: () => ifaceProba.getSighash("grantProbaRole"),
};
const revokeProbaRoleAction = {
  name: "revokeProbaRole",
  bcid: () => ifaceProba.getSighash("revokeProbaRole"),
};
const burnAction = {
  name: "burn",
  bcid: () => ifaceProba.getSighash("burn"),
};

export {
  createGHGProjectAction,
  createAssetsAction,
  createYieldsAction,
  validateGHGProjectAction,
  validateAssetsAction,
  validateYieldsAction,
  verifyYieldsAction,
  createEntitlementRecordsAction,
  createTradableCertificateAction,
  createAllocateAction,
  createClaimAction,
  createCancelPreCreditsAction,
  acceptCancelOfPreCreditsAction,
  grantProbaRoleAction,
  revokeProbaRoleAction,
  burnAction
};
