import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Form, Input, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../store/auth/authapi";
import { passwordRegexp } from "../Utils/inputValidationUtils";

function ResetPassword() {

  let { token } = useParams();


  const [form] = Form.useForm();
  const history = useHistory();
  const [resetPassword, { isError, isSuccess, isLoading }] = useResetPasswordMutation();

  const onResetPassword = async ({ password }) => {
    try {
      const res = await resetPassword({ password, token }).unwrap();
      console.log({ res });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url('/login-bg.png')",
        backgroundSize: "cover",
        minHeight: "100%",
        minWidth: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <div style={{ width: 500 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "12px 24px", gap: 16 }}
          >
            {/* <div style={{ marginBottom: 40 }}>
              <img src="/hydrotwin-logo.svg" alt="logo" style={{ width: 250 }} />
            </div> */}
            {isError ? (
              <Alert
                description="Invalid link"
                type="warning"
                showIcon
                style={{ marginBottom: "20px", width: "100%" }}
              />
            ) : isSuccess ? (
              <Alert
                description="Password reset"
                type="success"
                showIcon
                style={{ marginBottom: "20px", width: "100%" }}
              />
            ) : (
              <>
                <div style={{ alignSelf: "flex-start" }}>
                  <Typography.Title level={3} style={{ fontWeight: 600 }}>
                    New Password
                  </Typography.Title>
                  <Typography.Paragraph style={{ color: "gray" }}>Enter your new password</Typography.Paragraph>
                </div>

                <Form
                  form={form}
                  name="login"
                  scrollToFirstError
                  style={{ width: "100%", marginBottom: -28 }}
                  data-testid="login"
                  onFinish={onResetPassword}
                >
                  <p>Password</p>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password.",
                      },
                      {
                        pattern: passwordRegexp,
                        message:
                          "Please make sure your password has a lowercase letter, an uppercase letter, a number and a special character.",
                      },
                    ]}
                    style={{ marginTop: -12, marginBottom: 12 }}
                    hasFeedback
                  >
                    <Input.Password name="password" placeholder="Type your password" />
                  </Form.Item>
                  <p>Confirm password</p>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password.",
                        pattern: passwordRegexp,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error("The two passwords that you entered do not match!"));
                        },
                      }),
                    ]}
                    style={{ marginTop: -12 }}
                  >
                    <Input.Password name="confirmPassword" placeholder="Type your password again" />
                  </Form.Item>

                  <Form.Item style={{ marginTop: 48 }}>
                    <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: "100%" }}>
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            <Button
              loading={isLoading}
              size="middle"
              type="link"
              style={{ width: "100%", color: "gray" }}
              onClick={() => history.push("/login")}
            >
              <ArrowLeftOutlined /> Back to Login
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ResetPassword;
