import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const entitlementCreditBundleApi = createApi({
  reducerPath: "entitlementCreditBundleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/entitlement_credit_bundle",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["EntitlementCreditBundle"],
  endpoints: (build) => ({
    getEntitlementCreditBundle: build.query({
      query: (id) => `${id}`,
      providesTags: ["EntitlementCreditBundle"],
    }),
    getEntitlementCreditBundles: build.query({
      query: () => "",
      providesTags: ["EntitlementCreditBundle"],
    }),
    addEntitlementCreditBundle: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["EntitlementCreditBundle"],
    }),
    deleteEntitlementCreditBundle: build.mutation({
      query(id) {
        return {
          url: `/entitlement_record/${id}/entitlement_credit_bundle`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["EntitlementCreditBundle"],
    }),
    getEntitlementCreditBundleDetails: build.mutation({
        query(body) {
          return {
            url: `details`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["EntitlementCreditBundle"],
      }),
      updateEntitlementCreditBundles: build.mutation({
        query(body) {
          return {
            url: ``,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: ["EntitlementCreditBundle"],
      }),
  }),
});

export const {
  useGetEntitlementCreditBundleQuery,
  useGetEntitlementCreditBundlesQuery,
  useAddEntitlementCreditBundleMutation,
  useDeleteEntitlementCreditBundleMutation,
  useGetEntitlementCreditBundleDetailsMutation,
  useUpdateEntitlementCreditBundlesMutation,
} = entitlementCreditBundleApi;
