import { React, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Statistic,
  Table,
  Typography,
  message
} from "antd";
import {
  useAddMethodsMutation,
  useDeleteMethodsMutation,
  useGetMethodsQuery,
  useUpdateMethodsMutation
} from "../../store/is/methodsapi";
import {
  useDeleteParamsMutation
} from "../../store/is/paramsapi";
import Params from "./Params.js";

import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
  PlusOutlined
} from "@ant-design/icons";
import moment from 'moment';
import { DisplayDateTime } from "../Utils/DisplayDateTime";
import { DisplayUserName } from "../Utils/DisplayUserName";
import { ParamView } from "./Utils/ParamView";

function Methods() {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isView, setIsView] = useState(false);

  const [showMethodForm, toggleShowMethodForm] = useState(false);
  const [hideMethodTable, toggleHideMethodTable] = useState(true);
  const [showMethodView, toggleShowMethodView] = useState(false);

  const [isCreateParam, setIsCreateParam] = useState(false);
  const [isEditParam, setIsEditParam] = useState(false);
  const [editParamRecord, setEditParamRecord] = useState(null);
  const [paramId, setParamId] = useState(null);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },

    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (record) => {
        const timestamp = record ? (record["$date"] ? record["$date"] : record) : null;
        return <span>{timestamp ? moment(parseInt(timestamp)).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "updated",
      title: "Updated",
      dataIndex: "updated",
      render: (record) => {
        const timestamp = record ? (record["$date"] ? record["$date"] : record) : null;
        return <span>{timestamp ? moment(parseInt(timestamp)).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>;
      },
    },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <FolderViewOutlined
              onClick={() => {
                setIsView(true);
                setIsEditing(false);
                setIsCreate(false);
                onEditRecord(record);
                setParamId(record?.param_id);
                toggleShowMethodView(!showMethodView);
                toggleHideMethodTable(!hideMethodTable);
              }}
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical"></Divider>
            <EditOutlined
              onClick={() => {
                setIsEditing(true);
                setIsCreate(false);
                onEditRecord(record);
                setParamId(record.param_id);
                toggleShowMethodForm(!showMethodForm);
                toggleHideMethodTable(!hideMethodTable);
              }}
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical"></Divider>
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 10 }}
            />
          </>
        );
      },
    },
  ];
  const {
    data: methods,
    isLoading,
  } = useGetMethodsQuery();
  const [addMethods] = useAddMethodsMutation();
  const [updateMethods] = useUpdateMethodsMutation();
  const [deleteMethods] = useDeleteMethodsMutation();

  const [deleteParam] = useDeleteParamsMutation();

  if (!isLoading) {
    console.log(methods);
  }

  const key = "updatable";

  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const closeModal = () => {
    //setEditParamRecord(null);
    setIsCreateParam(false);
    setIsEditParam(false);
  };

  const updateParam = (param) => {
    console.log("------", param);
    setEditParamRecord(param);
    setParamId(param._id);

    //Update the param id to methods
    if (editingRecord) {
      editingRecord.param_id = param ? param._id : null;
      handleEditRecord(editingRecord);
      setEditingRecord(editingRecord);
    }
  };

  const handleAddRecord = async (record) => {
    try {
      return await addMethods(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Methodology added successfully!",
            key,
            duration: 2,
          });
          console.log(res);
          return res;
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Methodology! " + Object.values(err["data"])[0],
            key,
            duration: 4,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleEditRecord = async (record) => {
    try {
      const payload = {
        id: record._id,
        body: record,
      };
      await updateMethods(payload)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Methodology updated successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            // content: "Failed to update Methodology!:" + err,
            content:
              "Failed to update Methodology!: " + Object.values(err["data"])[0],
            key,
            duration: 4,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteRecord = async (id) => {
    try {
      await deleteMethods(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Methodology deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete Methodology!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteParam = async (id) => {
    try {
      await deleteParam(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Param removed successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to removed Param!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onCreate = async (values) => {
    if (isEditing) {
      //set newly added param
      editingRecord.param_id = editParamRecord ? editParamRecord._id : "";

      editingRecord.name = values.name;
      editingRecord.description = values.description;
      console.log("editingRecord", editingRecord);
      handleEditRecord(editingRecord);
      setEditingRecord(editingRecord);
    } else if (isCreate) {
      values.param_id = editParamRecord ? editParamRecord._id : "";

      const response = await handleAddRecord(values);
      setIsEditing(true);
      setIsCreate(false);
      //TODO - remove later if all changed to _id
      let currEditingRecord = { ...response?.data };
      currEditingRecord.method_id = currEditingRecord._id;
      onEditRecord(currEditingRecord);
      setParamId(response?.data?.param_id);
    }
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        console.log({ ...record });
        console.log({ ...record }._id);
        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2}>Methodology</Typography.Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          {hideMethodTable && (
            <Button
              type="primary"
              onClick={() => {
                setIsEditing(false);
                setIsCreate(true);
                setEditingRecord(null);
                setParamId(null);
                setEditParamRecord(null);
                toggleShowMethodForm(!showMethodForm);
                toggleHideMethodTable(!hideMethodTable);
              }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          )}

          {(showMethodForm || showMethodView) && (
            <Button
              type="primary"
              onClick={() => {
                setIsEditing(false);
                setIsCreate(false);
                setEditingRecord(null);
                toggleShowMethodView(false);
                toggleShowMethodForm(false);
                toggleHideMethodTable(true);
              }}
            >
              Back
            </Button>
          )}

          <Divider type="vertical"></Divider>

          {(showMethodForm || showMethodView) &&
            (editingRecord?.param_id ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsCreateParam(false);
                    setIsEditParam(true);
                    setParamId(editingRecord?.param_id);
                  }}
                >
                  Edit Param
                </Button>
                <Divider type="vertical"></Divider>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsCreateParam(false);
                    setIsEditParam(false);
                    Modal.confirm({
                      title: "Are you sure, you want to remove the Param?",
                      okText: "Yes",
                      okType: "danger",
                      onOk: () => {
                        handleDeleteParam(editingRecord?.param_id);
                        setEditParamRecord(null);
                        setParamId(null);

                        //Update Methodology - this will clear the param_id from the Methodology
                        let recordToUpdate = { ...editingRecord };
                        recordToUpdate.param_id = null;
                        handleEditRecord(recordToUpdate);
                        setEditingRecord(recordToUpdate);
                      },
                    });
                  }}
                >
                  Remove Param
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setIsCreateParam(true);
                  setIsEditParam(false);
                  setEditParamRecord(null);
                  setParamId(null);
                }}
              >
                Add Param
              </Button>
            ))}
        </div>
      </div>

      <div>
        <Row gutter={[10, 10]} align="stretch" style={{ display: "flex" }}>
          <Col span={24}>
            <Card
              className="shadow-card"
              style={{ height: "100%", borderRadius: 8, flexGrow: 1 }}
            >
              {showMethodForm && (
                <>
                  {isEditing || isCreate ? (
                    <MethodsForm
                      visible={isEditing || isCreate}
                      onCreate={onCreate}
                      onCancel={() => {
                        resetEditing();
                      }}
                      editingRecord={editingRecord}
                      editParamRecord={editParamRecord}
                      isCreateParam={isCreateParam}
                      isEditParam={isEditParam}
                      closeModal={closeModal}
                      updateParam={updateParam}
                      paramId={paramId}
                    />
                  ) : null}
                </>
              )}

              {hideMethodTable && (
                <Col>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={methods}
                  ></Table>
                </Col>
              )}

              {showMethodView && (
                <>
                  {isView ? (
                    <MethodView
                      onCreate={onCreate}
                      onCancel={() => {
                        resetEditing();
                      }}
                      editingRecord={editingRecord}
                      editParamRecord={editParamRecord}
                      isCreateParam={isCreateParam}
                      isEditParam={isEditParam}
                      closeModal={closeModal}
                      updateParam={updateParam}
                      paramId={paramId}
                    />
                  ) : null}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const MethodsForm = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
  editParamRecord,
  isCreateParam,
  isEditParam,
  closeModal,
  updateParam,
  paramId,
}) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        layout="vertical"
        name="dynamic_form_nest_item"
        form={form}
        initialValues={editingRecord}
        onFinish={onCreate}
        autoComplete="off"
      >
        <Row gutter={[100, 0]}>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input Methodology name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please input Methodology Description!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[100, 20]}>
          <Col span={24}>
            <Divider></Divider>
            <Typography.Title level={4}>Parameters</Typography.Title>
            <ParamView paramid={paramId}></ParamView>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit Methodology
          </Button>
        </Form.Item>
      </Form>

      <Params
        paramId={paramId}
        updateParam={updateParam}
        editParamRecord={editParamRecord}
        isCreate={isCreateParam}
        isEditing={isEditParam}
        closeModal={closeModal}
      />
    </>
  );
};

const MethodView = ({
  onCreate,
  onCancel,
  editingRecord,
  editParamRecord,
  isCreateParam,
  isEditParam,
  closeModal,
  updateParam,
  paramId,
}) => {
  const format = (value) => (
    <Typography.Text style={{ fontSize: 18 }}>
      {value === true ? "Yes" : value === false ? "No" : value}
    </Typography.Text>
  );

  console.log("Editing Record", editingRecord);
  return (
    <>
      <Row gutter={[100, 20]}>
        <Col span={12}>
          <Statistic
            title="Methodology Name"
            value={editingRecord?.name}
            formatter={(value) => format(value)}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Methodology Description"
            value={editingRecord?.description}
            formatter={(value) => format(value)}
          />
        </Col>
      </Row>

      <Row gutter={[100, 20]}>
        <Col span={24}>
          <Divider></Divider>
          <Typography.Title level={4}>Parameters</Typography.Title>
          <ParamView paramid={paramId}></ParamView>
        </Col>
        <Col span={12}>
          <Statistic
            title="Creator"
            value={editingRecord?.creator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Updator"
            value={editingRecord?.updator}
            formatter={(value) => (
              <DisplayUserName userid={value}></DisplayUserName>
            )}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Created At"
            value={editingRecord?.created}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Updated At"
            value={editingRecord?.updated}
            formatter={(value) => (
              <DisplayDateTime date_time={value}></DisplayDateTime>
            )}
          />
        </Col>
      </Row>

      <Params
        paramId={paramId}
        updateParam={updateParam}
        editParamRecord={editParamRecord}
        isCreate={isCreateParam}
        isEditing={isEditParam}
        closeModal={closeModal}
      />
    </>
  );
};

export default Methods;
