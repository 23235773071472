import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const interventionApi = createApi({
  reducerPath: "interventionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/intervention",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Intervention"],
  endpoints: (build) => ({
    getIntervention: build.query({
      query: (id) => `${id}`,
      providesTags: ["Intervention"],
    }),
    getInterventions: build.query({
      query: () => "",
      providesTags: ["Intervention"],
    }),
    getInterventionDocs: build.query({
      query: (id) => `/${id}/document`,
      providesTags: ["Intervention"],
    }),
    getInterventionEntitlements: build.query({
      query: (params) => {
        return {
          url: "/myentitlements",
          params: params,
        };
      },
      providesTags: ["Intervention"],
    }),
    getInterventionCertificates: build.query({
      query: (params) => {
        return {
          url: "/certificatedetails",
          params: params,
        };
      },
      providesTags: ["Intervention"],
    }),
    addIntervention: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Intervention"],
    }),
    addDocumentToIntervention: build.mutation({
      query(body) {
        return {
          url: `/${body.interventionid}/document`,
          method: "POST",
          body: body.requestbody,
        };
      },
      invalidatesTags: ["Intervention"],
    }),
    updateInterventionDocuments: build.mutation({
      query(body) {
        return {
          url: `/batch/document`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Intervention"],
    }),
    updateIntervention: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Intervention"],
    }),
    updateInterventionStatus: build.mutation({
      query(payload) {
        return {
          url: `/${payload.id}/status`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Intervention"],
    }),
    deleteIntervention: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Intervention"],
    }),
  }),
});

export const {
  useGetInterventionDocsQuery,
  useGetInterventionEntitlementsQuery,
  useGetInterventionCertificatesQuery,
  useAddDocumentToInterventionMutation,
  useUpdateInterventionDocumentsMutation,
  useGetInterventionQuery,
  useGetInterventionsQuery,
  useAddInterventionMutation,
  useUpdateInterventionMutation,
  useUpdateInterventionStatusMutation,
  useDeleteInterventionMutation,
} = interventionApi;
