import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const doctypeApi = createApi({
  reducerPath: "doctypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/ds/doctype",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["DocTypes"],
  endpoints: (build) => ({
    getDocTypes: build.query({
      query: () => "",
      providesTags: ["DocTypes"],
    }),
    getDocType: build.query({
      query: (id) => `${id}`,
      providesTags: ["DocTypes"],
    }),
    addDocType: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["DocTypes"],
    }),
    updateDocType: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["DocTypes"],
    }),
    deleteDocType: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DocTypes"],
    }),
  }),
});

export const {
  useGetDocTypeQuery,
  useGetDocTypesQuery,
  useAddDocTypeMutation,
  useUpdateDocTypeMutation,
  useDeleteDocTypeMutation,
} = doctypeApi;
