import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const orgApi = createApi({
  reducerPath: "orgApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/auth/org/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Orgs"],
  endpoints: (build) => ({
    getOrgs: build.query({
      query: () => "",
      providesTags: ["Orgs"],
    }),
    getOrg: build.query({
      query: (id) => `${id}`,
      providesTags: ["Orgs"],
    }),
    addOrg: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Orgs"],
    }),
    updateOrgs: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Orgs"],
    }),
    deleteOrg: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Orgs"],
    }),
  }),
});

export const {
  useGetOrgQuery,
  useGetOrgsQuery,
  useAddOrgMutation,
  useUpdateOrgsMutation,
  useDeleteOrgMutation,
} = orgApi;
