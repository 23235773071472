import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const methodsApi = createApi({
  reducerPath: "methodsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/is/methods",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Methods"],
  endpoints: (build) => ({
    getMethod: build.query({
      query: (id) => `${id}`,
      providesTags: ["Methods"],
    }),
    getMethods: build.query({
      query: () => "",
      providesTags: ["Methods"],
    }),
    addMethods: build.mutation({
      query(body) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Methods"],
    }),
    updateMethods: build.mutation({
      query(payload) {
        return {
          url: `${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["Methods"],
    }),
    deleteMethods: build.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Methods"],
    }),
  }),
});

export const {
  useGetMethodQuery,
  useGetMethodsQuery,
  useAddMethodsMutation,
  useUpdateMethodsMutation,
  useDeleteMethodsMutation,
} = methodsApi;
