import { React, useEffect, useState } from "react";


import {
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
  Tabs,
  Typography,
  message
} from "antd";

import {
  DeleteOutlined
} from "@ant-design/icons";
import { useGetOrgsQuery } from "../../store/auth/orgapi";
import { useGetUsersQuery } from "../../store/auth/userapi";
import {
  useGetAuthInfoQuery
} from "../../store/ds/documentaccessapi";
import { DisplayOrganization } from "../Utils/DisplayOrganization";
import { DisplayUserName } from "../Utils/DisplayUserName";

const { TabPane } = Tabs;

const DocumentAccess = ({
  dropdownValue,
  isModalOpen,
  documentId,
  closeModal,
  onGrantAccess,
  liftUserAccess,
  liftOrgAccess,
  handleChange,
  documentVisibilityPublic,
}) => {
  const { data: userdata, isLoading, isFetching, isError } = useGetUsersQuery();

  const { Option } = Select;

  useEffect(() => {
    // Update the selected radio when documentVisibilityPublic changes
    setSelectedRadio(documentVisibilityPublic === "PUBLIC" ? "PUBLIC" : "PRIVATE");
  }, [documentVisibilityPublic]);

  const userOptions = [];
  if (!isLoading && userdata && userdata.userList) {
    userdata.userList.forEach((data) => {
      userOptions.push(
        <Option key={data?._id}>
          {data?.fname} {data?.lname}
        </Option>
      );
    });
  }

  const { data: orgdata, isLoading: isorgLoading } = useGetOrgsQuery();

  const orgDataOptions = [];
  if (!isorgLoading && orgdata && orgdata.orgList) {
    orgdata.orgList?.forEach((org) => {
      orgDataOptions.push(<Option key={org._id}>{org.name}</Option>);
    });
  }

  const orgDataList = [];
  const userDataList = [];
  let isViewerDataAvailable = false;

  const {
    data: authinfo = [],
    isLoading: isAuthInfoLoading,
  } = useGetAuthInfoQuery(documentId, { skip: !documentId });

  if (!isAuthInfoLoading && documentId) {
    console.log("AUTH INFO:", authinfo);
    authinfo.forEach((obj) => {
      if(obj?.accessType === "VIEWER" && !isViewerDataAvailable){
        isViewerDataAvailable = true;
      }
      if (obj?.accessLevel === "USER") {
        userDataList.push([obj?.userId, obj?.accessType]);
      } else if (obj?.accessLevel === "ORG") {
        orgDataList.push([obj?.orgId, obj?.accessType]);
      }
    });
  }

  var ownerAccess = false;
  const authorizedUserColumns = [
    {
      key: "authorizedUserNameList",
      title: "User Name",
      dataIndex: 0,
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "accessType",
      title: "Access Type",
      dataIndex: 1,
      render: (record) => {
        return (
          <Typography.Text>
            {record === "OWNER"
              ? "Owner"
              : record === "VIEWER"
              ? "Viewer"
              : record === "EDITOR"
              ? "Editor"
              : record}
          </Typography.Text>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        if (record[1] === "OWNER") {
          ownerAccess = true;
        }
        return (
          <div>
            <Divider type="vertical"></Divider>
            {!ownerAccess ? (
              <DeleteOutlined
                onClick={() => {
                  liftUserAccess(record, documentId);
                }}
                style={{ color: "red", marginLeft: 10 }}
              />
            ) : (
              (ownerAccess = false)
            )}
          </div>
        );
      },
    },
  ];

  var organizationAccess = false;
  const authorizedOrgColumns = [
    {
      key: "authorizedOrgNameList",
      title: " Organization Name",
      dataIndex: 0,
      render: (record) => {
        return (
          <DisplayOrganization
            orgid={record}
            place="table"
          ></DisplayOrganization>
        );
      },
    },
    {
      key: "accessType",
      title: "Access Type",
      dataIndex: 1,
      render: (record) => {
        return (
          <Typography.Text>
            {record === "OWNER"
              ? "Owner"
              : record === "VIEWER"
              ? "Viewer"
              : record === "EDITOR"
              ? "Editor"
              : record}
          </Typography.Text>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        if (record[1] === "OWNER") {
          organizationAccess = true;
        }
        return (
          <div>
            <Divider type="vertical"></Divider>
            {!organizationAccess ? (
              <DeleteOutlined
                onClick={() => {
                  liftOrgAccess(record, documentId);
                }}
                style={{ color: "red", marginLeft: 10 }}
              />
            ) : (
              (organizationAccess = false)
            )}
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();
  const [selectedRadio, setSelectedRadio] = useState(null);
  const handleRadioButtonChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleGrantAccess = () => {
    let accessLevelMessage = "";
  
    if (selectedRadio === "PUBLIC") {
      accessLevelMessage = "Public";
    } else if (selectedRadio === "PRIVATE") {
      accessLevelMessage = "Private access level";
    }
  
    message.success(`Grant access updated successfully to ${accessLevelMessage}`);
  
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onGrantAccess({ ...values, documentvisibility: selectedRadio });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  

  return (
    <Modal
      width={700}
      title="Manage Access"
      open={isModalOpen}
      okText="Grant Access"
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      onOk={handleGrantAccess}
    >
      <Card style={{ height: "40%", borderRadius: 8, flexGrow: 1 }}>
        <Typography.Title level={5}>Document Access</Typography.Title>
        <Radio.Group
          onChange={handleRadioButtonChange}
          value={selectedRadio}
        >
          <Radio value="PRIVATE">Private</Radio> <br></br>
          <Radio value="PUBLIC">Public</Radio>
        </Radio.Group>
      </Card>

      <br></br>

      <Card
        style={{ height: "40%", borderRadius: 8, flexGrow: 1 }}
        disabled={selectedRadio === "PUBLIC"}
      >
        <Typography.Title level={5}>Grant Access</Typography.Title>
        <Form layout="vertical" form={form} name="Grant/Lift Access">
          <Form.Item
            name="accesslevel"
            label="Access Level"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select the Access Level"
              onChange={handleChange}
              options={[
                { value: "userlevel", label: "User" },
                { value: "orglevel", label: "Organization" },
              ]}
              disabled={selectedRadio === "PUBLIC" || documentVisibilityPublic === "PUBLIC"}
            />
          </Form.Item>

          {dropdownValue && (
            <Form.Item
              name="accesslevellist"
              label={
                dropdownValue === "userlevel"
                  ? "User"
                  : dropdownValue === "orglevel"
                  ? "Organization"
                  : " -- "
              }
              rules={[
                {
                  required: true,
                  message: "Please select the User/Organization",
                },
              ]}
              disabled={selectedRadio === "PUBLIC"}
            >
              {dropdownValue === "userlevel" ? (
                <Select
                  style={{ width: "100%" }}
                  placeholder="Please select the User"
                  disabled={selectedRadio === "PUBLIC"}
                >
                  {userOptions}
                </Select>
              ) : (
                <Select
                  style={{ width: "100%" }}
                  placeholder="Please select the Organization"
                  disabled={selectedRadio === "PUBLIC"}
                >
                  {orgDataOptions}
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item name="accesstype" label="Access Type" disabled>
            <Input defaultValue="Viewer" disabled />
          </Form.Item>
        </Form>
      </Card>

      <br />

      <br></br>
      <Card style={{ height: "40%", borderRadius: 8, flexGrow: 1 }}>
        <Typography.Title level={5}>Authorized Users</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={authorizedUserColumns}
          dataSource={selectedRadio !== "PUBLIC" ? userDataList : userDataList.filter((auth)=> auth.length > 1 && auth[1] === "OWNER")}
        ></Table>
        <br></br>
        <Typography.Title level={5}>Authorized Organizations</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={authorizedOrgColumns}
          dataSource={selectedRadio !== "PUBLIC" ? orgDataList : []}
        ></Table>
      </Card>
    </Modal>
  );
};

export default DocumentAccess;
