import React from "react";
import { Col, Row, Typography } from "antd";

import { useGetInterventionQuery } from "../../../store/is/interventionapi";

export const DisplayIntervention = ({ interventionid }) => {
    const { data, isLoading } = useGetInterventionQuery(interventionid, {
        skip: !interventionid,
      });
    if (!isLoading) {
        console.log("Intervention", data);
    }
    return (
        <div>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    <Row>
                        <Col>
                            <Typography.Text style={{ fontSize: 18 }}>
                                {data? data.data[0].name : ""}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
