import React from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { ShopOutlined } from "@ant-design/icons";

import { useGetOrgQuery } from "../../store/auth/orgapi";

export const DisplayOrganization = ({ orgid, place }) => {
  const { data, isLoading } = useGetOrgQuery(orgid);
  if (!isLoading) {
    // console.log("DisplayUser----data", data);
  }

  const displayFontSize = place === "table" ? 14 : 16

  return (
    <div>
      <Row align="middle" gutter={[8, 0]}>
        <Col>
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<ShopOutlined />}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Typography.Text style={{ fontSize: displayFontSize }}>
                {data?.name}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
