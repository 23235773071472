import { React, useState } from "react";

import { Button, Col, Divider, Form, Modal, Progress, Row, Spin, Statistic, Typography, Upload, message } from "antd";

import { InboxOutlined,CloseOutlined } from "@ant-design/icons";

import csv from "csvtojson";
import { assetApi } from "../../store/is/assetapi";
import { paramsApi } from "../../store/is/paramsapi";
import { yieldApi } from "../../store/is/yieldapi";
import store from "../../store/store";


export const AssetImport = ({
  visible,
  interventionDetails,
  onCancel,
  importlevel,
}) => {
  const [fileList, setFileList] = useState([]);
  const [fileValid, setFileValid] = useState(false);
  const [spinning, setSpinning] = useState(false);

  //Counters
  const [totalCount, setTotalCount] = useState(0);

  const [successFetchCount, setSuccessFetchCount] = useState(0);

  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [failedRecords, setFailedRecords] = useState([]);

  const key = "updatable";

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      if (fileList.length >= 1) {
        message.error(
          "You can only upload one file!, Please remove the existing file to upload a diffrent file."
        );
        file.status = "error";
        return false;
      }
      return true;
    },
    fileList,
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const convertCsvToJson = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const csvData = event.target.result;
        csv({
          encoding: "utf-8",
        })
          .fromString(csvData)
          .then((jsonArray) => {
            resolve(jsonArray);
          })
          .catch((error) => {
            reject(error);
          });
      };
      fileReader.readAsText(file);
    });
  };

  function uploadFile({ file, onSuccess }) {
    if (!file || !file.name.endsWith(".csv")) {
      message.error("Invalid file format. Please upload a CSV file.");
      setFileValid(false);
      return;
    }
    form.validateFields().then((values) => {
      onSuccess("ok");
      setFileValid(true);
    });
  }

  const addAssetParam = async (record) => {
    return await store.dispatch(
      paramsApi.endpoints.addParams.initiate(record)
    );
  };

  const addAssetsBatch = async (record) => {
    return await store.dispatch(
      assetApi.endpoints.addAssetsBatch.initiate(record)
    );
  };
  
  const updateAssetsBatch = async (record) => {

    return await store.dispatch(
      assetApi.endpoints.updateAssetBatch.initiate(record)
    );
  };

  const updateYieldsBatch = async (record) => {

    return await store.dispatch(
      yieldApi.endpoints.updateYieldBatch.initiate(record)
    );
  };


  const addYieldsBatch = async (record) => {
    return await store.dispatch(
      yieldApi.endpoints.addYieldsBatch.initiate(record)
    );
  };

  const fetchAssetDetails = async (intervention_id) => {
    return await store.dispatch(
      assetApi.endpoints.getAssetsByParam.initiate({
        // external_asset_id: external_asset_id,
        intervention_id: intervention_id
      })
    );
  };

  const fetchYieldDetails = async (asset_id, external_asset_id) => {
    return await store.dispatch(
      yieldApi.endpoints.getYieldsByParam.initiate({
        asset_id: asset_id,
        external_asset_id: external_asset_id
      })
    );
  };

  function extractParamValues(item) {
    const paramValuesList = [];
    for (const key in item) {
      if (key.startsWith("param_")) {
        const paramKey = key.substring(6).replace(/<|>/g, ""); // Remove "param_" prefix
        const paramValue = item[key].replace(/<|>/g, "");
        paramValuesList.push({
          type: "String",
          key: paramKey,
          value: paramValue,
        });
      }
    }
    return paramValuesList;
  }

  const onImportSubmit = async (values) => {
    const importAssetDetails = await fetchAssetDetails(
      interventionDetails?._id
    );
    if (fileList.length > 0) {
      const file = fileList[0];
      if (file && file.name.endsWith(".csv")) {
        const jsonArray = await convertCsvToJson(file);
        console.log("CSV to JSON:", jsonArray);
        // Initialize an array to store failed external_asset_id values
        const failedImports = [];
        setTotalCount(jsonArray?.length);
        let successCountLocal = 0;


        if (importlevel === "Assets") {
          const assetsToCreate = [];
          const assetsToUpdate = [];
        
          for (var i = 0; i < jsonArray?.length; i++) {
            const item = jsonArray[i];
        
            try {
              if (
                item.external_asset_id &&
                item.external_asset_id !== null &&
                item.name &&
                item.name !== null
              ) {
                const existingAsset = importAssetDetails.data?.data?.find(
                  (asset) => asset.external_asset_id === item.external_asset_id
                );
        
                // Extract param 
                const paramValuesList = extractParamValues(item);
                item.intervention_id = interventionDetails?._id;
        
                // add 
                let paramsList = paramValuesList.map((paramRecord) => ({
                  key: paramRecord.key,
                  value: paramRecord.value,
                }));
                const paramResponse = await addAssetParam({ param: paramsList });
        
               
                item.param_id = paramResponse.data?.data?._id || null;
                item.co2_emission_equivalent = item?.co2_emission_equivalent === "" ? null : item?.co2_emission_equivalent;
                item.equivalent_calculation_method = item?.equivalent_calculation_method === "" ? null : item?.equivalent_calculation_method;
        
                if (existingAsset) {
                  assetsToUpdate.push({ _id: existingAsset._id,status: existingAsset.status, ...item });
                  setSuccessFetchCount((prevCount) => prevCount + 1);
                } else {
                  assetsToCreate.push(item);
                  setSuccessFetchCount((prevCount) => prevCount + 1);
                }
              } else {
                setFailedCount((prevCount) => prevCount + 1);
                failedImports.push(JSON.stringify(item));
              }
            } catch (error) {
              setFailedCount((prevCount) => prevCount + 1);
              failedImports.push(JSON.stringify(item));
            }
          }
        
          // Batch update
          const batchSizeUpdate = 100;
          for (let i = 0; i < assetsToUpdate.length; i += batchSizeUpdate) {
            const batchAssetsUpdate = assetsToUpdate.slice(i, i + batchSizeUpdate);
            try {
              await updateAssetsBatch(batchAssetsUpdate);
              setSuccessCount((prevCount) => prevCount + batchAssetsUpdate?.length);
              successCountLocal += batchAssetsUpdate?.length;
            } catch (updateError) {
              setFailedCount((prevCount) => prevCount + batchAssetsUpdate?.length);
              failedImports.push(JSON.stringify(batchAssetsUpdate));
            }
          }
        
          // Batch create
          const batchSizeCreate = 100;
          for (let i = 0; i < assetsToCreate.length; i += batchSizeCreate) {
            const batchAssetsCreate = assetsToCreate.slice(i, i + batchSizeCreate);
            try {
              await addAssetsBatch(batchAssetsCreate);
              setSuccessCount((prevCount) => prevCount + batchAssetsCreate?.length);
              successCountLocal += batchAssetsCreate?.length;
            } catch (createError) {
              setFailedCount((prevCount) => prevCount + batchAssetsCreate?.length);
              failedImports.push(JSON.stringify(batchAssetsCreate));
            }
          }
        }
        
        if (importlevel === "Yields") {
          const yieldsToCreate = [];
          const yieldsToUpdate = [];
        
          for (var i = 0; i < jsonArray?.length; i++) {
            const item = jsonArray[i];
        
            try {
              if (
                item?.external_asset_id !== null &&
                item?.external_asset_id !== "" &&
                item?.period !== null &&
                item?.period !== "" &&
                item?.co2_estimated !== null &&
                item?.co2_estimated !== ""
              ) {
                const existingAsset = importAssetDetails.data?.data?.find(
                  (asset) => asset.external_asset_id === item.external_asset_id
                );
                const importYieldsDetails = await fetchYieldDetails(
                  existingAsset._id, item.external_asset_id
                );
        
                item.asset_id = existingAsset?._id;
                item.co2_realized = item?.co2_realized === "" ? null : item?.co2_realized;
                item.storage_duration = item?.storage_duration === "" ? null : item?.storage_duration;
        
                if (importYieldsDetails?.data?.data?.length > 0) {
                  // Check if the period is different
                  const existingYield = importYieldsDetails.data.data.find(
                    (yields) => yields.period === item.period);
        
                  if (existingYield) {
                    if (
                      existingYield.status !== "Realized" 
                    ) {
                      if (existingYield.is_pre_credits_available == true) {
                        yieldsToUpdate.push({
                          ...item,
                          _id: existingYield._id,
                          status: existingYield.status,
                          token_name: existingYield.token_name,
                          token_id: existingYield.token_id,
                          co2_estimated: existingYield.co2_estimated,
                          is_pre_credits_available: existingYield.is_pre_credits_available
                        });
                        setSuccessFetchCount((prevCount) => prevCount + 1);
                      } else {
                        yieldsToUpdate.push({
                          ...item,
                          _id: existingYield._id,
                          status: existingYield.status,
                          token_name: existingYield.token_name,
                          token_id: existingYield.token_id,
                        });
                        setSuccessFetchCount((prevCount) => prevCount + 1);
                      }
                    } else {
                      setFailedCount((prevCount) => prevCount + 1);
                      failedImports.push(JSON.stringify(item));
                    }
                  } else {
                    yieldsToCreate.push(item);
                    setSuccessFetchCount((prevCount) => prevCount + 1);
                  }
                } else {
                  yieldsToCreate.push(item);
                  setSuccessFetchCount((prevCount) => prevCount + 1);
                }
              } else {
                console.error("Mandatory columns are missing or have null/undefined values for item:", item);
                setFailedCount((prevCount) => prevCount + 1);
                failedImports.push(JSON.stringify(item));
              }
            } catch (error) {
              console.error("Error fetching asset details:", error);
              setFailedCount((prevCount) => prevCount + 1);
              failedImports.push(JSON.stringify(item));
            }
          }
        
          // Batch update yields
          const batchSizeUpdate = 100;
          for (let i = 0; i < yieldsToUpdate.length; i += batchSizeUpdate) {
            const batchYieldsUpdate = yieldsToUpdate.slice(i, i + batchSizeUpdate);
            try {
              await updateYieldsBatch(batchYieldsUpdate);
              setSuccessCount((prevCount) => prevCount + batchYieldsUpdate?.length);
              successCountLocal += batchYieldsUpdate?.length;
            } catch (updateError) {
              setFailedCount((prevCount) => prevCount + batchYieldsUpdate?.length);
              failedImports.push(JSON.stringify(batchYieldsUpdate));
            }
          }
        
          // Batch create yields
          const batchSizeCreate = 100;
          for (let i = 0; i < yieldsToCreate.length; i += batchSizeCreate) {
            const batchYieldsCreate = yieldsToCreate.slice(i, i + batchSizeCreate);
            try {
              await addYieldsBatch(batchYieldsCreate);
              setSuccessCount((prevCount) => prevCount + batchYieldsCreate?.length);
              successCountLocal += batchYieldsCreate?.length;
            } catch (createError) {
              setFailedCount((prevCount) => prevCount + batchYieldsCreate?.length);
              failedImports.push(JSON.stringify(batchYieldsCreate));
            }
          }
        }
        
        
        setFailedRecords(failedImports);
      }
    }
  };

  const closeButtonStyle = {
    position: "absolute",
    top: 14,
    right: 10,
    cursor: "pointer", 
  };

  const [form] = Form.useForm();
  return (
    <Modal
      width={500}
      title={"Import " + importlevel}
      open={visible}
      okText={spinning ? "" : "Import"}
      onCancel={() => {
        form.resetFields();
        setTotalCount(0);
        setSuccessFetchCount(0);
        setSuccessCount(0);
        setFailedCount(0);
        setFailedRecords([]);
        setSpinning(false);
        setFileList([]);
        onCancel();
      }}
      okButtonProps={{ disabled: !fileValid || fileList.length === 0 }}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            form.resetFields();
            try {
              setSpinning(true);
              await onImportSubmit(values);
            } catch (error) {
              console.error(error);
            } finally {
              setSpinning(false);
              setFileList([]); 
              setFileValid(false); 
              message.success(`Records imported successfully`);
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      closable={false}
    >
   
       <Button style={closeButtonStyle} onClick={onCancel}>
         <CloseOutlined /> Close
        </Button>
      <Spin spinning={spinning}>
        <Form layout="vertical" form={form} name="Documenttype">
          <Form.Item label={"Select the .csv file to import " + importlevel}>
            <Form.Item
              name="doc"
              valuePropName="fileList"
              label="Select the file"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                {...props}
                name="files"
                customRequest={uploadFile}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag a file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support only a single file upload.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
        <Divider orientation="left">Import Results</Divider>
        <Row gutter={[8, 16]}>
          <Col span={24}><Typography.Text type="secondary">Fetching</Typography.Text><Progress percent={Math.round(((successCount + failedCount) / totalCount) * 100)} status={spinning >0 ? "active" : (failedCount > 0 && successFetchCount === 0) ? "exception" : "normal"} /></Col>
          <Col span={24}><Typography.Text type="secondary">Creating</Typography.Text><Progress percent={Math.round((successFetchCount / totalCount) * 100)} status={spinning > 0 ? "active" : (failedCount > 0 && successFetchCount === 0) ? "exception" : "normal"} /></Col>
          <Col span={8}><Statistic title="Total Records" value={totalCount} /></Col>
          <Col span={8}><Statistic title="Imported Records" valueStyle={{
            color: '#3f8600',
          }} value={successCount} /></Col>
          <Col span={8}> <Statistic title="Failed Records" valueStyle={{
            color: '#cf1322',
          }} value={failedCount} /></Col>
        </Row>
        {failedRecords?.length > 0 && <><Divider orientation="left">Failed Records</Divider>
          <Typography.Text code>{failedRecords}</Typography.Text></>}
      </Spin>
    </Modal>
  );
};
