import React from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import moment from 'moment';


export const DisplayDateTime = ({ date_time }) => {
    return (
        <div>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    <Row>
                        <Col>
                            <Typography.Text style={{ fontSize: 18 }}>
                                <span>{date_time ? moment.utc(date_time).local().format("DD MMM, YYYY @ hh:mm:ss") : undefined}</span>
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
